import React from 'react'
import { useSelector, useDispatch } from '../../react-redux'
import { path } from 'ramda'

import { selectAuthenticatedUser } from '../../modules/auth/selectors'
import { selectIsMobile } from '../../modules/responsive/selectors'

import { modalClose, modalOpen } from '../../modules/modal/actions'

import AuthorizationModal from '../../routes/Verification/components/AuthorizationModal'
import ResourceLink from '../resource-link'
import TextButton from '../buttons/text'

type Props = {
  children?: React.ReactNode
  className?: string
  textContent?: string
  to?: any
  resourceContext?: any
  resource_id?: string
  resource_type?: string
  resource_title?: string
  modalId?: string
  isLearning?: boolean
}

/**
 * Component which checks the user authorization (verification_status)
 * Note that the check is just done for mobile users.
 * For desktop, the user is redirected to the page with ResourceLink and
 * the authorization modal will pop up after few seconds.
 */
const AuthorizationResourceLink = ({
  children,
  textContent,
  className,
  to,
  resourceContext,
  resource_id,
  resource_type,
  resource_title,
  modalId,
  isLearning
}: Props) => {
  const dispatch = useDispatch()

  const isMobile = useSelector(selectIsMobile)
  const currentUser = useSelector(selectAuthenticatedUser)
  const isAuthenticated = !!currentUser
  const verified = path(['verification_status'], currentUser) === 'verified'

  const content = children || textContent

  if (!content) {
    return null
  }

  const onNonVerifiedUserLinkClicked = (): any => {
    dispatch(modalOpen({ component: AuthorizationModal, size: 'fullscreen' }))
  }

  if (isMobile && isAuthenticated && !verified) {
    return (
      <TextButton className={className} onClick={onNonVerifiedUserLinkClicked}>
        {content}
      </TextButton>
    )
  }

  return (
    <div onClick={() => dispatch(modalClose(modalId))}>
      <ResourceLink
        className={className}
        to={to}
        resourceContext={resourceContext}
        resource_id={resource_id}
        resource_type={resource_type}
        resource_title={resource_title}
        isLearning={isLearning}
      >
        {content}
      </ResourceLink>
    </div>
  )
}

export default AuthorizationResourceLink
