import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createResponsiveStateReducer } from 'redux-responsive'

import api from './modules/api/reducer'
import activity from './modules/activity/reducer'
import auth from './modules/auth/reducer'
import categories from './modules/categories/reducer'
import {
  reduceNormalizedEntities,
  globalEntitiesReducer,
} from './modules/entities/reducer'
import features from './modules/features/reducer'
import filters from './modules/filters/reducer'
import modal from './modules/modal/reducer'
import notify from './modules/notify/reducer'
import search from './modules/search/reducer'
import VerificationReducer from './routes/Verification/reducer'
import AvatarUploadReducer from './routes/components/promowidget/reducer'
import router from './modules/routing/reducer'
import team from './components/Team/redux/reducer'
import likes from './modules/likes/reducer'
import follows from './modules/follows/reducer'
import replies from './modules/replyComment/reducer'
import branchReducer from './modules/branch/reducer'
import layout from './modules/layout/reducer'
import temp from './modules/temp/reducer'
import { globalReducer } from './globalReducer'

const combinedReducers = combineReducers({
  api,
  auth,
  activity,
  categories,
  entities: reduceNormalizedEntities,
  comments: combineReducers({ likes, replies }),
  case_page: combineReducers({ follows }),
  features,
  filters,
  form: formReducer,
  modal,
  notify,
  router,
  search,
  team,
  verification: VerificationReducer,
  widget: AvatarUploadReducer,
  layout,
  browser: createResponsiveStateReducer({
    // These are calculated with (16 * (min-width of next breakpoint))
    // from breakpoints in css/_settings.responsive.scss
    palm: 784,
    lap: 1023,
    desk: 1000000,
    //desk: 1600,
    //large_desk: 1000000,
  }),
  temp,
})

export type RootState = ReturnType<ReturnType<typeof combineReducers>>

const rootReducer = () => {
  return function rootReducer(state, action) {
    state = combinedReducers(state, action)
    state = globalEntitiesReducer(state, action)
    state = branchReducer(state, action)

    state = globalReducer(state, action)

    return state
  }
}

export default rootReducer
