import React, { useEffect, useState } from 'react'
import { ArrowIcons } from './icons'

const styles = {
  button: {
    position: 'fixed',
    minWidth: '2.5em',
    minHeight: '2.5em',
    bottom: '2rem',
    right: '1em',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '1.4em',
    backgroundColor: '#4b9bef',
    boxShadow: '0.2em 0.4em 0.6em rgb(0 0 0 / 40%)',
    opacity: '1',
    transition: 'box-shadow .2s, bottom .5s, opacity .5s',
    cursor: 'pointer',
    zIndex: 1000,
  } as React.CSSProperties,
  icon: {
    color: 'white',
    alignSelf: 'center',
  },
}

export const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const toggleVisibility = () =>
      window.scrollY > 500 ? setIsVisible(true) : setIsVisible(false)

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return isVisible &&
    typeof window !== 'undefined' &&
    !/\/cases\/[0-9]+/.test(window.location.pathname) ? (
    <div style={styles.button} onClick={scrollToTop} className="back-to-top">
      <ArrowIcons
        position="up"
        title="Back To Top"
        width={26}
        style={styles.icon}
      />
    </div>
  ) : null
}
