import React, { Component } from 'react'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import { UnOrderedList, BasicListItem } from '../Lists'
import { ImgOverlayWrapper, Image } from '../Img'

// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/ImageInputPreview.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  replaceText: string
  onChange: Function
  type: string
  size: string
  documentUrl: string
}
type StateType = {
  file: string
  previewUrl: string | ArrayBuffer
}

class ImageInputPreview extends Component<PropsType> {
  state = {
    file: '',
    previewUrl: '',
  }

  onChange = (e: any): any => {
    e.preventDefault()
    const { onChange } = this.props
    const reader = new FileReader()
    const file = e.target.files[0]

    if (file) {
      reader.onloadend = () => {
        this.setState({ file: file, previewUrl: reader.result }, () => {
          // on iPhone5, the preview is not re-rendered so we force to update it
          setTimeout((): any => this.forceUpdate(), 1000)
          this.setState({ file: file, previewUrl: reader.result })

          if (onChange) {
            onChange(file)
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  render(): React.ReactElement {
    const { replaceText, documentUrl, size, type }: PropsType = this.props
    const rejected = 'document_rejected'
    const { previewUrl } = this.state
    const URL: string = documentUrl && documentUrl.replace('{image_size}', size)
    const previewImg = previewUrl ? previewUrl : URL && type !== rejected && URL

    let labelStyle = {}

    if (previewImg) {
      labelStyle = { backgroundImage: `url('${previewImg}')` }
    }

    return (
      <UnOrderedList nospace>
        {type === rejected && (
          <BasicListItem>
            <ImgOverlayWrapper
              size="thumb-medium"
              alt="Rejected Document"
              inlineStyle={{ width: 80, height: 80 }}
              hover
              text="Not approved"
              url={URL}
            />
          </BasicListItem>
        )}

        <BasicListItem>
          <div className={cs(style.container, { [style.withImg]: previewUrl })}>
            <label htmlFor="image" className={cs(style.labelWrapper)}>
              {(previewUrl || URL) && (
                <div
                  className={cs(style.labelWrapper, style.withImage)}
                  style={labelStyle}
                />
              )}
              {(!(previewUrl || URL) || (type === rejected && !previewUrl)) && (
                <Image
                  className={'image-browser__thumb'}
                  url={'/static/media/images/add_image.png'}
                  size="case-thumb"
                />
              )}
              {(previewUrl || URL) &&
                (type !== rejected || previewUrl) &&
                replaceText && (
                  <div className={style.replace}>
                    <span>{replaceText}</span>
                  </div>
                )}
              <div className={style.tapArea} />
            </label>
            <input
              className={style.input}
              id="image"
              type="file"
              name="image"
              accept="image/png,image/x-png,image/gif,image/jpeg"
              onChange={this.onChange}
            />
          </div>
        </BasicListItem>
      </UnOrderedList>
    )
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ImageInputPreview
