// /////////////////////////////////////////////////////////// CONSTANTS

// //////////////////////////////////////////////////////////// GET TEAM
export const GET_MEMBER_LIST_REQUEST: string = 'team/GET_MEMBER_LIST_REQUEST'
export const GET_MEMBER_LIST_SUCCESS: string = 'team/GET_MEMBER_LIST_SUCCESS'
export const GET_MEMBER_LIST_FAILURE: string = 'team/GET_MEMBER_LIST_FAILURE'

// /////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////// ACTIONS CREATORS

// //////////////////////////////////////////////////// GET LIST BY NAME
export function getTeamListRequest(teamName: string): any {
  return { type: GET_MEMBER_LIST_REQUEST, teamName }
}
export function getTeamListSuccess(teamName: string, payload: any): any {
  return { type: GET_MEMBER_LIST_SUCCESS, teamName, payload }
}
export function getTeamListFailed(error: string): any {
  return { type: GET_MEMBER_LIST_FAILURE, error }
}
