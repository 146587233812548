import get from 'lodash/get'

const reducer = (state = {}, action) => {
  const filters = action.payload
    ? get(action.payload, ['response', 'filters'])
    : undefined
  if (filters) {
    return filters
  }

  return state
}

export default reducer
