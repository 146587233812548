import { createSelector } from 'reselect'
import { find } from 'ramda'

import * as roles from '../categories/roles'
import * as specialties from '../categories/specialties'

export function selectCategories(state) {
  return state.categories
}

export const selectSpecialtyMapper = createSelector(
  [selectCategories],
  categories =>
    categories.specialty_tree
      ? new specialties.SpecialtyMapper(
          categories.specialties,
          categories.specialty_tree,
          categories.ambiguous_terms
        )
      : undefined
)

export const selectRoleOptions = createSelector(
  [selectCategories],
  categories => roles.getRoleOptions(categories)
)

export const selectSignupRoleOptions = createSelector(
  [selectCategories],
  categories => roles.getSignupRoleOptions(categories)
)

export const selectSeniorityOptionsByRole = createSelector(
  [selectCategories],
  categories => roles.getSeniorityOptionsByRole(categories)
)

export const selectSeniorityGroupOptions = createSelector(
  [selectCategories],
  categories => roles.getSeniorityGroupOptions(categories)
)

export const selectSeniorityGroupLabels = createSelector(
  [selectCategories, (state, ids) => ids],
  (categories, ids) => roles.getSeniorityGroupLabels(categories, ids)
)

export const selectCategoryLoadError = state => {
  return state.categories.error
}

export const selectProfessionalIDLabel = (state, type) =>
  (
    find(x => x.id === type, state.categories.professional_id_types) ||
    find(x => x.id === '', state.categories.professional_id_types)
  ).label
