import { schema } from 'normalizr'

import * as types from './types'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const userSchema = new schema.Entity(types.USER)
export const caseSchema = new schema.Entity(types.CASE)
export const groupSchema = new schema.Entity(types.GROUP)
export const institutionSchema = new schema.Entity(types.INSTITUTION)
export const caseEntrySchema = new schema.Entity(types.ENTRY_CASE)
export const groupPermissionSchema = new schema.Entity(types.GROUP_PERMISSIONS)

export const pollSchema = new schema.Entity(types.POLL)

export const learningSeriesSchema = new schema.Entity(types.LEARNING_SERIES)
export const learningModuleSchema = new schema.Entity(types.LEARNING_MODULE)
export const docsearchSavedSearchSchema = new schema.Entity(
  types.DOCSEARCH_SAVED_SEARCH
)
export const adCardSchema = new schema.Entity(types.AD_CARD)

export const Feeds = {
  [types.FEED_MAIN_UPDATES]: {
    url: '/api/profile/feeds/updates',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MAIN_UPDATES,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_CONNECTION_REQUESTS]: {
    url: '/api/profile/feeds/connection-requests',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_CONNECTION_REQUESTS,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_USER_CONNECTION_REQUESTS]: {
    url: '/api/profile/feeds/connection-requests/user',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_USER_CONNECTION_REQUESTS,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_GROUP_CONNECTION_REQUESTS]: {
    url: '/api/profile/feeds/connection-requests/groups-and-institutions',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_CONNECTION_REQUESTS,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_GROUPS_ADMIN]: {
    url: '/api/profile/feeds/groups-and-institutions/admin',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUPS_ADMIN,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_GROUPS_ACTIVITY]: {
    url: '/api/profile/feeds/groups-and-institutions/updates',
    schema: () =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUPS_ACTIVITY,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_MAIN_CONTENT]: {
    url: '/api/profile/feeds/content?separate_banners=1',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MAIN_CONTENT,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_NEWEST_CONTENT]: {
    url: '/api/profile/feeds/content/latest?separate_banners=1',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_NEWEST_CONTENT,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_MY_CONTENT]: {
    url: '/api/profile/feeds/content?separate_banners=1',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_CONTENT,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_SIMILAR_CASES]: {
    url: '/api/profile/feeds/case/{id}/similar',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_SIMILAR_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_MY_CASES]: {
    url: '/api/profile/feeds/my-cases',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_CASES,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_USER_CASES]: {
    url: '/api/profile/feeds/user/{id}/cases',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_USER_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_USER_GROUPS]: {
    url: '/api/profile/feeds/user/{id}/groups-and-institutions',
    needID: true,
    schema: id =>
      groupsFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_USER_GROUPS + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_GROUP,
  },
  [types.FEED_USER_SUGGESTED_GROUPS]: {
    url: '/api/profile/feeds/groups-and-institutions/suggest',
    schema: () =>
      groupsFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_USER_SUGGESTED_GROUPS,
          })
        )
      ),
    entry_type: types.ENTRY_GROUP,
  },
  [types.FEED_GROUP_CASES]: {
    url: '/api/profile/feeds/group/{id}/cases',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_GROUP_FEATURED_CASES]: {
    url: '/api/groups/{id}/featured/cases',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_FEATURED_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_GROUP_FEATURED_LEARNING]: {
    url: '/api/groups/{id}/featured/learning',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_FEATURED_LEARNING + '_' + id,
          })
        )
      ),
    entry_type: types.LEARNING_SERIES,
  },
  [types.FEED_GROUP_CASES_FOR_FEATURING]: {
    url: '/api/profile/feeds/group/{id}/cases?for-feature=1',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_CASES_FOR_FEATURING + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_GROUP_ADMIN]: {
    url: '/api/profile/feeds/group/{id}/admin',
    needID: true,
    schema: id =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_ADMIN + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_GROUP_ACTIVITY]: {
    url: '/api/profile/feeds/group/{id}/updates?include=-admin',
    needID: true,
    schema: id =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_GROUP_ACTIVITY + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_INSTITUTION_CASES]: {
    url: '/api/profile/feeds/institution/{id}/cases',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_INSTITUTION_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_INSTITUTION_FEATURED_CASES]: {
    url: '/api/institutions/{id}/featured/cases',
    needID: true,
    schema: id =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_INSTITUTION_FEATURED_CASES + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_INSTITUTION_ADMIN]: {
    url: '/api/profile/feeds/institution/{id}/admin',
    needID: true,
    schema: id =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_INSTITUTION_ADMIN + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_INSTITUTION_ACTIVITY]: {
    url: '/api/profile/feeds/institution/{id}/updates?include=-admin',
    needID: true,
    schema: id =>
      updatesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_INSTITUTION_ACTIVITY + '_' + id,
          })
        )
      ),
    entry_type: types.ENTRY_UPDATE,
  },
  [types.FEED_SEARCH_RESULTS]: {
    url: '/api/search',
    schema: () =>
      searchResults(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_SEARCH_RESULTS,
            processStrategy: (value, parent, key) => {
              const { results, ...rest } = value
              return { ...rest, entries: results }
            },
          })
        )
      ),
    entry_type: types.ENTRY_SEARCH_RESULT,
  },
  [types.FEED_GROUP_MEMBER_SEARCH_RESULTS]: {
    url: '/api/profile/feeds/{type}/{id}/member-search',
    needID: true,
    schema: id =>
      groupMemberSearchResults(
        id,
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () =>
              types.FEED_GROUP_MEMBER_SEARCH_RESULTS + '_' + id,
            processStrategy: (value, parent, key) => {
              const { results, ...rest } = value
              return { ...rest, entries: results }
            },
          })
        )
      ),
    entry_type: types.ENTRY_GROUP_MEMBER,
  },
  [types.FEED_MY_DRAFT_CASES]: {
    url: '/api/profile/feeds/my-cases/drafts',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_DRAFT_CASES,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_MY_PUBLISHED_CASES]: {
    url: '/api/profile/feeds/my-cases/published',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_PUBLISHED_CASES,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_MY_FOLLOWING_CASES]: {
    url: '/api/profile/feeds/my-cases/following?exclude=own',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_FOLLOWING_CASES,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_MY_CASES_WITH_UNREAD_COMMENTS]: {
    url: '/api/profile/feeds/my-cases/unread-comments',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_MY_CASES_WITH_UNREAD_COMMENTS,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
  [types.FEED_DISCUSSION]: {
    url: '/api/profile/feeds/commented',
    schema: () =>
      casesFeed(
        feedCommon(
          new schema.Entity('feed', undefined, {
            idAttribute: () => types.FEED_DISCUSSION,
          })
        )
      ),
    entry_type: types.ENTRY_CASE,
  },
}

function feedCommon(s) {
  s.define({
    users: new schema.Values(userSchema),
    banners: [getCaseEntrySchema()],
  })
  return s
}

function casesFeed(s) {
  s.define({
    entries: [
      new schema.Union(
        {
          [types.ENTRY_CASE]: getCaseEntrySchema(),
          [types.ENTRY_AD_CARD]: getAdCardEntrySchema(),
          [types.ENTRY_LEARNING_SERIES]: getLearningSeriesEntrySchema(),
          [types.ENTRY_LEARNING_MODULE]: getLearningModuleEntrySchema(),
        },
        entry => {
          return types.getEntryTypeForEntityType(entry.target.type)
        }
      ),
    ],
  })
  return s
}

function mixedFeed(s) {
  s.define({
    entries: [
      new schema.Union(
        {
          [types.ENTRY_CASE]: getCaseEntrySchema(),
          [types.ENTRY_USER]: getUserEntrySchema(),
          [types.ENTRY_LEARNING_SERIES]: getLearningSeriesEntrySchema(),
          [types.ENTRY_LEARNING_MODULE]: getLearningModuleEntrySchema(),
          [types.ENTRY_DOCSEARCH_SAVED_SEARCH]:
            getDocsearchSavedSearchEntrySchema(),
          [types.ENTRY_GROUP]: getGroupEntrySchema(types.GROUP),
          [types.ENTRY_INSTITUTION]: getGroupEntrySchema(types.INSTITUTION),
        },
        entry => {
          return types.getEntryTypeForEntityType(entry.target.type)
        }
      ),
    ],
  })
  return s
}

function updatesFeed(s) {
  s.define({
    entries: [getUpdateEntrySchema()],
  })
  return s
}

export function searchResults(s) {
  s.define({
    entries: [
      new schema.Union(
        {
          [types.ENTRY_LEARNING_SERIES]: getLearningSeriesEntrySchema(),
          [types.ENTRY_LEARNING_MODULE]: getLearningModuleEntrySchema(),
          [types.ENTRY_AD_CARD]: getAdCardEntrySchema(),
          [types.ENTRY_CASE]: getCaseEntrySchema(),
          [types.ENTRY_USER]: getUserEntrySchema(),
          [types.ENTRY_GROUP]: getGroupEntrySchema(types.GROUP),
          [types.ENTRY_INSTITUTION]: getGroupEntrySchema(types.INSTITUTION),
        },
        entry => {
          return types.getEntryTypeForEntityType(entry.target.type)
        }
      ),
    ],
  })
  return s
}

function groupMemberSearchResults(id, s) {
  s.define({
    entries: [getGroupMemberEntrySchema(id)],
  })
  return s
}

function groupsFeed(s) {
  s.define({
    entries: [
      new schema.Union(
        {
          [types.ENTRY_GROUP]: getGroupEntrySchema(types.GROUP),
          [types.ENTRY_INSTITUTION]: getGroupEntrySchema(types.INSTITUTION),
        },
        entry => {
          return types.getEntryTypeForEntityType(entry.target.type)
        }
      ),
    ],
  })
  return s
}

export function getFeedSchemaByURL(url) {
  return mixedFeed(
    feedCommon(
      new schema.Entity('feed', undefined, {
        idAttribute: () => url,
      })
    )
  )
}

export function getFeedSchema(type, id?) {
  return Feeds[type].schema(id)
}

export function getGroupMemberEntrySchema(id) {
  const userEntrySchema = new schema.Entity(
    types.ENTRY_GROUP_MEMBER,
    undefined,
    {
      idAttribute: entry => {
        return id + ':' + entry.target.id
      },
    }
  )
  userEntrySchema.define({
    target: userSchema,
  })
  return userEntrySchema
}

export function getUserEntrySchema() {
  const userEntrySchema = new schema.Entity(types.ENTRY_USER, undefined, {
    idAttribute: entry => entry.target.id,
  })
  userEntrySchema.define({
    target: userSchema,
    users: [userSchema],
  })
  return userEntrySchema
}

export function getUpdateEntrySchema() {
  const updateEntrySchema = new schema.Entity(types.ENTRY_UPDATE, undefined, {
    idAttribute: entry => {
      return entry.id
      console.log('entry', entry)
      return (
        entry.verb +
        ':' +
        (entry.actor || '') +
        ':' +
        entry.ts +
        ':' +
        entry.resource.target
      )
    },
  })
  updateEntrySchema.define({
    resource: new schema.Union(
      {
        [types.ENTRY_CASE]: getCaseEntrySchema(),
        [types.ENTRY_USER]: getUserEntrySchema(),
        [types.ENTRY_GROUP]: getGroupEntrySchema(types.GROUP),
        [types.ENTRY_INSTITUTION]: getGroupEntrySchema(types.INSTITUTION),
        [types.ENTRY_PINNED_MESSAGE]: getPinnedMessageEntrySchema(
          types.PINNED_MESSAGE
        ),
      },
      entry => {
        return types.getEntryTypeForEntityType(entry.target.type)
      }
    ),
  })
  return updateEntrySchema
}

export function getCaseEntrySchema() {
  const caseEntrySchema = new schema.Entity(types.ENTRY_CASE, undefined, {
    idAttribute: entry => entry.target.id,
    processStrategy: (value, parent, key) => {
      if (key === 'entries') {
        const { users, ...rest } = value
        return { ...rest, interested_users: users }
      }
      return { ...value }
    },
  })
  caseEntrySchema.define({
    target: caseSchema,
    users: [userSchema],
  })
  return caseEntrySchema
}

export function getLearningSeriesEntrySchema() {
  const entrySchema = new schema.Entity(
    types.ENTRY_LEARNING_SERIES,
    undefined,
    {
      idAttribute: entry => entry.target.id,
    }
  )
  entrySchema.define({
    target: learningSeriesSchema,
  })
  return entrySchema
}

export function getLearningModuleEntrySchema() {
  const entrySchema = new schema.Entity(
    types.ENTRY_LEARNING_MODULE,
    undefined,
    {
      idAttribute: entry => entry.target.id,
    }
  )
  entrySchema.define({
    target: learningModuleSchema,
  })
  return entrySchema
}

export function getDocsearchSavedSearchEntrySchema() {
  const entrySchema = new schema.Entity(
    types.ENTRY_DOCSEARCH_SAVED_SEARCH,
    undefined,
    {
      idAttribute: entry => entry.target.id,
    }
  )
  entrySchema.define({
    target: docsearchSavedSearchSchema,
  })
  return entrySchema
}

export function getAdCardEntrySchema() {
  const entrySchema = new schema.Entity(types.ENTRY_AD_CARD, undefined, {
    idAttribute: entry => entry.target.id,
  })
  entrySchema.define({
    target: adCardSchema,
  })
  return entrySchema
}

export function getCaseLoadSchema() {
  const caseEntrySchema = new schema.Entity(types.ENTRY_CASE, undefined, {
    idAttribute: entry => (entry.target.id ? entry.target.id : undefined),
    processStrategy: (value, parent, key) => {
      return { ...value, fullyLoaded: true }
    },
  })
  caseEntrySchema.define({
    target: caseSchema,
    pre_poll: caseSchema,
    users: new schema.Values(userSchema),
  })
  return caseEntrySchema
}

export function getGroupEntrySchema(type: string) {
  const groupEntrySchema = new schema.Entity(
    type === types.GROUP ? types.ENTRY_GROUP : types.ENTRY_INSTITUTION,
    undefined,
    {
      idAttribute: entry => entry.target.id,
    }
  )
  groupEntrySchema.define({
    target: type === types.GROUP ? groupSchema : institutionSchema,
    users: [userSchema],
    // cases array is returned in group update feed entry
    cases: [getCaseEntrySchema()],
  })
  return groupEntrySchema
}

export function getPinnedMessageEntrySchema(type: string) {
  const groupEntrySchema = new schema.Entity(
    types.ENTRY_PINNED_MESSAGE,
    undefined,
    { idAttribute: entry => entry.target.id }
  )
  groupEntrySchema.define({
    target: getPinnedMessageSchema(),
  })
  return groupEntrySchema
}

export function getPinnedMessageSchema() {
  return new schema.Entity(types.PINNED_MESSAGE)
}

caseSchema.define({
  groups: [groupSchema],
  poll: pollSchema,
})

userSchema.define({
  connections: [userSchema],
  groups: [groupSchema],
  groups_requested: [groupSchema],
  groups_invited: [groupSchema],
  institutions: [institutionSchema],
  institutions_requested: [institutionSchema],
  institutions_invited: [institutionSchema],
  institutions_following: [institutionSchema],
})
