// /////////////////////////////////////////////////////////////////////// TYPES
export const API_CALL: string = 'api.CALL'
export const API_REQUEST: string = 'api.REQUEST'
export const API_SUCCESS: string = 'api.SUCCESS'
export const API_FAILURE: string = 'api.FAILURE'
export const API_GC_ASYNC: string = 'api.GC_ASYNC'
export const API_GC: string = 'api.GC'
export const API_FORGET: string = 'api.FORGET'

// ///////////////////////////////////////////////////////////////////// ACTIONS
export function request(action: any): any {
  return {
    ...action,
    type: API_CALL,
    id: action.id || (action.method || 'GET') + ' ' + action.url,
  }
}

export function forgetRequest(request: any): any {
  const id = typeof request === 'object' ? request.id : request

  return {
    type: API_FORGET,
    id,
  }
}
