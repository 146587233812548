export function selectByScreenSize(state: any, options: any): any {
  const screenSize = state.browser.mediaType

  if (options[screenSize] === undefined) {
    return options['_']
  }

  return options[screenSize]
}

export function selectMediaType(state: any): any {
  return state.browser.mediaType
}

export function selectIsMobile(state: any): boolean {
  return state.browser.mediaType === 'palm'
}

export function selectIsTablet(state: any): boolean {
  return state.browser.mediaType === 'lap'
}

export function selectIsDesktop(state: any): boolean {
  return state.browser.mediaType === 'desk'
}
