import cs from 'classnames'
import { TFunction, withTranslation } from 'next-i18next'
import { equals, isEmpty } from 'ramda'
import React from 'react'
import { Field, initialize, reduxForm } from 'redux-form'

import { validateEmail } from '../../../lib/helpers'
import loadAccount from '../../../modules/auth/actions/account_load'
import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { hasEmail } from '../../../modules/entities/user'
import { selectIsMobile } from '../../../modules/responsive/selectors'
import { connect } from '../../../react-redux'
import { Button } from '../../../ui/buttons'
import { InputField } from '../../../ui/forms'
import { Field as FormField } from '../../../ui/forms/base'
import { SpinnerIcon } from '../../../ui/icons'
import DeepInfoText from '../../../ui/Typo/DeepInfoText'
import { postConfirmEmailRequest, resetInitialEmail } from '../actions'
import style from './styles/VerifyForm.module.scss'

type Props = {
  title: string
  subTitle?: string
  untrustedEmails: boolean
  deepnote: boolean
  emailConfirmed?: boolean
  verificationPage?: boolean
}

type InternalProps = Props & {
  t: TFunction
  forms: any
  initialValues: any
  fields: any
  valid: boolean
  pristine: boolean
  onComplete: Function
  handleSubmit: Function
  loadAccount: Function
  checkTrustedEmailRequest: Function
  rebuildForm: Function
  reset: Function
  resetInitialEmail: Function
  resetInput: Function
  textInlineStyle: any
  inlineInputStyle: any
  checkTrustedDomainRequest: Function
  postConfirmEmailRequest: Function
  emailSending: boolean
  emailSent: boolean
  isMobile: boolean
  userHasPhone: boolean
  userKind: string
  inputValue: string
  initialEmail: string
  promoType: string
  emailValue: string
  userRegisteredCountry: string
  verification: any
  hasEmailExist: boolean
}

type State = {
  isChanged: boolean
  newSession: boolean
  formData: string | Object
}

const formName = 'verify-email'

class VerifyForm extends React.Component<InternalProps, State> {
  static defaultProps = {
    untrustedEmails: false,
    deepnote: false,
  }

  state = {
    isChanged: false,
    newSession: true,
    formData: 'notSet',
  }

  componentDidUpdate(prevProps: InternalProps) {
    const emailValue = this.props.emailValue
    const prevEmailValue = prevProps.emailValue
    if (emailValue !== prevEmailValue) {
      this.setState({ isChanged: true })
    }

    const emailSent = this.props.emailSending
    const prevEmailSent = prevProps.emailSending
    if (emailSent !== prevEmailSent) {
      this.setState({ isChanged: false })
    }

    // for verification page when user enter first email to the system
    const initialEmail = this.props.initialEmail
    const prevInitialEmail = prevProps.initialEmail

    if (initialEmail.length > 0 && prevInitialEmail.length === 0) {
      this.props.loadAccount()
    }

    if (
      equals(this.props.forms, prevProps.forms) &&
      !isEmpty(this.props.forms) &&
      !equals(this.props.forms, this.state.formData)
    ) {
      this.setState({ formData: this.props.forms })
    }

    // if the form state will destroy, use the local state and reinitialize the form data
    if (this.state.formData !== 'notSet' && isEmpty(this.props.forms)) {
      this.props.rebuildForm({
        email: this.state.formData[formName].values.email,
      })

      this.setState({ formData: 'notSet' })
    }
  }

  handleVerifyEmail = ({ email }: any): void => {
    const { hasEmailExist } = this.props
    const args = hasEmailExist ? 'proemail' : 'email'

    this.setState({ newSession: false })
    this.props.postConfirmEmailRequest(email, args)
  }

  handleResetForm = (): void => {
    this.props.resetInput()
    this.props.reset()
  }

  getDeepNoteText(): any {
    const { deepnote, userKind, userRegisteredCountry, t } = this.props
    let result: string | null = null

    if (deepnote) {
      //deepnote
      if (userKind === 'student') {
        result = t('common:verify_form_text.deepNote.student')
      } else result = t('common:verify_form_text.deepNote')

      //examples
      if (userKind === 'student') {
        result += t('common:verify_form_text.deepNote.example.student')
      } else {
        if (userRegisteredCountry === 'GB') {
          result += t('common:verify_form_text.deepNote.example.uk')
        } else {
          result += t('common:verify_form_text.deepNote.example.international')
        }
      }
    }

    return result
  }

  render(): React.ReactElement {
    const {
      t,
      handleSubmit,
      title,
      userKind,
      promoType,
      isMobile,
      verificationPage: isVerificationPage,
      userHasPhone,
      subTitle,
      emailSending,
      emailSent,
      untrustedEmails,
      emailConfirmed,
      deepnote,
      initialEmail,
      hasEmailExist,
    }: InternalProps = this.props
    const isApproved = promoType === 'unconfirmed_email_document_approved'
    const { isChanged, newSession } = this.state
    const renderPlaceholder =
      userKind === 'student'
        ? t('common:verify_form_text.deepNote.example.student.input')
        : t('common:verify_form_text.deepNote.example.uk.input')
    const validEmail = validateEmail(this.props.inputValue)
    const hasInitialEmail = !!initialEmail.length
    const pendingFlows =
      promoType === 'untrusted_confirmed_email_upload_document' ||
      promoType === 'document_rejected'
    const getButtonText = (): any => {
      let result

      if (emailSending) {
        result = (
          <span>
            Sending... <SpinnerIcon width={12} />
          </span>
        )
        // Verification page without email
      } else if (isVerificationPage && !hasEmailExist) {
        result = t('common:promo_any.send')
      } else if (isVerificationPage || userHasPhone) {
        // Verification page with email
        result = t('common:promo_any.send')
      } else if (pendingFlows) {
        // Flow 7,9
        result = t('common:promo_any.resend')
      } else if (newSession) {
        // When we open the modal again
        result = t('common:promo_any.resend')
      } else {
        if (!emailSending && isApproved) {
          result = t(
            'common:verify_form_text.untrusted_confirmed_email_upload_document.buttonLabel'
          )
        } else if (newSession) {
          result = t(
            'common:promo_verify_confirm_pro_email.update_save_button_label'
          )
        } else if (isChanged) {
          result = t(
            'common:promo_verify_confirm_pro_email.update_save_button_label'
          )
        } else if (!emailSending && !emailSent && !hasInitialEmail) {
          result = t('common:promo_any.send')
        } else if (
          !emailSending &&
          emailSent &&
          !isChanged &&
          initialEmail.length > 0
        ) {
          result = t(
            'common:verify_form_text.untrusted_unconfirmed_email_confirm.emailSent'
          )
        } else if (
          !emailSending &&
          emailSent &&
          isChanged &&
          initialEmail.length > 0
        ) {
          result = t(
            'common:promo_verify_confirm_pro_email.update_save_button_label'
          )
        }
      }

      return result
    }
    const buttonLayout = cs('end small-12')

    return (
      <form onSubmit={handleSubmit(this.handleVerifyEmail)}>
        <div className="row">
          <div className="medium-12" style={{ marginBottom: 10 }}>
            <h4 className={cs('long-header', { light: !isVerificationPage })}>
              <>{t(`${title}`)}</>
            </h4>
            {subTitle && !userHasPhone && (
              <div className="fs-exclude">
                <DeepInfoText color="dark">{subTitle}</DeepInfoText>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <FormField label={!!0}>
            <div className="u-10-of-12 small-fixed265">
              <Field
                component={InputField}
                className={cs(style.input)}
                inlineStyle={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
                resetForm={this.handleResetForm}
                placeholder={renderPlaceholder}
                type="email"
                name="email"
                withWrapper
              />
            </div>

            {untrustedEmails && (
              <div className="u-10-of-12 end" style={{ marginBottom: 10 }}>
                <DeepInfoText color="dark">
                  <>{t('common:verify_form_text.untrusted_emails')}</>
                </DeepInfoText>
              </div>
            )}

            {deepnote && !userHasPhone && (
              <div className="u-10-of-12 end" style={{ marginBottom: 10 }}>
                <DeepInfoText weight="w400" color="alert">
                  {this.getDeepNoteText()}
                </DeepInfoText>
              </div>
            )}

            <div className={buttonLayout} style={{ marginBottom: 10 }}>
              <div className="fixed-260">
                <Button
                  type="submit"
                  block
                  space={!isMobile}
                  fill
                  activeDisabled={emailSending}
                  disabled={
                    (emailSending ||
                      !validEmail ||
                      (emailConfirmed && !isChanged) ||
                      (emailSent && !isChanged && !newSession)) &&
                    !isApproved
                  }
                >
                  {getButtonText()}
                </Button>
              </div>
            </div>

            {!hasEmailExist && !userHasPhone && (
              <div className="u-10-of-12 end" style={{ marginBottom: 10 }}>
                <span className={style.pleaseConfirm}>
                  <>{t('common:verify_form_text.please_confirm')}</>
                </span>
              </div>
            )}
          </FormField>
        </div>
      </form>
    )
  }
}

export const validate = (values: any): any => {
  const errors: any = {}

  if (values.email === undefined || !validateEmail(values.email)) {
    errors.email = 'account_settings.error_email_required'
  }

  return errors
}

// ///////////////////////////////////////////////////////////// FORM DEFINITION
const WithForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(withTranslation()(VerifyForm))

const mapStateToProps = (state: any): any => {
  const user = selectAuthenticatedUser(state)
  const verifyEmail = state.form.hasOwnProperty(formName)
  const emailState = verifyEmail
    ? state.form[formName].values.email
    : state.verification.initialEmail
  const inputValue = verifyEmail && state.form[formName].values.email
  const initialEmail = user.professional_email || user.email
  const promoType = state.activity.promo.type
  const hasEmailExist = hasEmail(user)
  const userHasPhone = user.confirmed_phone && !hasEmailExist

  return {
    initialValues: { email: initialEmail },
    emailSending: state.verification.isLoading,
    emailSent: state.verification.isLoaded,
    verification: state.verification,
    userKind: user.kind,
    userHasPhone,
    userRegisteredCountry: user.registered_country,
    emailValue: emailState,
    hasEmailExist,
    initialEmail,
    inputValue,
    promoType,
    params: state.router.pathname,
    isMobile: selectIsMobile(state),
    forms: state.form,
  }
}
const mapDispatchToProps = (dispatch: Function): any => ({
  resetInput: (): void => dispatch(resetInitialEmail()),
  loadAccount: (): any => dispatch(loadAccount()),
  postConfirmEmailRequest: (email: string, args: string): any => {
    dispatch(postConfirmEmailRequest(email, args))
  },
  rebuildForm: (formData: any): any => {
    dispatch(initialize(formName, formData, 'email'))
  },
})

export default connect<Props>(mapStateToProps, mapDispatchToProps)(WithForm)
