import * as schemas from '../../entities/schemas'
import { request } from '../../api/actions'

export const LOAD_REQUEST = 'entities/group_invites_load.REQUEST'
export const LOAD_SUCCESS = 'entities/group_invites_load.SUCCESS'
export const LOAD_FAILURE = 'entities/group_invites_load.FAILURE'

export default function loadGroupInvites(resourceType, resourceID) {
  return request({
    url: `/api/${resourceType}s/${encodeURIComponent(resourceID)}/invites`,
    start: { type: LOAD_REQUEST },
    success: {
      type: LOAD_SUCCESS,
      resourceID,
      resourceType,
      meta: { schema: { response: [schemas.userSchema] } },
    },
    failure: LOAD_FAILURE,
  })
}
