import getConfig from '@/config'
import * as account_load from '../auth/actions/account_load'
import { selectAuthenticatedUserID } from '../auth/selectors'
import { getBranch } from './init'

const config = getConfig().publicRuntimeConfig

const handlers = {
  [account_load.LOAD_SUCCESS]: state => {
    getBranch().then(branch =>
      branch.setIdentity(selectAuthenticatedUserID(state))
    )
    return state
  },
  [account_load.LOAD_FAILURE]: state => {
    getBranch().then(branch => branch.logout())
    return state
  },
}

const branchReducer = (state, action) => {
  if (typeof handlers[action.type] === 'function') {
    state = handlers[action.type](state)
  }
  return state
}

export default branchReducer
