// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const REQUEST: string = 'REQUEST'
const SUCCESS: string = 'SUCCESS'
const FAILURE: string = 'FAILURE'

interface RequestType {
  REQUEST: string
  SUCCESS: string
  FAILURE: string
}

function createRequestTypes(base: string): RequestType {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc: any, type: string): any => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

function action(type: string, payload: any = {}): any {
  return { type, ...payload }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { createRequestTypes as default, action, REQUEST, SUCCESS, FAILURE }
