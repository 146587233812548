import React, { useState } from 'react'
import Modal from '../ui/Modal/MedshrModal'
import { CheckboxHtmlForm } from '../ui/forms/base'
import { useCookie } from '../lib/use-cookies'

export const TermsModal = ({ closeModal }: { closeModal: () => void }) => {
  const [checked, setChecked] = useState(false)
  const [marketing, setMarketingChecked] = useState(false)

  const isGDPR = useCookie('iseu')

  const checkboxLabel = (
    <>I confirm that I have read and accept these agreements.</>
  )

  const marketingCheckboxLabel = (
    <>Receive marketing communications from MedShr.</>
  )

  return (
    <Modal
      title="Welcome to MedShr"
      sideKickBtn={null}
      className="modal--terms"
    >
      <div className="modal--terms__body">
        <form method="post" action="/api/profile/accept-terms">
          <p>
            Before you continue please familiarize yourself with our{' '}
            <a href="/pages/privacy" target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="/pages/terms" target="_blank" rel="noreferrer noopener">
              Terms &amp; Conditions
            </a>
            .
          </p>

          <div style={{ margin: '1.5rem 0' }}>
            <CheckboxHtmlForm
              onChange={e => setChecked(e.target.checked)}
              name="terms"
              value="1"
              id="terms"
              label={checkboxLabel}
              defaultChecked={false}
              checkboxClassName="fs-unmask"
            />
          </div>

          {isGDPR ? (
            <div style={{ margin: '1.5rem 0' }}>
              <CheckboxHtmlForm
                onChange={e => setMarketingChecked(e.target.checked)}
                name="marketing"
                value="1"
                id="marketing"
                label={marketingCheckboxLabel}
                defaultChecked={false}
                checkboxClassName="fs-unmask"
              />
            </div>
          ) : undefined}

          <div style={{ textAlign: 'center', paddingBottom: 10 }}>
            <input
              type="hidden"
              name="referer"
              value={document.location.href}
            />
            <button
              type="submit"
              disabled={!checked}
              className="btn btn-primary btn--wide btn--fill"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
