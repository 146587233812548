'use client'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuthenticationState } from '../modules/auth/hooks'

const setupScript = (url: string, async: boolean, defer: boolean) => {
  ;(function (w: any, d) {
    window[`booted_${url}`] = true
    function r() {
      var s = document.createElement('script')
      s.async = async
      s.defer = defer
      s.type = 'text/javascript'
      s.src = url
      ;(d.body || d.head).appendChild(s)
    }
    if (document.readyState === 'complete') {
      r()
    } else if (w.attachEvent) {
      w.attachEvent('onload', r)
    } else {
      w.addEventListener('load', r, false)
    }
  })(window, document)
}

export const ScriptInclude = ({
  url,
  async,
  defer,
  includeGuests,
  includeMembers,
  includeStaff,
}: {
  id: string
  url: string
  async: boolean
  defer: boolean
  includeGuests: boolean
  includeMembers: boolean
  includeStaff?: boolean
}) => {
  const { isAuthenticating, isAuthenticated, user } = useAuthenticationState()
  const router = useRouter()

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const booted = !!window[`booted_${url}`]

    if (!booted) {
      if (isAuthenticating) {
        return
      }

      if (isAuthenticated) {
        if (!includeStaff && user?.is_medshr) {
          return
        }
        if (!includeMembers) {
          return
        }
      } else if (!includeGuests) {
        return
      }

      setupScript(url, async, defer)
    }
  }, [
    typeof window !== 'undefined' && !!window[`booted_${url}`],
    isAuthenticating,
    isAuthenticated,
    user?.id,
  ])

  return null
}
