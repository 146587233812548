import React from 'react'
import { useRouter } from 'next/router'
import { connect } from 'react-redux'
import NextLink from 'next/link'
import cs from 'classnames'
import { appendQuery, isExternalURL } from '../url'

// //////////////////////////////////////////////////////////////////// SELECTOR
import { isActiveRoute } from '../modules/routing/selectors'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export type LinkPropsType = {
  href: string
  as?: string
}

type LinkComponentType = LinkPropsType & {
  prefetch?: boolean
  active?: boolean
  underline?: boolean
  persistQuery?: boolean
  onlyActiveOnIndex?: boolean
  forceReload?: boolean
  className?: string
  activeClassName?: string
  style?: any
  children: React.ReactNode
  onClick?: Function
}

export const LinkComponent = ({
  children,
  onClick,
  style,
  className,
  href,
  as,
  activeClassName,
  persistQuery,
  active,
  underline,
  forceReload,
  prefetch,
}: LinkComponentType): React.ReactElement => {
  const component = forceReload ? 'a' : persistQuery ? NextLink : NextLink
  const childProps = {
    href,
    as,
    prefetch: !!prefetch,
    // className: cs(styles.links, className, {
    //   [activeClassName]: active,
    //   [styles.underline]: underline
    // }),
    // onClick: onClick,
    // style: style,
    // shape: 'circle'
  }

  if (isExternalURL(href)) {
    childProps['target'] = '_blank'
    childProps['rel'] = 'noreferrer noopener'
  }

  return React.createElement(component, childProps, children)
}

const mapStateToProps = (state: any, ownProps: any): any => {
  const href = ownProps.to || ownProps.href
  const as = ownProps.as
  const { activeClassName, onlyActiveOnIndex } = ownProps

  return {
    href,
    as,
    active:
      activeClassName &&
      isActiveRoute(
        state.router,
        typeof href === 'string'
          ? { onlyActiveOnIndex, pathname: href }
          : { onlyActiveOnIndex, ...href }
      ),
  }
}

export const Link = connect(mapStateToProps)(LinkComponent)

type TextLinkProps = {
  to: string | { to: string; as: string }
  as?: string
  className?: string
  activeClassName?: string
  title?: string
  style?: any
  prefetch?: boolean
  active?: boolean
  underline?: boolean
  forceReload?: boolean
  persistQuery?: boolean
  onlyActiveOnIndex?: boolean
  children: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  rel?: string
  targetBlank?: boolean
}

export const TextLink = ({
  active,
  activeClassName,
  as,
  children,
  className,
  forceReload,
  onClick,
  onlyActiveOnIndex,
  persistQuery,
  prefetch,
  rel,
  style,
  title,
  to,
  underline,
  targetBlank,
}: TextLinkProps): React.ReactElement => {
  let linkTo = typeof to === 'object' ? to.to : to
  const linkAs = typeof to === 'object' ? to.as : as

  const router = useRouter()

  if (activeClassName && !active) {
    // if not already set to active
    if (onlyActiveOnIndex) {
      const pathSegments = router.pathname.split(/\//g)
      const toPath = linkTo.split('?')[0].split(/\//g)
      const asPath = linkAs ? linkAs.split('?')[0].split(/\//g) : []
      active =
        pathSegments.length === toPath.length ||
        pathSegments.length === asPath.length
    } else {
      const toPaths = linkTo.split('?')[0]
      const asPaths = linkAs ? linkAs.split('?')[0] : undefined
      active =
        router.pathname.startsWith(toPaths) ||
        router.asPath.startsWith(toPaths) ||
        (asPaths && router.pathname.startsWith(asPaths))
    }
  }

  const classes = cs(className, {
    [activeClassName]: active,
    underline: underline,
  })
  if (persistQuery) {
    // append current query to `to`
    linkTo = appendQuery(linkTo, router.query)
  }
  if (forceReload) {
    return (
      <a
        href={linkTo}
        className={classes}
        onClick={onClick}
        title={title}
        style={style}
        rel={rel}
        target={targetBlank ? '_blank' : null}
      >
        {children}
      </a>
    )
  }
  return (
    <NextLink
      prefetch={!!prefetch}
      href={linkTo}
      as={linkAs}
      className={classes}
      onClick={onClick}
      title={title}
      style={style}
      rel={rel}
      target={targetBlank ? '_blank' : null}
    >
      {children}
    </NextLink>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Link
