import cs from 'classnames'
import filter from 'lodash/filter'
import get from 'lodash/get'
import { useTranslation } from 'next-i18next'
import { curry } from 'ramda'
import React from 'react'

import { isAdmin } from '../../modules/entities/user'
import { TextLink } from '../../ui/link'
import { link_for_resource } from '../../url'

const hoverMenuList = [
  {
    name: 'menu.your_cases',
    to: '/cases',
    prefix: true,
    when: user =>
      user.can_create_case &&
      (!user.has_group_restrictions || user.groups?.length),
  },
  {
    name: 'menu.your_groups',
    to: '/groups',
    prefix: true,
    when: user => !user.has_group_restrictions || user.groups?.length,
  },
  {
    name: 'menu.your_subscriptions',
    to: '/profile/subscriptions',
    when: (user, features) =>
      !user.has_dab && (features.learning_subscriptions || features.docsearch),
  },
  {
    name: 'menu.medshr_learning',
    to: '/learning/r',
    when: (user, features) => features.learning && !user.has_dab,
  },
  { divider: true },
  {
    name: 'menu.set_password',
    to: '/profile/account?update-password',
    when: user => !user.has_password,
  },
  {
    name: 'menu.notification_settings',
    to: '/profile/preferences',
  },
  { name: 'menu.account_settings', to: '/profile/account' },
  { divider: true },
  { name: 'menu.medshr_team', to: '/team' },
  { name: 'menu.medshr_partners', to: '/team/partners' },
]

const renderHoverMenuList = (data, user) => {
  const { t } = useTranslation('common')

  const link = curry(link_for_resource)('user', user.id)
  const urlBuild = item => {
    if (item.prefix) return link(item.to, undefined)
    return item.to
  }

  return data.map((item, index) => (
    <li className="nav-item" key={`${item.name}_${index}`}>
      {item.divider ? (
        <div className="user-menu__hover__menu__item--group-starter">
          <div className="user-menu__hover-menu__util-border" />
        </div>
      ) : (
        <TextLink
          to={urlBuild(item)}
          className={cs('user-menu__hover-menu__link', item.className)}
        >
          {t(item.name)}
        </TextLink>
      )}
    </li>
  ))
}

interface HoverMenuProps {
  showWhenNotHovered: boolean
  currentUser: any
  features?: any
}

export const HoverMenu = ({
  showWhenNotHovered,
  currentUser,
  features,
}: HoverMenuProps) => {
  const { t } = useTranslation()

  const listClasses = cs('user-menu__hover-menu', 'list-bare', {
    'user-menu__hover-menu--show': showWhenNotHovered,
  })

  let menu = hoverMenuList

  menu = filter(menu, item =>
    item.when ? item.when(currentUser, features) : true
  )
  if (menu[0].divider) {
    delete menu[0]
  }

  return (
    <ul className={listClasses}>
      {renderHoverMenuList(menu, currentUser)}
      {isAdmin(currentUser) && (
        <li>
          <div className="user-menu__hover__menu__item--group-starter">
            <div className="user-menu__hover-menu__util-border" />
            <a
              href={currentUser.admin_base_url}
              className="user-menu__hover-menu__link"
            >
              Admin
            </a>
          </div>
        </li>
      )}
      {currentUser ? (
        <li>
          <div className="user-menu__hover__menu__item--group-starter">
            <div className="user-menu__hover-menu__util-border" />
            <a href="/auth/logout" className="user-menu__hover-menu__link">
              {t('common:menu.logout')}
            </a>
          </div>
        </li>
      ) : null}
    </ul>
  )
}
