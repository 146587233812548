export const ADD_TEMP = 'temp/ADD_TEMP'
export const UPDATE_TEMP = 'temp/UPDATE_TEMP'
export const DELETE_TEMP = 'temp/DELETE_TEMP'

export const CLEAR_TEMPS = 'temp/CLEAR_TEMPS'

export const ADD_SCROLL = 'temp/ADD_SCROLL'
export const DELETE_SCROLL = 'temp/DELETE_SCROLL'

export const INVITE_LIST_UPDATED = 'temp/INVITE_LIST_UPDATED'
export const INVITE_LIST_CLEAR = 'temp/INVITE_LIST_CLEAR'

// ///////////////////////////////////////////////////////////// ACTION CREATORS
export function addTemp({ id, value }) {
  return { type: ADD_TEMP, id, value }
}

export function updateTemp(id) {
  return { type: UPDATE_TEMP, id }
}

export function deleteTemp(id) {
  return { type: DELETE_TEMP, id }
}

export function clearTemps(id) {
  return { type: CLEAR_TEMPS, id }
}

// ////////////////////////////////////////////////////////////////////// SCROLL
export function addScroll(id: string) {
  return { type: ADD_SCROLL, id }
}
export function deleteScroll(id) {
  return { type: DELETE_SCROLL, id }
}

// ///////////////////////////////////////////////////////// INVITE LIST UPDATED
export function invitesUpdated(entityId) {
  return { type: INVITE_LIST_UPDATED, id: entityId }
}
export function invitesClear(entityId?: string) {
  return { type: INVITE_LIST_CLEAR, id: entityId }
}
