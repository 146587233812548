import { INVITE_LIST_UPDATED } from '../../modules/temp/actions'

import * as actions from './actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type InitialType = {
  stack: any
  scrollMap: any
  inviteMap: any
}

const INITIALS: InitialType = {
  stack: {},
  scrollMap: {},
  inviteMap: {},
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
const reducer = (
  state: InitialType = INITIALS,
  { type, id, value, newStack }: any
): any => {
  switch (type) {
    // ///////////////////////////////////////////////////// INVITE LIST UPDATED
    case actions.INVITE_LIST_UPDATED:
      return { ...state, inviteMap: { ...state.inviteMap, [id]: true } }

    // //////////////////////////////////////////////////////////// RESET INVITE
    case actions.INVITE_LIST_CLEAR:
      return { ...state, inviteMap: {} }

    // //////////////////////////////////////////////////////////////// ADD TEMP
    case actions.ADD_TEMP:
      return { ...state, stack: { ...state.stack, [id]: value } }

    // //////////////////////////////////////////////////////////////// ADD TEMP
    case actions.UPDATE_TEMP:
      return { ...state, stack: { ...state.stack, [id]: newStack } }

    // ///////////////////////////////////////////////////////////// DELETE TEMP
    case actions.DELETE_TEMP:
      return { ...state, stack: { ...deleteProp(state.stack, id) } }

    // ///////////////////////////////////////////////////////////// RESET STACK
    case actions.CLEAR_TEMPS:
      return { ...INITIALS }

    // ////////////////////////////////////////////////////////////// ADD SCROLL
    case actions.ADD_SCROLL:
      return { ...state, scrollMap: { ...state.scrollMap, [id]: id } }

    // /////////////////////////////////////////////////////////// DELETE SCROLL
    case actions.DELETE_SCROLL:
      return { ...state, scrollMap: { ...deleteProp(state.scrollMap, id) } }

    default:
      return state
  }
}

export default reducer

export function deleteProp(state: any, id: string) {
  if (id && state) {
    if (state[id]) delete state[id]
  }

  return state
}
