import React from 'react'
import { string, node, func, bool, any, object } from 'prop-types'
import cs from 'classnames'

// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
import { isExternalURL } from '../../url'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: React.MouseEventHandler
  className?: string
  link?: string
  children?: React.ReactNode
  text?: string
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  active?: boolean
  activeDisabled?: boolean
  disabledVisually?: boolean
  wide?: boolean
  danger?: boolean
  small?: boolean
  fill?: boolean
  dark?: boolean
  white?: boolean
  block?: boolean
  space?: boolean
  whiteStroke?: boolean
  selected?: boolean
  square?: boolean
  connect?: any
  inlineStyle?: object
  id?: string
  reference?: React.Dispatch<any> | React.MutableRefObject<any>
  secret?: boolean
}

const defaultProps = {
  type: 'button' as const,
  connect: false,
  disabled: false,
  square: false,
  danger: false,
  link: '',
  inlineStyle: {},
}

export const Button: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref) => {
    const {
      className,
      children,
      type,
      disabled,
      onClick,
      text,
      connect,
      link,
      inlineStyle,
      id,
      reference,
    } = { ...defaultProps, ...props }
    const classes = cs(className, 'btn', {
      'fs-unmask': !props.secret,
      'btn--primary': props.primary,
      'btn--secondary': props.secondary,
      'btn--disabled': props.disabled && !props.activeDisabled,
      'btn--active--disabled': props.activeDisabled,
      'btn--off': props.disabledVisually,
      'btn--active': props.active,
      'btn--wide': props.wide,
      'btn--danger': props.danger,
      'btn--small': props.small,
      'btn--fill': props.fill || props.selected,
      'btn--dark': props.dark || props.selected,
      'btn--white': props.white,
      'btn--block': props.block,
      'btn--whiteStroke': props.whiteStroke,
      'space-bottom': props.space,
      'btn--square': props.square,
    })

    if (link) {
      const linkProps: any = { href: link }
      if (isExternalURL(link)) {
        linkProps['target'] = '_blank'
        linkProps['rel'] = 'noreferrer noopener'
      }
      return (
        <a
          id={id}
          ref={ref ?? reference}
          {...linkProps}
          className={classes}
          style={inlineStyle}
          onClick={disabled ? undefined : onClick}
        >
          {children} {text}
        </a>
      )
    }

    if (connect) {
      return (
        <button
          id={id}
          ref={ref ?? reference}
          className={classes}
          type={type}
          disabled={disabled}
          onClick={onClick}
          style={inlineStyle}
        >
          {children} {text}
        </button>
      )
    }

    return (
      <button
        id={id}
        ref={ref ?? reference}
        className={classes}
        type={type}
        disabled={disabled}
        onClick={onClick}
        style={inlineStyle}
      >
        {children} {text}
      </button>
    )
  }
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Button
