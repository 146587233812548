import React from 'react'
import { Children } from 'react'
import cs from 'classnames'

import style from './styles/DeepInfoText.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type DeepInfoTextType = {
  children?: React.ReactNode
  color?: string
  weight?: string
  inlineStyle?: any
}

const DeepInfoText = ({
  children,
  color = 'default',
  inlineStyle,
  weight = 'w300',
}: DeepInfoTextType): React.ReactElement => {
  color = color ?? 'default'
  weight = weight ?? 'w300'

  const classes: any = cs(style.commonText, {
    [style[color]]: true,
    [style[weight]]: true,
  })

  return (
    <div className={classes} style={inlineStyle}>
      {children}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default DeepInfoText
