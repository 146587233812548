import React from 'react'

import Icon from './icon'
import { IconWithDefsType } from './icon'

interface FacebookProps {
  id?: string
  title?: string
  className?: string
  onClick?(): void
  width?: number
}

const Facebook = (props: FacebookProps): React.ReactElement => {
  return (
    <Icon
      id={props.id ?? 'icon-fb'}
      title={props.title ?? 'Facebook'}
      className={props.className}
      onClick={props.onClick}
      width={props.width}
      sourceWidth={16}
      sourceHeight={30}
      {...props}
    >
      <g strokeWidth="1">
        <path d="M15.5769231,0.216346154 L15.5769231,4.97596154 L12.7463942,4.97596154 C11.7127352,4.97596154 11.0156268,5.19230553 10.6550481,5.625 C10.2944694,6.05769447 10.1141827,6.70672644 10.1141827,7.57211538 L10.1141827,10.9795673 L15.3966346,10.9795673 L14.6935096,16.3161058 L10.1141827,16.3161058 L10.1141827,30 L4.59735577,30 L4.59735577,16.3161058 L0,16.3161058 L0,10.9795673 L4.59735577,10.9795673 L4.59735577,7.04927885 C4.59735577,4.81369075 5.22234952,3.07993404 6.47235577,1.84795673 C7.72236202,0.615979417 9.38700883,-3.58687439e-15 11.4663462,0 C13.2331819,-3.58687439e-15 14.6033605,0.0721146635 15.5769231,0.216346154 L15.5769231,0.216346154 Z" />
      </g>
    </Icon>
  )
}

export default Facebook
