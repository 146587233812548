import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/BasicListItem.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type ListItemType = {
  children: React.ReactNode
  side?: 'left' | 'right' | 'center'
  position?: 'vertical' | 'horizontal'
  space?: boolean
  className?: string
}

const ListItem = ({
  children,
  side,
  space,
  className,
  position,
}: ListItemType): React.ReactElement => {
  const classes = cs(
    style.listItem,
    style[side ?? 'left'],
    style[position ?? 'horizontal'],
    className,
    {
      [style.space]: space,
    }
  )

  return <li className={classes}>{children}</li>
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ListItem
