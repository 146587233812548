import Cookie from 'js-cookie'
import { fork, take, put, all } from 'redux-saga/effects'

import get from 'lodash/get'

import { addScript, addStyle } from '../../lib/dom'
import { isOpenPage } from '../../lib/isOpenPage'
import { redirectAuthCheck } from '../../url'

// ////////////////////////////////////////////////////////////// ACTION
import {
  LOAD_SUCCESS,
  LOAD_FAILURE,
  REFRESH,
  loadAccount,
} from './actions/account_load'

import { SETTINGS_SAVE_SUCCESS } from './actions/account_settings_save'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

function* authSuccess(action): Generator {
  const u = get(action, ['payload', 'response'])
  if (u.signup_product === 'client-portal' && !isOpenPage()) {
    document.location.href =
      'https://insights.' +
      document.location.href.replace(/^https?:\/\/[^\.]+\.([^\/\?#]+).*/, '$1')
    return
  }
  if (u.needs_setup || !u.name || !u.kind) {
    if (
      !/(\/join(\/profile|\/verification.*)?|\/docsearch\/plans)$/.test(
        get(global, ['document', 'location', 'pathname']) ?? '/'
      )
    ) {
      setTimeout(() => {
        document.location.href =
          '/auth/register/continue?next=' +
          encodeURIComponent(document.location.href)
      }, 250)
    }
  }
}

function* authSaveSuccess(action): Generator {
  setTimeout(() => {
    document.location.href = '/'
  }, 50)
}

function* authFailure(): Generator {
  Cookie.remove('authed')
  if (
    process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT &&
    /authed=/.test(document.cookie)
  ) {
    redirectAuthCheck()
  }
}

function* watchauthReload(): Generator {
  while (true) {
    const action = yield take(REFRESH)
    console.log('watchauthReload', action)
    yield put(loadAccount())
  }
}

function* watchauthSuccess(): Generator {
  while (true) {
    const action = yield take(LOAD_SUCCESS)
    yield fork(authSuccess, action)
  }
}

function* watchauthSaveSuccess(): Generator {
  while (true) {
    const action = yield take(SETTINGS_SAVE_SUCCESS)
    yield fork(authSaveSuccess, action)
  }
}

function* watchauthFailure(): Generator {
  while (true) {
    yield take(LOAD_FAILURE)
    yield fork(authFailure)
  }
}

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////// ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([
    fork(watchauthReload),
    fork(watchauthSuccess),
    fork(watchauthFailure),
    fork(watchauthSaveSuccess),
  ])
}
