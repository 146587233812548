import { filter, path } from 'ramda'
import loadCategories from './actions/load'
import callAPI from '../api/call'
import { error } from '../analytics/track'

import getConfig from '@/config'

const config = getConfig().publicRuntimeConfig

const isServer = typeof window === 'undefined'

type Cache = {
  response: any
  time: number
}

let lru: { [k: string]: Cache } = {}

const categoryCacheDuration = 60 * 1000

setInterval(() => {
  const now = Date.now()
  lru = filter(x => x.time + categoryCacheDuration > now, lru)
}, 10000)

export const initializeCategoriesStore = reduxStore => {
  if (!reduxStore.getState().categories.roles) {
    if (isServer) {
      const action = loadCategories()
      const now = Date.now()
      const lang = path(['router', 'locale'], reduxStore.getState()) ?? 'en'
      const categories = lru[lang]
      if (categories) {
        reduxStore.dispatch({
          type: action.success,
          payload: categories.response,
        })
      } else {
        callAPI(
          {
            ...action,
            headers: {
              'accept-language': lang,
              'user-agent': `medshr.net frontend/${config.version}`,
            },
          },
          true
        )
          .then((response: any) => {
            lru[lang] = { response: response.payload, time: Date.now() }
            reduxStore.dispatch({
              type: action.success,
              payload: response.payload,
            })
          })
          .catch(e => {
            if (e.error) {
              error(e.error, e)
            } else {
              error(e)
            }
          })
      }
    } else {
      updateClientCategories(reduxStore)
    }
  }
}

const updateClientCategories = reduxStore => {
  if (typeof window['medshrCategories'] === 'object') {
    const action = loadCategories()
    reduxStore.dispatch({
      type: action.success,
      payload: { response: window['medshrCategories'] },
    })
  } else {
    setTimeout(() => updateClientCategories(reduxStore), 200)
  }
}
