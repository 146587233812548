export const OPEN: string = 'modal.OPEN'
export const CLOSE: string = 'modal.CLOSE'
export const CLOSE_ALL: string = 'modal.CLOSE_ALL'
export const REPLACE: string = 'modal.REPLACE'

export const OPEN_MODAL: string = 'modal.OPEN_MODAL'
export const CLOSE_MODAL: string = 'modal.CLOSE_MODAL'
export const CLOSE_ALL_MODAL: string = 'modal.CLOSE_ALL_MODAL'

// ///////////////////////////////////////////////////////////////////// ACTIONS
function openModal(payload: any): any {
  return { type: OPEN, payload }
}
function closeModal(payload: any = {}): any {
  return { type: CLOSE, payload }
}
function closeAllModals(payload: any = {}): any {
  return { type: CLOSE_ALL, payload }
}
function replaceTopModal(payload: any): any {
  return { type: REPLACE, payload }
}

// New Modal actions
function modalOpen(payload: any): any {
  return { type: OPEN_MODAL, payload }
}
function modalClose(id: string): any {
  return { type: CLOSE_MODAL, payload: id }
}
function allModalClose(): any {
  return { type: CLOSE_ALL_MODAL }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export {
  openModal,
  closeModal,
  closeAllModals,
  replaceTopModal,
  modalOpen,
  modalClose,
  allModalClose,
}
