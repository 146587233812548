import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEqual from 'lodash/isEqual'
import memoize from 'lodash/memoize'

function createLocationDescriptor(to, _ref) {
  var query = _ref.query
  var hash = _ref.hash
  var state = _ref.state

  if (query || hash || state) {
    return { pathname: to, query: query, hash: hash, state: state }
  }

  return { pathname: to }
}

export const isActiveRoute = memoize(
  (routerState, params) => {
    return false
    if (!routerState) return false
    const to = typeof params === 'string' ? params : params.pathname
    const { query, hash, state, onlyActiveOnIndex } = params
    var location = createLocationDescriptor(to, { query, hash, state })
    return reduce(
      location,
      (a, v, k) => {
        if (a) {
          if (k === 'pathname' && !onlyActiveOnIndex) {
            return routerState.pathname.startsWith(v)
          }
          return isEqual(v, routerState[k])
        }
      },
      true
    )
  },
  (routerState, params) => JSON.stringify({ routerState, params })
)

export const isCurrentURLPrefix = (routerState, path) => {
  return false
  if (!routerState) return false
  const location: Location = path(['document', 'location'], global)
  if (location) {
    return location.pathname.substring(0, path.length) === path
  }
  return false
}

export const isHashChange = (globalState, locationDescriptor) => {
  return false
  const hash = get(globalState, ['router', 'hash'])
  if (hash === locationDescriptor.hash) {
    return false
  }

  const path = get(globalState, ['router', 'pathname'])
  const search = get(globalState, ['router', 'search'])
  const uri = path + search
  const action_uri = locationDescriptor.pathname + locationDescriptor.search
  return uri === action_uri
}
