import React from 'react'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'
import { Select } from './base'

// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'

// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/SelectField.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type SelectFieldType = {
  input: any
  meta: any
  type: string
  placeholder: string
  label: string
}

const SelectField = ({
  input: { ...restInput },
  meta: { touched, error, warning, dirty, ...restMeta },
  placeholder,
  type,
  label,
  ...rest
}: SelectFieldType): React.ReactElement => {
  const labelText = (
    <label htmlFor={label || placeholder}>{label || placeholder}</label>
  )

  return (
    <div className={style.container}>
      {label ? labelText : null}

      <Select
        id={label}
        placeholder={placeholder}
        type={type}
        {...restInput}
        {...restMeta}
        {...rest}
      />

      {touched &&
        dirty &&
        ((error && (
          <Error customClass={style.textError} message={error} hasError />
        )) ||
          (warning && (
            <Error customClass={style.textError} message={error} hasWarning />
          )))}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default SelectField
