import callAPI from '../../modules/api/call'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export type RepliesResponse = {
  page: number
  pages: number
  per_page: number
  results?: any[]
  users: any
}

// ///////////////////////////////////////////////////////// GET REPLIES
export function getReplies(caseId: string, commentId: string): Promise<any> {
  return callAPI({
    url: `/api/cases/${caseId}/comments/${commentId}/replies`,
    method: 'GET',
  })
    .then(({ payload }: any): RepliesResponse => payload as RepliesResponse)
    .catch((err: any): any => err)
}

// ////////////////////////////////////////////////////////// POST REPLY
export function postReply({
  caseId,
  commentId,
  text,
  updated_since,
  is_dislike,
}: any): any {
  return callAPI({
    url: `/api/cases/${caseId}/comments/${commentId}/replies`,
    method: 'POST',
    json: { body: text, updated_since, is_dislike },
  })
    .then(({ payload }: any): any => payload)
    .catch((err: any): any => err)
}
