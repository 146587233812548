import React from 'react'

import Icon from './icon'
import { IconWithDefsType } from './icon'

interface LinkedInProps {
  id?: string
  title?: string
  className?: string
  onClick?(): void
  width?: number
}

const LinkedIn = (props: LinkedInProps): React.ReactElement => {
  const defaultProps = {
    title: 'LinkedIn',
    id: 'icon-linkedin',
  }

  return (
    <Icon {...{ ...defaultProps, ...props }} sourceWidth={30} sourceHeight={30}>
      <g strokeWidth="1">
        <ellipse cx="3.75" cy="3.78640777" rx="3.75" ry="3.78640777" />
        <path d="M0.613557692,30 L6.93951923,30 L6.93951923,10.4997087 L0.613557692,10.4997087 L0.613557692,30 Z M10.6549039,30 L16.9808654,30 L16.9808654,19.3668932 C16.9808654,17.2252427 18.0227885,15.1963107 20.404327,15.1963107 C23.865,15.1963107 23.5673077,17.9015534 23.5673077,20.5692233 L23.5673077,30 L29.8932693,30 L29.8932693,17.4882524 C29.8932693,14.6702913 29.3723077,9.9361165 22.9347116,9.9361165 C20.6275962,9.9361165 18.0227885,11.1760194 16.8692308,13.1673786 L16.7948077,13.1673786 L16.7948077,10.4997087 L10.6549039,10.4997087 L10.6549039,30 Z" />
      </g>
    </Icon>
  )
}

export default LinkedIn
