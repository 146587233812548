import callAPI from '../../../modules/api/call'
// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////// ACTIONS
export const LOAD_REQUEST = 'follows/followers/LOAD_REQUEST'
export const LOAD_SUCCESS = 'follows/followers/LOAD_SUCCESS'
export const LOAD_FAILURE = 'follows/followers/LOAD_FAILURE'

export const SEE_MORE_REQUEST = 'follows/followers/SEE_MORE_REQUEST'
export const SEE_MORE_SUCCESS = 'follows/followers/SEE_MORE_SUCCESS'
export const SEE_MORE_FAILURE = 'follows/followers/SEE_MORE_FAILURE'

export const RESET_INITIAL_STATE = 'follows/followers/RESET_INITIAL_STATE'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////// ACTION CREATORS
export const loadCaseFollowersRequest = (caseId: string): any => {
  return { type: LOAD_REQUEST, caseId }
}

export const loadCaseFollowersSuccess = (payload: any) => {
  return { type: LOAD_SUCCESS, payload }
}

export const loadCaseFollowersFailure = (error: any) => {
  return { type: LOAD_FAILURE, error }
}

export const seeMoreCaseFollowersRequest = (caseId: string): any => {
  return { type: SEE_MORE_REQUEST, caseId }
}

export const seeMoreCaseFollowersSuccess = (payload: any): any => {
  return { type: SEE_MORE_SUCCESS, payload }
}

export const seeMoreCaseFollowersFailure = (error: string): any => {
  return { type: SEE_MORE_FAILURE, error }
}

export const resetInitialState = (): any => {
  return { type: RESET_INITIAL_STATE }
}

// //////////////////////////////////////////////////////////// API CALL
export const getCaseFollowers = (caseId: string, page: number) => {
  return callAPI({
    url: `/api/follows/in/case/${caseId}?page=${page}`,
    method: 'GET',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: any): any => err)
}
