export const NOTIFY: string = 'notify/NOTIFY'
export const SHOW: string = 'notify/SHOW'
export const DISMISS: string = 'notify/DISMISS'

export function postNotification(notification: any): any {
  return { type: NOTIFY, notification }
}

export function dismissNotification(id: string): any {
  return { type: DISMISS, id }
}
