import React from 'react'
import FormattedHTMLMessage from '../FormattedHTMLMessage'
import cs from 'classnames'

import noop from '../../utils/noop'
import GroupIcon from '../icons/group'
import Spinner from '../icons/spinner'
import Button from './button'
import style from './styles/join-group.module.scss'
import { useTranslation } from 'next-i18next'

type Props = {
  group: any
  toggleJoin: (e: React.MouseEvent<Element>) => void
  is_requesting: boolean
  fill?: boolean
  dark?: boolean
  className?: string
  multiline?: boolean
  brief?: boolean
  old?: boolean
  disabled?: boolean
}
type RequestedProps = {
  multiline?: boolean
  old?: boolean
  className?: string
  fill?: boolean
  dark?: boolean
}
type JoinGroupProps = {
  toggleJoin: (e: React.MouseEvent<Element>) => void
  group: any
  is_member: boolean
  is_invited: boolean
  is_requesting: boolean
  is_requested: boolean
  access_type: string
  old?: boolean
  fill?: boolean
  dark?: boolean
}

// /////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////// JOIN
const Join = ({
  group,
  is_requesting,
  toggleJoin,
  fill,
  className,
  old,
  multiline,
  ...rest
}: Props): React.ReactElement => {
  if (old) {
    return (
      <Button
        {...rest}
        className={cs(className, { multiline: multiline })}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        {group.type === 'institution' ? (
          <React.Fragment>
            <FormattedHTMLMessage id="group:institution.follow_button_label" />
            {is_requesting ? <Spinner width={12} /> : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormattedHTMLMessage id="group:group.join_button_label" />
            {is_requesting ? <Spinner width={12} /> : <GroupIcon />}
          </React.Fragment>
        )}
      </Button>
    )
  }

  return (
    <div className={style.flexContainer}>
      <span className={style.linkText} onClick={toggleJoin}>
        {group.type === 'institution' ? (
          <FormattedHTMLMessage id="group:institution.follow_button_label" />
        ) : (
          <FormattedHTMLMessage id="group:group.join_button_label_text" />
        )}
      </span>
      <Button
        className={cs(className, style.btn)}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        {is_requesting ? (
          <Spinner className={style.icon} width={12} />
        ) : (
          <GroupIcon className={style.icon} />
        )}
      </Button>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////// LEAVE
const Leave = ({
  is_requesting,
  toggleJoin,
  fill,
  dark,
  className,
}: Props): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <div className={style.flexContainer}>
      <span className={style.linkText} onClick={toggleJoin}>
        {t('group:group.leave_button_label_text')}
      </span>
      <Button
        className={cs(className, style.btn)}
        dark={is_requesting || dark}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        {is_requesting ? (
          <Spinner className={style.icon} width={12} />
        ) : (
          <GroupIcon className={style.icon} />
        )}
      </Button>
    </div>
  )
}

// ////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////// ACCEPT
const Invited = ({
  is_requesting,
  brief,
  toggleJoin,
  multiline,
  fill,
  className,
  old,
  ...rest
}: Props): React.ReactElement => {
  const { t } = useTranslation()

  if (old) {
    return (
      <Button
        {...rest}
        className={cs(className, { multiline: multiline })}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        <FormattedHTMLMessage
          id={t(
            brief
              ? 'group:group.accept_access_short_button_label'
              : 'group:group.accept_access_button_label'
          )}
        />
        {is_requesting ? <Spinner width={12} /> : <GroupIcon />}
      </Button>
    )
  }

  return (
    <div className={style.flexContainer}>
      <span className={style.linkText} onClick={toggleJoin}>
        {t(
          brief
            ? 'group:group.join_button_label_text'
            : 'group:group.accept_access_button_label_text'
        )}
      </span>
      <Button
        className={cs(className, style.btn)}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        {is_requesting ? (
          <Spinner className={style.icon} width={12} />
        ) : (
          <GroupIcon className={style.icon} />
        )}
      </Button>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////// REQUEST
const Request = ({
  is_requesting,
  brief,
  toggleJoin,
  multiline,
  fill,
  className,
  old,
  ...rest
}: Props): React.ReactElement => {
  const { t } = useTranslation()

  if (old) {
    return (
      <Button
        {...rest}
        className={cs(className, { multiline: multiline })}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        <FormattedHTMLMessage
          id={
            brief
              ? 'group:group.request_access_short_button_label'
              : 'group:group.request_access_button_label'
          }
        />
        {is_requesting ? <Spinner width={12} /> : <GroupIcon />}
      </Button>
    )
  }

  return (
    <div className={style.flexContainer}>
      <span className={style.linkText} onClick={toggleJoin}>
        {t(
          brief
            ? 'group:group.join_button_label_text'
            : 'group:group.request_access_button_label_text'
        )}
      </span>
      <Button
        className={cs(className, style.btn)}
        dark={is_requesting}
        fill={is_requesting || fill}
        onClick={toggleJoin}
      >
        {is_requesting ? (
          <Spinner className={style.icon} width={12} />
        ) : (
          <GroupIcon className={style.icon} />
        )}
      </Button>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////// REQUESTED
const Requested = ({
  className,
  old,
  ...rest
}: RequestedProps): React.ReactElement => {
  const { t } = useTranslation()

  if (old) {
    return (
      <Button
        disabled={true}
        className={cs(className, 'noActionDark noOpa')}
        {...rest}
        onClick={noop}
      >
        <FormattedHTMLMessage id="group.requested_access_button_label" />
        <GroupIcon />
      </Button>
    )
  }

  return (
    <div className={style.flexContainer}>
      <span className={style.linkText} onClick={noop}>
        {t('group:group.requested_access_button_label')}
      </span>
      <Button className={cs(className, style.btn)} onClick={noop}>
        <GroupIcon className={style.icon} />
      </Button>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////// JOIN GROUP BUTTON
const JoinGroupButton = (props: JoinGroupProps): React.ReactElement => {
  if (props.is_member) return <Leave {...props} dark fill />
  if (props.is_requested) return <Requested {...props} dark fill />
  if (props.is_invited) return <Invited {...props} fill />
  if (props.access_type === 'approved') return <Request {...props} />
  if (props.access_type === 'closed') {
    return (
      <div style={{ visibility: 'hidden' }}>
        <Request {...props} disabled />
      </div>
    )
  }

  return <Join {...props} />
}

export default JoinGroupButton
