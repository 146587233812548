import React from 'react'
import { defaultTo, find, path } from 'ramda'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const selectModalVisible = (state, ModalComponent) => {
  const testModal = React.createElement(ModalComponent, {})

  // convert state.modal.stack of type `[function]` to `[element]`
  // so that we can test the type property
  let modalStackAsElements = []

  if (state.modal.stack[0]) {
    state.modal.stack.map(modalComponent => {
      if (
        !(
          typeof modalComponent === 'function' ||
          modalComponent instanceof React.Component
        )
      ) {
        modalComponent = modalComponent.component
      }
      modalStackAsElements.push(React.createElement(modalComponent, {}))
    })
  }

  return !!find(modal => modal.type === testModal.type, modalStackAsElements)
}

export const countVisibleModals = state => {
  return defaultTo(0, path(['modal', 'stack', 'length'], state))
}
