import React from 'react'
import { useDispatch, useSelector } from '../../react-redux'
import get from 'lodash/get'

import * as apiState from '../../modules/api/selectors'
import * as entityState from '../../modules/entities/selectors'
import { selectAuthenticatedUser } from '../../modules/auth/selectors'

import FollowButton from '../buttons/follow'
import follow from '../../modules/entities/actions/follow'
import { postNotification } from '../../modules/notify/actions'
import { useTranslation } from 'next-i18next'

interface Props {
  resource: any
  flex?: boolean
  className?: string
  wide?: boolean
}

const SmartFollowButton = ({ resource, flex, className, ...rest }: Props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectAuthenticatedUser)
  const canFollow = useSelector(state =>
    resource.type === 'case'
      ? selectAuthenticatedUser(state).can_follow_case
      : selectAuthenticatedUser(state).can_follow_user
  )
  const is_requesting = useSelector(
    state =>
      apiState.isRequestActive(state, follow(resource, true)) ||
      apiState.isRequestActive(state, follow(resource, false))
  )
  const is_following = useSelector(state =>
    entityState.selectFollowStatus(state, resource.type, resource.id)
  )
  const userVerified = useSelector(
    state => selectAuthenticatedUser(state).verification_status === 'verified'
  )

  const { t } = useTranslation('notification')

  const dispatchFollow = (resource: any, id: string, action: 'add'): any => {
    dispatch(follow(resource, true, id, action))
  }
  const unFollow = (resource: any, id: string, action: 'remove'): any => {
    dispatch(follow(resource, false, id, action))
  }
  const postVerificationNotification = (message: string): any => {
    dispatch(postNotification({ kind: 'info', message, closeBtn: false }))
  }

  const toggleFollowing = (): any => {
    const currentUserId = get(currentUser, 'id', null)

    if (is_following) {
      unFollow(resource, currentUserId, 'remove')
    } else {
      dispatchFollow(resource, currentUserId, 'add')

      if (!userVerified) {
        postVerificationNotification(
          t('notification.unverified_users.follow_case')
        )
      }
    }
  }

  if (canFollow) {
    return (
      <FollowButton
        key={`${is_following}:${is_requesting}:${canFollow}`}
        resourceType={resource.type}
        toggleFollowing={toggleFollowing}
        is_following={is_following}
        is_requesting={is_requesting}
        flex={flex}
        className={className}
        {...rest}
      />
    )
  }

  return null
}

export default SmartFollowButton
