import Router from 'next/router'
import get from 'lodash/get'
import { path, pick } from 'ramda'

import * as track from '../../modules/analytics/track'

import { gc } from '../../modules/entities/actions/entity_gc'
import { closeAllModals } from '../../modules/modal/actions'
import { countVisibleModals } from '../../modules/modal/selectors'
import { isHashChange } from '../../modules/routing/selectors'
import { applySubstitutions } from '../../url'

import { countActiveRequests } from '../../modules/api/selectors'

let nextGC = new Date().getTime() + 120 * 1000

const createRoutingMiddleware = () => {
  return ({ dispatch, getState }) => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', (e: any) => {
        const message = get(getState(), 'router.block')
        if (message) {
          ;(e || window.event).returnValue = message //Gecko + IE
          return message //Gecko + Webkit, Safari, Chrome etc.
        }
      })
    }

    Router.events.on('routeChangeComplete', url => {
      //console.log('routeChangeComplete', url)
      dispatch({ type: 'ROUTER_SET_LOCATION', url })
      dispatch({
        type: 'ROUTER_UPDATE',
        route: pick(['route', 'query', 'asPath', 'pathname'], Router.router),
      })
    })

    return next => action => {
      if (action.type === 'ROUTER_PUSH') {
        if (action.to) {
          Router.push(action.to, action.as)
        } else {
          Router.push(action.payload)
        }
      } else if (action.type === 'ROUTER_RELOAD') {
        location.reload()
      } else if (action.type === 'ROUTER_GO_BACK') {
        countVisibleModals(getState()) > 0 && dispatch(closeAllModals())
        Router.back()
      }
      if (action.type === 'ROUTER_SET_LOCATION') {
        if (!isHashChange(getState(), action.payload)) {
          countVisibleModals(getState()) > 0 && dispatch(closeAllModals())
          const now = new Date().getTime()
          if (now >= nextGC) {
            nextGC = now + 120 * 1000
            dispatch(gc())
          }
          const location: Location = path(['document', 'location'], global)
          if (location) {
            const start = new Date().getTime()
            const trackIfReadyOrTimeout = () => {
              const a = countActiveRequests(getState())
              const delta = new Date().getTime() - start
              const title = path(['document', 'title'], global)
              if ((a > 0 && delta < 1000) || (title === '' && delta < 1500)) {
                setTimeout(trackIfReadyOrTimeout, 250)
              } else {
                track.view(location.pathname + location.search, title)
              }
            }
            trackIfReadyOrTimeout()
          }
        }
      }
      return next(action)
    }
  }
}

export { createRoutingMiddleware }
