import React from 'react'
import cs from 'classnames'

// import style from './Footer.module.scss';

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  children: React.ReactNode
}

const Footer = ({ children }: Props): React.ReactElement => (
  <footer className={cs('footer-navigation fs-unmask')}>{children}</footer>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Footer
