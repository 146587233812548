export const hasAutofill = (el: Element): boolean => {
  try {
    return el.matches(':autofill')
  } catch (e) {}
  return false
}

export const closest = (el: Node, match: Function): any => {
  if (!el || match(el)) {
    return el
  }
  return closest(el.parentNode, match)
}

export const getScreenSize = (): any => {
  const w: Window = window
  const d: HTMLDocument = document
  const e: HTMLElement = d.documentElement
  const g: HTMLBodyElement = d.getElementsByTagName('body')[0]

  return {
    width: w.innerWidth || e.clientWidth || g.clientWidth,
    height: w.innerHeight || e.clientHeight || g.clientHeight,
  }
}

let scripts = 0

export const addScript = (src: string, id?: string, removeAfter?: number) => {
  const scriptID = 'scripts-' + (id || ++scripts)
  if (document.getElementById(scriptID)) {
    return
  }
  var s = document.createElement('script')
  s.setAttribute('id', scriptID)
  s.setAttribute('src', src)
  document.body.appendChild(s)
  if (removeAfter) {
    setTimeout(() => s.parentNode.removeChild(s), removeAfter)
  }
}

export const addStyle = (src, id) => {
  const scriptID = 'styles-' + (id || ++scripts)
  if (document.getElementById(scriptID)) {
    return
  }
  var link = document.createElement('link')
  link.id = scriptID
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = src
  link.media = 'all'
  document.head.appendChild(link)
}
