import React from 'react'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  position: 'up' | 'down' | 'right' | 'left'
  title: string
  width?: number
  className?: string
  onClick?: Function
  style?: React.CSSProperties
}

const ArrowIcons = ({
  position,
  width = 17,
  title,
  className,
  onClick,
  style = {},
}: Props): React.ReactElement => {
  const styles: { height: number; width: number } = {
    height: position === 'down' || position === 'up' ? 17 : 30,
    width: position === 'down' || position === 'up' ? 30 : 17,
  }

  return (
    <Icon
      width={width ?? 17}
      sourceWidth={styles.width}
      sourceHeight={styles.height}
      title={title}
      id={`icon-arrow-${position}`}
      className={className}
      onClick={onClick}
      style={style}
    >
      {renderPolyline(position)}
    </Icon>
  )
}

function renderPolyline(position: string): any {
  switch (position) {
    case 'up':
      return (
        <polyline
          stroke="#fff"
          fill="none"
          strokeWidth="2"
          transform="translate(15.000000, 9.000000) rotate(-180.000000) translate(-15.000000, -9.000000)"
          points="1 2.0179913 14.9909986 16 29 2"
        />
      )
    case 'down':
      return (
        <polyline
          stroke="#fff"
          fill="none"
          strokeWidth="2"
          points="1 2.0179913 14.9909986 16 29 2"
        />
      )
    case 'right':
      return (
        <polyline
          stroke="#fff"
          strokeWidth="2"
          transform="translate(8.000000, 15.000000) scale(-1, 1) rotate(-180.000000) translate(-8.000000, -15.000000) "
          points="1.0179913 1 15 14.9909986 1 29"
        />
      )
    case 'left':
      return (
        <polyline
          stroke="#fff"
          strokeWidth="2"
          transform="translate(9.000000, 15.000000) rotate(-180.000000) translate(-9.000000, -15.000000) "
          points="2.0179913 1 16 14.9909986 2 29"
        />
      )

    default:
      return null
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export { ArrowIcons as default, renderPolyline }
