import React from 'react'
import cs from 'classnames'

import style from './styles/Img.module.scss'

export type ImgType = {
  src?: string
  alt?: string
  title?: string
  shape?: 'square' | 'rounded' | 'circle' | 'original'
  className?: string
  inlineStyle?: any
  adaptive?: boolean
  width?: number
  height?: number
}

const Img = ({
  src,
  alt,
  title,
  className,
  inlineStyle,
  adaptive,
  shape,
  ...rest
}: ImgType): React.ReactElement => {
  const classes: string = cs(className, style.img, {
    [style.adaptive]: adaptive,
    [style[shape ?? 'square']]: true,
  })

  return (
    <img
      src={src}
      alt={alt}
      title={title}
      className={classes}
      style={inlineStyle}
      {...rest}
    />
  )
}

export default Img
