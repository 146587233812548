import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import TagPickerRow from './TagPickerRow'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  results: Array<any>
  selections: any
  onToggleCategory: Function
}

const TagSearchList = ({
  results,
  selections,
  onToggleCategory,
}: Props): any => {
  return (
    <ul className="tag-picker__subspecialties list-bare">
      {results &&
        results.map(category => (
          <TagPickerRow
            key={category.id}
            category={category}
            tristate={true}
            selected={selections[category.id]}
            onToggleCategory={onToggleCategory}
          />
        ))}
    </ul>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TagSearchList
