import React from 'react'
import Icon from './icon'

interface Props {
  width?: number
  id?: string
  fill?: string
  stroke?: boolean
}

const StarHalfIcon = ({
  width = 13,
  id = 'icon-star-half',
  fill = 'black',
  stroke = false,
}: Props) => {
  return stroke ? (
    <Icon
      width={width}
      sourceWidth={35}
      sourceHeight={35}
      title="Star Half"
      id={id}
    >
      <g id="Rating Star full">
        <g id="filled/toggle/star" clipPath="url(#clip0_3934_66719)">
          <path
            id="Vector"
            d="M27.0007 30.5108L24.6776 20.5526L32.412 13.8523L33.3077 13.0763L32.1269 12.9761L21.9419 12.112L17.9617 2.7209L17.5013 1.63472L17.0409 2.7209L13.0607 12.112L2.8757 12.9761L1.69489 13.0763L2.59058 13.8523L10.325 20.5526L8.00188 30.5108L7.73267 31.6647L8.74717 31.0524L17.5013 25.7688L26.2554 31.0524L27.2699 31.6647L27.0007 30.5108Z"
            fill="#0C3569"
            stroke="black"
          />
          <g id="Half - yellow">
            <g id="01 ratingstar">
              <mask
                id="mask0_3934_66719"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="36"
                height="36"
              >
                <g id="masked icon">
                  <g id="filled/toggle/star_2">
                    <path
                      id="Vector_2"
                      d="M18.0131 24.3445L17.513 24.0427L17.013 24.3445L9.96401 28.599L11.8346 20.5804L11.9673 20.0116L11.5258 19.6291L5.29696 14.233L13.4969 13.5373L14.0784 13.488L14.3061 12.9507L17.513 5.38424L20.7199 12.9507L20.9476 13.488L21.5291 13.5373L29.7291 14.233L23.5002 19.6291L23.0587 20.0116L23.1914 20.5804L25.062 28.599L18.0131 24.3445Z"
                      stroke="black"
                      strokeWidth="1.93549"
                    />
                  </g>
                </g>
              </mask>
              <g mask="url(#mask0_3934_66719)">
                <rect
                  id="half empty"
                  x="17.4258"
                  y="-0.0117188"
                  width="17.4194"
                  height="35"
                  fill="#F2C94C"
                />
              </g>
            </g>
            <g id="01 ratingstar_2">
              <mask
                id="mask1_3934_66719"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="36"
                height="36"
              >
                <g id="masked icon_2">
                  <g id="filled/toggle/star_3">
                    <path
                      id="Vector_3"
                      d="M17.513 25.173L26.5255 30.6126L24.1339 20.3605L32.0964 13.4626L21.6109 12.573L17.513 2.9043L13.4151 12.573L2.92969 13.4626L10.8922 20.3605L8.50052 30.6126L17.513 25.173Z"
                      fill="black"
                    />
                  </g>
                </g>
              </mask>
              <g mask="url(#mask1_3934_66719)">
                <rect
                  id="half full - always see this side"
                  x="0.0117188"
                  y="-0.0117188"
                  width="17.4194"
                  height="35"
                  fill="#F2C94C"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3934_66719">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  ) : (
    <Icon
      width={width}
      sourceWidth={13}
      sourceHeight={13}
      title="Star Half"
      id={id}
    >
      <path
        d="M2.99256 12.3062L6.50082 10.4623V0C6.34455 0 6.21693 0.106783 6.11796 0.320353L4.35992 3.87549L0.437555 4.44587C0.14585 4.49275 0 4.61256 0 4.80529C0 4.91468 0.0651117 5.0397 0.195337 5.18034L3.03945 7.94631L2.36749 11.8531C2.35707 11.926 2.35186 11.9781 2.35186 12.0093C2.35186 12.1187 2.37921 12.2112 2.4339 12.2867C2.4886 12.3622 2.57064 12.4 2.68003 12.4C2.77379 12.4 2.87797 12.3687 2.99256 12.3062Z"
        fill={fill}
      />
      <path
        d="M9.6131 7.58835L9.42486 7.77193L9.46943 8.03107L10.1174 11.7983L6.73345 10.0197L6.50082 9.8974L6.26819 10.0197L2.88424 11.7983L3.53221 8.03107L3.57686 7.7715L3.38804 7.58787L0.645695 4.92086L4.43188 4.37028L4.69172 4.3325L4.80812 4.09712L6.50082 0.674106L8.19352 4.09712L8.30991 4.3325L8.56976 4.37028L12.3494 4.91991L9.6131 7.58835Z"
        stroke={fill}
      />
    </Icon>
  )
}

export default StarHalfIcon
