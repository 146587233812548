import cs from 'classnames'
import get from 'lodash/get'
import { TFunction, useTranslation, withTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'

import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { hasEmail } from '../../../modules/entities/user'
import { modalClose, modalOpen } from '../../../modules/modal/actions'
import FormattedHTMLMessage from '../../../ui/FormattedHTMLMessage'
import userKindFinder from '../../../utils/userKind'
import VerifyModal from '../../components/VerifyModal/VerifyModal'
import style from './styles/AuthorizationModal.module.scss'

type Props = {
  t: TFunction
  widgetId: string
  userProEmail: string
  userKind: string
  modalId: string
  userHasPhone: boolean
  closeModal: Function
  onClose: Function
  openVerifyModal: Function
}

class AuthorizationModal extends React.Component<Props> {
  closeModal = (): void => {
    this.props.closeModal(this.props.modalId)

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleOpenVerifyModal = (): void => {
    this.closeModal() // close the AuthorizationModal modal
    this.props.openVerifyModal() // open the Verification modal
  }

  getContent = (): any => {
    const { widgetId, userProEmail, userKind, userHasPhone, t } = this.props
    const emailType =
      userKind === 'student' ? (
        <Translate key={'email_type.academic'} />
      ) : (
        <Translate key={'email_type.institutional'} />
      )
    const userWithPhone = userHasPhone ? '_nofb' : ''
    const userKindText = t(userKindFinder(userKind))

    switch (widgetId) {
      // //////////////////////////////////////////////////////// Module 1-2-3-4
      case 'trusted_email_confirm':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate key={'promo_any.not_verified'} />
              </div>
              <div className={cs('row', style.instructions)}>
                <Translate key={'promo_any.verify_your_account'} />
              </div>
              <div className={cs('row', 'fs-exclude', style.email)}>
                {userProEmail}
              </div>
              <div className={cs('row', style.action)}>
                <span
                  className={style.linkBtn}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={'trusted_email_confirm.buttonLabel'} />
                </span>
              </div>
            </section>
            <section className={style.item}>{this.renderFooter(true)}</section>
          </div>
        )

      // ///////////////////////////////////////////////////////// Module 5-6-12
      case 'verified_pending_email_confirmation':
      case 'untrusted_unconfirmed_email_confirm':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                {userHasPhone ? (
                  <Translate
                    key={
                      'untrusted_unconfirmed_email_confirm_nofb_mobile.modal.message'
                    }
                    values={{ userKind: userKindText }}
                  />
                ) : (
                  <Translate key={'promo_any.not_verified'} />
                )}
              </div>
              {userHasPhone ? null : (
                <div className={cs('row', style.instructions)}>
                  <Translate key={'promo_any.confirm_by_clicking'} />
                </div>
              )}
              {userProEmail && (
                <div className={cs('row', 'fs-exclude', style.email)}>
                  {userProEmail}
                </div>
              )}
              <div className={cs('row', style.action)}>
                {userHasPhone ? (
                  <Translate
                    key={
                      'untrusted_unconfirmed_email_confirm_mobile_nofb.title'
                    }
                    values={{ emailType }}
                  />
                ) : (
                  <Translate key={'promo_any.wrong_email_upload'} />
                )}

                <div>
                  <span
                    className={cs(style.linkBtn, style.m_t_10)}
                    onClick={this.handleOpenVerifyModal}
                  >
                    <Translate
                      key={
                        userHasPhone
                          ? 'untrusted_unconfirmed_email_confirm_mobile_nofb.buttonLabel'
                          : 'promo_any.update_or_upload'
                      }
                    />
                  </span>
                </div>
              </div>
            </section>

            <section className={style.item}>
              {this.renderFooter(true, userHasPhone)}
            </section>
          </div>
        )

      // ////////////////////////////////////////////////////////////// Module 7
      case 'untrusted_confirmed_email_upload_document':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate key={'promo_any.not_verified'} />
              </div>
              <div className={cs('row', style.instructions)}>
                <FormattedHTMLMessage id="promo_any.thanks_for_confirming" />
              </div>
              <div className={cs('row', 'fs-exclude', style.email)}>
                {userProEmail}
              </div>
              <div className={cs('row', style.action)}>
                <Translate key={'promo_any.please_upload'} />
                <span
                  className={style.linkBtn}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={'promo_any.upload_or_add'} />
                </span>
              </div>
            </section>
            <section className={style.item}>{this.renderFooter(false)}</section>
          </div>
        )

      // ////////////////////////////////////////////////////////////// Module 8
      case 'untrusted_unconfirmed_email_document_pending':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate
                  key={
                    userHasPhone
                      ? 'untrusted_unconfirmed_email_document_pending_nofb.title'
                      : 'untrusted_unconfirmed_email_document_pending.title'
                  }
                />
                {!userHasPhone && (
                  <div className={style.subtitle}>
                    <FormattedHTMLMessage id="untrusted_confirmed_email_document_pending.content.text" />
                  </div>
                )}
              </div>

              <div className={cs('row', style.instructions)}>
                <FormattedHTMLMessage
                  id={`promo_any.confirm_by_clicking_email${userWithPhone}`}
                />
                {userHasPhone && (
                  <div className={style.subtitle}>
                    <FormattedHTMLMessage id="untrusted_confirmed_email_document_pending.content.text" />
                  </div>
                )}
              </div>

              {!userHasPhone && (
                <div className={cs('row', 'fs-exclude', style.email)}>
                  {userProEmail}
                </div>
              )}

              <div className={cs('row', style.action)}>
                <Translate
                  key={`promo_any.wrong_email${userWithPhone}`}
                  values={{ emailType }}
                />
                <span
                  className={cs(style.linkBtn, style.m_t_10, style.m_b_10)}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={`promo_any.update_now${userWithPhone}`} />
                </span>
              </div>
            </section>

            <section className={style.item}>
              {this.renderFooter(true, userHasPhone)}
            </section>
          </div>
        )

      // ////////////////////////////////////////////////////////////// Module 9
      case 'document_rejected':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate key={'promo_document_rejected.title'} />
              </div>

              <div className={cs('row', style.action)}>
                {userKind === 'student' ? (
                  <Translate key={'promo_any.please_upload.student'} />
                ) : (
                  <Translate key={'promo_any.please_upload'} />
                )}
                <span
                  className={style.linkBtn}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={'promo_any.upload_or_add'} />
                </span>
              </div>
            </section>

            <section className={style.item}>{this.renderFooter(true)}</section>
          </div>
        )

      // ///////////////////////////////////////////////////////////// Module 10
      case 'unconfirmed_email_document_approved':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate
                  key={'promo_unconfirmed_user_document_approved.title'}
                />
              </div>
              <div className={cs('row', style.instructions)}>
                <FormattedHTMLMessage id="promo_any.confirm_by_clicking" />
              </div>
              <div className={cs('row', 'fs-exclude', style.email)}>
                {userProEmail}
              </div>
              <div className={cs('row', style.action)}>
                <Translate key={'promo_any.wrong_email'} />
                <span
                  className={style.linkBtn}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={'promo_any.update_now'} />
                </span>
              </div>
            </section>
            <section className={style.item}>{this.renderFooter(true)}</section>
          </div>
        )

      // ///////////////////////////////////////////////////////////// Module 11
      case 'untrusted_confirmed_email_document_pending':
        return (
          <div className={style.body}>
            <section className={style.item}>
              <div className={cs('row', style.title)}>
                <Translate key={'promo_any.not_verified'} />
              </div>

              <div className={cs('row', style.instructions)}>
                <FormattedHTMLMessage id="promo_any.thanks_for_confirming" />
              </div>
              <div className={cs('row', 'fs-exclude', style.email)}>
                {userProEmail}
              </div>
              <div className={cs('row', style.action)}>
                <Translate key={'promo_any.wrong_email'} />
                <span
                  className={style.linkBtn}
                  onClick={this.handleOpenVerifyModal}
                >
                  <Translate key={'promo_any.update_now'} />
                </span>
              </div>

              <div className={cs('row', style.instructionsSmallPadding)}>
                <FormattedHTMLMessage id="promo_any.thanks_for_submitting" />
              </div>
              <div className={cs('row', style.action)}>
                <Translate key={'promo_any.document_pending_delay'} />
              </div>
            </section>

            <section className={style.item}>{this.renderFooter(false)}</section>
          </div>
        )
    }
  }

  renderFooter(
    displayCheckSpam: boolean = false,
    userHasPhone: any = false
  ): any {
    return (
      <div className={cs('row', style.footer)}>
        {displayCheckSpam && !userHasPhone && (
          <div className={style.spam}>
            <Translate key={'promo_any.spam'} />
            <Translate key={'promo_any.spam'} />
          </div>
        )}
        <div className={style.disclaimer}>
          <Translate key={'promo_any.disclaimer'} />
          <Translate key={'promo_any.spam'} />

          <span className={style.linkBtn} onClick={this.handleOpenVerifyModal}>
            <Translate key={'promo_any.find_out_more'} />
          </span>
        </div>
        <div className={style.questions}>
          <FormattedHTMLMessage id="common:questions" />
        </div>
      </div>
    )
  }

  render(): React.ReactElement {
    return (
      <div className={style.modal}>
        <div className={style.closeButton}>
          <div className={style.closeShape} onClick={this.closeModal} />
        </div>

        {this.getContent()}
      </div>
    )
  }
}

const Translate = ({ key, values }: { key: string; values?: object }) => {
  const { t } = useTranslation('common')
  return <>{t(key, values)}</>
}

const mapStateToProps = (state: any): any => {
  const user = selectAuthenticatedUser(state)
  const emailExist = hasEmail(user)
  const userHasPhone = get(user, 'confirmed_phone') && !emailExist

  return {
    widgetId: get(state, 'activity.promo.type'),
    userProEmail: get(user, 'professional_email', ''),
    userKind: user.kind,
    modalId: get(state, 'modal.newStack[0].id'),
    userHasPhone,
  }
}

const mapDispatchToProps = (dispatch: Function): any => ({
  closeModal(modalId: string): any {
    dispatch(modalClose(modalId))
  },
  openVerifyModal(): any {
    dispatch(modalOpen({ component: VerifyModal }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AuthorizationModal))
