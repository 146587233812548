import React from 'react'

// ////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
import { IconWithDefsType } from './icon'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// //////////////////////////////////////////////////// RENDER VARIABLES
const renderState = {
  normal: (
    <path
      d="M20,8L0,0,2.5,8,0,16ZM1.58,1.71L16.06,7.5H3.39ZM3.39,8.5H16.06L1.58,14.29Z"
      style={{ fill: '#4b9bef' }}
    />
  ),
  hover: (
    <g>
      <polygon
        points="18.75 7.5 0 0 2.34 7.5 18.75 7.5"
        style={{ fill: '#1b6ebf' }}
      />
      <polygon
        points="0 16 18.75 8.5 2.34 8.5 0 16"
        style={{ fill: '#1b6ebf' }}
      />
    </g>
  ),
  posting: (
    <g>
      <polygon
        points="18.75 7.5 0 0 2.34 7.5 18.75 7.5"
        style={{ fill: '#314559' }}
      />
      <polygon
        points="0 16 18.75 8.5 2.34 8.5 0 16"
        style={{ fill: '#314559' }}
      />
    </g>
  ),
}
const noop = () => {}

interface PostIconProps {
  width?: number
  id?: string
  title?: string
  className?: string
  isMobile?: boolean
  posting?: boolean
  hover?: boolean
  onClick?: () => void
}

const PostIcon = ({
  width = 20,
  id = 'icon-like',
  title = 'like',
  className = undefined,
  isMobile = false,
  posting = false,
  hover = false,
  onClick = noop,
}: PostIconProps): React.ReactElement => {
  const state = stateSwitcher({ posting, isMobile, hover })
  const selectedRender = renderState[state]

  return (
    <Icon
      width={width}
      sourceWidth={20}
      sourceHeight={16}
      title={title}
      id={id}
      className={className}
      onClick={onClick}
    >
      {selectedRender}
    </Icon>
  )
}

function stateSwitcher({ posting, isMobile, hover }: any): string {
  if (posting) return 'posting'
  else if (isMobile) return 'mobile'
  else if (hover) return 'hover'

  return 'normal'
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default PostIcon
