import update from 'react-addons-update'
import { dissocPath } from 'ramda'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

export const callJSON = (v, state) => {
  const funcs = {
    $eq: (a, b) => a == b,
    $ne: (a, b) => a != b,
    $and: (a, b) => a && b,
    $selectState: (...args) => get(state, args),
  }

  const parse = (r, v, k) => {
    if (isArray(r)) {
      r.push(callJSON(v, state))
    } else if (isObject(r)) {
      r[k] = callJSON(v, state)
    } else {
      r = v
    }
    return r
  }

  if (isArray(v)) {
    return reduce(v, parse, [])
  }

  if (isObject(v)) {
    const keys = Object.keys(v)
    if (keys.length === 1 && keys[0][0] === '$') {
      const functionName = keys[0]
      const args = callJSON(v[functionName], state)
      const res = funcs[functionName].apply(null, args)
      return res
    } else {
      v = reduce(v, parse, {})
    }
  }

  return v
}

export const globalReducer = (state = {}, action) => {
  if (action.type === 'PATCH') {
    if (!action.cond || callJSON(action.cond, state)) {
      try {
        return update(state, action.patch)
      } catch (e) {
        return state
      }
    }
  } else if (action.type === 'DELETE') {
    return dissocPath(action.path, state)
  }
  return state
}
