import React from 'react'
// /////////////////////////////////////////////////////////////////// COMPONENT
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
}

const XCircle = (props: Props): React.ReactElement => (
  <Icon
    id={props.id || 'icon-x-circle'}
    sourceWidth={18}
    sourceHeight={18}
    title={props.title ?? 'Clear'}
    className={props.className}
    onClick={props.onClick}
  >
    <g strokeWidth="1.25" fill="none">
      <path d="M6,12 L12,6" strokeLinecap="round" />
      <path d="M12,12 L6,6" strokeLinecap="round" />
      <path d="M17,9 C17,13.4185 13.418,17 9,17 C4.582,17 1,13.4185 1,9 C1,4.5815 4.582,1 9,1 C13.418,1 17,4.5815 17,9 L17,9 Z" />
    </g>
  </Icon>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default XCircle
