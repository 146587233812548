import get from 'lodash/get'
import React from 'react'
import { selectIsAuthenticated } from '../modules/auth/selectors'
import { useSelector } from '../react-redux'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { link_for_resource } from '../url'
import { TextLink } from './link'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  className?: string
  to?: any
  style?: any
  resourceContext?: any
  resource_id?: string
  resource_type?: string
  resource_title?: string
  children?: React.ReactNode
  isLearning?: boolean
}

const ResourceLink = ({
  className,
  to,
  style,
  resourceContext,
  resource_id,
  resource_type,
  resource_title,
  isLearning,
  children,
}: Props): React.ReactElement => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isResource_type = get(to, 'type', resource_type)
  const isResource_id = get(to, 'id', resource_id)
  const url = link_for_resource(
    { type: isResource_type, id: isResource_id },
    undefined,
    resourceContext
  )
  const LinkDefaultClass = resource_type
    ? `link--${resource_type.toString()}`
    : ''
  const classesName = className
    ? `${className} ${LinkDefaultClass}`
    : LinkDefaultClass

  if (isAuthenticated) {
    return (
      <TextLink to={url} className={classesName} style={style} targetBlank={isLearning}>
        {children}
      </TextLink>
    )
  } else {
    // this path avoids using a next link, meaning it actually refreshes on load
    // (which in turn performs authorization checks + redirecting)
    return <a href={url.as}>{children}</a>
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ResourceLink
