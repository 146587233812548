import { useTranslation } from 'next-i18next'
import React from 'react'
import Spinner from '../icons/spinner'
import Tick from '../icons/tick'
import Button from './button'

interface Props {
  type?: 'button' | 'submit' | 'reset'
  className?: string
  saving?: boolean
  fill?: boolean
  save_complete?: boolean
  disabled?: boolean
  onClick?: (any) => void
}

const SaveButton = (props: Props) => {
  const { disabled, saving, save_complete, onClick, ...rest } = props
  const isDisabled = disabled || saving || save_complete
  const { t } = useTranslation()

  return (
    <Button
      {...rest}
      onClick={isDisabled ? () => {} : onClick}
      disabled={isDisabled}
    >
      {save_complete ? (
        <span>
          {t('common:form.saved_button_label')}
          <Tick title="Accept" />
        </span>
      ) : saving ? (
        <span>
          {t('common:form.saving_button_label')}
          <Spinner width={15} />
        </span>
      ) : (
        t('common:form.save_button_label')
      )}
    </Button>
  )
}

export default SaveButton
