// ///////////////////////////////////////////////////////////////////// MODULE
import callAPI from '../../../modules/api/call'

export type TeamResponse = {
  success: boolean
  response: any
}

export function getTeamByName(teamName: string): Promise<TeamResponse> {
  let url = '/team/' + (teamName ? teamName : '')

  if (url === '/team/') url = '/team/medshr'

  return callAPI({
    url: `/api${url}`,
    method: 'GET',
  })
    .then(({ payload }: any): TeamResponse => payload as TeamResponse)
    .catch((err: any): any => err) as Promise<TeamResponse>
}
