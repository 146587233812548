import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'
import { Checkbox } from './base'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/InputField.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type InputFieldType = {
  input: any
  meta?: any
  type?: string
  label: string
  labelExtra?: string
  disabled?: boolean
  checked?: boolean
  onChange?: Function
  value?: string
  flexbox?: boolean
  hasError?: boolean
}

const CheckBoxField = ({
  input: { ...restInput },
  meta: { touched, error, warning, dirty, ...restMeta },
  type,
  label,
  hasError,
  labelExtra,
  flexbox,
  ...rest
}: InputFieldType): any => {
  const labelText = (
    <label htmlFor={label} className={style.label}>
      {label}
      {labelExtra && (
        <span className={cs(style.extraLabel, { [style.hasError]: hasError })}>
          {labelExtra}
        </span>
      )}
    </label>
  )

  if (flexbox) {
    return (
      <div className={style.flexContainer}>
        {label ? labelText : null}
        <Checkbox
          id={label}
          type={type}
          {...restInput}
          {...restMeta}
          {...rest}
        />
        {renderErrors({ touched, dirty, error, warning })}
      </div>
    )
  }

  return (
    <div className={style.container}>
      {label ? labelText : null}
      <Checkbox id={label} type={type} {...restInput} {...restMeta} {...rest} />
      {renderErrors({ touched, dirty, error, warning })}
    </div>
  )
}

function renderErrors({ touched, dirty, error, warning }: any): any {
  if (touched && dirty) {
    if (error) {
      return <Error customClass={style.textError} message={error} hasError />
    } else if (warning) {
      return <Error customClass={style.textError} message={error} hasWarning />
    }
  }

  return null
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default CheckBoxField
