import React from 'react'
import Select from 'react-select'
import Async from 'react-select/async'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'

// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/ReactSelectField.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  input: any
  meta: any
  type: string
  placeholder: string
  label: string
}

const noop = () => {}

const ReactSelectField = ({
  input: { value, onBlur, ...restInput },
  meta,
  placeholder,
  label,
  type,
  ...rest
}: PropsType): React.ReactElement => {
  let labelText = null
  if (label) {
    labelText = (
      <label htmlFor={label || placeholder}>{label || placeholder}</label>
    )
  }
  const SelectComponent =
    type === 'AsyncCreatable' ? Async : type === 'Async' ? Async : Select

  return (
    <div className={style.container}>
      {labelText}
      <SelectComponent
        style={{
          height: 40,
          borderRadius: 2,
          marginBottom: 10,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          color: '#4b9bef',
        }}
        value={value || ''}
        {...restInput}
        {...meta}
        {...rest}
      />
      {meta.touched && meta.error && (
        <Error
          customClass={style.textError}
          message={meta.error}
          hasError
          size={'md'}
        />
      )}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ReactSelectField
