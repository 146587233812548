import { eqBy, prop, unionWith } from 'ramda'

// ///////////////////////////////////////////////////////////// ACTIONS
import * as repliesTypes from './actions/LoadReplies'
import * as addReplyTypes from './actions/AddReply'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

type MergeType = Array<Object>

const INITIALSTATE = {
  error: '',
  isLoading: {},
  fetching: false,
  list: {},
  users: {},
}

const mergeArrays = (oldArr: MergeType, newArr: MergeType): any => {
  return unionWith(eqBy(prop('id')), oldArr, newArr)
}

const likeReducer = (
  state: any = INITIALSTATE,
  { type, payload, commentId, error }: any
): any => {
  switch (type) {
    // ////////////////////////////////////////////// LOAD LIKES REQUEST
    case repliesTypes.LOAD_REPLIES_REQUEST:
      return {
        ...state,
        isLoading: { ...state.isLoading, [commentId]: true },
      }
    // ////////////////////////////////////////////// LOAD LIKES SUCCESS
    case repliesTypes.LOAD_REPLIES_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, [payload.commentId]: false },
        list: { ...state.list, [payload.commentId]: payload.comments },
        users: { ...state.users, ...payload.users },
      }
    // ////////////////////////////////////////////// LOAD LIKES SUCCESS
    case repliesTypes.LOAD_REPLIES_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, [commentId]: false },
        error,
      }

    /// ////////////////////////////////////////////// ADD REPLY SUCCESS
    case addReplyTypes.ADD_REPLY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [payload.parent.id]: [
            ...mergeArrays(state.list[payload.parent.id], payload.results),
          ],
        },
        users: { ...state.users, ...payload.users },
      }

    // /////////////////////////////////////////////////////////// RESET
    case repliesTypes.RESET_REPLIES_STATE:
      return {
        error: '',
        isLoading: {},
        fetching: false,
        list: {},
        users: {},
      }

    default:
      return state
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default likeReducer
