import get from 'lodash/get'
import isArray from 'lodash/isArray'

import * as searchTypes from './types'

export function selectSearchTerm(state) {
  return state.search.term || ''
}

export function selectSearchFilters(state) {
  return (
    state.search.filter || {
      user: true,
      case: true,
      group: true,
      learning: true,
    }
  )
}

export function selectRecentSearches(state, searchType) {
  if (isArray(state.search.recentSearches)) {
    if (searchType === searchTypes.SEARCH_TYPE_MAIN) {
      return state.search.recentSearches || []
    }
    return []
  }
  return get(state.search.recentSearches, searchType, [])
}
