import cs from 'classnames'
import React from 'react'

import noop from '../../utils/noop'
import FormattedHTMLMessage from '../FormattedHTMLMessage'
import Connecting from '../icons/spinner'
import Connected from '../icons/user'
import Connect from '../icons/user-connect'
import Button from './button'
import style from './styles/connect.module.scss'

type Props = {
  connected?: boolean
  active?: boolean
  sent?: boolean
  received?: boolean
  flex?: boolean
  wide?: boolean
  onConnectClick: (e: React.MouseEvent) => void
  className: string
  textOnly?: boolean
  multiLine?: boolean
}

const ConnectButton = ({
  connected,
  active,
  textOnly,
  sent,
  received,
  className,
  onConnectClick,
  multiLine = false,
  flex = false,
  ...props
}: Props): any => {
  const icon = textOnly ? null : active ? (
    <Connecting width={12} />
  ) : connected ? (
    <Connected />
  ) : (
    <Connect />
  )

  const message = () => {
    if (active) return 'member.sending_button_label'
    else if (sent) return 'member.sent_button_label'
    else if (received) return 'member.received_button_label'
    else if (connected) return 'member.connected_button_label'

    return 'member.connect_button_label'
  }

  const textClass = cs('btn--connect__label', {
    multiline: multiLine,
  })

  if (flex) {
    return (
      <div className={style.flexContainer}>
        <span
          className={style.linkText}
          onClick={active ? noop : onConnectClick}
        >
          <FormattedHTMLMessage id={message()} />
        </span>
        <Button className={style.btn} onClick={active ? noop : onConnectClick}>
          {icon}
        </Button>
      </div>
    )
  }

  return (
    <Button
      connect={connected || !active}
      dark={active || connected}
      fill={active || connected}
      className={cs(className, 'btn--connect')}
      onClick={active ? noop : onConnectClick}
      {...props}
    >
      <span className={textClass}>
        <FormattedHTMLMessage id={message()} />
      </span>
      {icon}
    </Button>
  )
}

export default ConnectButton
