import React from 'react'
import { number, string } from 'prop-types'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  width?: number
  id?: string
  title?: string
  className?: string
}

const PlusIcon = ({
  width = 20,
  id = 'icon-plus-default',
  title = '+',
  className = undefined,
}: Props): React.ReactElement => {
  return (
    <Icon
      width={width}
      sourceWidth={20}
      sourceHeight={20}
      title={title}
      id={id}
      className={className}
    >
      <path d="M9,9 L0,9 L0,11 L9,11 L9,20 L11,20 L11,11 L20,11 L20,9 L11,9 L11,0 L9,0 L9,9 Z" />
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default PlusIcon
