import createRequestTypes, {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../utils/createRequestType'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const CHECK_TRUSTED_EMAIL_REQUEST: string = 'CHECK_TRUSTED_EMAIL_REQUEST'
export const CHECK_TRUSTED_EMAIL_SUCCESS: string = 'CHECK_TRUSTED_EMAIL_SUCCESS'
export const CHECK_TRUSTED_EMAIL_FAILURE: string = 'CHECK_TRUSTED_EMAIL_FAILURE'

export const POST_CONFIRMATION_EMAIL: string = 'POST_CONFIRMATION_EMAIL'
export const POST_CONFIRMATION_EMAIL_SUCCESS: string =
  'POST_CONFIRMATION_EMAIL_SUCCESS'
export const POST_CONFIRMATION_EMAIL_FAILURE: string =
  'POST_CONFIRMATION_EMAIL_FAILURE'

export const POST_DOCUMENT: string = 'POST_DOCUMENT'
export const POST_DOCUMENT_SUCCESS: string = 'POST_DOCUMENT_SUCCESS'
export const POST_DOCUMENT_FAILURE: string = 'POST_DOCUMENT_FAILURE'

export const GET_INITIAL_EMAIL: string = 'GET_INITIAL_EMAIL'
export const UPDATE_INITIAL_EMAIL: string = 'UPDATE_INITIAL_EMAIL'
export const SAVE_INITIAL_EMAIL: string = 'SAVE_INITIAL_EMAIL'

export const RESET_INITIAL_EMAIL: string = 'RESET_INITIAL_EMAIL'
export const DOCUMENT_LOAD_STATE: string = 'DOCUMENT_LOAD_STATE'

export const EMAIL_SAVING: string = 'EMAIL_SAVING'
export const EMAIL_SAVED: string = 'EMAIL_SAVED'

export const INIT_ISLOADED: string = 'INIT_ISLOADED'

// //////////////////////////////////////////////////// INITIALIZE DEFAULT EMAIL
export function initDefaultLoaded(): any {
  return { type: INIT_ISLOADED }
}

// ////////////////////////////////////////////////////////////////// SAVE PHONE
export const SAVE_PHONE = createRequestTypes('SAVE_PHONE')
export const savePhone = {
  request: (payload: any): any => action(SAVE_PHONE[REQUEST], payload),
  success: (payload: any): any => action(SAVE_PHONE[SUCCESS], { payload }),
  failure: (error: any): any => action(SAVE_PHONE[FAILURE], { error }),
}

// ///////////////////////////////////////////////////////////////// VERIFY CODE
export const VERIFY_CODE = createRequestTypes('VERIFY_CODE')
export const verifyCode = {
  request: (payload: any = {}): any => action(VERIFY_CODE[REQUEST], payload),
  success: (payload: any = {}): any =>
    action(VERIFY_CODE[SUCCESS], { payload }),
  failure: (error: any): any => action(VERIFY_CODE[FAILURE], { error }),
}

// ///////////////////////////////////////////////////// Post confirmation email
export function postConfirmEmailRequest(email: string, emailType: string): any {
  return { type: POST_CONFIRMATION_EMAIL, email, emailType }
}

export function postConfirmEmailSuccess(payload: boolean): any {
  return { type: POST_CONFIRMATION_EMAIL_SUCCESS, payload }
}

export function postConfirmEmailFailed(error: string): any {
  return { type: POST_CONFIRMATION_EMAIL_FAILURE, error }
}

// /////////////////////////////////////////////////////////////// Post document
export function postDocumentRequest(file: File): any {
  return { type: POST_DOCUMENT, file }
}

export function postDocumentSuccess(): any {
  return { type: POST_DOCUMENT_SUCCESS }
}

export function postDocumentFailed(error: any): any {
  return { type: POST_DOCUMENT_FAILURE, error }
}

// ///////////////////////////////////////////////////////// Check trusted email
export function checkTrustedEmailRequest(email: any): any {
  return { type: CHECK_TRUSTED_EMAIL_REQUEST, email }
}

export function checkTrustedEmailSuccess(trusted: boolean): any {
  return { type: CHECK_TRUSTED_EMAIL_SUCCESS, trusted }
}

export function checkTrustedEmailFailed(error: string): any {
  return { type: CHECK_TRUSTED_EMAIL_FAILURE, error }
}

// //////////////////////////////////////////////// Get initial email from store
export function getInitialEmail(): any {
  return { type: GET_INITIAL_EMAIL }
}

export function saveInitialEMail(payload: string): any {
  return { type: SAVE_INITIAL_EMAIL, payload }
}

export function resetInitialEmail(): any {
  return { type: RESET_INITIAL_EMAIL }
}

// /////////////////////////////////////////////// Reset the document load state
export function resetDocumentLoad(payload: boolean): any {
  return { type: DOCUMENT_LOAD_STATE, payload }
}
