import callAPI from '../../../modules/api/call'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const UPDATE_LIKE_REQUEST = 'UPDATE_LIKE_REQUEST'
export const UPDATE_LIKE_SUCCESS = 'UPDATE_LIKE_SUCCESS'
export const UPDATE_LIKE_FAILURE = 'UPDATE_LIKE_FAILURE'
export const OPTIMISTIC_UPDATE_LIKE = 'OPTIMISTIC_UPDATE_LIKE'

export const BLOCK_LIKE_REQUEST = 'BLOCK_LIKE_REQUEST'
export const UNBLOCK_LIKE_REQUEST = 'UNBLOCK_LIKE_REQUEST'

// ////////////////////////////////////////////////////////// BLOCK LIKE REQUEST
export function blockLikeRequest(): any {
  return { type: BLOCK_LIKE_REQUEST }
}
export function unBlockLikeRequest(): any {
  return { type: UNBLOCK_LIKE_REQUEST }
}

// ///////////////////////////////////////////////////////////// ACTION CREATORS
export function updateLikeRequest(caseId: string, commentId: string): any {
  return { type: UPDATE_LIKE_REQUEST, commentId, caseId }
}
export function optimisticUpdate(newState: any): any {
  return { type: OPTIMISTIC_UPDATE_LIKE, newState }
}
export function updateLikeSuccess(newState: any): any {
  return { type: UPDATE_LIKE_SUCCESS, newState }
}
export function updateLikeFailed(error: string): any {
  return { type: UPDATE_LIKE_FAILURE, error }
}

// /////////////////////////////////////////////////////////////// LIKE API CALL
export function updateLikeById(
  caseId: string,
  commentId: string
): Promise<any> {
  return callAPI({
    url: `/api/cases/${caseId}/comments/${commentId}/likes`,
    method: 'POST',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: Error): any => err)
}
