import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import cs from 'classnames'
import { useAuthenticationState } from '../../modules/auth/hooks'

import { useSelector, useDispatch } from '../../react-redux'
import { selectIsClientPortalUser } from '../../modules/auth/selectors'
import { selectIsMobile } from '../../modules/responsive/selectors'

import { TextLink } from '../../ui/link'
import MedShrLogo from '../../ui/medshr-logo'
import MedShrLogoText from '../../ui/medshr-logo-text'
import UserMenu from './UserMenu'
import SpecialtiesHeader from './SpecialtiesHeader'
import MenuIcon from '../../ui/icons/menu'
import SearchIcon from '../../ui/icons/search'

import * as modal from '../../modules/modal/actions'

const SearchModal = dynamic(() =>
  import('../../routes/components/SearchModal').then(mod => mod.SearchModal)
)

type Props = {
  toggleMobileMenu: () => void
}

const Header = ({ toggleMobileMenu }: Props) => {
  const router = useRouter()

  const { isAuthenticating, isAuthenticated } = useAuthenticationState()
  const isClientPortalUser = useSelector(selectIsClientPortalUser)
  const isMobile = useSelector(selectIsMobile)
  const dispatch = useDispatch()

  const isSignupPage =
    router.pathname.startsWith('/join/') ||
    router.pathname.startsWith('/docsearch/join')
  const isDocsearch =
    router.pathname.startsWith('/docsearch') ||
    router.asPath === '/pages/docsearch'
  const isLearningLandingPage = router.pathname.startsWith('/learning')
  const isVerificationPage = router.pathname.startsWith('/join/verification')
  const isDocsearchLogin = router.pathname.startsWith('/docsearch/login')
  const isLearningModule = router.pathname.startsWith(
    '/learning/groups/[id]/[seriesID]/[moduleID]'
  )

  const showNavigation =
    !isAuthenticating &&
    isAuthenticated &&
    !isVerificationPage &&
    !isSignupPage &&
    !isClientPortalUser

  const handleSearchClick = () =>
    dispatch(modal.openModal({ component: SearchModal }))

  return (
    <header
      className={cs('dark-background page__header fs-unmask', {
        'page__header--docsearch': isDocsearch,
      })}
    >
      <div
        className={`page__header-inner page__container--desk page__container--lap page__container--large-desk`}
      >
        {isDocsearch ? (
          <div className="page__header__logo">
            <h1 className="site-logo">
              <TextLink
                to="/"
                className="site-logo__link"
                forceReload={!isAuthenticated}
              >
                <img
                  src="/static/images/medshr-docsearch.svg"
                  height="50"
                  style={{
                    background: 'transparent',
                    maxWidth: '100%',
                  }}
                  alt="MedShr docsearch"
                />
              </TextLink>
            </h1>
          </div>
        ) : (
          <div className="page__header__logo">
            <h1 className="site-logo">
              <TextLink
                to="/"
                className="site-logo__link"
                forceReload={!isAuthenticated}
              >
                <MedShrLogo width={40} />
                {isAuthenticated ? null : (
                  <span className="not-palm">
                    <MedShrLogoText />
                  </span>
                )}
              </TextLink>
            </h1>
            {showNavigation ? <SpecialtiesHeader /> : null}
          </div>
        )}

        {isAuthenticated || isAuthenticating || isDocsearchLogin ? null : (
          <div className="page__header__signin">
            <span>
              {isSignupPage || isDocsearch ? (
                <span className="page__header__already-member">
                  Already a MedShr member?
                </span>
              ) : null}
              <span className="btn-list">
                <Link
                  className="btn btn--medium btn--pill"
                  href={`/login?next=${router.asPath}`}
                >
                  Sign in
                </Link>
                <Link
                  className="btn btn--medium btn--pill btn--fill btn--secondary"
                  href={`/join?next=${router.asPath}`}
                >
                  Sign up
                </Link>
              </span>
            </span>
          </div>
        )}

        {showNavigation && isMobile ? (
          <>
            {isDocsearch ? null : (
              <span
                onClick={() => handleSearchClick()}
                className={'not-lap-and-up search-toggle'}
              >
                <SearchIcon width={24} />
              </span>
            )}
            <span
              onClick={() => toggleMobileMenu()}
              className={'not-lap-and-up off-canvas-navigation-toggle'}
            >
              <MenuIcon />
            </span>
          </>
        ) : null}

        {showNavigation && (
          <div>{!isMobile && <UserMenu minimal={isDocsearch} />}</div>
        )}
      </div>
    </header>
  )
}

export default Header
