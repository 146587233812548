const INITIALSTATE: { url?: string; previous?: string; block?: string } = {}

const reducer = (state: any = INITIALSTATE, action: any): any => {
  switch (action.type) {
    case 'ROUTER_SET_LOCATION':
      return { ...state, url: action.url, previous: state.url }
    case 'ROUTER_UPDATE':
      return { ...state, ...action.route }
    case 'ROUTER_BLOCK':
      return { ...state, block: action.message }
    case 'ROUTER_UNBLOCK':
      return { ...state, block: undefined }
  }

  return state
}

export default reducer
