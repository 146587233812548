import { map, path, prop } from 'ramda'

import Router from 'next/router'
import getConfig from '@/config'

import { LOAD_SUCCESS } from '../auth/actions/account_load'
import { COMMENT_SUCCESS } from '../entities/actions/comment'
import { UPDATE_SUCCESS as CONNECT_SUCCESS } from '../entities/actions/connect'
import { UPDATE_SUCCESS as FOLLOW_SUCCESS } from '../entities/actions/follow'
import { SAVE_SUCCESS as CASE_SAVE_SUCCESS } from '../entities/actions/case_save'
import { VOTE_SUCCESS } from '../entities/actions/poll_vote'

import { identify, event } from '../analytics/track'
import { selectAuthenticatedUserID } from '../auth/selectors'

const config = getConfig().publicRuntimeConfig

const analyticsResourceNames = {
  case: 'Case',
  group: 'Group',
  institution: 'Institution',
  user: 'User',
}

const label = type => prop(type, analyticsResourceNames) || type

const handlers = {
  [LOAD_SUCCESS]: action => {
    const user = action.payload.response
    const counters = action.payload.activity?.counters
    identify(user, {
      gacd: action.payload.ga || {},
      env: config.env,
      appVersion: config.version,

      ...(action.payload.user_vars ?? {}),
    })
  },

  [COMMENT_SUCCESS]: action => {
    event(label(action.resource.type), 'Commented', action.resource.id)
  },

  [CONNECT_SUCCESS]: action => {
    event(
      label(action.resource.type),
      action.enable ? 'Connect' : 'Disconnect',
      action.resource.id
    )
  },

  [FOLLOW_SUCCESS]: action => {
    event(
      label(action.resource.type),
      action.enable ? 'Follow' : 'Unfollow',
      action.resource.id
    )
  },

  [CASE_SAVE_SUCCESS]: (action, getState) => {
    event(
      'Edit case',
      action.payload.response.status === 'published' ? 'Publish' : 'Save draft',
      {
        'Content ID': action.payload.response.id,
        'User ID': selectAuthenticatedUserID(getState()),
        'Created as': action.payload.response.created_as,
        'Has poll': !!(
          action.payload.response.poll && action.payload.response.poll.id
        ),
      }
    )
  },

  [VOTE_SUCCESS]: (action, getState) => {
    event('Poll', 'Voted', {
      'Content ID': action.resource.id.toString(),
      'Parent ID': action.payload.case_id.toString(),
      'User ID': selectAuthenticatedUserID(getState()),
      'URL Route': Router.router.route,
      'URL Path': Router.router.asPath || Router.router.pathname,
    })
  },
}

export const createAnalyticsMiddleware = () => {
  return ({ getState }) =>
    next =>
    action => {
      const handler = prop(action.type, handlers) || (() => {})
      handler(action, getState)

      return next(action)
    }
}
