import { fork, take, all, put } from 'redux-saga/effects'

import get from 'lodash/get'

import { LOAD_SUCCESS } from '../auth/actions/account_load'
import { loadConnections } from '../entities/actions/connections_load'
import { selectAuthenticatedUser } from '../auth/selectors'

function* accountLoaded(action): Generator {
  const connections = get(action, ['payload', 'response', 'connections'])
  const count = get(action, [
    'payload',
    'activity',
    'counters',
    'user_connections_count',
  ])
  if (connections && count && connections.length < count) {
    yield put(
      loadConnections(get(action, ['payload', 'response', 'id']), 1, 5000)
    )
  }
}

function* watchAccountLoaded(): Generator {
  while (true) {
    const action = yield take(LOAD_SUCCESS)
    yield fork(accountLoaded, action)
  }
}

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////// ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([fork(watchAccountLoaded)])
}
