// ////////////////////////////////////////////////////////////////////// ACTION
import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const SEARCH_REQUEST_COLLEAGUES = 'entities/group-membership-colleagues.REQUEST'
const SEARCH_SUCCESS_COLLEAGUES = 'entities/group-membership-colleagues.SUCCESS'
const SEARCH_FAILURE_COLLEAGUES = 'entities/group-membership-colleagues.FAILURE'
const SEARCH_REQUEST_MEMBERS = 'entities/group-membership-members.REQUEST'
const SEARCH_SUCCESS_MEMBERS = 'entities/group-membership-members.SUCCESS'
const SEARCH_FAILURE_MEMBERS = 'entities/group-membership-members.FAILURE'

function searchColleaguesGroupMembership(
  userID,
  resourceType,
  resourceID,
  searchTerm
) {
  const searchTerms = encodeURIComponent(searchTerm)
  const url = `/api/profile/feeds/${resourceType}/${resourceID}/colleague-membership?q=${searchTerms}&per_page=100`

  return request({
    url: url,
    debounce: true,
    start: SEARCH_REQUEST_COLLEAGUES,
    success: {
      type: SEARCH_SUCCESS_COLLEAGUES,
      searchTerm,
      userID,
      resourceID,
      resourceType,
    },
    failure: SEARCH_FAILURE_COLLEAGUES,
  })
}

function searchMembersGroupMembership(
  userID,
  resourceType,
  resourceID,
  searchTerm
) {
  const searchTerms = encodeURIComponent(searchTerm)
  const url = `/api/profile/feeds/${resourceType}/${resourceID}/members-for-invite?q=${searchTerms}&per_page=100`

  return request({
    url: url,
    debounce: true,
    start: SEARCH_REQUEST_MEMBERS,
    success: {
      type: SEARCH_SUCCESS_MEMBERS,
      searchTerm,
      userID,
      resourceType,
      resourceID,
    },
    failure: SEARCH_FAILURE_MEMBERS,
  })
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export {
  searchMembersGroupMembership,
  searchColleaguesGroupMembership,
  SEARCH_REQUEST_COLLEAGUES,
  SEARCH_SUCCESS_COLLEAGUES,
  SEARCH_FAILURE_COLLEAGUES,
  SEARCH_REQUEST_MEMBERS,
  SEARCH_SUCCESS_MEMBERS,
  SEARCH_FAILURE_MEMBERS,
}
