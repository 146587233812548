export type AdminRole =
  | 'cases'
  | 'features'
  | 'groups'
  | 'institutions'
  | 'users'

export const isAdmin = user =>
  !!(user && user.roles && user.roles.indexOf('admin') >= 0)

export const hasAdminRole = (user, role: AdminRole) =>
  !!(
    user &&
    user.roles &&
    (user.roles.indexOf('admin') >= 0 ||
      user.roles.indexOf(`admin-${role}`) >= 0)
  )

export const isGroupAdmin = (user, groupID: string) =>
  !!(
    user &&
    user.groups &&
    !!user.groups.find(x => x.id.toString() === groupID && x.is_admin)
  )

export const hasEmail = user =>
  user && !!user.email && !user.email.match(/^no(fb|li)-[^@]+$/)

export const hasProEmail = user =>
  user &&
  !!user.professional_email &&
  !user.professional_email.match(/^no(fb|li)-[^@]+$/)

export const needProEmail = user => user && user.need_professional_email

export const hasConfirmedPhoneNumber = user => user && user.confirmed_phone
