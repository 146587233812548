import { TFunction, withTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'
import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import filter from 'lodash/filter'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import zipObject from 'lodash/zipObject'
// /////////////////////////////////////////////////////////////////////// UTILS
import safeId from '../../utils/safeId'
// ///////////////////////////////////////////////////////// ACTIONS & SELECTORS
import * as userState from '../../modules/auth/selectors'
import { selectSpecialtyMapper } from '../../modules/categories/selectors'
import * as modal from '../../modules/modal/actions'
import updateFilters from '../../modules/filters/actions/update'
import { selectIsMobile } from '../../modules/responsive/selectors'
import { saveRecentSearch } from '../../modules/search/actions'
import { selectRecentSearches } from '../../modules/search/selectors'
import { SEARCH_TYPE_TAGS } from '../../modules/search/types'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import SearchIcon from '../../ui/icons/search'
import XCircleIcon from '../../ui/icons/x-circle'
import { Checkbox } from '../../ui/forms/base'
import ScrollTrap from '../../ui/scroll-trap'
import { ModalClose } from '../../ui/modals'
import { TagSearchList, TagPickerRow, TagPickerList } from './TagPickers'

type JustAddedProps = {
  filters: Array<any>
  onToggleCategory: Function
  onToggleCategories: Function
}

// JustAddedList: List of current selections grouped by specialty
const JustAddedList = (props: JustAddedProps): any => {
  if (isEmpty(props.filters)) {
    return null
  }

  const enabledCount = reduce(
    props.filters,
    (r, v) => r + (v.enabled ? 1 : 0),
    0
  )

  const removeAllClassName = cs({
    'action-link': enabledCount > 0,
    'disabled-link': enabledCount === 0,
  })
  const removeAllLabel = 'Remove all'

  const onRemoveAll = () => {
    const enabled = filter(props.filters, v => v.enabled)
    props.onToggleCategories(enabled, false)
  }

  return (
    <div className="tag-picker__just-added modal__section">
      <div className="tag-picker__just-added-header">
        <h5 className="tag-picker__just-added-label">Just added…</h5>
        <div className="tag-picker__just-added-tools">
          <span className={removeAllClassName} onClick={onRemoveAll}>
            {removeAllLabel}
          </span>
        </div>
      </div>
      <ul className="tag-picker__subspecialties list-bare">
        {map(props.filters, ({ enabled, id, label }) => {
          const html_id = safeId(id)

          return (
            <li key={html_id} className="tag-picker__subspecialty">
              <Checkbox
                align="right"
                id={html_id}
                label={label}
                value={id}
                checked={enabled}
                onChange={checked =>
                  props.onToggleCategory({ id, label }, checked)
                }
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

type RecentTagsProps = {
  results: Array<any>
  selections: any
  onToggleCategory: Function
}

// RecentTagsList: list of recently used tags
const RecentTagsList = (props: RecentTagsProps): any => {
  if (isEmpty(props.results)) {
    return null
  }

  return (
    <div>
      <div className="tag-picker__just-added modal__section">
        <ul className="tag-picker__subspecialties list-bare">
          {map(props.results, (category, i) => {
            return (
              <TagPickerRow
                key={i}
                category={category}
                onToggleCategory={props.onToggleCategory}
                tristate
                selected={props.selections[category.id]}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

// TagPickerView: Smart component that manages filter selection and search state.
const mapStateToPropsForEditor = state => {
  const specialties = selectSpecialtyMapper(state)
  const recentSearches = selectRecentSearches(state, SEARCH_TYPE_TAGS)
  console.log({ specialties })
  return { specialties, recentSearches, isMobile: selectIsMobile(state) }
}

const mapDispatchToPropsForEditor = (dispatch, ownProps) => {
  return {
    saveRecentSearch: category_id => {
      dispatch(saveRecentSearch(SEARCH_TYPE_TAGS, category_id))
    },
    updateFilters: filters => {
      each(filters, (enabled, id) => {
        if (enabled) {
          dispatch(saveRecentSearch(SEARCH_TYPE_TAGS, id))
        }
      })
      ownProps.saveChanges(filters, dispatch)
      dispatch(modal.closeModal())
    },
  }
}

const mapStateToPropsForFilters = state => {
  let filters = state.filters
  if (!filters) {
    // if we have no filters already we'll populate using the users profile categories
    const user = userState.selectAuthenticatedUser(state)
    filters = zipObject(
      user.specialty,
      new Array(user.specialty.length).fill(false)
    )
  }

  const specialties = selectSpecialtyMapper(state)

  if (specialties) {
    if (isEmpty(filters)) {
      // if we have specialties only, populate all sub-specialties.
      filters = reduce(
        filters,
        (acc, enabled, id) => {
          const kids = map(specialties.getChildrenFor(id), c => c.id)
          return enabled
            ? { ...acc, ...zipObject(kids, new Array(kids.length).fill(true)) }
            : acc
        },
        {}
      )
    }
  }

  const recentSearches = selectRecentSearches(state, SEARCH_TYPE_TAGS)

  if (isEmpty(recentSearches) && isEmpty(filters)) {
    const allSpecialtyIDs = specialties.getSpecialtyIDs()
    filters = zipObject(
      allSpecialtyIDs,
      new Array(allSpecialtyIDs.length).fill(false)
    )
  }

  return {
    selections: filters,
    specialties,
    recentSearches,
    isMobile: selectIsMobile(state),
  }
}

const mapDispatchToPropsForFilters = dispatch => {
  return {
    saveRecentSearch(category_id) {
      dispatch(saveRecentSearch(SEARCH_TYPE_TAGS, category_id))
    },
    updateFilters(filters) {
      each(filters, (enabled, id) => {
        if (enabled) {
          dispatch(saveRecentSearch(SEARCH_TYPE_TAGS, id))
        }
      })
      dispatch(updateFilters(filters))
      dispatch(modal.closeModal())
    },
  }
}

type TagPickerProps = {
  selections: any
  specialties: any
  recentSearches: Array<any>
  saveRecentSearch: Function
  updateFilters?: Function
  purpose: string
  isMobile: boolean
  t: TFunction
}
type TagPickerState = {
  searchTerm: string
  searchFocused?: boolean
  workingFilters: any
  selections: any
  justAdded: Array<any>
}

class TagPickerView extends React.Component<TagPickerProps, TagPickerState> {
  constructor(props: TagPickerProps) {
    super(props)

    this.state = {
      // current search term
      searchTerm: '',
      // the set of filters that the user has built up so far
      workingFilters: {},
      // categories selected while in search mode
      selections: {},
      // the most recently added selections (list of IDs)
      justAdded: [],
    }
  }

  onSearchChange = (e: any) => {
    this.setState(assign({}, this.state, { searchTerm: e.target.value }))
  }

  onClearSearchClick = () => {
    this.setState(assign({}, this.state, { searchTerm: '' }))
  }

  // Update multiple categories in our working set
  onToggleCategories = (categories, enabled) => {
    this.setState({
      ...this.state,
      ...{
        workingFilters: {
          ...this.props.selections,
          ...this.state.workingFilters,
          ...zipObject(
            map(categories, c => c.id),
            new Array(categories.length).fill(enabled)
          ),
        },
      },
    })
  }

  // Update a category in our working set
  onToggleCategory = (category, enable) => {
    const merge = { [category.id]: enable }
    this.setState(
      assign({}, this.state, {
        workingFilters: assign(
          {},
          this.props.selections,
          this.state.workingFilters,
          merge
        ),
      })
    )
  }

  // Update a category in the current in-search selections
  onToggleSelection = (category, enable) => {
    if (enable) {
      this.setState({
        ...this.state,
        selections: { ...this.state.selections, [category.id]: true },
      })
    } else {
      const {
        [category.id]: exist, // eslint-disable-line no-unused-vars
        ...rest
      } = this.state.selections
      this.setState({ ...this.state, selections: rest })
    }
  }

  // Update multiple categories in our in-search selections
  onToggleSelections = (categories, enabled) => {
    this.setState({
      ...this.state,
      ...{
        selections: {
          ...this.state.selections,
          ...zipObject(
            map(categories, c => c.id),
            new Array(categories.length).fill(enabled)
          ),
        },
      },
    })
  }

  // Cancel current search, clearing search term and current in-search selections
  onCancelSearch = () => {
    this.setState(
      assign({}, this.state, {
        searchFocused: false,
        searchTerm: '',
        selections: {},
      })
    )
  }

  // Add current in-search selections to the working set of filters
  onAddFilters = () => {
    this.setState(
      assign({}, this.state, {
        searchFocused: false,
        workingFilters: assign(
          {},
          this.props.selections,
          this.state.workingFilters,
          this.state.selections
        ),
        justAdded: keys(this.state.selections),
        selections: {},
        searchTerm: '',
      })
    )
  }

  // Save filters to the server and close interface
  onDone = () => {
    this.props.updateFilters(
      assign({}, this.props.selections, this.state.workingFilters)
    )
  }

  isSearching() {
    return this.state.searchTerm.length > 0 // || !isEmpty(this.state.selections)
  }

  setSearchFocused = () => {
    this.setState({ ...this.state, searchFocused: true })
  }

  isSearchFocused() {
    return this.state.searchFocused && this.state.searchTerm.length === 0
  }

  messageID(id_suffix) {
    return 'tag_picker.' + this.props.purpose + '_' + id_suffix
  }

  renderPickerBody = () => {
    const { t } = this.props
    const specialties = this.props.specialties
    const workingFilters = assign(
      {},
      this.props.selections,
      this.state.workingFilters
    )
    const filters = assign(
      {},
      this.props.selections,
      this.state.workingFilters,
      this.state.selections
    )
    if (!this.isSearchFocused()) {
      if (this.isSearching()) {
        return (
          <div className="tag-picker__content">
            <div className="tag-picker__status modal__prompt">
              <div className="modal__prompt-content">
                <>
                  {t('common:tag_picker.search_results_prompt')}
                  <button
                    type="button"
                    className={cs('btn btn--action btn--fill', {
                      'btn--disabled': isEmpty(this.state.selections),
                    })}
                    onClick={this.onAddFilters}
                  >
                    <>
                      {t(
                        `common:${this.messageID('add_filters_button_label')}`
                      )}
                    </>
                  </button>
                </>
              </div>
            </div>
            <ScrollTrap className="tag-picker__body modal__body">
              <TagSearchList
                results={specialties.searchCategories(
                  this.state.searchTerm,
                  filters
                )}
                selections={this.state.selections}
                onToggleCategory={this.onToggleSelection}
              />
            </ScrollTrap>
          </div>
        )
      } else if (!isEmpty(workingFilters)) {
        const justAddedSubspecialties = filter(
          this.state.justAdded,
          specialties.isSubspecialty
        )
        const justAdded = map(justAddedSubspecialties, id => ({
          id,
          enabled: filters[id],
          label: specialties.getUnambiguousLabelFor(id),
        }))

        const filtersWithoutNew = omit(filters, justAddedSubspecialties)

        return (
          <div className="tag-picker__content">
            <div className={cs('tag-picker__status modal__prompt')}>
              <div className="modal__prompt-content">{this.renderCount()}</div>
            </div>
            <ScrollTrap className="tag-picker__body modal__body">
              <JustAddedList
                filters={justAdded}
                onToggleCategory={this.onToggleCategory}
                onToggleCategories={this.onToggleCategories}
              />
              <TagPickerList
                specialty_list={specialties.groupFiltersBySpecialty(
                  filtersWithoutNew
                )}
                selections={filters}
                onToggleCategory={this.onToggleCategory}
                onToggleCategories={this.onToggleCategories}
              />
            </ScrollTrap>
          </div>
        )
      }
    }

    const existingSpecialties = filter(this.props.recentSearches, id =>
      specialties.exists(id)
    )

    const recentSearches = map(existingSpecialties, id => ({
      ...specialties.getCategory(id),
      id,
      enabled: filters[id],
      label: specialties.getUnambiguousLabelFor(id),
    }))

    return (
      <div className="tag-picker__content">
        <div className="tag-picker__status modal__prompt">
          {(() => {
            if (recentSearches.length) {
              return (
                <div className="modal__prompt-content">
                  <span>
                    <>{t('common:tag_picker.recent_searches_prompt')}</>
                  </span>
                  <button
                    type="button"
                    className={cs('btn btn--action btn--fill', {
                      'btn--disabled': isEmpty(this.state.selections),
                    })}
                    onClick={this.onAddFilters}
                  >
                    <>
                      {t(
                        `common:${this.messageID('add_filters_button_label')}`
                      )}
                    </>
                  </button>
                </div>
              )
            }
            return (
              <div className="modal__prompt-content">
                <span>
                  <>{t('common:tag_picker.search_prompt')}</>
                </span>
              </div>
            )
          })()}
        </div>
        <ScrollTrap className="tag-picker__body modal__body">
          <RecentTagsList
            results={recentSearches}
            selections={this.state.selections}
            onToggleCategory={this.onToggleSelection}
          />
        </ScrollTrap>
      </div>
    )
  }

  renderCount = () => {
    const { purpose, specialties, t } = this.props
    const filters = assign(
      {},
      this.props.selections,
      this.state.workingFilters,
      this.state.selections
    )

    if (purpose === 'profile_tags') {
      const counts = reduce(
        filters,
        (counts, enabled, id) => {
          if (enabled) {
            if (specialties.isSubspecialty(id)) {
              ++counts.subspecialties
            } else if (specialties.isSpecialty(id)) {
              ++counts.specialties
            }
          }
          return counts
        },
        { count: 0, specialties: 0, subspecialties: 0 }
      )
      const specialtiesLabel =
        counts.specialties > 0
          ? t(
              'common:tag_picker.profile_tags_filter_count_prompt_specialties',
              {
                count: counts.specialties,
              }
            )
          : null
      const subSpecialtiesLabel =
        counts.subspecialties > 0
          ? t(
              'common:tag_picker.profile_tags_filter_count_prompt_subspecialties',
              { count: counts.subspecialties }
            )
          : null
      if (specialtiesLabel && subSpecialtiesLabel) {
        return t(
          `common:${this.messageID('filter_count_prompt_you_have_both')}`,
          {
            specialtiesLabel,
            subSpecialtiesLabel,
          }
        )
      } else if (subSpecialtiesLabel) {
        return t(`common:${this.messageID('filter_count_prompt_you_have')}`, {
          label: subSpecialtiesLabel,
        })
      } else if (specialtiesLabel) {
        return t(`common:${this.messageID('filter_count_prompt_you_have')}`, {
          label: specialtiesLabel,
        })
      }
    }

    return t(`common:${this.messageID('filter_count_prompt')}`, {
      count: specialties.countEnabledFilters(filters),
    })
  }

  render() {
    const { isMobile, t, purpose } = this.props

    return (
      <div className="tag-picker">
        <div className="tag-picker__header modal__header dark-background">
          <div
            className={cs('search-field', {
              'search-field--has-value': this.state.searchTerm.length > 0,
            })}
          >
            <SearchIcon />
            <input
              type="text"
              placeholder={t(
                `common:tag_picker.${purpose}_search_placeholder` +
                  (isMobile ? '_mobile' : '')
              )}
              className="fs-unmask search-field__input tag-picker__search-input"
              onChange={this.onSearchChange}
              value={this.state.searchTerm}
              onFocus={() => this.setSearchFocused()}
            />
            <XCircleIcon onClick={this.onClearSearchClick} />
          </div>

          {(() => {
            if (this.isSearching() || this.isSearchFocused()) {
              return (
                <span
                  className="action-link tag-picker__search-cancel"
                  role="button"
                  onClick={this.onCancelSearch}
                >
                  <>{t('common:tag_picker.cancel_button_label')}</>
                </span>
              )
            }
            return (
              <button
                type="button"
                className="btn btn--fill btn--action"
                onClick={this.onDone}
              >
                <>{t('common:tag_picker.done_button_label')}</>
              </button>
            )
          })()}
        </div>
        {this.renderPickerBody()}
      </div>
    )
  }
}

const FilterTagPicker = connect(
  mapStateToPropsForFilters,
  mapDispatchToPropsForFilters
)(withTranslation()(TagPickerView))

const FilterTagPickerModal = ({
  onClose,
}: {
  onClose: Function
}): React.ReactElement => (
  <div className="fs-unmask modal-container modal-container--tag-picker">
    <div className="modal-container__content page__container">
      <ModalClose onClose={onClose} className="not-palm" />
      <div className="modal modal--tag-picker modal--listing modal--centered">
        <FilterTagPicker purpose="filters" />
      </div>
    </div>
  </div>
)

const TagPicker = connect(
  mapStateToPropsForEditor,
  mapDispatchToPropsForEditor
)(withTranslation()(TagPickerView))

const TagPickerModal = {
  component: TagPicker,
  className: 'fs-unmask modal--tag-picker modal--listing modal--centered',
  id: 'tag-picker',
}

export { TagPicker, TagPickerModal, FilterTagPickerModal, FilterTagPicker }
