import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { IconWithDefs } from './icon'
import { IconWithDefsType } from './icon'

interface PlayIconProps {
  id?: string
  title?: string
  width?: number
  className?: string
  onClick?: () => void
}

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const PlayIcon = (props: PlayIconProps): any => {
  const filter_id = `${props.id}-shadow-filter`

  const defaultProps = {
    id: 'icon-play-large',
    title: 'Play',
  }

  return (
    <IconWithDefs
      {...{ ...defaultProps, ...props }}
      sourceWidth={68}
      sourceHeight={68}
      viewBox="0 0 68 68"
    >
      <defs>
        <filter id={filter_id}>
          <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(5.000000, 4.000000)" stroke="none" fill="none">
        <circle
          className={`${props.id}__shadow`}
          filter={`url(#'${filter_id}')`}
          cx="29"
          cy="29"
          r="32"
          fill="rgba(0, 0, 0, 0.4)"
        ></circle>
        <circle
          className={`${props.id}__background`}
          cx="29"
          cy="29"
          r="29"
          stroke="#4B9BEF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#4B9BEF"
        ></circle>
        <polygon
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#FFFFFF"
          points="20.1956805 40.8640102 20.1956805 18.141981 42.9162965 29.502495"
        ></polygon>
      </g>
    </IconWithDefs>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default PlayIcon
