import cs from 'classnames'
import dynamic from 'next/dynamic'
import React, { useEffect, useMemo, useState } from 'react'
import { isNativeAppBrowserAsync } from '../../lib/client-info'

import * as apiState from '../../modules/api/selectors'
import * as modal from '../../modules/modal/actions'
import { dismissNotification } from '../../modules/notify/actions'
import { selectIsMobile } from '../../modules/responsive/selectors'
import { selectAuthenticatedUser } from '../../modules/auth/selectors'
import { useDispatch, useSelector } from '../../react-redux'
import ActivityIndicator from '../../routes/components/ActivityIndicator'
import { BackToTop } from '../../ui/back-to-top'
import Fade from '../../ui/fx/fade'
import ModalContainer from '../../ui/modal'
import ReactModalContainer from '../../ui/Modal/container/ReactModalContainer'
import { Notification } from '../../ui/Notification'
import Footer from './Footer'
import Header from './Header'

import { isOpenPage } from '../../lib/isOpenPage'
import { TermsModal } from '../TermsModal'
import { modalOpen } from '../../modules/modal/actions'
import * as layout from '../../modules/layout/actions'
import { selectLayoutClasses } from '../../modules/layout/selectors'

const MainMenu = dynamic(() => import('./MobileMainMenu'))

export type PageLayoutProps = {
  children: React.ReactNode
}

const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
  const [revealOffCanvasMenu, setRevealOffCanvasMenu] = useState(false)

  const [isClient, setIsClient] = useState(false)

  const dispatch = useDispatch()
  const modals = useSelector(state => state.modal)
  const currentUser = useSelector(selectAuthenticatedUser)
  const notifications = useSelector(state => state.notify)
  const isMobile = useSelector(selectIsMobile)
  const apiActivityType = useSelector(apiState.activityType)

  const [isNativeApp, setIsNativeApp] = useState(false)

  useEffect(() => setIsClient(true), [])

  useMemo(() => {
    const getNative = async () => {
      const native =
        (await isNativeAppBrowserAsync()) ||
        (typeof document !== 'undefined' &&
          /via=\/api\/pages/.test(decodeURIComponent(document.location.search)))
      setIsNativeApp(native)
    }
    void getNative()
  }, [])

  useEffect(() => {
    if (currentUser?.needs_terms_acceptance && !isOpenPage() && !isNativeApp) {
      if (!modals.newStack.find(x => x.component === TermsModal)) {
        dispatch(
          modalOpen({ name: 'terms', component: TermsModal, size: 'small' })
        )
      }
    }
  }, [
    currentUser?.needs_terms_acceptance,
    isNativeApp,
    global?.document?.location.href,
  ])

  useEffect(() => {
    dispatch({ type: layout.CLEAR_LAYOUT_CLASSES })
  }, [global?.document?.location.href])

  const handleCloseModal = () => dispatch(modal.closeModal())
  const handleCloseModalNew = (id: string) => dispatch(modal.modalClose(id))

  const handleOffCanvasToggleClick = () =>
    setRevealOffCanvasMenu(!revealOffCanvasMenu)
  const handleOffCanvasClick = () => setRevealOffCanvasMenu(false)

  const handlePageClick = (e: any): boolean | undefined => {
    if (revealOffCanvasMenu) {
      setRevealOffCanvasMenu(false)
      e.preventDefault()
      return false
    }
  }

  const dispatchDismissNotification = (id: string): any =>
    dispatch(dismissNotification(id))

  const styles = {
    container: cs(
      'fullH',
      modals.stack.map((payload: any): any => `showing-modal--${payload.id}`),
      { 'showing-modal': modals.stack.length > 0 }
    ),
    pageRest: cs('page__rest', {
      'page--reveal-off-canvas': revealOffCanvasMenu,
    }),
  }

  const layoutClasses = useSelector(selectLayoutClasses)

  return (
    <>
      <div
        className={cs(styles.container, layoutClasses, {
          'native-embed': isNativeApp,
          'with-main-menu': revealOffCanvasMenu,
        })}
        style={{ overflowY: 'auto' }}
      >
        {isNativeApp ? null : (
          <>
            <Header toggleMobileMenu={handleOffCanvasToggleClick} />
            <GhostBanner currentUser={currentUser} />
          </>
        )}

        <main className="page globalWrapper" onClick={handlePageClick}>
          {isMobile && revealOffCanvasMenu && !isNativeApp ? (
            <MainMenu closeMenu={handleOffCanvasClick} />
          ) : null}

          <div className={styles.pageRest}>
            <section className="page__body fullH">{children}</section>
          </div>

          <Fade>
            {modals.stack.map((payload: any, i: number): any =>
              payload instanceof React.Component ||
              typeof payload === 'function' ? (
                React.createElement(payload, {
                  key: `${i}_modalStack`,
                  onClose: handleCloseModal,
                })
              ) : (
                <ModalContainer key={`${i}_modalStack`} {...payload} />
              )
            )}
          </Fade>

          <Fade>
            {modals.newStack.map((modal: any, i: number): any => {
              return <ReactModalContainer isOpen key={modal.id} {...modal} />
            })}
          </Fade>

          <Fade>
            {notifications.stack.map((payload: any, i: number): any => (
              <Notification
                key={i}
                closeBtn={true}
                index={i}
                count={notifications.stack.length}
                dismissNotification={() =>
                  dispatchDismissNotification(payload.id)
                }
                {...payload}
              />
            ))}
          </Fade>

          {apiActivityType && isClient ? (
            <Fade>
              <ActivityIndicator apiActivityType={apiActivityType} />
            </Fade>
          ) : null}
        </main>

        {isNativeApp ? null : <Footer />}
      </div>
      <BackToTop />
    </>
  )
}

export default PageLayout

const GhostBanner = ({ currentUser }) => {
  if (!currentUser?.is_ghost) return null

  return (
    <div className="ghost-banner">
      <div className="page__container ghost-banner__inner">
        <p>
          <span role="img" aria-label="waving hand">
            👋
          </span>{' '}
          You are currently impersonating <strong>{currentUser?.name}</strong>.
        </p>
      </div>
    </div>
  )
}
