import uniq from 'lodash/uniq'
import countBy from 'lodash/countBy'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import intersection from 'lodash/intersection'
import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'

import * as lookup from './lookup'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export class SpecialtyMapper {
  category_list: Array<any>
  category_tree: any
  ambiguous_terms: any

  constructor(category_list, category_tree, ambiguous_terms) {
    this.category_list = [...category_list]
    this.category_tree = { ...category_tree }
    this.ambiguous_terms = { ...ambiguous_terms }
  }

  getFields() {
    return this.category_list
  }

  getWithCompactFields() {
    return map(this.category_list, category =>
      category.children.length === 1 ? category.children[0] : category
    )
  }

  getSpecialtyIDs() {
    return flatMap(this.category_list, field =>
      map(field.children, specialty => specialty.id)
    )
  }

  getChildrenFor(category_id) {
    const category = lookup.getCategoryFromTree(category_id, this.category_tree)
    return category ? category.children : undefined
  }

  getLabelFor(category_id) {
    const category = lookup.getCategoryFromTree(category_id, this.category_tree)
    return category ? category.label : undefined
  }

  getLabelsFor(category_ids) {
    return filter(
      map(category_ids, id => this.getLabelFor(id)),
      label => typeof label === 'string'
    )
  }

  getUnambiguousLabelsFor(ids) {
    return map(ids, id => this.getUnambiguousLabelFor(id))
  }

  getUnambiguousLabelFor(id) {
    if (this.isSubspecialty(id)) {
      const category = lookup.getCategoryFromTree(id, this.category_tree)
      if (category) {
        if (this.ambiguous_terms[category.label]) {
          const parent = lookup.getCategoryFromTree(
            this.getParentCategoryID(id),
            this.category_tree
          )
          if (parent) {
            return getQualifiedLabel(parent.label, category.label)
          }
        }
        return category.label
      }
    }
    return this.getLabelFor(id)
  }

  getCommonSubspecialties(list_a, list_b) {
    return intersection(
      filter(list_a, this.isSubspecialty),
      filter(list_b, this.isSubspecialty)
    )
  }

  getCommonSubspecialtyLabels(list_a, list_b) {
    return map(this.getCommonSubspecialties(list_a, list_b), id =>
      this.getUnambiguousLabelFor(id)
    )
  }

  getCommonSpecialties(list_a, list_b) {
    return intersection(
      filter(list_a, this.isSpecialty),
      filter(list_b, this.isSpecialty)
    )
  }

  getCommonSpecialtyLabels(list_a, list_b) {
    return map(this.getCommonSpecialties(list_a, list_b), id =>
      this.getUnambiguousLabelFor(id)
    )
  }

  isSubspecialty(category_id) {
    return category_id.split('.').length === 3
  }

  isSpecialty(category_id) {
    return category_id.split('.').length === 2
  }

  pickSubspecialties(list) {
    return filter(list, this.isSubspecialty)
  }

  pickSpecialties(list) {
    return filter(list, this.isSpecialty)
  }

  isSubspecialtyOf(category_id, specialty_id) {
    return (
      this.isSubspecialty(category_id) &&
      this.isSpecialty(specialty_id) &&
      specialty_id === category_id.substring(0, specialty_id.length)
    )
  }

  getParentCategoryID(category_id) {
    const parts = category_id.split('.')
    if (parts.length > 1) {
      return parts.slice(0, parts.length - 1).join('.')
    }
  }

  getCategory(id) {
    const category = lookup.getCategoryFromTree(id, this.category_tree)
    if (category) {
      const children = values(category.children)
      return {
        ...category,
        is_specialty: this.isSpecialty(category.id),
        children,
      }
    }
  }

  exists(id) {
    return !!this.getCategory(id)
  }

  /**
   * Given a list of category IDs, find the primary category.
   - If there is only 1 sub-specialty, then return the sub-specialty
   - if there is more than one sub-specialty in a single specialty, then return the specialty
   - If there is more than one specialty then return the specialty with the most sub-specialties
   */
  findPrimary(category_ids) {
    let { fields, specialties, subspecialties } = groupByDepth(category_ids)

    if (fields.length === 1 && specialties.length === 1) {
      // If there is only 1 sub-specialty, then return the sub-specialty
      if (subspecialties.length === 1) {
        return subspecialties[0]
      }
      // if there is more than one sub-specialty in a single specialty, then return the specialty
      return specialties[0]
    }

    let counts,
      primary = ''

    if (subspecialties.length) {
      // If there is more than one specialty then return the specialty with the most sub-specialties
      counts = countBy(subspecialties, function (subspecialty) {
        return subspecialty.split('.').slice(0, -1).join('.')
      })

      primary = Object.keys(counts).reduce(function (max, specialty) {
        return max === undefined || counts[specialty] > counts[max]
          ? specialty
          : max
      })
    }

    if (!primary && specialties.length) {
      return specialties[0]
    }

    if (!primary && category_ids.length) {
      // If still not found, look for common Field instead
      counts = countBy(category_ids, function (subspecialty) {
        return subspecialty.split('.').slice(0, -1).join('.')
      })

      primary = Object.keys(counts).reduce(function (max, specialty) {
        return max === undefined || counts[specialty] > counts[max]
          ? specialty
          : max
      })
    }

    return primary
  }

  /*
   * As for findPrimary but searching through a filters object, which is
   * of the form {'category': true, 'category2': false, 'category3': true}
   * where the boolean indicated whether the filter is enabled or not
   */
  findPrimaryFilterCategory(filters) {
    const categories = Object.keys(filters).filter(function (key) {
      return filters[key]
    })
    if (categories.length) {
      return this.findPrimary(categories)
    }
    return ''
  }

  /*
   * Count the enabled filters
   */
  countEnabledFilters(filters) {
    return reduce(filters, (r, v) => r + (v ? 1 : 0), 0)
  }

  groupFiltersBySpecialty(filters) {
    let keys = Object.keys(filters)
    keys.sort()
    let { specialties, subspecialties } = groupByDepth(keys)

    return sortBy(
      specialties.map(id => {
        const category = lookup.getCategoryFromTree(id, this.category_tree)
        const subfilters = sortBy(
          subspecialties
            .filter(subspecialty_id => subspecialty_id.startsWith(id))
            .map(id => {
              return {
                id: id,
                label: this.getLabelFor(id),
                enabled: !!filters[id],
              }
            }),
          v => v.label
        )
        let children = values(category.children)
        return {
          id: category.id,
          level: category.level,
          label: category.label,
          children_count: children.length,
          enabled: !!filters[category.id],
          children: children,
          subfilters: subfilters,
        }
      }),
      v => v.label
    )
  }

  getCompactSubtrees(category_ids) {
    let keys = [...category_ids]
    keys.sort()
    let { specialties, subspecialties } = groupByDepth(keys)

    return sortBy(
      specialties.map(id => {
        const category = lookup.getCategoryFromTree(id, this.category_tree)
        const subfilters = sortBy(
          subspecialties
            .filter(subspecialty_id => subspecialty_id.startsWith(id))
            .map(id => {
              return lookup.getCategoryFromTree(id, this.category_tree)
            }),
          v => v.label
        )
        return {
          ...category,
          children_count: category.children.length,
          children: subfilters,
        }
      }),
      v => v.label
    )
  }

  search(term) {
    let lterm = term.toLowerCase().replace(/^\s*(.+?)\s*$/, '$1')
    const flatList = root =>
      flatMap(root, x =>
        x.children?.length ? [x].concat(flatList(x.children)) : x
      )
    return flatList(this.category_list).filter(
      x => x.label.toLowerCase().indexOf(lterm) > -1
    )
  }

  searchTree(term) {
    let lterm = term.toLowerCase().replace(/^\s*(.+?)\s*$/, '$1')
    return reduce(
      this.category_list,
      (a, category) => {
        const matching_specialties = reduce(
          category.children,
          (a, category) => {
            if (category.label.toLowerCase().indexOf(lterm) > -1) {
              return [].concat(a).concat([category])
            }
            const matching_subs = reduce(
              category.children,
              (a, category) => {
                if (category.label.toLowerCase().indexOf(lterm) > -1) {
                  return [].concat(a).concat([category])
                }
                return a
              },
              []
            )
            if (matching_subs.length) {
              return []
                .concat(a)
                .concat([{ ...category, children: matching_subs }])
            }
            return a
          },
          []
        )

        if (matching_specialties.length) {
          return []
            .concat(a)
            .concat([{ ...category, children: matching_specialties }])
        }
        return a
      },
      []
    )
  }

  searchCategories(term, filters) {
    let results = []
    let lterm = term.toLowerCase()
    forEach(this.category_tree, field => {
      forEach(field.children, specialty => {
        let label = specialty.label.toLowerCase()
        let specialty_result: any = {
          id: specialty.id,
          label: specialty.label,
          children: values(specialty.children),
        }
        if (label.indexOf(lterm) > -1) {
          specialty_result.is_specialty = specialty_result.children.length > 0
          results.push(specialty_result)
        }
        forEach(specialty.children, subspecialty => {
          let label = subspecialty.label.toLowerCase()
          let subspecialty_result: any = { id: subspecialty.id }
          if (label.indexOf(lterm) > -1) {
            subspecialty_result.subspecialty_label = subspecialty.label
            subspecialty_result.label = getQualifiedLabel(
              specialty.label,
              subspecialty.label
            )
            results.push(subspecialty_result)
          }
          subspecialty_result.enabled = !!filters[subspecialty.id]
        })
        specialty_result.enabled = !!filters[specialty.id]
      })
    })
    results.sort((a, b) => {
      if (!!a.is_specialty !== !!b.is_specialty) {
        // this is an XOR
        return a.is_specialty ? -1 : 1
      }
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    })
    return results
  }
}

function getQualifiedLabel(specialty_label, subspecialty_label) {
  return specialty_label + ' - ' + subspecialty_label
}

function groupByDepth(category_ids) {
  let fields = [],
    specialties = [],
    subspecialties = []
  for (let id of category_ids) {
    let keys = id.split('.')
    let c = keys.length
    if (c === 1) {
      fields.push(id)
    } else if (c === 2) {
      fields.push(keys[0])
      specialties.push(id)
    } else if (c === 3) {
      fields.push(keys[0])
      specialties.push(keys[0] + '.' + keys[1])
      subspecialties.push(id)
    }
  }

  fields = uniq(fields).sort()
  specialties = uniq(specialties).sort()
  subspecialties = uniq(subspecialties).sort()

  return { fields, specialties, subspecialties }
}
