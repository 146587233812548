const delay = (ms: number = 0): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms)) // eslint-disable-line

/**
 * Give a sleep time before invoke the functions
 * @param duration
 * @param fn
 * @returns {Promise.<void>}
 */
const sleep = async (
  duration: number,
  ...fn: Array<() => void>
): Promise<void> => {
  await delay(duration)
  fn.forEach((item: any): void => item())
}

/**
 * Check email string and return boolean
 * @param email
 * @returns {boolean}
 */
const validateEmail = (email: string): boolean => {
  return isEmail(email)
}

const isEmail = (s: string): boolean =>
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
    s
  )

// ///////////////////////////////////////////////////// CAPITALIZE FIRST LETTER
const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { delay, sleep, validateEmail, capitalizeFirstLetter }
