import callAPI from '../../../modules/api/call'

export const LOAD_REQUEST = 'follows/followersWhoAreConnections/LOAD_REQUEST'
export const LOAD_SUCCESS = 'follows/followersWhoAreConnections/LOAD_SUCCESS'
export const LOAD_FAILURE = 'follows/followersWhoAreConnections/LOAD_FAILURE'

export const RESET_INITIAL_STATE =
  'follows/followersWhoAreConnections/RESET_INITIAL_STATE'

export const loadCaseFollowersWhoAreConnectionsRequest = (caseId: string) => {
  return { type: LOAD_REQUEST, caseId }
}

export const loadCaseFollowersWhoAreConnectionsSuccess = (payload: any) => {
  return { type: LOAD_SUCCESS, payload }
}

export const loadCaseFollowersWhoAreConnectionsFailure = (error: any) => {
  return { type: LOAD_FAILURE, error }
}

export const resetInitialState = (): any => {
  return { type: RESET_INITIAL_STATE }
}

export const getCaseFollowersWhoAreConnections = (
  caseId: string,
  page: number
) => {
  return callAPI({
    url: `/api/follows/in/case/${caseId}/connections?page=${page}`,
    method: 'GET',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: any): any => err)
}
