import React from 'react'
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux'
import type { RootState } from './rootReducer'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
//export const useDispatch = () => useReduxDispatch<AppDispatch>()
export const useDispatch = useReduxDispatch
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const connect = function <P>(
  mapStateToProps = undefined,
  mapDispatchToProps = undefined
) {
  return (Component: React.ComponentType<P>): React.FC<P> => {
    function Wrapper(props: P) {
      const dispatch = useDispatch()
      const stateProps = useSelector(state =>
        mapStateToProps ? mapStateToProps(state, props) : {}
      )
      const dispatchProps = mapDispatchToProps
        ? mapDispatchToProps(dispatch, props)
        : {}
      //return <Component {...props} {...stateProps} {...dispatchProps} />
      return React.createElement(Component, {
        ...props,
        ...stateProps,
        ...dispatchProps,
      })
    }
    return Wrapper
  }
}
