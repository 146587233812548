import React from 'react'
import cs from 'classnames'
import ReactModal from 'react-modal'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import XIcon from '../icons/x'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/BasicModal.module.scss'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  children: React.ReactNode
  isOpen: boolean
  withAnimation?: boolean
  shouldCloseOnOverlayClick?: boolean
  theme?: 'light' | 'dark'
  contentLabel?: string
  size:
    | 'small'
    | 'medium'
    | 'large'
    | 'sampleImg'
    | 'fullscreen'
    | 'information'
  className?: string
  onRequestClose?: Function
  shouldDisplayCloseButton: boolean
}
type State = {
  isOpen: boolean
}

class BasicModal extends React.Component<Props, State> {
  static defaultProps = {
    withAnimation: true,
    isOpen: false,
    shouldCloseOnOverlayClick: true,
    shouldDisplayCloseButton: false,
    theme: 'light',
    contentLabel: '',
    size: 'large',
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  closeModal = (event: any): any => {
    this.setState({ isOpen: false })

    if (this.props.onRequestClose) {
      this.props.onRequestClose(event)
    }
  }

  render(): React.ReactElement {
    const {
      children,
      shouldDisplayCloseButton,
      size,
      theme,
      contentLabel,
      className,
      withAnimation,
      ...rest
    } = this.props
    const { isOpen } = this.state
    const classes = cs(
      'modalReact',
      style.modalContent,
      style[size],
      style[theme],
      className,
      { [style.moveDown]: withAnimation }
    )
    const overlayClass = cs(style.modalOverlay, style[size])

    return (
      <ReactModal
        ariaHideApp={false}
        contentLabel={contentLabel}
        isOpen={isOpen}
        className={classes}
        overlayClassName={overlayClass}
        onRequestClose={this.closeModal}
        {...rest}
      >
        {shouldDisplayCloseButton ? (
          <div className={cs('page__content', style.closeBlock)}>
            <span className={style.closeBtn}>
              <XIcon color="#fff" onClick={this.closeModal} />
            </span>
          </div>
        ) : null}

        {children}
      </ReactModal>
    )
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default BasicModal
