export const setDeep = (path, value, state) => {
  if (typeof path === 'string') {
    return realSetDeep(path.split('.'), value, state)
  }
  return realSetDeep(path, value, state)
}

const realSetDeep = (path, value, state) => {
  if (path.length === 0) {
    return state
  }
  if (path.length === 1) {
    return { ...state, [path[0]]: value }
  }
  return {
    ...state,
    [path[0]]: realSetDeep(path.slice(1), value, state[path[0]] || {}),
  }
}
