import React from 'react'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import { TextLink } from '../link'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  href?: string
  to?: { to: string; as: string }
  onClick?: Function
  className?: string
  children: React.ReactNode
  text?: string
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  disabledVisually?: boolean
  wide?: boolean
  small?: boolean
  fill?: boolean
  dark?: boolean
  white?: boolean
  selected?: boolean // = fill + dark
  forceReload?: boolean
}

export const LinkButton = (props: Props): React.ReactElement => {
  const { className, disabled, onClick, text, children } = props
  const classes = cs(className, {
    btn: true,
    'btn--primary': props.primary === true,
    'btn--secondary': props.secondary === true,
    'btn--disabled': props.disabled === true,
    'btn--off': props.disabledVisually === true,
    'btn--wide': props.wide === true,
    'btn--small': props.small === true,
    'btn--fill': props.fill === true || props.selected === true,
    'btn--dark': props.dark === true || props.selected === true,
    'btn--white': props.white,
  })
  const linkProps = {
    to:
      typeof props.to !== 'undefined'
        ? props.to
        : typeof props.href === 'string'
        ? props.href
        : undefined,
    className: classes,
    disabled,
    onClick: () => (onClick ? onClick() : undefined),
    forceReload: props.forceReload,
  }

  return (
    <TextLink {...linkProps}>
      <span>
        {children} {text}
      </span>
    </TextLink>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default LinkButton
