import { fork, all } from 'redux-saga/effects'
// /////////////////////////////////////////////////////////////////////// SAGAS
import api_saga from './modules/api/saga'
import activity_saga from './modules/activity/saga'
import entities_saga from './modules/entities/sagas'
import notify_saga from './modules/notify/saga'
import authFailureSaga from './modules/auth/saga'
import Verification from './routes/Verification/saga'
import TeamSagas from './components/Team/redux/saga'
import LikesSagas from './modules/likes/saga'
import FollowersSagas from './modules/follows/followers/saga'
import FollowersWhoAreConnectionsSagas from './modules/follows/followersWhoAreConnections/saga'
import RepliesSagas from './modules/replyComment/saga'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export default function* rootAuthSaga() {
  yield all([
    fork(api_saga),
    fork(activity_saga),
    fork(entities_saga),
    fork(notify_saga),
    fork(authFailureSaga),
    fork(Verification),
    fork(TeamSagas),
    fork(LikesSagas),
    fork(FollowersSagas),
    fork(FollowersWhoAreConnectionsSagas),
    fork(RepliesSagas),
  ])
}
