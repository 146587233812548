import * as socket from '../../modules/realtime/socket'

import * as account_load from '../../modules/auth/actions/account_load'
import { selectAuthenticatedUserID } from '../../modules/auth/selectors'

export const createRealtimeMiddleware = () => {
  return ({ dispatch, getState }) =>
    next =>
    action => {
      const result = next(action)
      if (
        action.type === 'ROUTER_SET_LOCATION' ||
        action.type === 'ROUTER_PUSH'
      ) {
        socket.reloadIfNecessary()
      }
      if (
        action.type === account_load.LOAD_SUCCESS ||
        action.type === socket.RECONNECT
      ) {
        socket.initialize(dispatch)
        socket.setUserID(selectAuthenticatedUserID(getState()))
      }
      return result
    }
}
