import React from 'react'
import Icon from './icon'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  width?: number
  id?: string
  title?: string
  className?: string
  style?: React.CSSProperties
}

const Tick = ({
  width,
  className,
  title,
  id,
  style,
}: Props): React.ReactElement => (
  <Icon
    width={width ?? 17}
    sourceWidth={14}
    sourceHeight={10}
    title={title}
    id={id ?? 'icon-tick'}
    className={className}
    style={style}
  >
    <polyline
      fill="none"
      strokeWidth="2"
      points="12.3000002 1 4.82488802 9.91296552 1 6.08703448"
    />
  </Icon>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Tick
