import React from 'react'

import Icon from './icon'

interface MoreProps {
  width?: number
  id?: string
  title?: string
  className?: string
  onClick?: () => void
}

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const More = ({ width, title, id, className, onClick }: MoreProps): any => {
  return (
    <Icon
      width={width ?? 21}
      sourceWidth={21}
      sourceHeight={5}
      title={title ?? 'More'}
      id={id ?? 'icon-more'}
      className={className}
      onClick={onClick}
    >
      <g>
        <circle cx="18.5" cy="2.5" r="2.5" />
        <circle cx="10.5" cy="2.5" r="2.5" />
        <circle cx="2.5" cy="2.5" r="2.5" />
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default More
