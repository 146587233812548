import React from 'react'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  children: React.ReactNode
  className?: string
  enabled?: boolean
  style?: any
  onClick?: (e: React.MouseEvent<Element>) => void
}
type State = {
  scrollBarWidth: any
}

class ScrollTrap extends React.Component<Props, State> {
  _el: any

  static defaultProps = {
    enabled: true,
  }

  constructor(props: Props, context: any) {
    super(props, context)

    this.state = {
      scrollBarWidth: getScrollbarWidth(),
    }

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ scrollBarWidth: getScrollbarWidth() })
  }

  trapOuterScroll = (e: any): any => {
    if (!this.props.enabled) {
      return
    }

    let elem = e.currentTarget
    const scrollTop = elem.scrollTop
    const scrollHeight = elem.scrollHeight
    const height = elem.clientHeight
    const wheelDelta = e.deltaY
    const isDeltaPositive = wheelDelta > 0

    if (isDeltaPositive && wheelDelta > scrollHeight - height - scrollTop) {
      elem.scrollTop = scrollHeight
      return false
    } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
      elem.scrollTop = 0
      return false
    }
  }

  scrollToTop = () => {
    if (this._el) {
      this._el.scrollTop = 0
    }
  }

  scrollToBottom = () => {
    if (this._el) {
      this._el.scrollTop = this._el.scrollHeight
    }
  }

  /* TODO
     scrollToChild(selector) {
     }
   */

  render(): React.ReactElement {
    const { style, onClick, className, children } = this.props

    return (
      <div
        style={style}
        className={cs('scroll-trap', className)}
        onClick={onClick}
        onWheel={this.trapOuterScroll}
        ref={(el: any): any => {
          this._el = el
        }}
      >
        <div>{children}</div>
      </div>
    )
  }
}

function getScrollbarWidth(): any {
  let outer: any = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  let widthNoScroll = outer.offsetWidth
  // force scrollbars
  outer.style.overflow = 'scroll'

  // add inner div
  let inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  let widthWithScroll = inner.offsetWidth

  // remove divs
  outer.parentNode.removeChild(outer)

  return widthNoScroll - widthWithScroll
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { ScrollTrap as default }
