import React from 'react'

import Image, { Sizes } from '../Img/image'
import { AuthorizationResourceLink } from '../AuthorizationResourceLink'

type Props = {
  userVerified?: boolean
  className?: string
  size: Sizes
  profile?: any
  user?: any
  user_id?: string
  user_name?: string
  shape?: 'square' | 'rounded' | 'circle'
  isLearning?: boolean
  width?: number
}

const ProfileImageLink = ({
  userVerified,
  profile,
  user,
  size,
  user_name,
  user_id,
  shape = 'circle',
  className,
  isLearning,
  width,
  ...rest
}: Props): React.ReactElement => {
  const resource_id = profile
    ? profile.id
    : user
    ? user.id
    : user_id
    ? user_id
    : ''
  const resource_type = profile ? profile.type : 'user'
  const resource_title = profile ? profile.title : user ? user.name : user_name

  return (
    <AuthorizationResourceLink
      className={className}
      resource_id={resource_id}
      resource_type={resource_type}
      resource_title={resource_title}
      {...rest}
      isLearning={isLearning}
    >
      <Image
        url={profile ? profile.image_url : user ? user.avatar_url : ''}
        size={size}
        width={width}
        shape={shape ?? 'circle'}
        alt={profile ? profile.title : user ? user.name : user_name}
      />
    </AuthorizationResourceLink>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ProfileImageLink
