import { concat, take } from 'ramda'
import { generateUUID } from '../../lib/uuid'

import * as actions from './actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type StateType = {
  stack: Array<Object>
  newStack: Array<Object>
}
type ActionType = {
  type: string
  payload: any
}

// /////////////////////////////////////////////////////////////// INITIAL STATE
const INITIALSTATE: StateType = {
  stack: [],
  newStack: [],
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function Reducer(
  state: StateType = INITIALSTATE,
  { type, payload }: ActionType
): any {
  switch (type) {
    case actions.OPEN:
      return {
        ...state,
        stack: concat(state.stack, [payload]),
        lastButton: undefined,
        top: payload,
      }
    case actions.CLOSE:
      return {
        ...state,
        stack: take(state.stack.length - 1, state.stack),
        lastButton: payload ? payload.button : undefined,
        top: state.stack[state.stack.length - 1],
      }
    case actions.CLOSE_ALL:
      return {
        ...state,
        stack: [],
        lastButton: payload ? payload.button : undefined,
        top: undefined,
      }
    case actions.REPLACE:
      return {
        ...state,
        stack: concat(take(state.stack.length - 1, state.stack), [payload]),
        lastButton: payload ? payload.button : undefined,
        top: state.stack[state.stack.length - 1],
      }

    case actions.OPEN_MODAL:
      return {
        ...state,
        newStack: [...state.newStack, { id: generateUUID(), ...payload }],
      }
    case actions.CLOSE_MODAL:
      return {
        ...state,
        newStack: state.newStack.filter(
          (modal: any): boolean => modal.id !== payload
        ),
      }
    case actions.CLOSE_ALL_MODAL:
      return {
        newStack: [],
      }

    default:
      return state
  }
}
