import { any, defaultTo, path, values } from 'ramda'
import { denormalize } from 'normalizr'
import assign from 'lodash/assign'
import concat from 'lodash/concat'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import head from 'lodash/head'
import tail from 'lodash/tail'
import uniqBy from 'lodash/uniqBy'

// ///////////////////////////////////////////////////////////// TYPES & ACTIONS
import * as types from './types'
import * as schemas from './schemas'
import connectTo from './actions/connect'
import { selectSpecialtyMapper } from '../categories/selectors'

// ////////////////////////////////////////////////////////////////////// IMPORT
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////// SELECT ENTITY
export const selectEntity = (state, type, id) => {
  const e = get(state, ['entities', type, id], undefined)
  if (e && type === types.CASE && typeof e.poll === 'string') {
    return { ...e, poll: selectEntity(state, types.POLL, e.poll) }
  }
  return e
}

// ///////////////////////////////////////////////////////////// SELECT ENTITIES
export const selectEntities = (state, type, ids) => {
  return map(
    map(ids, id =>
      typeof id === 'object' && typeof id.id !== 'undefined' ? id.id : id
    ),
    id => state.entities[type][id]
  ).filter(u => !!u)
}

// //////////////////////////////////////////////////////// SELECT USER ENTITIES
export const selectUserEntities = state => state.entities.user

// //////////////////////////////////////////////////////////////// SELECT USERS
export const selectUsers = (state, ids) => {
  return map(ids, id => state.entities.user[id]).filter(u => !!u)
}

// ///////////////////////////////////////////////////////////////// SELECT USER
export const selectUser = (state, id) => {
  if (state.entities && state.entities.user) {
    return denormalize(id, schemas.userSchema, state.entities)
  }
}

// /////////////////////////////////////////////////////////// SELECT USER ENTRY
export const selectUserEntry = (state, id) => {
  if (state.entities && state.entities.entry_user) {
    return assign({}, state.entities[types.ENTRY_USER][id], {
      target: state.entities[types.USER][id],
    })
  }
}

const feedKey = (feed_type, feed_id = undefined) => {
  return schemas.Feeds[feed_type]?.needID
    ? `${feed_type}_${feed_id}`
    : feed_type
}

const selectFeed = (state, feed_type, feed_id = undefined) => {
  return state.entities.feed
    ? state.entities.feed[feedKey(feed_type, feed_id)]
    : undefined
}

// ///////////////////////////////////////////////////////////////////// CHECK FEED LOAD
export const checkFeedLoaded = (state, feed_type, feed_id = undefined) => {
  return !!selectFeed(state, feed_type, feed_id)
}

// ///////////////////////////////////////////////////////////////////// COUNT FEED RESULT
export const countFeedResults = (state, feed_type, feed_id = undefined) => {
  return selectFeed(state, feed_type, feed_id)?.entries?.length ?? 0
}

export const countFeedEntriesWhere = (
  filterFunc,
  state,
  feed_type,
  feed_id = undefined
) => {
  const feed = selectFeedState(state, feed_type, feed_id)
  return feed.feed && feed.feed.entries
    ? filter(feed.feed.entries, filterFunc).length
    : 0
}

export const feedIsLoading = (state, feed_type, feed_id = undefined) => {
  const url = schemas.Feeds[feed_type].url
  const api_key = feed_id === undefined ? url : url.replace('{id}', feed_id)
  const api = state.api[api_key]
  return api && api.active
}

function searchCategories(state, searchTerm) {
  if (searchTerm) {
    const lterm = searchTerm.toLowerCase()
    const specialties = selectSpecialtyMapper(state)

    return uniqBy(
      map(
        filter(
          specialties.searchCategories(searchTerm, {}),
          category =>
            category.label.toLowerCase() !== lterm &&
            (!category.subspecialty_label ||
              category.subspecialty_label.toLowerCase() != lterm)
        ),
        category => {
          return {
            target: {
              label: category.subspecialty_label || category.label,
              type: 'category',
            },
          }
        }
      ),
      'target.label'
    )
  }
  return []
}

// /////////////////////////////////////////////////////////// SELECT FEED STATE
export function selectFeedState(
  state,
  feed_type,
  feed_id = undefined,
  searchTerm = undefined,
  includeCategories = true
) {
  const feed = selectFeed(state, feed_type, feed_id)
  if (
    !feed ||
    (feed_type === types.FEED_SEARCH_RESULTS && searchTerm !== feed.term)
  ) {
    return {}
  }

  const entry_type = schemas.Feeds[feed_type]?.entry_type
  return assign(
    {},
    {
      feed: assign({}, feed, {
        entries: concat(
          feed_type === types.FEED_SEARCH_RESULTS && includeCategories
            ? searchCategories(state, searchTerm)
            : [],
          map(feed.entries, entry => {
            if (typeof entry === 'object') {
              return selectEntry(state, entry.schema, entry.id)
            }
            return state.entities[entry_type]
              ? selectEntry(state, entry_type, entry)
              : entry
          })
        ),

        banners: map(feed.banners || [], id => {
          return selectEntry(state, entry_type, id)
        }),
      }),
      users: state.entities.user,
      searchTerm: searchTerm,
    }
  )
}

// //////////////////////////////////////////////////////// SELECT FEEDBACK PAGE
export function selectFeedPage(feedState, page, perPage) {
  if (feedState.feed) {
    return {
      ...feedState,
      feed: {
        ...feedState.feed,
        entries: feedState.feed
          ? feedState.feed.entries.slice(0, page * perPage)
          : [],
      },
    }
  }
  return feedState
}

export function selectEditedDetails(state, resource_type, resource_id) {
  return get(state, `entities.__edit__.${resource_type}:${resource_id}`)
}

// //////////////////////////////////////////////////////////////// SELECT ENTRY
export function selectEntry(state, type, id) {
  if (state.entities && state.entities[type]) {
    let entry = state.entities[type][id]
    if (!entry) {
      return
    }

    if (type === types.CASE && typeof entry.poll === 'string') {
      entry = { ...entry, poll: selectEntry(state, types.POLL, entry.poll) }
    }

    if (typeof entry.resource === 'object') {
      return assign({ entry_id: id }, entry, {
        original_id: entry.id,
        id,
        actor: selectUser(state, entry.actor),
        resource: selectEntry(state, entry.resource.schema, entry.resource.id),
      })
    } else if (typeof entry.target === 'string') {
      const targetType = types.getEntityTypeForEntryType(type)
      if (state.entities[targetType]) {
        if (
          entry.cases &&
          (targetType === types.GROUP || targetType === types.INSTITUTION)
        ) {
          entry = assign({}, entry, {
            cases: entry.cases.map(id =>
              selectEntry(state, types.ENTRY_CASE, id)
            ),
          })
        }
        return assign({ entry_id: id }, entry, {
          id: entry.target,
          target: selectEntry(state, targetType, entry.target),
          //target: state.entities[targetType][entry.target]
        })
      }
    } else {
      return entry
    }
  }
}

export function selectCurrentFeed(state, resourceContext) {
  if (resourceContext.feed_type) {
    return selectFeedState(
      state,
      resourceContext.feed_type,
      resourceContext.feed_id
    )
  }
}

// /////////////////////////////////////////////////////// FEED CONTAINS WELCOME
export function feedContainsWelcome(state) {
  return reduce(
    get(state, 'entities.entry_update'),
    (result, update) => result || update.verb === 'welcome',
    false
  )
}

// //////////////////////////////////////////////////// SELECT PREVIOUS AND NEXT
export function selectPreviousAndNext(state, feed, entity) {
  const prevnext: any = {}
  const entity_id = entity.target.id
  const index = findIndex(feed.entries, entry => entry.target.id == entity_id)
  if (index > 0) {
    prevnext.previous = feed.entries[index - 1]
  }
  if (index < feed.entries.length - 1) {
    prevnext.next = feed.entries[index + 1]
  }
  return prevnext
}

export function selectCurrentPreviousAndNext(state, resourceContext, entity) {
  const feed = selectCurrentFeed(state, resourceContext)
  if (feed && feed.feed) {
    return selectPreviousAndNext(state, feed.feed, entity)
  }
}

// ///////////////////////////////////////////////////////////////// SELECT CASE
export function selectCase(state, id) {
  if (
    state.entities &&
    state.entities[types.ENTRY_CASE] &&
    state.entities[types.CASE]
  ) {
    const post = assign({}, state.entities[types.ENTRY_CASE][id] || {}, {
      target: selectEntry(state, types.CASE, id),
    })
    if (post.pre_poll) {
      post.pre_poll = selectEntry(state, types.CASE, post.pre_poll)
    }
    return post
  }
}

// //////////////////////////////////////////////////////////////// SELECT GROUP
export function selectGroup(state, type, id) {
  const entry_type =
    type === types.INSTITUTION ? types.ENTRY_INSTITUTION : types.ENTRY_GROUP
  if (state.entities && state.entities[entry_type]) {
    if (state.entities[entry_type][id]) {
      return assign({}, state.entities[entry_type][id] || {}, {
        target: state.entities[type][id],
      })
    }
    const target = head(filter(state.entities[type], e => e.slug == id))
    return target
      ? assign({}, state.entities[entry_type][target.id] || {}, { target })
      : undefined
  }
}

// ///////////////////////////////////////////////////////// SELECT GROUP MEMBER
export function selectGroupMembers(state, type, id) {
  const group = selectGroup(state, type, id)
  if (group) {
    return selectUsers(state, group.members || [])
  }
}

// //////////////////////////////////////////////////////// SELECT FOLLOW STATUS
export function selectFollowStatus(state, resource_type, resource_id) {
  return get(state, [
    'entities',
    types.getEntryTypeForEntityType(resource_type),
    resource_id,
    'follows_target',
  ])
}

export function userHasConnection(state, connection_id) {
  const status =
    state.entities.user[connection_id] &&
    state.entities.user[connection_id].connection_status
  return (
    (status !== undefined && status === 'connected') ||
    state.auth.user_id == connection_id
  )
}

export function userHasReceivedConnectionRequest(state, connection_id) {
  const status =
    state.entities.user[connection_id] &&
    state.entities.user[connection_id].connection_status
  return status !== undefined && status === 'received'
}

export function userHasRequestedConnection(state, connection_id) {
  const status =
    state.entities.user[connection_id] &&
    state.entities.user[connection_id].connection_status
  return status !== undefined && status === 'sent'
}

export function userHasConnectionRequestInFlight(
  state,
  connection_id,
  connecting
) {
  const api = state.api[connectTo('user', connection_id, connecting).id]
  return api && api.active
}

// Group membership status / requests
export function searchUserInstitutions(state, user, searchTerm) {
  const term = searchTerm.toLowerCase()

  return filter(selectUserInstitutions(state, user), profile => {
    return profile.title && -1 < profile.title.toLowerCase().indexOf(term)
  })
}

export function userHasGroupMembership(state, user, group) {
  const key = `${group.type}s`

  return user[key] && -1 < user[key].indexOf(group.id)
}

export function userHasRequestedGroupMembership(state, user, group) {
  const key = group.type + 's_requested'

  if (user && user[key] && -1 < user[key].indexOf(group.id)) {
    return true
  }
  return false
}

export function userHasGroupInvite(state, user, group) {
  const key = `${group.type}s_invited`
  return (
    user && user[key] && user[key].find(x => x == group.id || x.id == group.id)
  )
}

export function anyAdminableGroups(state) {
  return any(
    x => x.is_admin,
    values(defaultTo({}, path(['entities', types.ENTRY_GROUP], state)))
  )
}

// /////////////////////////////////////////////// USER HAS IGNORED GROUP INVITE
export function userHasIgnoredGroupInvite(state, user, group) {
  const key = `${group.type}s_ignored`

  if (user && user[key] && -1 < user[key].indexOf(group.id)) {
    return true
  }
  return false
}

export function userHasMembershipRequestInFlight(state, group, connecting) {
  const api = state.api[connectTo(group.type, group.id, connecting).id]
  return api && api.active
}

// ////////////////////////////////////////////////////// SELECTING INSTITUTIONS
export function selectUserInstitutions(state, profile) {
  return profile.institutions.length ? profile.institutions : []
}

// ///////////////////////////////////////////////////// SELECT MAIN INSTITUTION
export function selectMainInstitution(state, profile) {
  if (profile.institutions.length) {
    return selectEntity(state, 'institution', head(profile.institutions).id)
  }
}

// /////////////////////////////////////////////////// SELECT OTHER INSTITUTIONS
export function selectOtherInstitutions(state, profile) {
  if (profile.institutions.length) {
    return selectEntities(state, 'institution', tail(profile.institutions))
  }
}

// /////////////////////////////////////////////// SELECT NETWORK SEARCH RESULTS
export function selectNetworkSearchResults(state, userID) {
  if (state.entities.network_search && state.entities.network_search[userID]) {
    return map(
      uniqBy(state.entities.network_search[userID].results, 'id'),
      result => selectEntity(state, result.schema, result.id)
    )
  }
}

export function selectGroupMembershipsForResults(
  state,
  resourceType,
  resourceID
) {
  return (
    state.entities.group_membership_by_group_id &&
    state.entities.group_membership_by_group_id[`${resourceType}_${resourceID}`]
  )
}

export function selectGroupInvitesForResults(state, resourceType, resourceID) {
  return (
    state.entities.group_invites &&
    state.entities.group_invites[`${resourceType}_${resourceID}`]
  )
}

// //////////////////////////////////////////////////// SELECT JOIN REQ STATUSES
function selectJoinRequestStatus(state, group_type, group_id, user_id) {
  return get(
    state,
    [
      'entities',
      'group_admin_join_request',
      group_type + '_' + group_id + '_' + user_id,
    ],
    ''
  )
}

export function hasAcceptedJoinRequest(state, group, user_id) {
  return (
    'accept' === selectJoinRequestStatus(state, group.type, group.id, user_id)
  )
}

export function hasIgnoredJoinRequest(state, group, user_id) {
  return (
    'ignore' === selectJoinRequestStatus(state, group.type, group.id, user_id)
  )
}
