import { request } from '../../api/actions'

import { pollSchema } from '../schemas'

export const VOTE_REQUEST = 'entities/poll_vote.REQUEST'
export const VOTE_SUCCESS = 'entities/poll_vote.SUCCESS'
export const VOTE_FAILURE = 'entities/poll_vote.FAILURE'
export const VOTE_FAILURE_SET_ALREADY_VOTED =
  'entities/poll_vote.FAILURE_SET_ALREADY_VOTED'

export const postVote = (target, answer) => {
  const url = `/api/polls/${encodeURIComponent(target.id)}`

  return request({
    url: url,
    id: url,
    method: 'POST',
    json: {
      answer,
    },
    start: VOTE_REQUEST,
    success: [
      {
        type: VOTE_SUCCESS,
        meta: { schema: { response: pollSchema } },
        resource: target,
        answer,
      },
    ],
    failure: [
      {
        type: VOTE_FAILURE,
        meta: { schema: { response: pollSchema } },
        resource: target,
        answer,
      },
      {
        type: VOTE_FAILURE_SET_ALREADY_VOTED,
      },
    ],
  })
}
