import { createSelector } from 'reselect'

import {
  concat,
  partial,
  difference,
  differenceWith,
  equals,
  find,
  innerJoin,
  intersection,
  prop,
  sortBy,
} from 'ramda'

import * as entities from '../entities/selectors'
import { isRequestActive } from '../api/selectors'
import loadAccount from './actions/account_load'
import { AuthenticatedUser } from '../../types/userTypes'
import { AuthState } from './reducer'
import { RootState } from '../../rootReducer'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

const selectAuth = ({ auth }: RootState): AuthState => auth

export const selectIsAuthenticating = state =>
  isRequestActive(state, loadAccount()) ||
  (typeof window === 'undefined' && state.auth?.fetching)

export const selectAuthenticatedUserID = createSelector(selectAuth, auth =>
  auth.authed ? auth.user_id : undefined
)

export const selectIsAuthenticated = state => !!selectAuthenticatedUserID(state)

export const selectAuthenticatedUser = createSelector(
  selectAuthenticatedUserID,
  state => state,
  (id, state): AuthenticatedUser | undefined => entities.selectUser(state, id)
)

export const selectIsClientPortalUser = createSelector(
  selectAuthenticatedUser,
  state => state,
  (user, state): boolean | undefined => user?.signup_product === 'client-portal'
)

const objectID = x =>
  typeof x === 'string' || typeof x === 'number' ? String(x) : String(x.id)

const matchGroupWithID = (state, group_id, filter) => {
  if (typeof group_id === 'object' && group_id) {
    return filter(group_id)
  }
  const group = entities.selectEntity(state, 'group', group_id)
  return group ? filter(group) : false
}

export const checkGroupMembership = (state, group, filter = undefined) => {
  const user = selectAuthenticatedUser(state)
  const id = prop('id', group)
  const eq = partial(
    (a, b) =>
      a == objectID(b) && (!filter || matchGroupWithID(state, b, filter)),
    [id]
  )
  return (
    user &&
    (!!find(eq, user.groups || []) ||
      !!find(eq, user.institutions || []) ||
      !!find(eq, user.institutions_following || []))
  )
}

export const countGroupInvites = user => {
  return (
    (user.groups_invited ? user.groups_invited.length : 0) +
    (user.institutions_invited ? user.institutions_invited.length : 0)
  )
}

export const countCombinedGroupsAndInstitutions = user => {
  return (
    (user.groups || []).length +
    (user.institutions || []).length +
    (user.institutions_following || []).length
  )
}

export const selectCombinedGroupsAndInstitutions = (state, user) => {
  return sortBy(
    prop('id'),
    concat(
      entities.selectEntities(state, 'group', user.groups) || [],
      entities.selectEntities(state, 'institution', user.institutions) || [],
      entities.selectEntities(
        state,
        'institution',
        user.institutions_following
      ) || []
    )
  )
}

export const selectInvitableGroups = (state, user) =>
  selectCombinedGroupsAndInstitutions(state, user).filter(
    x => x.can_create_case
  )

export const selectCommonConnections = (state, id_list1, id_list2) => {
  if (id_list1 && id_list2) {
    return entities.selectUsers(state, intersection(id_list1, id_list2))
  }
  return []
}

export const selectOtherConnections = (state, id_list1, id_list2) => {
  if (id_list1 && id_list2) {
    return entities.selectUsers(state, difference(id_list1, id_list2))
  }
  return []
}

export const selectCommonEntities = (state, entity_list1, entity_list2) => {
  if (entity_list1 && entity_list2) {
    return innerJoin((a, b) => a.id == b.id, entity_list1, entity_list2)
  }
  return []
}

export const selectOtherEntities = (state, entity_list1, entity_list2) => {
  if (entity_list1 && entity_list2) {
    return differenceWith((a, b) => a.id == b.id, entity_list1, entity_list2)
  }
  return []
}
