import React, { Component, createElement } from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'

import * as actions from '../modules/modal/actions'
import CloseIcon from './icons/x'
import ScrollTrap from './scroll-trap'

type ModalViewType = {
  onClose: () => void
  children?: React.ReactNode
  className?: string
  id?: string
}

interface ModalViewProps {
  onClose(): void
  children: React.ReactNode
  className?: string
  id?: string
}

const ModalView = ({
  onClose,
  children,
  className,
  id,
}: ModalViewProps): React.ReactElement => (
  <div className={cs('modal-container', `modal-container--${id}`)}>
    <div className="modal-container__content page__container">
      <CloseIcon className="modal-container__close" onClick={onClose} />
      <div className={cs('modal', className)}>{children}</div>
    </div>
  </div>
)

type ModalPageType = {
  onClose: () => void
  children?: React.ReactNode
  title?: string
  className?: string
}

interface ModalPageProps {
  onClose(): void
  children: React.ReactNode
  title: string
  className?: string
}

const ModalPage = ({
  onClose,
  children,
  title,
  className,
}: ModalPageProps): React.ReactElement => (
  <div className="modal-container modal-container--fullpage">
    <div className="modal-container__content">
      <header className="modal-container__header">
        <div className="page__container">
          <h1 className="mega">{title}</h1>
          <CloseIcon className="modal-container__close" onClick={onClose} />
        </div>
      </header>
      <ScrollTrap>
        <div className={cs('modal-page', className)}>{children}</div>
      </ScrollTrap>
    </div>
  </div>
)

type ModalType = {
  openModal: () => void
  closeModal: () => void
  component: () => void
  componentProps?: any
  className?: string
  id?: string
  title?: string
  fullpage?: boolean
}

class Modal extends Component<any, ModalType, any> {
  closeModal = () => {
    this.props.closeModal()
  }

  render(): React.ReactElement {
    const componentProps = {
      closeModal: this.closeModal,
      ...this.props.componentProps,
    }

    if (this.props.fullpage) {
      return (
        <ModalPage
          onClose={this.closeModal}
          className={this.props.className}
          title={this.props.title}
        >
          {createElement(this.props.component, componentProps)}
        </ModalPage>
      )
    }
    return (
      <ModalView
        onClose={this.closeModal}
        className={this.props.className}
        id={this.props.id}
      >
        {createElement(this.props.component, componentProps)}
      </ModalView>
    )
  }
}

const mapStateToProps = ({ modal }: any): any => {
  return {
    modal,
  }
}

const mapDispatchToProps = (dispatch: Function): any => {
  return {
    openModal: (modal: any): any => {
      dispatch(actions.openModal(modal))
    },

    closeModal: (): any => {
      dispatch(actions.closeModal())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
