import React from 'react'
// /////////////////////////////////////////////////////////////////// COMPONENT
import Icon from './icon'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
  width?: number
}

const Pdf = (props: Props): any => {
  const defaultProps = {
    title: 'PDF',
    id: 'icon-pdf',
    width: 17,
  }

  return (
    <Icon {...{ ...defaultProps, ...props }} sourceWidth={32} sourceHeight={32}>
      <g>
        <path
          d="M24.313,18.421 C23.886,18.001 22.941,17.778 21.501,17.759 C20.527,17.748 19.354,17.834 18.121,18.007 C17.569,17.688 16.999,17.342 16.553,16.924 C15.351,15.802 14.348,14.244 13.722,12.53 C13.763,12.37 13.797,12.229 13.83,12.086 C13.83,12.086 14.507,8.24 14.328,6.94 C14.303,6.762 14.288,6.71 14.24,6.571 L14.181,6.42 C13.997,5.995 13.636,5.545 13.07,5.57 L12.729,5.559 C12.098,5.559 11.583,5.882 11.448,6.364 C11.037,7.878 11.461,10.142 12.229,13.075 L12.032,13.553 C11.482,14.893 10.794,16.242 10.186,17.433 L10.107,17.588 C9.468,18.839 8.887,19.901 8.362,20.801 L7.819,21.088 C7.779,21.109 6.849,21.601 6.631,21.733 C4.779,22.839 3.552,24.094 3.349,25.09 C3.284,25.408 3.332,25.815 3.662,26.003 L4.187,26.267 C4.415,26.381 4.655,26.439 4.901,26.439 C6.22,26.439 7.751,24.796 9.86,21.115 C12.295,20.322 15.068,19.663 17.498,19.3 C19.35,20.343 21.627,21.067 23.065,21.067 C23.32,21.067 23.54,21.043 23.719,20.995 C23.995,20.922 24.227,20.765 24.369,20.551 C24.648,20.131 24.704,19.553 24.629,18.961 C24.606,18.785 24.466,18.568 24.314,18.42 L24.313,18.421 Z M4.614,25.439 C4.855,24.781 5.806,23.481 7.214,22.328 C7.302,22.256 7.52,22.052 7.72,21.862 C6.248,24.21 5.262,25.145 4.614,25.439 L4.614,25.439 Z M12.951,6.24 C13.375,6.24 13.616,7.309 13.636,8.31 C13.656,9.311 13.422,10.015 13.131,10.535 C12.89,9.765 12.774,8.551 12.774,7.757 C12.774,7.757 12.756,6.24 12.951,6.24 L12.951,6.24 Z M10.464,19.922 C10.759,19.393 11.067,18.836 11.381,18.245 C12.146,16.798 12.63,15.665 12.99,14.734 C13.706,16.037 14.598,17.144 15.646,18.031 C15.777,18.142 15.915,18.253 16.061,18.364 C13.929,18.786 12.087,19.299 10.465,19.922 L10.465,19.922 L10.464,19.922 Z M23.903,19.802 C23.773,19.883 23.401,19.93 23.162,19.93 C22.39,19.93 21.435,19.577 20.096,19.003 C20.611,18.965 21.082,18.946 21.505,18.946 C22.279,18.946 22.509,18.943 23.266,19.136 C24.023,19.329 24.033,19.721 23.903,19.803 L23.903,19.803 L23.903,19.802 Z"
          id="Shape"
        />
        <path
          d="M26.681,7.159 C25.987,6.212 25.019,5.106 23.957,4.043 C22.895,2.98 21.788,2.013 20.841,1.319 C19.229,0.137 18.448,0 18,0 L2.5,0 C1.122,0 0,1.121 0,2.5 L0,29.5 C0,30.878 1.121,32 2.5,32 L25.5,32 C26.878,32 28,30.878 28,29.5 L28,10 C28,9.552 27.863,8.77 26.681,7.159 L26.681,7.159 Z M22.543,5.457 C23.502,6.416 24.255,7.282 24.811,8 L20,8 L20,3.189 C20.718,3.745 21.584,4.498 22.543,5.457 L22.543,5.457 Z M26,29.5 C26,29.771 25.771,30 25.5,30 L2.5,30 C2.229,30 2,29.771 2,29.5 L2,2.5 C2,2.229 2.229,2 2.5,2 C2.5,2 17.999,2 18,2 L18,9 C18,9.552 18.448,10 19,10 L26,10 L26,29.5 Z"
          id="Shape"
        />
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Pdf
