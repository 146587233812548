// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as likeTypes from './actions/loadLikes'

// /////////////////////////////////////////////////////////////////////// TYPES
import { LikeType } from './flowType'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

const INITIAL_STATE: LikeType = {
  error: '',
  isLoading: true,
  isLoaded: false,
  fetching: false,
  likes: [],
  users: {},
  currentLike: {
    page: 0,
    pages: 0,
    per_page: 0,
    total: 0,
  },
}

const likeReducer = (
  state: LikeType = INITIAL_STATE,
  { type, payload, caseId, commentId, error }: any
): LikeType => {
  switch (type) {
    // ////////////////////////////////////////////// LOAD LIKES REQUEST
    case likeTypes.LOAD_LIKES_REQUEST:
      return { ...state, isLoading: true, isLoaded: false }
    // ////////////////////////////////////////////// LOAD LIKES SUCCESS
    case likeTypes.LOAD_LIKES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        likes: payload.results,
        users: payload.users,
        currentLike: {
          caseId,
          commentId,
          page: payload.page,
          pages: payload.pages,
          per_page: payload.per_page,
          total: payload.total,
        },
      }

    // //////////////////////////////////////////////// SEE MORE REQUEST
    case likeTypes.SEE_MORE_REQUEST:
      return { ...state, fetching: true }
    // //////////////////////////////////////////////// SEE MORE SUCCESS
    case likeTypes.SEE_MORE_SUCCESS:
      return {
        ...state,
        fetching: false,
        likes: [...state.likes, ...payload.results],
        users: {
          ...state.users,
          ...payload.users,
        },
        currentLike: {
          ...state.currentLike,
          page: payload.page,
          pages: payload.pages,
          per_page: payload.per_page,
          total: payload.total,
        },
      }
    // /////////////////////////////////////////////// LOAD LIKES FAILED
    case likeTypes.SEE_MORE_FAILURE:
      return { ...state, error, fetching: false }

    // /////////////////////////////////////////////// LOAD LIKES FAILED
    case likeTypes.LOAD_LIKES_FAILURE:
      return { ...state, isLoading: false, error, isLoaded: false }

    // /////////////////////////////////////////////////// RESET LOADING
    case likeTypes.RESET_LOADING_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default likeReducer
