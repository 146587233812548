import { useTranslation } from 'next-i18next'
import React from 'react'

const FormattedHTMLMessage: React.FC<{
  id: string
}> = ({ id }): JSX.Element => {
  const { t } = useTranslation()

  return <span dangerouslySetInnerHTML={{ __html: t(id) }}></span>
}

export default FormattedHTMLMessage
