import React from 'react'
import cs from 'classnames'

export type IconWithDefsType = {
  sourceWidth: number
  sourceHeight: number
  id: string
  theme?: string
  color?: string
  title?: string
  children: React.ReactNode
  viewBox?: string
  width?: number
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
  liked?: boolean
  hover?: boolean
  posting?: boolean
  isMobile?: boolean
  replied?: boolean
  style?: Record<string, string | number>
}

const IconWithDefs = ({
  viewBox,
  sourceWidth,
  sourceHeight,
  width,
  onClick,
  className,
  title,
  id,
  children,
  style,
}: IconWithDefsType): React.ReactElement => {
  const ratio = sourceWidth / sourceHeight
  const theWidth = width ?? sourceWidth
  const height = theWidth / ratio
  const titleId = `${id}__title`
  const classes = cs('icon', id, className, {
    'icon--clickable': onClick,
  })

  return (
    <svg
      width={theWidth}
      height={height}
      viewBox={viewBox || `0 0 ${sourceWidth} ${sourceHeight}`}
      aria-labelledby={titleId}
      className={classes}
      onClick={onClick}
      style={style}
    >
      <title id={titleId}>{title}</title>
      {children}
    </svg>
  )
}

export const Icon = ({ children, ...props }: any): React.ReactElement => {
  return (
    <IconWithDefs {...props}>
      <g className="icon__content">{children}</g>
    </IconWithDefs>
  )
}

export { Icon as default, IconWithDefs }
