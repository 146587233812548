import React, { useEffect } from 'react'
import cs from 'classnames'

import { TextLink } from '../link'
import { addScript } from '../../lib/dom'
import { isPrintingToPDF } from '../../lib/client-info'

global['googleTranslateElementInit'] = function () {
  new global['google'].translate.TranslateElement(
    {
      layout: global['google'].translate.TranslateElement.InlineLayout.VERTICAL,
    },
    'google_translate_element'
  )
}

const GoogleTranslate = () => {
  useEffect(() => {
    addScript(
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
      'google-translate'
    )
  }, [])
  return (
    <>
      <div
        id="google_translate_element"
        style={{ position: 'relative', zIndex: 100000 }}
      ></div>
    </>
  )
}

type FooterMenuType = {
  menuData: any
  year?: string
  withTranslate?: boolean
}

const FooterMenu = ({
  menuData,
  year,
  withTranslate,
}: FooterMenuType): React.ReactElement => (
  <nav className={cs('page__container', 'footer-Block')}>
    <ul className={'footer-Block-menu'}>{renderMenuItems(menuData)}</ul>
    <p className={'footer-Block-copyright'}>
      {`© ${year || new Date().getFullYear()} MedShr. All rights reserved.`}
    </p>
    {withTranslate && !isPrintingToPDF() ? <GoogleTranslate /> : null}
  </nav>
)

function renderMenuItems(menuData: any): any {
  if (!menuData.length) return

  return (
    <>
      {menuData.map((item: any, index: number): any => {
        if (item.to) {
          return (
            <li
              key={`${item.name}${index}`}
              className={cs(
                'footer-menu-item',
                item.className ? `footer-menu-item--${item.className}` : ''
              )}
            >
              <TextLink to={item.to} as={item.as}>
                {item.name}
              </TextLink>
            </li>
          )
        }
        if (item.href) {
          return (
            <li
              key={`${item.name}${index}`}
              className={cs(
                'footer-menu-item',
                item.className ? `footer-menu-item--${item.className}` : ''
              )}
            >
              <a href={item.href} target="_blank" rel="noreferrer noopener">
                {' '}
                {item.name}{' '}
              </a>
            </li>
          )
        }
        if (item.email) {
          return (
            <li
              key={`${item.name}${index}`}
              className={cs(
                'footer-menu-item',
                item.className ? `footer-menu-item--${item.className}` : ''
              )}
            >
              <a href={`mailto:${item.email}`}> {item.name} </a>
            </li>
          )
        }

        return (
          <li
            key={`${item.name}${index}`}
            className={cs(
              'footer-menu-item',
              item.className ? `footer-menu-item--${item.className}` : ''
            )}
          >
            <span>{item.name}</span>
          </li>
        )
      })}
    </>
  )
}

export { FooterMenu as default, renderMenuItems }
