// import { delay } from 'redux-saga';
import { put, select, fork, take, all, call } from 'redux-saga/effects'
import update from 'lodash/update'
import { clone } from 'ramda'
// /////////////////////////////////////////////////////////////// ACTIONS & API
import {
  blockLikeRequest,
  unBlockLikeRequest,
  updateLikeSuccess,
  updateLikeFailed,
  updateLikeById,
  optimisticUpdate,
  UPDATE_LIKE_REQUEST,
} from './actions/likeComment'
import {
  UPDATE_DISLIKE_REQUEST,
  updateDisLikeSuccess,
  updateDisLikeFailed,
  // unBlockDisLikeRequest,
  // blockDisLikeRequest,
  updateDisLikeById,
} from './actions/dislikeComments'
import { invitesUpdated } from '../temp/actions'
import getInvites, { GET_INVITES, getInvitations } from './actions/case_invites'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// //////////////////////////////////////////////////////////// UPDATE LIKE SAGA
function* updateLike(commentId: string, caseId: string) {
  yield put(blockLikeRequest())
  const case_state = yield select(
    (state: any): any => state.entities.entry_case
  )
  const cloneCaseState = clone(case_state)

  try {
    // /////////////////////////////////////////////////////// OPTIMISTIC UPDATE
    const commentIndex = case_state[caseId].comments.findIndex(
      (i: any): any => i.id === commentId
    )

    // //////////////////////////////////////////////////////////////////// LIKE
    if (!case_state[caseId].comments[commentIndex].has_user_liked) {
      update(
        case_state,
        `${caseId}.comments[${commentIndex}].like_count`,
        (): any => case_state[caseId].comments[commentIndex].like_count + 1
      )
      update(
        case_state,
        `${caseId}.comments[${commentIndex}].has_user_liked`,
        (): any => true
      )
      update(
        case_state,
        `${caseId}.comments[${commentIndex}].has_user_disliked`,
        (): any => false
      )

      yield put(optimisticUpdate(case_state))
    } else {
      // //////////////////////////////////////////////////////////////// UNLIKE
      update(
        case_state,
        `${caseId}.comments[${commentIndex}].like_count`,
        (): any => case_state[caseId].comments[commentIndex].like_count - 1
      )
      update(
        case_state,
        `${caseId}.comments[${commentIndex}].has_user_liked`,
        (): any => false
      )

      yield put(optimisticUpdate(case_state))
    }

    // //////////////////////////////////////////////////////////////// CALL API
    const data = yield call(updateLikeById, caseId, commentId)

    if (data.error) {
      yield put(updateLikeSuccess(cloneCaseState))
      yield put(unBlockLikeRequest())
    } else {
      const state = yield select((state: any): any => state.entities.entry_case)

      yield put(
        updateLikeSuccess(updateLikeState(state, data, { caseId, commentId }))
      )
      yield put(unBlockLikeRequest())
    }
  } catch (err) {
    yield put(updateLikeSuccess(cloneCaseState))
    yield put(updateLikeFailed(`can't like the comment`))
    yield put(unBlockLikeRequest())
  }
}

// ///////////////////////////////////////////////////////// UPDATE DISLIKE SAGA
function* updateDisLike({ caseId, commentId }: any) {
  try {
    const state = yield select((state: any): any => state.entities.entry_case)
    const data = yield call(updateDisLikeById, caseId, commentId)

    yield put(
      updateDisLikeSuccess(updateLikeState(state, data, { caseId, commentId }))
    )
    // yield put(unBlockLikeRequest());
  } catch (err) {
    yield put(updateDisLikeFailed(`can't like the comment`))
    // yield put(unBlockLikeRequest());
  }
}

// ///////////////////////////////////////////////////// FETCH CASE INVITES SAGA
function* getCaseInvites(caseId: string, editMode) {
  try {
    const { results, success } = yield call(getInvites, caseId)

    if (success) {
      const case_state = yield select(({ entities }: any): any => entities.case)
      const objReduced = results.map(({ id, type, title, ...rest }) => {
        return { id: id.toString(), type, name: title, invited: true, ...rest }
      })
      const updatedState = {
        ...case_state,
        [caseId]: { ...case_state[caseId], viewers: objReduced },
      }
      yield put(getInvitations.success(updatedState))
      if (editMode) {
        // Trigger render for invite modal on case page
        yield put(invitesUpdated(caseId))
      }
    } else {
      yield put(getInvitations.failure(new Error('error')))
    }
  } catch (err) {
    yield put(getInvitations.failure(err))
  }
}

// /////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////// HELPER FUNCTIONS
export function updateLikeState(
  state: any,
  nextState: any,
  additional: any
): any {
  const { caseId, commentId } = additional
  const commentIndex = state[caseId].comments.findIndex(
    (i: any): any => i.id === commentId
  )

  update(
    state,
    `${caseId}.comments[${commentIndex}].like_count`,
    (): any => nextState.like_count
  )
  update(
    state,
    `${caseId}.comments[${commentIndex}].has_user_liked`,
    (): any => nextState.has_user_liked
  )
  update(
    state,
    `${caseId}.comments[${commentIndex}].has_user_disliked`,
    (): any => nextState.has_user_disliked
  )
  update(
    state,
    `${caseId}.comments[${commentIndex}].has_user_replied`,
    (): any => nextState.has_user_replied
  )
  update(
    state,
    `${caseId}.comments[${commentIndex}].latest_likes`,
    (): any => nextState.latest_likes
  )

  return state
}

// /////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////// WATCHERS
function* watchUpdateLike() {
  while (true) {
    const { commentId, caseId } = yield take(UPDATE_LIKE_REQUEST)
    yield fork(updateLike, commentId, caseId)
  }
}

function* watchUpdateDisLike() {
  while (true) {
    const { commentId, caseId } = yield take(UPDATE_DISLIKE_REQUEST)
    yield fork(updateDisLike, { caseId, commentId })
  }
}

function* watchGetCaseInvite() {
  while (true) {
    const { payload, editMode } = yield take(GET_INVITES.REQUEST)
    yield fork(getCaseInvites, payload, editMode)
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function* rootSaga() {
  return yield all([
    fork(watchUpdateLike),
    fork(watchUpdateDisLike),
    fork(watchGetCaseInvite),
  ])
}
