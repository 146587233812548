import React from 'react'
import cs from 'classnames'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { Like } from '../icons'
import LikeTooltipList from './LikeTooltipList'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './LikeBtn.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  isMobile?: boolean
  isLiked?: boolean
  hover?: boolean
  noHover?: boolean
  showTooltip: boolean
  fetching?: boolean
  commentId?: string
  likeList?: Array<any>
  userMap?: any
  onClick?: Function
  setHover?: Function
  openLikesModal?: Function
  count: number
  readOnly?: boolean
}

const LikeBtn = ({
  isLiked,
  count,
  onClick,
  userMap,
  likeList,
  commentId,
  isMobile,
  openLikesModal,
  showTooltip,
  setHover,
  hover,
  noHover,
  fetching,
  readOnly,
}: Props): any =>
  readOnly && !count ? null : (
    <span className={cs('fs-unmask', style.container)}>
      <span
        className={cs(style.icon, { [style.noHover]: noHover || readOnly })}
        onClick={readOnly ? undefined : () => onClick()}
        onMouseEnter={readOnly ? undefined : () => setHover(true)}
        onMouseLeave={readOnly ? undefined : () => setHover(false)}
      >
        <Like
          className={cs(style.icon, { [style.noHover]: noHover || readOnly })}
          liked={isLiked}
          isMobile={isMobile}
          hover={hover}
        />
      </span>
      {count > 0 && !!userMap && !!likeList && (
        <LikeTooltipList
          count={count}
          likeList={likeList}
          userMap={userMap}
          commentId={commentId}
          isMobile={isMobile}
          openLikesModal={openLikesModal}
          showTooltip={showTooltip}
          noHover={noHover}
          fetching={fetching}
        />
      )}
    </span>
  )

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default LikeBtn
