import * as schemas from '../../entities/schemas'
import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const REFRESH: string = 'auth/REFRESH'
export const LOAD_REQUEST: string = 'auth/LOAD_REQUEST'
export const LOAD_SUCCESS: string = 'auth/LOAD_SUCCESS'
export const LOAD_FAILURE: string = 'auth/LOAD_FAILURE'

export const loadSuccess = (): any => ({
  type: LOAD_SUCCESS,
  meta: { schema: { response: schemas.userSchema } },
})

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export function loadAccount(): any {
  return request({
    url: '/api/profile/me?include=filters%2Cmemberships',
    start: LOAD_REQUEST,
    success: {
      type: LOAD_SUCCESS,
      meta: { schema: { response: schemas.userSchema } },
    },
    failure: LOAD_FAILURE,
  })
}

export default loadAccount
