import React from 'react'

// ////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'
import InstitutionPicker from './institution-picker'
import { filterReduxFormProps } from './base'

// /////////////////////////////////////////////////////////////// STYLE
import style from './styles/SelectField.module.scss'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

type SelectFieldType = {
  input: any
  meta: any
  placeholder: string
  label: string
}

const InstitutionPickerField: any = ({
  input: { ...restInput },
  meta: { touched, error, warning, dirty, ...restMeta },
  placeholder,
  label,
  ...rest
}: SelectFieldType): any => {
  const labelText = (
    <label htmlFor={label || placeholder}>{label || placeholder}</label>
  )

  return (
    <div className={style.container}>
      {label ? labelText : null}

      <InstitutionPicker
        placeholder={placeholder}
        {...filterReduxFormProps(restInput)}
        {...filterReduxFormProps(restMeta)}
        {...filterReduxFormProps(rest)}
      />

      {touched &&
        dirty &&
        ((error && (
          <Error customClass={style.textError} message={error} hasError />
        )) ||
          (warning && (
            <Error customClass={style.textError} message={error} hasWarning />
          )))}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default InstitutionPickerField
