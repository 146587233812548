import { useTranslation } from 'next-i18next'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from '../../../react-redux'
import cs from 'classnames'
import get from 'lodash/get'

import * as verifyActions from '../actions'
import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { hasEmail } from '../../../modules/entities/user'
import { ImageInputPreview } from '../../../ui/ImageInputPreview'
import { TextButton } from '../../../ui/buttons'
import { SpinnerIcon } from '../../../ui/icons'
import SampleImgModal from '../../../ui/Modal/SampleImg'
import { Button } from '../../../ui/buttons'
import DeepInfoText from '../../../ui/Typo/DeepInfoText'
import styles from './styles/UploadVerifyImg.module.scss'

type ComponentProps = {
  photoType: 'id' | 'proof'
  isVerificationPage?: boolean
}

const UploadVerifyImg: React.FC<ComponentProps> = ({
  photoType,
  isVerificationPage,
}) => {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const { user, type, userHasPhone, isDocumentLoaded, isDocumentLoading } =
    useSelector(state => {
      const user = selectAuthenticatedUser(state)
      const activityPromo = get(state, 'activity.promo.type', '')
      const emailExist = hasEmail(user)
      const userHasPhone = user.confirmed_phone && !emailExist

      return {
        user,
        type: activityPromo,
        userHasPhone,
        isDocumentLoading: get(state, 'verification.isDocumentLoading', false),
        isDocumentLoaded: get(state, 'verification.isDocumentLoaded', false),
      }
    })

  const [state, setState] = useState({
    sampleImgModal: false,
    file: null,
    isChanged: false,
  })

  useEffect(() => {
    if (state.isChanged) {
      setState({ ...state, isChanged: false })
    }
  }, [isDocumentLoading])

  const onDocumentChange = (file: File) => {
    setState({ ...state, file, isChanged: true })
    dispatch(verifyActions.resetDocumentLoad(false)) // reset the documentLoaded state to false
  }

  const onSendButtonClicked = () => {
    if (state.file) {
      dispatch(verifyActions.postDocumentRequest(state.file))
    }
  }

  const handleModalToggle = () => {
    setState({ ...state, sampleImgModal: !state.sampleImgModal })
  }

  const userDocument = user.document_url
  const { file, isChanged } = state
  const buttonLayout = cs('end small-12')
  const imageSrc =
    user.kind === 'student'
      ? '/static/media/images/verify-photo-id_student.jpg'
      : '/static/media/images/verify-photo-id.png'

  return (
    <div className="medium-12">
      <h4 className={cs('long-header', { light: !isVerificationPage })}>
        {user && getTitle({ t, type, user, userHasPhone })}
      </h4>
      {user && (
        <DeepInfoText color="dark" inlineStyle={{ marginBottom: 10 }}>
          {renderLongHeader({ t, type, user })}
        </DeepInfoText>
      )}

      <div className={cs('fs-exclude', styles.uploadBtn)}>
        <ImageInputPreview
          type={type}
          size="thumb-medium"
          documentUrl={userDocument}
          replaceText={t('upload_verify.replace')}
          onChange={onDocumentChange}
        />
      </div>
      <div className={styles.showMeExample}>
        <div>
          {type === 'document_rejected' && (
            <div className={styles.emailUs}>
              <a className="action-link" href="mailto:contact@medshr.net">
                {t('upload_verify.email_us_1')}
              </a>
              {t('upload_verify.email_us_2')}
            </div>
          )}
          <TextButton className={styles.link} onClick={handleModalToggle}>
            Show me an example
          </TextButton>
        </div>
      </div>

      <div className={buttonLayout} style={{ paddingRight: 9 }}>
        <div className="fixed-260">
          <Button
            type="submit"
            disabled={
              isDocumentLoading || !file || (isDocumentLoaded && !isChanged)
            }
            activeDisabled={isDocumentLoading}
            onClick={onSendButtonClicked}
            block
            space
            fill
          >
            {isDocumentLoading ? (
              <span>
                Sending... <SpinnerIcon width={12} />
              </span>
            ) : isChanged ? (
              t('promo_verify_provide_id.send_button_label')
            ) : isDocumentLoaded ? (
              t('promo_verify_provide_id.document_sent')
            ) : (
              t('promo_verify_provide_id.send_button_label')
            )}
          </Button>
        </div>
      </div>

      <SampleImgModal
        isOpen={state.sampleImgModal}
        onCancel={handleModalToggle}
        onModalClose={handleModalToggle}
        modalOpened={handleModalToggle}
        shouldCloseOnOverlayClick
        imgUrl={imageSrc}
      />
    </div>
  )
}

const getTitle = ({ t, user, type, userHasPhone }): string => {
  if (
    type === 'untrusted_unconfirmed_email_document_pending' ||
    type === 'untrusted_confirmed_email_document_pending'
  ) {
    const messageId = userHasPhone
      ? 'untrusted_unconfirmed_email_document_pending.title_nofb'
      : 'untrusted_unconfirmed_email_document_pending.title'

    return t(messageId)
  } else {
    if (
      user.kind === 'doctor' ||
      user.kind === 'nurse' ||
      user.kind === 'professional'
    ) {
      return t('upload_verify_img_text.title.doctor_and_nurse')
    } else if (user.kind === 'dentist') {
      return t('upload_verify_img_text.title.dental')
    } else if (user.kind === 'academic') {
      return t('upload_verify_img_text.title.academic')
    } else if (user.kind === 'student') {
      if (user.seniority === 'clinical' || user.seniority === 'pre_clinical') {
        return t('upload_verify_img_text.title.medical_student')
      }
      return t('upload_verify_img_text.title.dental_student')
    }
  }

  throw new Error(`${user.kind} isn't match`)
}

const renderLongHeader = ({ t, user, type }): string => {
  if (
    type === 'untrusted_unconfirmed_email_document_pending' ||
    type === 'untrusted_confirmed_email_document_pending'
  ) {
    return t('untrusted_confirmed_email_document_pending.content.text')
  } else {
    if (
      user.kind === 'doctor' ||
      user.kind === 'nurse' ||
      user.kind === 'professional'
    ) {
      return t('upload_verify_img_text.doctor_and_nurse')
    } else if (user.kind === 'dentist') {
      return t('upload_verify_img_text.dental')
    } else if (user.kind === 'academic') {
      return t('upload_verify_img_text.academic')
    } else if (user.kind === 'student') {
      if (user.seniority === 'clinical' || user.seniority === 'pre_clinical') {
        return t('upload_verify_img_text.medical_student')
      }
      return t('upload_verify_img_text.dental_student')
    }
  }

  throw new Error(`${user.kind} isn't match`)
}

export default UploadVerifyImg
