import * as actions from './actions'

type InitialState = {
  isLoading: boolean
  results: [string?]
  error: string
}

const initialState: InitialState = {
  isLoading: true,
  results: [],
  error: '',
}

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action

  switch (type) {
    case actions.LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case actions.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload.results,
        page: payload.page,
        pages: payload.pages,
      }

    case actions.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error,
      }

    case actions.RESET_INITIAL_STATE:
      return initialState

    default:
      return state
  }
}

export default reducer
