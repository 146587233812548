import React, { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

type FadeType = {
  duration?: number
  children: React.ReactNode
  in?: boolean
}

const Fade = ({
  duration = 260,
  children,
  in: inProp,
}: FadeType): React.ReactElement => {
  const ref = useRef()
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        nodeRef={ref}
        classNames="fx-fade"
        appear
        timeout={{ enter: duration ?? 260, exit: duration ?? 260 }}
        in={
          typeof inProp === 'undefined'
            ? React.Children.count(children) > 0
            : inProp
        }
      >
        <div ref={ref}>{children}</div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Fade
