'use client'
import React, { useEffect, useState } from 'react'

export const CookieConsent = ({ needsConsent }: { needsConsent: boolean }) => {
  const [visible, setVisible] = useState<boolean>(needsConsent)

  if (!visible) {
    return null
  }

  const handleClick = () => {
    setVisible(false)
    fetch('/wapi/cc', { method: 'POST' })
  }

  return (
    <div
      role="dialog"
      aria-live="polite"
      aria-label="cookieconsent"
      aria-describedby="cookieconsent:desc"
      className="cc-window"
    >
      <div className="cc-window__inner">
        <p>
          MedShr uses cookies to ensure you get the best experience.{' '}
          <a href="/pages/cookies" target="_blank">
            Learn more
          </a>
        </p>
        <button onClick={handleClick} className="btn btn--primary">
          Got it
        </button>
      </div>
    </div>
  )
}
