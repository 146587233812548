import React from 'react'
import Image from 'next/image'

import MedShrLogo from '/public/static/media/svg/awards/MedShr_Team.svg'

interface Props {
  id?: string
  title?: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  width?: number
}

const MedshrEditorBadge = ({
  id = 'icon-editor-badge',
  title = 'MedShr Team',
  className,
  onClick,
  width,
}: Props) => {
  return (
    <Image
      id={id}
      alt={title}
      title={title}
      src={MedShrLogo}
      className={className}
      width={width}
      height={width}
      onClick={onClick}
    />
  )
}

export default MedshrEditorBadge
