import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/UnOrderedList.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  children?: React.ReactNode
  side?: 'left' | 'right' | 'center'
  type?: 'vertical' | 'horizontal'
  nospace?: boolean
  className?: string
}

const UnOrderedList = ({
  type = 'horizontal',
  nospace,
  side = 'left',
  children,
  className,
}: PropsType): React.ReactElement => {
  const classes = cs(
    className,
    style.list,
    style[type ?? 'horizontal'],
    style[side ?? 'left'],
    {
      [style.nospace]: nospace,
    }
  )

  return <ul className={classes}>{children}</ul>
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default UnOrderedList
