import * as types from './actions'
import { AvatarUpload } from './flowTypes'

export const INITIAL_STATE: AvatarUpload = {
  isUploading: false,
  isUploaded: false,
  error: '',
  widgetForm: false,
}

export default function Reducer(
  state: AvatarUpload = INITIAL_STATE,
  { type, error }: any
): any {
  switch (type) {
    case types.PROMO_WIDGET_AVATAR_UPLOAD_PENDING:
      return { ...state, isUploading: true, isUploaded: false }
    case types.PROMO_WIDGET_AVATAR_UPLOAD_COMPLETE:
      return { ...state, isUploading: false, isUploaded: true }
    case types.PROMO_WIDGET_AVATAR_UPLOAD_ERROR:
      return { ...state, isUploading: false, isUploaded: false, error }

    case types.WIDGET_EDIT_MODE_ON:
      return toogleEdit(state)
    default:
      return state
  }
}

// toggle the edit state
export function toogleEdit(state: any): any {
  let newState = Object.assign({}, state)
  newState.widgetForm = !newState.widgetForm

  return newState
}
