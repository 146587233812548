import React from 'react'
import Icon from './icon'

interface Props {
  width?: number
  id?: string
  fill?: string
  stroke?: boolean
}

const StarIcon = ({
  width = 13,
  id = 'icon-star',
  fill = 'black',
  stroke = false,
}: Props) => {
  return stroke ? (
    <Icon width={width} sourceWidth={35} sourceHeight={35} title="Star" id={id}>
      <g id="Rating Star full">
        <g id="filled/toggle/star">
          <path
            id="Vector"
            d="M27.0007 30.5108L24.6776 20.5526L32.412 13.8523L33.3077 13.0763L32.1269 12.9761L21.9419 12.112L17.9617 2.7209L17.5013 1.63472L17.0409 2.7209L13.0607 12.112L2.8757 12.9761L1.69489 13.0763L2.59058 13.8523L10.325 20.5526L8.00188 30.5108L7.73267 31.6647L8.74717 31.0524L17.5013 25.7688L26.2554 31.0524L27.2699 31.6647L27.0007 30.5108Z"
            fill="white"
            stroke="black"
          />
          <g id="Rating Star full_2">
            <g id="filled/toggle/star_2">
              <path
                id="Vector_2"
                d="M17.5013 25.1848L26.5138 30.6243L24.1221 20.3723L32.0846 13.4743L21.5992 12.5848L17.5013 2.91602L13.4034 12.5848L2.91797 13.4743L10.8805 20.3723L8.4888 30.6243L17.5013 25.1848Z"
                fill="#F2C94C"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  ) : (
    <Icon width={width} sourceWidth={13} sourceHeight={13} title="Star" id={id}>
      <path
        d="M13.0016379,4.80529286 C13.0016379,4.9198912 12.9339217,5.0449057 12.7984873,5.18034011 L9.96219251,7.94631356 L10.6341522,11.8530557 C10.6393612,11.8895188 10.6419656,11.9416082 10.6419656,12.0093254 C10.6419656,12.1187147 10.6146187,12.2111734 10.5599241,12.2867041 C10.5052294,12.3622348 10.4257931,12.3999996 10.3216128,12.3999996 C10.2226415,12.3999996 10.1184627,12.368746 10.0090734,12.3062378 L6.50081896,10.4622555 L2.9925645,12.3062378 C2.87796615,12.368746 2.7737874,12.3999996 2.68002512,12.3999996 C2.57063579,12.3999996 2.48859503,12.3622348 2.43390037,12.2867041 C2.3792057,12.2111734 2.35185878,12.1187147 2.35185878,12.0093254 C2.35185878,11.9780713 2.35706772,11.9259819 2.36748575,11.8530557 L3.0394454,7.94631356 L0.195337108,5.18034011 C0.0651117182,5.03969668 0,4.91468219 0,4.80529286 C0,4.61255928 0.145850249,4.49275372 0.437555122,4.44587258 L4.35992425,3.87548822 L6.11795822,0.320352857 C6.21692952,0.106783218 6.34454849,0 6.50081896,0 C6.65708942,0 6.78470839,0.106783218 6.88367969,0.320352857 L8.64171366,3.87548822 L12.5640828,4.44587258 C12.8557877,4.49275372 13.0016379,4.61255928 13.0016379,4.80529286 L13.0016379,4.80529286 Z"
        fill={fill}
      />
    </Icon>
  )
}

export default StarIcon
