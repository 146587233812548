import React from 'react'
import Autosuggest from 'react-autosuggest'
import concat from 'lodash/concat'
import findIndex from 'lodash/findIndex'
// ////////////////////////////////////////////////////////////////////// ACTION
import callAPI from '../../modules/api/call'
import * as console from '../../lib/console'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id: string
  value: any
  onChange: Function
  placeholder?: string
  name?: string
  autoFocus?: any
}
type State = {
  term: string
  suggestions: Array<any>
  isLoading: boolean
}

class InstitutionPicker extends React.Component<Props, State> {
  static defaultProps = {
    value: { title: '', id: '' },
  }

  constructor(props: Props) {
    super(props)

    const { value } = props

    this.state = {
      term: typeof value === 'object' ? value.title : '',
      suggestions: [],
      isLoading: false,
    }
  }

  loadSuggestions(term: string) {
    this.setState({ ...this.state, term, isLoading: true })

    callAPI({
      url: `/api/institutions/search?q=${encodeURIComponent(term)}`,
      debounce: true,
      id: '/api/institutions/search',
    }).then(
      ({ payload }) => {
        if (term === this.state.term) {
          const lterm = term.toLowerCase()
          const index = findIndex(
            payload.results,
            result => result.title.toLowerCase() === lterm
          )
          this.setState({
            ...this.state,
            isLoading: false,
            suggestions:
              index === -1
                ? concat(payload.results, [
                    { title: term, id: `create:${term}` },
                  ])
                : payload.results,
          })
        } else {
          // Ignore suggestions if input term changed
          this.setState({ ...this.state, isLoading: false })
        }
      },
      error => {
        if (error && !error.cancelled) {
          console.error(error)
        }
      }
    )
  }

  onChange = (e: any, { newValue }: any) => {
    this.setState({ ...this.state, term: newValue })
  }

  onSuggestionSelected = (e: any, { suggestion }: any) => {
    this.setState({ ...this.state, term: suggestion.title })
    this.props.onChange(suggestion)
  }

  onSuggestionsUpdateRequested = ({ value }: any) => {
    this.loadSuggestions(value)
  }

  getSuggestionValue(suggestion: any): string {
    return suggestion.title
  }

  renderSuggestion(suggestion: any): any {
    return <span>{suggestion.title}</span>
  }

  render(): React.ReactElement {
    const { id, placeholder, autoFocus, name } = this.props
    const inputProps = {
      className: 'fs-unmask',
      placeholder,
      id,
      value: this.state.term || '',
      onChange: this.onChange,
      autoFocus,
      name,
    }

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsUpdateRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        focusInputOnSuggestionClick={false}
      />
    )
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default InstitutionPicker
