import { filter } from 'ramda'
import React from 'react'

import { useSelector } from '../../react-redux'
import { Footer, FooterMenu } from '../../ui/Footer'

const footer: Array<Object> = [
  { to: '/pages/[id]', as: '/pages/about', name: 'About MedShr' },
  { to: '/team', name: 'Our Team' },
  { to: '/team/[id]?id=partners', as: '/team/partners', name: 'Partners' },
  {
    to: '/news',
    as: '/news',
    name: 'News',
  },
  {
    to: '/pages/[id]',
    as: '/pages/privacy',
    name: 'Privacy policy',
    className: 'legal',
  },
  {
    to: '/pages/[id]',
    as: '/pages/terms',
    name: 'Terms & Conditions',
    className: 'legal',
  },
  {
    to: '/pages/[id]',
    as: '/pages/medshr-case-studies',
    name: 'Case Studies',
  },
  { to: '/covid', name: 'COVID-19' },
  // { name: `© ${(new Date).getFullYear()} MedShr. All rights reserved.` },
  { email: 'contact@medshr.net', name: 'contact@medshr.net' },
]

const FooterContainer = () => {
  const featureFlags = useSelector(state => state.features)
  let footer_menu = footer

  return (
    <Footer>
      <FooterMenu
        menuData={footer_menu}
        withTranslate={!!featureFlags.google_translate_widget}
      />
    </Footer>
  )
}

export default FooterContainer
