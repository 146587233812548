import React from 'react'
import Icon from './icon'

interface Props {
  width?: number
  id?: string
  fill?: string
  stroke?: boolean
}

const StarEmptyIcon = ({
  width = 13,
  id = 'icon-star-empty',
  fill = 'black',
  stroke = false,
}: Props) => {
  return stroke ? (
    <Icon
      width={width}
      sourceWidth={35}
      sourceHeight={35}
      title="Star Half"
      id={id}
    >
      <g id="Rating Star full">
        <g id="filled/toggle/star" clipPath="url(#clip0_3934_66722)">
          <path
            id="Vector"
            d="M27.0007 30.5108L24.6776 20.5526L32.412 13.8523L33.3077 13.0763L32.1269 12.9761L21.9419 12.112L17.9617 2.7209L17.5013 1.63472L17.0409 2.7209L13.0607 12.112L2.8757 12.9761L1.69489 13.0763L2.59058 13.8523L10.325 20.5526L8.00188 30.5108L7.73267 31.6647L8.74717 31.0524L17.5013 25.7688L26.2554 31.0524L27.2699 31.6647L27.0007 30.5108Z"
            fill="#0C3569"
            stroke="black"
          />
          <g id="Full - yellow">
            <g id="01 ratingstar">
              <mask
                id="mask0_3934_66722"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <g id="masked icon">
                  <g id="filled/toggle/star_2">
                    <path
                      id="Vector_2"
                      d="M18.0053 24.364L17.5052 24.0622L17.0051 24.364L9.9562 28.6185L11.8268 20.5999L11.9595 20.0311L11.518 19.6486L5.28914 14.2525L13.4891 13.5569L14.0706 13.5075L14.2983 12.9702L17.5052 5.40377L20.7121 12.9702L20.9398 13.5075L21.5213 13.5569L29.7213 14.2525L23.4924 19.6486L23.0509 20.0311L23.1836 20.5999L25.0542 28.6185L18.0053 24.364Z"
                      stroke="black"
                      strokeWidth="1.93549"
                    />
                  </g>
                </g>
              </mask>
              <g mask="url(#mask0_3934_66722)">
                <rect
                  id="half empty"
                  x="1.29297"
                  y="0.0078125"
                  width="33.5441"
                  height="35"
                  fill="#F2C94C"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3934_66722">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  ) : (
    <Icon
      width={width}
      sourceWidth={13}
      sourceHeight={13}
      title="Star Half"
      id={id}
    >
      <path
        d="M9.6131 7.58835L9.42486 7.77193L9.46943 8.03107L10.1174 11.7983L6.73345 10.0197L6.50082 9.8974L6.26819 10.0197L2.88424 11.7983L3.53221 8.03107L3.57686 7.7715L3.38804 7.58787L0.645695 4.92086L4.43188 4.37028L4.69172 4.3325L4.80812 4.09712L6.50082 0.674106L8.19352 4.09712L8.30991 4.3325L8.56976 4.37028L12.3494 4.91991L9.6131 7.58835Z"
        stroke={fill}
      />
    </Icon>
  )
}

export default StarEmptyIcon
