import React from 'react'
import Router from 'next/router'
import { initializeStore } from '../store'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState?: any) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

const WithReduxStore = App => {
  return class AppWithRedux extends React.Component {
    reduxStore: any

    static async getInitialProps(appContext) {
      const { req } = appContext.ctx
      const { headers, locale } = req || window['__NEXT_DATA__']['props']

      const passHeaders =
        isServer && headers
          ? {
              'accept-language': headers['accept-language'],
            }
          : {}

      const reduxStore = getOrCreateStore({
        router: { ...appContext.router, locale: locale, headers: passHeaders },
      })

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      const { categories, router, ...initialReduxState } = reduxStore.getState()
      const { headers: _, ...clientRouter } = router

      return {
        ...appProps,
        initialReduxState: {
          ...initialReduxState,
          auth: {
            ...initialReduxState.auth,
            fetching: !!req?.cookies['authed'],
          },
          router: clientRouter,
        },
      }
    }

    constructor(props) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
      if (typeof location !== 'undefined') {
        this.reduxStore.dispatch({
          type: 'ROUTER_SET_LOCATION',
          url: Router.asPath,
        })
      }
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  }
}

export default WithReduxStore
