import React from 'react'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import Image from './image'
import { WrappedImageType, Sizes } from './image'

// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/ImgOverlayWrapper.module.scss'

interface ImgOverlayWrapperProps {
  url: string
  alt?: string
  size: Sizes
  text?: string | React.ReactElement
  inlineStyle?: any
  theme?: 'dark' | 'light'
  hover?: boolean
  adaptive?: boolean
}

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const ImgOverlayWrapper = (
  props: ImgOverlayWrapperProps
): React.ReactElement => {
  const classes = cs(style.imageOverlay, {
    [style[props.theme ?? 'light']]: true,
    [style.visibility]: props.hover,
    [style.ellipsis]: props.adaptive,
  })

  return (
    <div className={classes} style={props.inlineStyle}>
      {props.text && <span>{props.text}</span>}
      <Image {...props} />
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ImgOverlayWrapper
