import { request } from '../../api/actions'

export const LOAD_REQUEST = 'categories/load.REQUEST'
export const LOAD_SUCCESS = 'categories/load.SUCCESS'
export const LOAD_FAILURE = 'categories/load.FAILURE'

export default function loadCategories() {
  return request({
    url: '/api/categories',
    start: LOAD_REQUEST,
    success: LOAD_SUCCESS,
    failure: LOAD_FAILURE,
  })
}
