import get from 'lodash/get'

import { ActivityType } from './flowTypes'
import { HIDE_PROMO } from './actions/promo_hide'

const INITIALSTATE: ActivityType = {
  promo: undefined,
}

const reducer = (state = INITIALSTATE, action) => {
  if (action.type === HIDE_PROMO) {
    if (action.promo_type === get(state, ['promo', 'type'])) {
      state = {
        ...state,
        promo: undefined,
      }
    }
  }

  const activity = get(action, ['payload', 'activity'])
  if (activity) {
    return {
      ...state,
      ...activity,
    }
  }

  return state
}

export default reducer
