import { schema as NormalizrSchema } from 'normalizr'

import * as schemas from '../../entities/schemas'
import * as types from '../../entities/types'

import { request } from '../../api/actions'

export const LOAD_REQUEST = 'entities/connections_load.REQUEST'
export const LOAD_SUCCESS = 'entities/connections_load.SUCCESS'
export const LOAD_FAILURE = 'entities/connections_load.FAILURE'

export function loadConnections(of_user_id, page = 1, per_page = 1000) {
  const responseSchema = new NormalizrSchema.Entity(
    'connections_load',
    undefined,
    {
      idAttribute: () => of_user_id,
    }
  )
  responseSchema.define({
    results: [schemas.userSchema],
  })
  return request({
    url:
      '/api/profile/network/colleagues?page=' +
      encodeURIComponent(page) +
      '&per_page=' +
      encodeURIComponent(per_page),
    start: {
      type: LOAD_REQUEST,
      resource_type: 'user',
      resource_id: of_user_id,
    },
    success: {
      type: LOAD_SUCCESS,
      meta: { schema: responseSchema },
      resource_type: 'user',
      resource_id: of_user_id,
      page,
      per_page,
    },
    failure: LOAD_FAILURE,
  })
}
