import cs from 'classnames'
import keys from 'lodash/keys'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { selectAuthenticatedUser } from '../../modules/auth/selectors'
import { getKindLabel } from '../../modules/categories/roles'
import { selectSpecialtyMapper } from '../../modules/categories/selectors'
import { SpecialtyMapper } from '../../modules/categories/specialties'
import * as modal from '../../modules/modal/actions'
import { useDispatch, useSelector } from '../../react-redux'
import { FilterTagPickerModal } from '../../routes/components/tag-picker'
import { AuthenticatedUser } from '../../types/userTypes'
import { rawTextTrimmer } from '../../ui/formatters/text'

export const specialtyLabel = (
  categories: any,
  specialties: SpecialtyMapper,
  user: AuthenticatedUser,
  filters: any
): any => {
  const { t } = useTranslation('common')

  let label

  if (specialties && filters) {
    label = specialties.getLabelFor(
      specialties.findPrimaryFilterCategory(filters)
    )
    const grouped = specialties.groupFiltersBySpecialty(filters)
    const n = keys(grouped).length

    if (n > 1) {
      label = t('menu.specialty_plus_others', { count: n - 1, label: label })
    } else {
      label = specialties.getLabelFor(
        specialties.findPrimaryFilterCategory(filters)
      )
    }
  }

  if (!label) {
    if (user) {
      if (user.kind === 'other') label = ''
      else label = getKindLabel(user, categories)
    }

    if (!label) {
      label = ''
    }
  }

  if (!label) {
    label = ''
  }

  return rawTextTrimmer({ text: label, maxLetters: 39 })
}

const Specialties: React.FC = ({}): JSX.Element => {
  const user = useSelector(selectAuthenticatedUser)
  const filters = useSelector(state => state.filters)
  const categories = useSelector(state => state.categories)
  const specialties = useSelector(selectSpecialtyMapper)
  const screenSize = useSelector(state => state.browser.mediaType)
  const dispatch = useDispatch()
  const pickSpecialties = () => dispatch(modal.openModal(FilterTagPickerModal))

  if (screenSize === 'palm') {
    return (
      <SpecialtiesMobile
        user={user}
        filters={filters}
        categories={categories}
        specialties={specialties}
        pickSpecialties={pickSpecialties}
      />
    )
  }

  return (
    <SpecialtiesDesktop
      user={user}
      filters={filters}
      categories={categories}
      specialties={specialties}
      pickSpecialties={pickSpecialties}
    />
  )
}

type Props = {
  user: AuthenticatedUser
  filters: any
  categories: any
  specialties: SpecialtyMapper
  pickSpecialties: () => void
}

const SpecialtiesMobile: React.FC<Props> = ({
  user,
  filters,
  categories,
  specialties,
  pickSpecialties,
}): JSX.Element => (
  <div className="current-specialties">
    <div className="current-specialties__label">MedShr</div>
    <div className="current-specialties__manage">
      <span
        className={cs('fake-link', 'truncated-link')}
        onClick={pickSpecialties}
      >
        {specialtyLabel(categories, specialties, user, filters) || 'Filters'}
      </span>
    </div>
  </div>
)

const SpecialtiesDesktop: React.FC<Props> = ({
  user,
  filters,
  categories,
  specialties,
  pickSpecialties,
}): JSX.Element => (
  <div className="current-specialties">
    <div className="current-specialties__label">
      {specialtyLabel(categories, specialties, user, filters) || 'MedShr'}
    </div>
    <div className="current-specialties__manage">
      <span className="fake-link" onClick={pickSpecialties}>
        Add sub-specialties
      </span>
    </div>
  </div>
)

export default Specialties
