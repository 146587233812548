import { appWithTranslation } from 'next-i18next'
import App from 'next/app'
import Head from 'next/head'
import getConfig from '@/config'
import React from 'react'
import { createPortal } from 'react-dom'
import { Provider } from 'react-redux'
import Layout from '../components/Layout/Standard'

import { withApollo } from '../hoc/with-apollo'
import '../lib/math-polyfill'
import withReduxStore from '../lib/with-redux-store'
import { error as logError, event } from '../modules/analytics/track'
import { ScriptInclude } from '../components/ScriptInclude'
import { CookieConsent } from '../app/_components/CookieConsent'
import { isPrintingToPDF } from '../lib/client-info'
import { getCookie } from '../lib/use-cookies'

import '../style/index.scss'

const config = getConfig().publicRuntimeConfig

const BodyComponent = ({ Component, pageProps }) => {
  if (Component.FullPage) {
    return <Component {...pageProps} />
  }

  return (
    <Layout>
      <Head>
        <title>MedShr</title>
      </Head>
      <Component {...pageProps} />
      <PageRouterCookieConsent />,
    </Layout>
  )
}

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo)
    super.componentDidCatch(error, errorInfo)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (typeof sessionStorage !== 'undefined') {
      const storage = sessionStorage
      if (!storage) return
      const prevPath = storage.getItem('currentPath')
      storage.setItem('prevPath', prevPath)
      storage.setItem('currentPath', global.location.pathname)
    }
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props

    return (
      <Provider store={reduxStore}>
        <BodyComponent Component={Component} pageProps={pageProps} />
        {config.env === 'production' ? (
          <>
            <ScriptInclude
              url={'https://js.hs-scripts.com/1714081.js'}
              async
              defer
              includeGuests
              includeMembers
              includeStaff={false}
            />
          </>
        ) : null}
      </Provider>
    )
  }
}

export const PageRouterCookieConsent = () => {
  const [needsConsent, setNeedsConsent] = React.useState(false)
  React.useEffect(() => {
    if (!needsConsent && typeof document !== 'undefined') {
      setTimeout(() => setNeedsConsent(!getCookie('cookieconsent_status')), 250)
    }
  }, [needsConsent, getCookie])
  return needsConsent
    ? createPortal(<CookieConsent needsConsent={needsConsent} />, document.body)
    : null
}

export const reportWebVitals = ({ id, name, label, value }) => {
  event(`Next.js ${label} metric`, name, {
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  })
}

export default withReduxStore(
  withApollo({ ssr: true })(appWithTranslation(MyApp))
)
