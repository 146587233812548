import React from 'react'
// /////////////////////////////////////////////////////////////////// COMPONENT
import Img from './Img'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export type ImageType = {
  url: string
  alt?: string
  size: Sizes
  shape?: Shape
  className?: string
  width?: number | boolean
  height?: number | boolean
  adaptive?: boolean
  text?: string | React.ReactElement
  onLoad?: (e: any) => any
  onClick?: () => any
  onContextMenu?: Function
  inlineStyle?: any
  style?: React.CSSProperties
}

export type WrappedImageType = ImageType & {
  theme?: string
  hover?: boolean
}

export type Shape = 'square' | 'rounded' | 'circle' | 'original'
export type Sizes =
  | 'profile-small'
  | 'profile-thumb'
  | 'profile-thumb-alt'
  | 'profile-large'
  | 'profile-background'
  | 'profile-background-nofx'
  | 'post-image'
  | 'feed-image'
  | 'case-slide'
  | 'case-thumb'
  | 'case-large'
  | 'item'
  | 'small'
  | 'logo'
  | 'group-logo'
  | 'group-thumb'
  | 'group-thumb-alt'
  | 'thumb-large'
  | 'thumb-medium'
  | 'update-thumb'
  | 'promo-link'
  | 'preview-medium'
  | 'm-feed-image'
  | 'm-profile-thumb'
  | 'm-profile-large'
  | 'm-case-thumb'
  | 'm-case-large'
  | 'm-group-large'
  | 'm-invite-large'
  | 'm-invite-web'
  | 'original'
  | 'sidebar-resource-image'
  | 'sidebar-resource-image-small'

export const getSizeStringWithPixelDensity = (size: Sizes): string => {
  let density = 1

  if (typeof window !== 'undefined') {
    density =
      typeof window.devicePixelRatio === 'number' && -1 === size.indexOf('@')
        ? Math.ceil(window.devicePixelRatio)
        : 1
  }

  return density === 1
    ? size
    : size + '@' + Math.max(1, Math.min(3, density)) + 'x'
}

const Image = ({
  url,
  alt,
  size,
  className,
  height,
  width,
  adaptive,
  shape,
  ...rest
}: ImageType): React.ReactElement => {
  const sizeString = getSizeStringWithPixelDensity(size)
  const URL: string = url ? url.replace('{image_size}', sizeString) : ''
  const costumeClass: string = className ? className : ''
  const hasSize: string = size ? size.toString() : ''
  const classes = `${costumeClass.toString()} image--${hasSize}`

  return (
    <Img
      alt={alt}
      src={URL.toString()}
      inlineStyle={{ height, width }}
      width={typeof width === 'number' ? width : undefined}
      height={typeof height === 'number' ? height : undefined}
      className={classes}
      adaptive={adaptive}
      shape={shape}
      {...rest}
    />
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Image
