export const PROMO_WIDGET_AVATAR_UPLOAD_PENDING: string =
  'promoWidget/AVATAR_UPLOAD_PENDING'
export const PROMO_WIDGET_AVATAR_UPLOAD_COMPLETE: string =
  'promoWidget/AVATAR_UPLOAD_COMPLETE'
export const PROMO_WIDGET_AVATAR_UPLOAD_ERROR: string =
  'promoWidget/AVATAR_UPLOAD_ERROR'

export const WIDGET_EDIT_MODE_ON: string =
  'routes/components/promoWidget/content/WIDGET_EDIT_MODE_ON'

export function setAvatarUploadPending(): any {
  return {
    type: PROMO_WIDGET_AVATAR_UPLOAD_PENDING,
  }
}
export function setAvatarUploadComplete(): any {
  return {
    type: PROMO_WIDGET_AVATAR_UPLOAD_COMPLETE,
  }
}
export function setAvatarUploadError(error: Error): any {
  return { type: PROMO_WIDGET_AVATAR_UPLOAD_ERROR, error: error.toString() }
}

export function widgetEditModeSwitch(): any {
  return { type: WIDGET_EDIT_MODE_ON }
}
