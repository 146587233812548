import React from 'react'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../../utils/noop'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import LikeBtn from '../../LikeBtn/LikeBtn'
import XIcon from '../../icons/x'
import { Button } from '../../buttons'
import CssLoader from '../../../ui/CssLoader'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from '../styles/CommonModal.module.scss'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  children: React.ReactNode
  currentLike: CurrentLikeType
  isLoading: boolean
  isLoaded: boolean
  fetching: boolean
  closeModal: Function
  loadMore: Function
}

type CurrentLikeType = {
  total: number
  per_page: number
  pages: number
  page: number
}

const LikeModal = ({
  children,
  currentLike: { total, pages, page },
  closeModal,
  loadMore,
  isLoaded,
  fetching,
}: PropsType): React.ReactElement => {
  const btnProps = {
    wide: true,
    block: true,
    fill: true,
    square: true,
    className: style.btnSquare,
  }

  return (
    <div className={style.container}>
      <header className={style.header}>
        <LikeBtn
          count={total}
          noHover
          isLiked
          setHover={noop}
          showTooltip={false}
        />
        <XIcon color="#bbc2c9" onClick={() => closeModal()} />
      </header>

      <div className={style.body}>
        {children}

        {page < pages && isLoaded ? (
          <div className={style.footer}>
            <Button
              {...btnProps}
              text={!fetching ? 'See more' : ''}
              onClick={(): any => loadMore(page, pages)}
            >
              <CssLoader isLoading={fetching} theme="dark" />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default LikeModal
