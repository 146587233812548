import callAPI from '../../../modules/api/call'
import createRequestTypes, {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../../utils/createRequestType'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const GET_INVITES = createRequestTypes('GET_INVITES')
const getInvitations = {
  request: (payload: string, editMode?: boolean): any =>
    action(GET_INVITES[REQUEST], { payload, editMode }),
  success: (payload: any): any => action(GET_INVITES[SUCCESS], { payload }),
  failure: (error: Error): any => action(GET_INVITES[FAILURE], { error }),
}

// //////////////////////////////////////////////////////////////////// API CALL
function getInvites(caseId: string): Promise<any> {
  return callAPI({
    url: `/api/profile/cases/${caseId}/viewers`,
    method: 'GET',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: Error): any => err)
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { getInvites as default, getInvitations, GET_INVITES }
