import * as actions from './actions'
import { all, fork, take, put, call } from 'redux-saga/effects'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////// WORKER SAGA

function* fetchCaseFollowersWhoAreConnections(caseId) {
  try {
    const response = yield call(
      actions.getCaseFollowersWhoAreConnections,
      caseId,
      1
    )
    if (response) {
      yield put(actions.loadCaseFollowersWhoAreConnectionsSuccess(response))
    }
  } catch (error) {
    yield put(actions.loadCaseFollowersWhoAreConnectionsFailure(error))
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////// WATCH SAGA
function* watchFetchCaseFollowersWhoAreConnections() {
  while (true) {
    const { caseId }: any = yield take(actions.LOAD_REQUEST)
    yield fork(fetchCaseFollowersWhoAreConnections, caseId)
  }
}

export default function* rootSaga() {
  yield all([fork(watchFetchCaseFollowersWhoAreConnections)])
}
