import React from 'react'
import cs from 'classnames'

// /////////////////////////////////////////////////////////////// STYLE
import style from './CssLoader.module.scss'

interface CssLoaderProps {
  isLoading?: boolean
  className?: string
  theme?: string
}

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

const CssLoader = ({
  isLoading,
  className,
  theme = 'light',
}: CssLoaderProps) => {
  if (!isLoading) return null

  return (
    <div className={style.wrapper}>
      <span className={cs(style.loadingPulse, className, style[theme])} />
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default CssLoader
