import { useTranslation } from 'next-i18next'
import React, { Children } from 'react'
import cs from 'classnames'
import omit from 'lodash/omit'

import Button from '../buttons/button'
import { Tick, XCircle } from '../icons'
import Image from '../Img/image'
import style from './styles/base.module.scss'

// TODO: Cleanup all proptypes and replace with typescript types

type FormProps = {
  method?: string
  action: string
  className?: string
  children: React.ReactNode
}

export const Form = (props: FormProps) => {
  const defaultProps = {
    className: 'form',
    method: 'post',
  }
  const { className, method, ...rest } = { ...defaultProps, ...props }

  return (
    <form className={cs('form', className)} method={method} {...rest}>
      {props.children}
    </form>
  )
}

interface SectionProps {
  className?: string
  children?: React.ReactNode
}

// ///////////////////////////////////////////////////////////////////// SECTION
export const Section = ({ children, className }: SectionProps) => {
  return <div className={cs('form__section', className)}>{children}</div>
}

const lookupChildID = children => {
  let id

  Children.forEach(children, function (node) {
    if (node) {
      if (node.props.id) id = node.props.id

      let child_id = lookupChildID(node.children)

      if (child_id) id = child_id
    }
  })

  return id
}

interface FieldProps {
  id?: string
  icon?: any
  className?: string
  label?: any
  description?: string
  children: React.ReactNode
  disabled?: boolean
  inlineStyle?: any
  labelInline?: any
}

// /////////////////////////////////////////////////////////////////////// FIELD
export const Field = ({
  id,
  children,
  className,
  disabled,
  label,
  description,
  inlineStyle,
  labelInline,
  icon,
}: FieldProps) => {
  const isID = id ? id : lookupChildID(children)
  const classes = cs(
    'form__field',
    'field',
    `field--${isID}`,
    className ?? 'fieldset',
    {
      'field--disabled': disabled,
    }
  )

  return (
    <div className={classes} style={inlineStyle}>
      {label && (
        <label className="field__label" style={labelInline} htmlFor={isID}>
          {label} {icon && icon}
        </label>
      )}
      <div className="field__control">{children}</div>

      {description && <p className="field__description">{description}</p>}
    </div>
  )
}

interface FieldsetProps {
  label: string
  children: React.ReactNode
  className?: string
}

// /////////////////////////////////////////////////////////////////// FIELD SET
export const Fieldset = (props: FieldsetProps) => (
  <fieldset className={cs('form__fieldset', 'fieldset', props.className)}>
    <legend className="fieldset__label">{props.label}</legend>
    <div className="fieldset__controls">{props.children}</div>
  </fieldset>
)

interface InputProps {
  type: string
  id?: string
  placeholder?: string
  disabled?: boolean
  value?: any
  className?: string
  withWrapper?: boolean
  onChange?: any
  autoFocus?: boolean
  resetForm?: any
  inlineStyle?: any
  style?: any
  name?: string
}

// /////////////////////////////////////////////////////////////////////// INPUT
export const Input = (props: InputProps) => {
  const { className, withWrapper, resetForm, inlineStyle, value, ...p } = props
  const defaultClasses = cs(
    className,
    'form__input',
    'input',
    `input--${props.type}`,
    {
      [style.withIcon]: value.length > 0 && withWrapper,
    }
  )

  if (withWrapper) {
    return (
      <div className={style.inputWrapper}>
        <input
          value={value}
          style={inlineStyle}
          className={defaultClasses}
          {...filterReduxFormProps(p)}
        />
        {value.length > 0 && <XCircle onClick={resetForm} />}
      </div>
    )
  }

  return (
    <input
      value={value}
      className={defaultClasses}
      {...filterReduxFormProps(p)}
    />
  )
}

interface SelectProps {
  className?: string
  options?: any
  children?: React.ReactNode
  id?: string
  onChange?: Function
  value?: any
  disabled?: boolean
}

// ////////////////////////////////////////////////////////////////////// SELECT
export const Select = (props: SelectProps) => {
  const { className, options, children, ...p } = props
  const renderOptions = opt => {
    if (opt) {
      return options.map((label_value, i) => {
        return (
          <option value={label_value[1]} key={i}>
            {label_value[0]}
          </option>
        )
      })
    }

    return children
  }

  return (
    <select
      className={cs(className, 'form__select', 'select')}
      {...filterReduxFormProps(p)}
    >
      {renderOptions(options)}
    </select>
  )
}

// ///////////////////////////////////////////////////////////////// CHECK LABEL
type CheckableProps = {
  input: any
  id: string
  label: string
  type: string
  title: string
  mobileSupport?: boolean
  checked: boolean
  disabled: boolean
  align: string
  flex?: boolean
  onChange: Function
  value: string
  className: string
  children: React.ReactNode
  labelStyle: any
  imgUrl: string
}
type CheckableState = {
  align: string
  type: string
  checked: boolean
}

class Checkable extends React.Component<CheckableProps, CheckableState> {
  static defaultProps = {
    mobileSupport: false,
    flex: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      align: 'left',
      type: 'checkbox',
      checked: props.checked,
    }
  }

  componentDidUpdate(prevProps: CheckableProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  onInputChange = e => {
    this.setState({ checked: e.target.checked })

    if (this.props.onChange) {
      this.props.onChange(e.target.checked, this.props.value)
    }
  }

  onIconClick = () => {
    let checked

    if (!this.state) {
      checked = !this.props.checked
    } else {
      checked = !this.state.checked
    }

    this.setState({ checked: checked })

    if (this.props.onChange) {
      this.props.onChange(checked, this.props.value)
    }
  }

  renderIcon = (useProps: boolean = false) => {
    const checked = useProps ? this.props.input.value : this.state.checked

    if (this.props.type === 'radio') {
      return (
        <Button
          dark={checked}
          onClick={this.onIconClick}
          className="btn--custom-checkbox custom-radio__icon"
        />
      )
    }

    const classes = cs('btn--custom-checkbox custom-checkbox__icon', {
      'checkbox__icon_opa-50': this.props.disabled,
    })
    const onClick = () => this.props.onChange(!this.props.input.value)

    return (
      <Button
        dark={checked}
        fill={checked}
        disabled={this.props.disabled}
        onClick={useProps ? onClick : this.onIconClick}
        className={classes}
        inlineStyle={{ padding: '0 0 0 3px' }}
      >
        {checked ? <Tick /> : null}
      </Button>
    )
  }

  renderAvatar(url): any {
    if (url) {
      return (
        <span style={{ position: 'relative', marginLeft: -40, marginRight: 4 }}>
          <Image url={url} size={`profile-small`} shape={'circle'} />
        </span>
      )
    }

    return null
  }

  render(): React.ReactElement {
    const {
      id,
      value,
      type,
      label,
      align,
      className,
      disabled,
      imgUrl,
      title,
    } = this.props

    // this one is using redux form state
    if (this.props.flex) {
      const { input, ...rest } = this.props
      const checkState = input.value ? 'checked' : ''

      return (
        <div className={'flex-checkbox-container'}>
          <input
            type={type}
            id={id}
            disabled={disabled}
            {...input}
            onChange={this.onInputChange}
          />
          {label && (
            <label
              htmlFor={id}
              className={`flex-checkbox-label ${align}`}
              title={title}
            >
              <div
                className={`flex-checkbox-label-text ${align} ${checkState}`}
              >
                {label}
              </div>
              {this.renderIcon(true)}
            </label>
          )}
        </div>
      )
    }

    const container = cs(className, `custom-${type}`, {
      [`custom-${type}--disabled`]: disabled,
      [`custom-${type}--right`]: align === 'right',
      [`custom-${type}--left`]: align === 'left',
    })
    const labelClass = cs(`custom-${type}__label`, {
      [style.checkboxLabel]: this.props.mobileSupport,
    })
    const innerClass = cs(`custom-${type}__label-inner`, {
      [style.checkboxLabelInner]: this.props.mobileSupport,
    })

    return (
      <span className={container}>
        <input
          type={type}
          id={id}
          value={value}
          checked={this.state.checked}
          disabled={disabled}
          onChange={this.onInputChange}
        />
        {this.renderIcon()}

        {label && (
          <label htmlFor={id} className={labelClass} title={title}>
            <span className={innerClass}>
              <span style={this.props.labelStyle}>
                {this.renderAvatar(imgUrl)} {label}
              </span>
              {this.props.children}
            </span>
          </label>
        )}
      </span>
    )
  }
}

// //////////////////////////////////////////////////////////////////// HTML FORM CHECKBOX (not controlled with react state)

type CheckboxHtmlFormPropTypes = {
  name: string
  value: string | number
  id: string
  label: JSX.Element | string
  defaultChecked?: boolean
  className?: string
  checkboxClassName?: string
  onChange?: (e: any) => void
}

export const CheckboxHtmlForm = (props: CheckboxHtmlFormPropTypes) => (
  <div className={cs('form__checkbox-html-form', props.className)}>
    <input
      type="checkbox"
      name={props.name}
      value={props.value}
      className={cs('form__checkbox-html-form-input', props.checkboxClassName)}
      id={props.id}
      defaultChecked={props.defaultChecked}
      onChange={props.onChange}
    />{' '}
    <label htmlFor={props.id}>
      <span style={{ marginLeft: 10, display: 'block' }}>{props.label}</span>
    </label>
  </div>
)

// //////////////////////////////////////////////////////////////////// CIRCULAR CHECKBOX
export const Checkbox = props => <Checkable {...props} type="checkbox" />

// /////////////////////////////////////////////////////////////////////// RADIO
export const Radio = props => <Checkable {...props} type="radio" />

interface Props {
  touched: boolean
  error: string
}

export const InlineErrorMessage = ({ touched, error }: Props) => {
  const { t } = useTranslation('common')

  return touched && error ? (
    <p className="form__error">
      {typeof error === 'string' ? t(error) : error}
    </p>
  ) : null
}

export function filterReduxFormProps(props) {
  return omit(props, [
    'asyncValidating',
    'dispatch',
    'initial',
    'submitting',
    'submitFailed',
    'initialValue',
    'autofill',
    'onUpdate',
    'valid',
    'invalid',
    'dirty',
    'pristine',
    'error',
    'active',
    'touched',
    'visited',
    'autofilled',
  ])
}
