import update from 'react-addons-update'
import { normalize } from 'normalizr'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import assign from 'lodash/assign'
import keyBy from 'lodash/keyBy'
import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import concat from 'lodash/concat'
import get from 'lodash/get'
import set from 'lodash/set'
import reduce from 'lodash/reduce'
import { find } from 'ramda'
// ///////////////////////////////////////////////////////////////////// HELPERS
import { setDeep } from '../../lib/fp'
// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as types from './types'
import * as likeTypes from './actions/likeComment'
import { GET_INVITES } from './actions/case_invites'
import * as dislikeTypes from './actions/dislikeComments'
import * as connect from './actions/connect'
import * as comment from './actions/comment'
import * as feed_load from './actions/feed_load'
import * as follow from './actions/follow'
import * as case_delete from './actions/case_delete'
import * as case_save from './actions/case_save'
import * as case_visibility_update from './actions/case_visibility_update'
import * as case_files_transcoded from './actions/case_files_transcoded'
import * as connections_load from './actions/connections_load'
import * as network_search from './actions/network_search'
import * as group_membership from './actions/group_membership'
import * as group_admin_join_request from './actions/group_admin_join_request'
import * as group_invites_load from './actions/group_invites_load'
import * as group_delete from './actions/group_delete'
import * as entity_viewed from './actions/entity_viewed'
import * as hide_update from './actions/hideUpdate'
import * as poll_vote from './actions/poll_vote'
import { GC } from '../entities/actions/entity_gc'
import * as search from '../search/actions'
// /////////////////////////////////////////////////////////////////// SELECTORS
import {
  selectAuthenticatedUser,
  selectAuthenticatedUserID,
} from '../auth/selectors'
import {
  selectUser,
  searchUserInstitutions,
  selectFeedState,
  selectEntry,
} from '../entities/selectors'
import { getUpdateEntrySchema } from './schemas'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// ////////////////////////////////////////////////////////////// MERGE ENTITIES
function mergeEntities(
  existing_entities,
  normalized_entities,
  feed_replace = false,
  schemaKeys = []
) {
  let entities = existing_entities
  let merged_entities = cloneDeep(entities)
  for (let entity_type in normalized_entities) {
    if (entity_type === 'feed') {
      merged_entities[entity_type] = mergeWith(
        cloneDeep(entities[entity_type]),
        normalized_entities[entity_type],
        (objValue, srcValue, key) => {
          if (key === 'entries') {
            if (feed_replace || !objValue) {
              return srcValue
            }
            return objValue.concat(srcValue)
          }
        }
      )
    } else if ((schemaKeys || []).indexOf(entity_type) > -1) {
      merged_entities[entity_type] = {
        ...entities[entity_type],
        ...normalized_entities[entity_type],
      }
    } else {
      merged_entities[entity_type] = mergeWith(
        cloneDeep(entities[entity_type]),
        normalized_entities[entity_type],
        (objValue, srcValue) => {
          // if a list is returned, replace existing list in store with
          // new one instead of merging.
          if (isArray(srcValue) || isArray(objValue)) {
            return srcValue
          }
        }
      )
    }
    entities = merged_entities
  }
  return entities
}

const clearFeeds = state =>
  update(state, {
    feed: { $set: {} },
    entry_user: { $set: {} },
    entry_case: { $set: {} },
  })

const clearFeed = (state, feed_type, optional_id?) => {
  const type =
    optional_id === undefined ? feed_type : feed_type + '_' + optional_id
  return get(state, ['feed', type])
    ? update(state || {}, { feed: { [type]: { $set: undefined } } })
    : state
}

const clearEntityType = (state, type) =>
  update(state, { [type]: { $set: undefined } })
const clearEntity = (state, type, id) =>
  update(state, { [type]: { [id]: { $set: undefined } } })

// ///////////////////////////////////////////////////////////// ACTION HANDLERS
const actionHandlers = {
  [GC]: state => {
    state = clearFeeds(state)
    state = clearEntityType(state, 'entry_update')
    state = clearEntityType(state, 'case')
    state = clearEntityType(state, 'entry_case')
    state = clearEntityType(state, 'entry_group')
    return state
  },

  [case_save.SAVE_SUCCESS]: state => clearFeeds(state),

  [feed_load.LOAD_REQUEST]: (state, action) =>
    action.clearExisting && action.feed_type
      ? clearFeed(state, action.feed_type)
      : state,

  [feed_load.REMOVE_FEED]: (state, action) =>
    action.feed_type ? clearFeed(state, action.feed_type) : state,

  [comment.COMMENT_SUCCESS]: (state, action) =>
    mapEntry(state, action.resource.type, action.resource.id, entry => {
      return update(entry, {
        comment_count: { $set: action.payload.total },
        comments: {
          $set: mergeComments(entry.comments, action.payload.results),
        },
      })
    }),

  [follow.UPDATE_SUCCESS]: (state, action) =>
    mapEntry(state, action.resource.type, action.resource.id, entry => {
      return update(entry, {
        interested_users: {
          $set: updateInterestedUser(
            entry.interested_users ?? [],
            action.resource.currentUserId,
            action.resource.method
          ),
        },
        follows_target: { $set: action.payload.following },
        follower_count: {
          $set: optimisticFollowerCountUpdate(
            entry.follower_count,
            action.payload.following
          ),
        },
      })
    }),

  [poll_vote.VOTE_SUCCESS]: (state, action) =>
    action.payload.case_id && typeof action.payload.follows_case === 'boolean'
      ? mapEntry(state, 'case', action.payload.case_id, entry => {
          return update(entry, {
            follows_target: { $set: action.payload.follows_case },
            follower_count: {
              $set: optimisticFollowerCountUpdate(
                entry.follower_count,
                action.payload.follows_case
              ),
            },
          })
        })
      : state,

  [poll_vote.VOTE_FAILURE_SET_ALREADY_VOTED]: (state, action) => {
    return {
      ...state,
      poll: {
        ...state.poll,
        [action.payload.response.id]: {
          ...state.poll[action.payload.response.id],
          already_voted: true,
        },
      },
    }
  },

  [hide_update.UPDATE_SUCCESS]: (state, action) =>
    mapEntity(state, types.ENTRY_UPDATE, action.update_id, update => {
      return { ...update, visible: action.visible }
    }),

  [case_delete.DELETE_SUCCESS]: (state, action) => {
    return clearFeeds(
      clearEntity(
        mapEntry(state, action.resource.type, action.resource.id, () => {
          return undefined
        }),
        action.resource.type,
        action.resource.id
      )
    )
  },

  [group_delete.DELETE_SUCCESS]: (state, action) => {
    return clearFeeds(
      clearEntity(
        mapEntry(state, action.resource.type, action.resource.id, () => {
          return undefined
        }),
        action.resource.type,
        action.resource.id
      )
    )
  },

  [group_admin_join_request.UPDATE_FAILURE]: (state, action) => {
    if (action.error && action.error.status == 409) {
      const resource = action.resource
      const jr = state.group_admin_join_request
        ? { ...state.group_admin_join_request }
        : {}
      return {
        ...state,
        group_admin_join_request: set(
          jr,
          [`${resource.type}_${resource.id}_${resource.user_id}`],
          resource.request_type == 'ignore' ? 'accept' : 'ignore'
        ),
      }
    }
    return state
  },

  [group_admin_join_request.UPDATE_SUCCESS]: (state, action) => {
    const resource = action.resource
    const jr = state.group_admin_join_request
      ? { ...state.group_admin_join_request }
      : {}
    return {
      ...state,
      group_admin_join_request: set(
        jr,
        [`${resource.type}_${resource.id}_${resource.user_id}`],
        resource.request_type
      ),
    }
  },

  [case_visibility_update.UPDATE_REQUEST]: (state, action) => {
    return mapEntity(state, action.resource.type, action.resource.id, entry => {
      return update(entry, {
        previousVisibility: { $set: entry.visibility },
        visibility: { $set: action.visibility },
      })
    })
  },

  ['entities/PATCH']: (state, action) => {
    return mapEntity(state, action.resource.type, action.resource.id, entry => {
      return update(entry, action.patch)
    })
  },

  [case_visibility_update.UPDATE_FAILURE]: (state, action) => {
    return mapEntity(state, action.resource.type, action.resource.id, entry => {
      return update(entry, {
        previousVisibility: {
          $set: entry.previousVisibility
            ? entry.previousVisibility
            : entry.visibility,
        },
      })
    })
  },

  [entity_viewed.ENTITY_VIEWED]: (state, action) =>
    mapEntry(
      state,
      action.payload.resource_type,
      action.payload.resource_id,
      entry => {
        return update(entry, {
          new_comment_count: { $set: 0 },
        })
      }
    ),

  [connections_load.LOAD_SUCCESS]: (state, action) => {
    return mapEntity(state, action.resource_type, action.resource_id, entry => {
      if (action.payload.page == 1) {
        return update(entry, {
          connections: { $set: Object.keys(action.normalized.entities.user) },
        })
      } else {
        const connections = []
          .concat(entry.connections)
          .concat(Object.keys(action.normalized.entities.user))
        return update(entry, {
          connections: { $set: connections },
        })
      }
      return entry
    })
  },
}

// ///////////////////////////////////////////////////////////////// HELPER FUNC
export function optimisticFollowerCountUpdate(previewCount, followingState) {
  let result

  if (followingState) result = previewCount + 1
  else result = previewCount - 1

  return result
}

export type InterestedUserUpdateType = '' | 'add' | 'remove'

export function updateInterestedUser(
  entry: Array<string>,
  userId: string,
  action: InterestedUserUpdateType
) {
  let result

  if (!userId) {
    result = entry
  } else if (action === 'add') {
    result = [...entry, userId]
  } else if (action === 'remove') {
    result = entry.filter(i => i !== userId)
  } else {
    result = entry
  }

  return result
}

// ////////////////////////////////////////////////// REDUCE NORMALIZED ENTITIES
export function reduceNormalizedEntities(state = {}, action) {
  const handler = actionHandlers[action.type]
  if (handler) {
    state = handler(state, action)
  }

  if (action.normalized) {
    if (action.normalized.response && action.normalized.response.entities) {
      state = mergeEntities(
        state || {},
        action.normalized.response.entities,
        action.payload.response.page == 1,
        action.schemaKeys
      )
    } else if (action.normalized.entities) {
      state = mergeEntities(
        state || {},
        action.normalized.entities,
        action.payload.page == 1,
        action.schemaKeys
      )
    } else {
      let entities = assign({}, state || {})
      for (let key in action.normalized) {
        entities = assign(
          entities,
          mergeEntities(
            entities,
            action.normalized[key].entities,
            false,
            action.schemaKeys
          )
        )
      }
      state = assign({}, state, entities)
    }
  }

  return state
}

function patchCaseFiles(state, action) {
  const values = get(state, 'form.case_edit.values')
  if (values) {
    const id = values['id']
    if (id == action.case_id) {
      const files = action.files.map(file => {
        const local = find(x => x.id == file.id, values['files'])
        if (local) {
          return {
            ...file,
            title: local.title,
            sharable_externally: local.sharable_externally,
          }
        }
        return file
      })
      return update(state, {
        form: { case_edit: { values: { files: { $set: files } } } },
      })
    }
  }
  return state
}

// ///////////////////////////////////////////////////// GLOBAL ENTITIES REDUCER
// this reducer receives the entire state object, not just the entities subset.
export function globalEntitiesReducer(state: any = {}, action: any) {
  let key
  switch (action.type) {
    case connect.UPDATE_SUCCESS:
      if (action.payload.update || action.resource.type === 'user') {
        state = replaceUpdate(
          state,
          {
            verb: 'connection_invite',
            'resource.target.id': action.resource.id,
            'resource.target.type': action.resource.type,
          },
          action.payload.update
        )

        state = replaceUpdate(
          state,
          {
            verb: 'group_invite',
            'resource.target.id': action.resource.id,
            'resource.target.type': action.resource.type,
          },
          action.payload.update
        )

        state = replaceUpdate(
          state,
          {
            verb: 'group_invite_admin',
            'resource.target.id': action.resource.id,
            'resource.target.type': action.resource.type,
          },
          action.payload.update
        )
      }
      if (action.resource.type === 'user') {
        key = 'connections'
        state = {
          ...state,
          entities: {
            ...state.entities,
            user: {
              ...state.entities.user,
              [action.resource.id]: {
                ...state.entities.user[action.resource.id],
                connection_status: action.payload.connection_status,
              },
            },
          },
        }
      } else if (
        action.resource.type === 'group' ||
        action.resource.type === 'institution'
      ) {
        key = `${action.resource.type}s`
        state = replaceEntityConnectionStatus(
          state,
          action.resource,
          action.payload.connection_status
        )
        state =
          action.resource.type === 'institution'
            ? {
                ...state,
                entities: clearFeed(
                  clearFeed(
                    state.entities,
                    types.FEED_USER_GROUPS,
                    selectAuthenticatedUserID(state)
                  ),
                  types.FEED_USER_SUGGESTED_GROUPS
                ),
              }
            : state
      }
      if (key) {
        if (action.payload.connection_status === 'sent') {
          return moveConnectionStatus(
            state,
            action.resource,
            key,
            `${key}_requested`
          )
        } else if (action.payload.connection_status === 'connected') {
          return moveConnectionStatus(
            state,
            action.resource,
            `${key}_requested`,
            key
          )
        } else if (action.payload.connection_status === '') {
          return moveConnectionStatus(
            moveConnectionStatus(state, action.resource, key, `${key}_ignored`),
            action.resource,
            `${key}_requested`,
            `${key}_ignored`
          )
        }
      }
      return state

    case group_admin_join_request.UPDATE_ALL_SUCCESS:
      state = clearFeeds(state)
      return state

    case group_membership.SEARCH_SUCCESS_COLLEAGUES:
    case group_membership.SEARCH_SUCCESS_MEMBERS:
      return {
        ...state,
        entities: {
          ...state.entities,
          group_membership_by_group_id: {
            ...state.entities.group_membership,
            [`${action.resourceType}_${action.resourceID}`]: {
              // must merge existing results for group, as simultaneous search of members/colleagues uses this same dict
              ...(state.entities.group_membership &&
                state.entities.group_membership[
                  `${action.resourceType}_${action.resourceID}`
                ]),
              ...Object.assign(
                {},
                ...action.payload.results.map((v, _k) => ({
                  [v.id]: v.is_member ? true : false,
                }))
              ),
            },
          },
        },
      }

    case network_search.SEARCH_SUCCESS:
      state = update(state, {
        entities: {
          network_search: {
            $merge: {
              [action.userID]: {
                results: concat(
                  -1 < action.areas.indexOf(network_search.AREA_INSTITUTIONS)
                    ? toEntityRefs(
                        searchUserInstitutions(
                          state,
                          selectUser(state, action.userID),
                          action.searchTerm
                        )
                      )
                    : [],
                  [],
                  get(
                    state,
                    ['entities', 'network_search', action.userID, 'results'],
                    []
                  )
                ),
              },
            },
          },
        },
      })
      break

    case search.UPDATE_FILTER:
    case search.UPDATE_TERM:
      return {
        ...state,
        entities: clearFeed(state.entities, 'search_results'),
      }

    // //////////////////////////////////////////////////////////// UPDATE CASES
    case likeTypes.UPDATE_LIKE_SUCCESS:
    case likeTypes.OPTIMISTIC_UPDATE_LIKE:
    case dislikeTypes.UPDATE_DISLIKE_SUCCESS:
    case comment.UPDATE_COMMENT_REPLY:
      return {
        ...state,
        entities: { ...state.entities, entry_case: action.newState },
      }

    case likeTypes.BLOCK_LIKE_REQUEST:
      return { ...state, widget: { ...state.widget, fetching: true } }

    case likeTypes.UNBLOCK_LIKE_REQUEST:
      return { ...state, widget: { ...state.widget, fetching: false } }

    case GET_INVITES.SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, case: { ...action.payload } },
      }

    case group_invites_load.LOAD_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          group_invites: {
            ...state.entities.group_invites,
            [`${action.resourceType}_${action.resourceID}`]: keyBy(
              action.payload.results,
              invite => invite.user_id
            ),
          },
        },
      }

    case case_files_transcoded.PATCH:
      return patchCaseFiles(state, action)
  }

  return state
}

function mapEntry(state, resource_type, resource_id, callback) {
  const entry_type = types.getEntryTypeForEntityType(resource_type)

  if (entry_type && state[entry_type]) {
    const entry = state[entry_type][resource_id]

    if (entry) {
      const updated_entry = callback(entry)

      return update(state, {
        [entry_type]: { [resource_id]: { $set: updated_entry } },
      })
    }
  }
  return state
}

function mapEntity(state, resource_type, resource_id, callback) {
  if (resource_type && state[resource_type]) {
    const entry = state[resource_type][resource_id]
    if (entry) {
      const updated_entry = callback(entry)
      return update(state, {
        [resource_type]: { [resource_id]: { $set: updated_entry } },
      })
    }
  }

  return state
}

function mergeComments(current_comments, new_comments) {
  if (!current_comments || !current_comments.length) {
    return new_comments
  }
  if (!new_comments || !new_comments.length) {
    return current_comments
  }
  const last = current_comments[current_comments.length - 1]
  let append = filter(
    new_comments,
    comment => parseInt(comment.id, 10) > parseInt(last.id, 10)
  )
  append.reverse()

  return concat(current_comments, append)
}

function toEntityRefs(entities) {
  return map(entities, entity => ({ schema: entity.type, id: entity.id }))
}

function moveConnectionStatus(state, target, from_list, to_list) {
  let currentUser = selectAuthenticatedUser(state)
  if (!currentUser[to_list]) {
    currentUser = assign({}, currentUser, { [to_list]: [] })
  }
  currentUser = update(currentUser, {
    [to_list]: { $push: [target.id] },
    [from_list]: {
      $set: filter(currentUser[from_list], id => id != target.id),
    },
  })
  return update(state, {
    entities: { user: { [currentUser.id]: { $set: currentUser } } },
  })
}

function replaceEntityConnectionStatus(state, resource, connection_status) {
  const { type, id } = resource
  const entry_type = types.getEntryTypeForEntityType(type)

  return setDeep(
    ['entities', entry_type, id, 'connection_status'],
    connection_status,
    state
  )
}

function replaceUpdate(state, withProperties, newUpdate = undefined) {
  const normalized = newUpdate
    ? normalize(newUpdate, getUpdateEntrySchema())
    : undefined

  return reduce(
    [
      types.FEED_MAIN_UPDATES,
      types.FEED_CONNECTION_REQUESTS,
      types.FEED_USER_CONNECTION_REQUESTS,
      types.FEED_GROUP_CONNECTION_REQUESTS,
      types.FEED_GROUPS_ACTIVITY,
    ].concat(
      withProperties['verb'] === 'group_invite_admin' &&
        withProperties['resource.target.type'] === 'group'
        ? [types.FEED_GROUP_ACTIVITY]
        : []
    ),
    (state, feed_type) => {
      const feed = selectFeedState(
        state,
        feed_type,
        withProperties['verb'] === 'group_invite_admin' &&
          withProperties['resource.target.type'] === 'group'
          ? withProperties['resource.target.id']
          : undefined
      )

      // TODO: finish replacement of group_invite_admin

      if (isEmpty(feed)) {
        return state
      }

      const replaceEntryIDs = feed.feed
        ? reduce(
            feed.feed.entries,
            (a, entry) => {
              if (
                reduce(
                  withProperties,
                  (a, v, k) => a && get(entry, k.split('.')) == v,
                  true
                )
              ) {
                return concat(a, [entry.id])
              }
              return a
            },
            []
          )
        : []

      if (normalized) {
        state = {
          ...state,
          entities: mergeEntities(state.entities, normalized.entities),
        }
      }

      const path = ['entities', 'feed', feed_type, 'entries']

      return setDeep(
        path,
        pickBy(
          mapValues(get(state, path, []), id => {
            return -1 < replaceEntryIDs.indexOf(id)
              ? normalized
                ? normalized.result
                : undefined
              : id
          })
        ),
        state
      )
    },
    state
  )
}
