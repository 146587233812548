import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  className?: string
  width?: number
  onClick?: Function
}

const SearchIcon = ({
  id,
  width,
  className,
  onClick,
}: Props): React.ReactElement => {
  return (
    <Icon
      id={id || 'icon-search'}
      sourceWidth={20}
      sourceHeight={20}
      viewBox="-2 -2 20 20"
      width={width || 17}
      title="Search"
      onClick={onClick}
      className={className}
    >
      <path
        d="M10.5955138,10.7416792 L16.2520675,16.398834"
        strokeLinecap="round"
      />
      <path d="M12.4137931,6.35275085 C12.4137931,9.78118409 9.63496552,12.560307 6.20689655,12.560307 C2.77882759,12.560307 0,9.78118409 0,6.35275085 C0,2.92431761 2.77882759,0.145194737 6.20689655,0.145194737 C9.63496552,0.145194737 12.4137931,2.92431761 12.4137931,6.35275085 L12.4137931,6.35275085 Z" />
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default SearchIcon
