import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const UPDATE_REQUEST = 'entities/follow.REQUEST'
export const UPDATE_SUCCESS = 'entities/follow.SUCCESS'
export const UPDATE_FAILURE = 'entities/follow.FAILURE'

export type FollowUpdateType = 'add' | 'remove'

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function update(
  resource: any,
  enable: boolean,
  currentUserId?: string,
  method?: FollowUpdateType
) {
  return request({
    url: `/api/profile/follows/out/${resource.type}/${resource.id}`,
    method: 'POST',
    json: { following: enable },
    start: UPDATE_REQUEST,
    success: {
      type: UPDATE_SUCCESS,
      enable,
      resource: { type: resource.type, id: resource.id, currentUserId, method },
    },
    failure: UPDATE_FAILURE,
  })
}
