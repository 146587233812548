// import { delay } from 'redux-saga';
import { put, fork, take, all, call } from 'redux-saga/effects'

// ///////////////////////////////////////////////// SELECTORS & ACTIONS
import * as types from './actions'

// ///////////////////////////////////////////////////////////////// API
import { TeamResponse, getTeamByName } from './api'

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////// SAGAS

function* fetchTeamList(teamName: string): Generator {
  try {
    const x: any = yield call(getTeamByName, teamName)

    if (x.success) {
      yield put(types.getTeamListSuccess(teamName, x.response))
    }
  } catch (err) {
    yield put(types.getTeamListFailed(err))
  }
}

// /////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////// FUNTIONS
export function cleanUrl(str: string): any {
  const cleanStr = str.split('/')

  if (cleanStr.length === 2) {
    return 'medshr'
  }

  return cleanStr[2]
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////// WATCH SAGA

function* watchTestNewsletter(): Generator {
  while (true) {
    const { teamName }: any = yield take(types.GET_MEMBER_LIST_REQUEST)
    yield fork(fetchTeamList, teamName)
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([fork(watchTestNewsletter)])
}
