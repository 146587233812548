import * as types from './actions'
import { VerificationType } from './flowTypes'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const INITIAL_STATE: VerificationType = {
  isLoading: false,
  isLoaded: false,
  isTrusted: false,
  isTouched: false,
  initialized: false,
  isIputUpdated: 'initial',
  initialEmail: '',
  isDocumentLoading: false,
  isDocumentLoaded: false,
  error: '',
  emailSaving: false,
  isTrustLoaded: false,
  phoneInfo: {},
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function Reducer(
  state: VerificationType = INITIAL_STATE,
  { type, payload, trusted, email, error }: any
): VerificationType {
  switch (type) {
    // reset the loaded state for verification modal button state
    case types.INIT_ISLOADED:
      return {
        ...state,
        isLoaded: false,
        isTouched: false,
        isDocumentLoaded: false,
      }

    // ///////////////////////////////////////////////// CHECK EMAIL BEFORE POST
    // /////////////////////////////////////////////////// POST EMAIL TO CONFIRM
    case types.POST_CONFIRMATION_EMAIL:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isTrusted: false,
        isTouched: true,
      }
    case types.POST_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        initialized: true,
        isTrusted: payload,
        isIputUpdated: false,
      }
    case types.POST_CONFIRMATION_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error,
        initialized: false,
        isTrusted: false,
      }

    // /////////////////////////////////////////////////////////// POST DOCUMENT
    case types.POST_DOCUMENT:
      return { ...state, isDocumentLoading: true }
    case types.POST_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDocumentLoading: false,
        isDocumentLoaded: true,
      }
    case types.POST_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        isDocumentLoaded: false,
        error,
      }

    // ////////////////////////////////////////////// GET INITIAL EMAIL TO STORE
    case types.SAVE_INITIAL_EMAIL:
      return { ...state, initialEmail: payload }
    case types.RESET_INITIAL_EMAIL:
      return { ...state, initialEmail: '' }
    case types.UPDATE_INITIAL_EMAIL:
      return { ...state, initialEmail: email }

    case types.DOCUMENT_LOAD_STATE:
      return { ...state, isDocumentLoaded: payload }

    case types.EMAIL_SAVING:
      return { ...state, emailSaving: true }
    case types.EMAIL_SAVED:
      return { ...state, emailSaving: false }

    // /////////////////////////////////////////////////////// CHECK EMAIL TRUST
    case types.CHECK_TRUSTED_EMAIL_REQUEST:
      return { ...state, isTrustLoaded: false, isTrusted: false }
    case types.CHECK_TRUSTED_EMAIL_SUCCESS:
      return { ...state, isTrustLoaded: true, isTrusted: trusted }
    case types.CHECK_TRUSTED_EMAIL_FAILURE:
      return { ...state, isTrustLoaded: false, isTrusted: false }

    // ////////////////////////////////////////////////////////////// SAVE PHONE
    case types.SAVE_PHONE.SUCCESS:
      return { ...state, phoneInfo: { ...payload } }
    case types.SAVE_PHONE.FAILURE:
      return { ...state }

    // ///////////////////////////////////////////////////////////// CHECK PHONE
    case types.VERIFY_CODE.REQUEST:
      return { ...state, error: '' }
    case types.VERIFY_CODE.SUCCESS:
      return { ...state, error: '' }
    case types.VERIFY_CODE.FAILURE:
      return { ...state, error }

    default:
      return state
  }
}
