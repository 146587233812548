// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////// ACTIONS
export const LOAD_REPLIES_REQUEST = 'replyComment/LOAD_REPLIES_REQUEST'
export const LOAD_REPLIES_SUCCESS = 'replyComment/LOAD_REPLIES_SUCCESS'
export const LOAD_REPLIES_FAILURE = 'replyComment/LOAD_REPLIES_FAILURE'

export const RESET_REPLIES_STATE = 'replyComment/RESET_REPLIES_STATE'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////// ACTION CREATORS
export function loadRepliesRequest({ caseId, commentId }: any): any {
  return { type: LOAD_REPLIES_REQUEST, caseId, commentId }
}
export function loadRepliesSuccess(payload: any): any {
  return { type: LOAD_REPLIES_SUCCESS, payload }
}
export function loadRepliesFailed(error: any, commentId: string): any {
  return { type: LOAD_REPLIES_FAILURE, error, commentId }
}

// /////////////////////////////////////////////////////////////// RESET
export function resetRepliesState(): any {
  return { type: RESET_REPLIES_STATE }
}
