import { encode, ParsedUrlQuery } from 'querystring'

export function redirect(to: string, query?: ParsedUrlQuery): any
export function redirect(to: string, as: string, query?: ParsedUrlQuery): any
export function redirect(
  to: { to: string; as: string },
  query?: ParsedUrlQuery
): any

export function redirect(
  to: string | { to: string; as: string },
  as?: string | ParsedUrlQuery,
  query?: ParsedUrlQuery
): any {
  if (typeof as === 'object') {
    query = as
    as = to
  }
  if (typeof to === 'object') {
    if (query) {
      to = { as: to.as + '?' + encode(query), to: to.to }
    }
    return { ...to, type: 'ROUTER_PUSH' }
  }
  if (query) {
    as += '?' + encode(query)
  }
  return {
    type: 'ROUTER_PUSH',
    to,
    as,
  }
}

export function redirectAs(to: string, query?: ParsedUrlQuery): any
export function redirectAs(to: string, as: string, query?: ParsedUrlQuery): any
export function redirectAs(
  to: { to: string; as: string },
  query?: ParsedUrlQuery
): any

export function redirectAs(
  to: string | { to: string; as: string },
  as?: string | ParsedUrlQuery,
  query?: ParsedUrlQuery
): any {
  if (typeof as === 'object') {
    query = as
    as = to
  }
  if (typeof to === 'object') {
    if (query) {
      to = { as: to.as + '?' + encode(query), to: to.to }
    }
    return { ...to, type: 'ROUTER_PUSH' }
  }
  if (query) {
    as += '?' + encode(query)
  }
  return {
    type: 'ROUTER_PUSH',
    to,
    as,
  }
}

export const goBack = () => {
  return {
    type: 'ROUTER_GO_BACK',
  }
}

export const block = (message: string) => {
  return {
    type: 'ROUTER_BLOCK',
    message,
  }
}

export const unblock = () => {
  return {
    type: 'ROUTER_UNBLOCK',
  }
}

export const reload = () => {
  return {
    type: 'ROUTER_RELOAD',
  }
}
