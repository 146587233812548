import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/InfoBulletList.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  children: React.ReactNode
  darkTheme: boolean
}

const InfoBulletList = ({ children, darkTheme }: Props): React.ReactElement => {
  const classes = cs(style.container, {
    [style.dark]: darkTheme,
  })

  return <ul className={classes}>{children}</ul>
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default InfoBulletList
