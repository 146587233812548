import cs from 'classnames'
import get from 'lodash/get'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import * as userState from '../../modules/auth/selectors'
import { useSelector } from '../../react-redux'
import MenuIcon from '../../ui/icons/menu'
import { TextLink } from '../../ui/link'
import Badge from '../../ui/menu-link-badge'
import ProfileImageLink from '../../ui/Profile/profile-image-link'
import { link_for_resource, useParameter } from '../../url'
import { HoverMenu } from './HoverMenu'

// /////////////////////////////////////////////////////////// USER MENU
const UserMenu: React.FC<{ minimal?: boolean }> = ({ minimal }) => {
  const router = useRouter()
  const idParameter = useParameter('id')
  const { t } = useTranslation('common')

  const currentUser = useSelector(userState.selectAuthenticatedUser)
  const counters = useSelector(state => get(state, 'activity.counters'))
  const features = useSelector(state => get(state, 'features'))
  const isGroupMember = useSelector(
    state =>
      router.pathname.startsWith('/groups/') &&
      userState.checkGroupMembership(
        state,
        { id: idParameter },
        x => x.can_post_to_feed !== false
      )
  )
  const canCreatePolls = useSelector(
    state => !!get(state, 'activity.flags.create_polls')
  )

  const [forceShowHoverMenu, setForceShowHoverMenu] = useState(false)

  const handleMouseEnter = () => {
    setForceShowHoverMenu(true)
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      setForceShowHoverMenu(false)
    }, 150)
  }

  const non_medical = currentUser.seniority === 'advisory_board_mod'
  const cases_badge = counters ? counters.user_cases_with_comments : 0
  const network_badge = counters ? counters.pending_group_invite_count : 0
  const connection_badge =
    counters && !non_medical ? counters.pending_invite_count : 0

  const path = document ? document.location.pathname : router.pathname

  const groupsMatch = /^\/?(institutions|groups)(\b|\/)/.test(path)

  const groupsClasses = cs('badgeable user-menu__link user-menu__link--text', {
    active: !!groupsMatch,
  })

  const createPostSuffix = isGroupMember
    ? `?group_id=${encodeURIComponent(idParameter)}`
    : ''

  return (
    <div className="user-menu not-palm">
      <ul className="list-inline">
        {minimal ? null : (
          <>
            {!currentUser.has_group_restrictions ||
            currentUser.groups.length > 0 ? (
              <li>
                <TextLink
                  to={cases_badge > 0 ? '/cases/discussion' : '/cases'}
                  className="badgeable user-menu__link user-menu__link--text"
                  activeClassName="active"
                >
                  {t('menu.cases_short')}
                  <Badge value={cases_badge} />
                </TextLink>
              </li>
            ) : (
              <li>
                <TextLink
                  to={'/news'}
                  className="user-menu__link user-menu__link--text"
                  activeClassName="active"
                >
                  {t('menu.blog_short')}
                </TextLink>
              </li>
            )}

            {features.learning && !currentUser.has_dab ? (
              <li>
                <TextLink
                  to={'/learning/r'}
                  className="user-menu__link user-menu__link--text"
                  activeClassName="active"
                >
                  {t('menu.learning')}
                </TextLink>
              </li>
            ) : null}

            {!currentUser.has_group_restrictions ||
            currentUser.groups.length > 0 ? (
              <li>
                <TextLink
                  to={link_for_resource('user', currentUser.id, 'groups')}
                  className={groupsClasses}
                  activeClassName="active"
                >
                  {t('menu.groups_short')}
                  <Badge value={network_badge} />
                </TextLink>
              </li>
            ) : null}

            {currentUser.can_create_case ? (
              <>
                <li>
                  <TextLink
                    to={`/cases/create${createPostSuffix}`}
                    className="user-menu__link user-menu__link--text"
                    activeClassName="active"
                  >
                    {t('menu.create_case')}
                  </TextLink>
                </li>
                {canCreatePolls ? (
                  <li>
                    <TextLink
                      to={`/cases/create-poll${createPostSuffix}`}
                      className="user-menu__link user-menu__link--text"
                      activeClassName="active"
                    >
                      {t('menu.create_poll')}
                    </TextLink>
                  </li>
                ) : null}
              </>
            ) : null}
          </>
        )}
        <li className="badgeable">
          <ProfileImageLink
            className="user-menu__link user-menu__link--image"
            user={currentUser}
            shape={'circle'}
            size="profile-small"
            width={30}
          />
          <Badge value={connection_badge} />
        </li>
        <li
          className="user-menu__item user-menu__item--icon"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuIcon />
          <HoverMenu
            showWhenNotHovered={forceShowHoverMenu}
            currentUser={currentUser}
            features={features}
          />
        </li>
      </ul>
    </div>
  )
}

export default UserMenu
