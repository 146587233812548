import React from 'react'

import Icon from './icon'
import { IconWithDefsType } from './icon'

interface TwitterProps {
  id?: string
  title?: string
  className?: string
  onClick?(): void
  width?: number
}

const Twitter = (props: TwitterProps): React.ReactElement => {
  const defaultProps = {
    title: 'Twitter',
    id: 'icon-twitter',
  }

  return (
    <Icon {...{ ...defaultProps, ...props }} sourceWidth={30} sourceHeight={24}>
      <g strokeWidth="1">
        <path d="M29.1851852,2.81481481 C28.3580206,4.02469741 27.3580306,5.0555513 26.1851852,5.90740741 C26.1975309,6.08024778 26.2037037,6.33950444 26.2037037,6.68518519 C26.2037037,8.29013148 25.9691381,9.89196731 25.5,11.4907407 C25.0308619,13.0895142 24.317906,14.6234494 23.3611111,16.0925926 C22.4043162,17.5617357 21.2654387,18.8611055 19.9444444,19.9907407 C18.6234502,21.120376 17.0308735,22.0216016 15.1666667,22.6944444 C13.3024598,23.3672873 11.3086526,23.7037037 9.18518519,23.7037037 C5.83948944,23.7037037 2.77779167,22.8086509 0,21.0185185 C0.432100926,21.0679015 0.913577593,21.0925926 1.44444444,21.0925926 C4.22223611,21.0925926 6.69752,20.2407493 8.87037037,18.537037 C7.57406759,18.5123456 6.41358537,18.1142014 5.38888889,17.3425926 C4.36419241,16.5709838 3.66049574,15.5864257 3.27777778,14.3888889 C3.68518722,14.4506176 4.06172667,14.4814815 4.40740741,14.4814815 C4.93827426,14.4814815 5.46296037,14.4135809 5.98148148,14.2777778 C4.59875852,13.9938257 3.45370824,13.305561 2.5462963,12.212963 C1.63888435,11.1203649 1.18518519,9.85185907 1.18518519,8.40740741 L1.18518519,8.33333333 C2.02469556,8.80247148 2.92592111,9.05555537 3.88888889,9.09259259 C3.07407,8.54938 2.42592833,7.83951056 1.94444444,6.96296296 C1.46296056,6.08641537 1.22222222,5.13580759 1.22222222,4.11111111 C1.22222222,3.02468593 1.49382444,2.01852315 2.03703704,1.09259259 C3.53087167,2.93210796 5.34875472,4.40431546 7.49074074,5.50925926 C9.63272676,6.61420306 11.9259137,7.22839444 14.3703704,7.35185185 C14.2716044,6.8827137 14.2222222,6.42592815 14.2222222,5.98148148 C14.2222222,4.32715222 14.8055497,2.9166725 15.9722222,1.75 C17.1388947,0.5833275 18.5493744,0 20.2037037,0 C21.9321074,0 23.388883,0.629623333 24.5740741,1.88888889 C25.9197598,1.62962833 27.1851793,1.14815167 28.3703704,0.444444444 C27.913578,1.86420463 27.0370435,2.96295907 25.7407407,3.74074074 C26.8888946,3.61728333 28.0370313,3.30864444 29.1851852,2.81481481 L29.1851852,2.81481481 Z" />
      </g>
    </Icon>
  )
}

export default Twitter
