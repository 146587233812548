import React from 'react'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/ModalPrompt.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  sideKick?: React.ReactNode
  children: React.ReactNode
}

const ModalPromptText = ({ sideKick, children }: Props): React.ReactElement => (
  <div className={style.modalPrompt}>
    {children}
    {sideKick && <span className={style.sideKick}>{sideKick}</span>}
  </div>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default ModalPromptText
