import { path } from 'ramda'
import getConfig from '@/config'
import * as console from '../../lib/console'
import { whenAvailable } from '../../lib/when-available'

const config = getConfig().publicRuntimeConfig

export const RECONNECT = 'realtime/reconnect'

let sockjs, isConnected
let queue = []

let wantsReload = false

export const reloadIfNecessary = () => {
  if (wantsReload) {
    const location: Location = path(['document', 'location'], global)
    if (location) {
      location.reload()
    }
  }
}

const handler = dispatch => {
  return action => {
    console.log('action', action)
    if (action.type === 'server/upgrade') {
      wantsReload = true
      if (action.immediate) {
        reloadIfNecessary()
      }
    } else {
      dispatch(action)
    }
  }
}

let started = false

export const initialize = (dispatch, callback = undefined): void => {
  if (started) {
    return
  }

  started = true

  if (typeof window === 'undefined' || !config.realtime) {
    return
  }

  whenAvailable(
    () => Boolean(path(['SockJS'], global)),
    () => {
      const SockJS: any = path(['SockJS'], global)
      if (SockJS && global['document'] && !global['document']['documentMode']) {
        // window.document.documentMode is in ie8-11 only
        const handleMessage = handler(dispatch)
        const sockjs_url = '/rt'
        sockjs = new SockJS(sockjs_url, undefined, {
          transports: [
            'websocket',
            'xhr-streaming',
            'eventsource',
            'iframe-eventsource',
            'xhr-polling',
            'iframe-xhr-polling',
          ],
        })

        isConnected = false

        sockjs.onopen = function () {
          console.log('[*] open', sockjs.protocol)
          isConnected = true
          for (let message of queue) {
            sockjs.send(message)
          }
          queue = []
          if (callback) {
            callback()
          }
        }
        sockjs.onmessage = function (e) {
          console.log('[.] message', e.data)
          handleMessage(JSON.parse(e.data))
        }
        sockjs.onclose = function () {
          console.log('[*] close')
          isConnected = false
          started = false
          setTimeout(
            () => initialize(dispatch, () => dispatch({ type: RECONNECT })),
            2000 + Math.random() * 8000
          )
        }

        if (process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT === 'local') {
          const win: any = global
          win.sockjs = sockjs
        }
      }
    }
  )
}

export const setUserID = user_id => {
  if (sockjs) {
    const message = JSON.stringify({ type: 'auth/user_id', user_id: user_id })
    if (isConnected) {
      sockjs.send(message)
    } else {
      queue.push(message)
    }
  }
}
