import {
  SimpleEffect,
  Effect,
  put,
  fork,
  take,
  all,
  call,
  select,
} from 'redux-saga/effects'
import { last } from 'ramda'
import get from 'lodash/get'
import update from 'lodash/update'

import {
  loadRepliesSuccess,
  loadRepliesFailed,
  LOAD_REPLIES_REQUEST,
} from './actions/LoadReplies'
import {
  ADD_REPLY_REQUEST,
  addReplyFailed,
  addReplySuccess,
} from './actions/AddReply'
import { updateDisLikeSuccess } from '../entities/actions/dislikeComments'
import { updateCommentReply } from '../entities/actions/comment'

import { getReplies, postReply, RepliesResponse } from './api'

import { updateLikeState } from '../entities/sagas'

// ///////////////////////////////////////////////////////// FETCH LIKES
function* fetchReplies(caseId: string, commentId: string): Generator {
  try {
    const { page, pages, per_page, results, users }: any = yield call(
      getReplies,
      caseId,
      commentId
    )

    if (results) {
      yield put(
        loadRepliesSuccess({
          commentId,
          comments: results,
          users,
          page,
          pages,
          per_page,
        })
      )
    }
  } catch (err) {
    yield put(loadRepliesFailed(err, commentId))
  }
}

// ////////////////////////////////////////////////////////// POST REPLY
function* addReply({ caseId, commentId, text, is_dislike }: any): Generator {
  try {
    const repliesList = yield select(
      (state: any): any => state.comments.replies.list[commentId]
    )
    const updated_since = get(last(repliesList), 'created_at')

    const { total, parent, results, users, error }: any = yield call(
      postReply,
      {
        caseId,
        commentId,
        text,
        is_dislike,
        updated_since: updated_since || new Date(),
      }
    )

    if (error) {
      yield put(addReplyFailed(error))
    } else {
      let state = yield select((state: any): any => state.entities.entry_case)
      const commentIndex = state[caseId].comments.findIndex(
        (i: any): any => i.id === commentId
      )

      if (is_dislike) {
        // ////////////////////////////////////////////// UPDATE DISLIKE
        yield put(
          updateDisLikeSuccess(
            updateLikeState(state, parent, { caseId, commentId })
          )
        )
      }

      // ////////////////////////////////////////////////// UPDATE REPLY
      yield put(addReplySuccess({ commentId, parent, results, users }))

      // //////////////////////////////////////// UPDATE REPLY ON ENTITY
      update(
        state,
        `${caseId}.comments.${commentIndex}.reply_count`,
        (): any => total
      )
      update(
        state,
        `${caseId}.comments.${commentIndex}.has_user_replied`,
        (): any => parent.has_user_replied
      )
      yield put(updateCommentReply(state))
    }
  } catch (err) {
    yield put(addReplyFailed(err))
  }
}

// /////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////// WATCHERS
function* watchFetchReplies(): Generator {
  while (true) {
    const { caseId, commentId }: any = yield take(LOAD_REPLIES_REQUEST)
    yield fork(fetchReplies, caseId, commentId)
  }
}

function* watchAddReply(): Generator {
  while (true) {
    const { caseId, commentId, text, is_dislike }: any = yield take(
      ADD_REPLY_REQUEST
    )
    yield fork(addReply, { caseId, commentId, text, is_dislike })
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([fork(watchFetchReplies), fork(watchAddReply)])
}
