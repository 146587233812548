import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import style from './styles/IconButton.module.scss'

type IconButtonType = {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<Element>) => void
  border?: boolean
  type?: string
  shape?: string
  theme?: 'dark' | 'light'
}

const defaultProps = {
  onClick: () => {},
  border: true,
  shape: 'circle',
  type: 'socialButtons',
  theme: 'dark',
}

const IconButton = (props: IconButtonType): React.ReactElement => {
  const { children, onClick, shape, type, theme } = {
    ...defaultProps,
    ...props,
  }
  const classes = cs(style.btn, [style[shape]], [style[type]], {
    [style[theme]]: true,
  })

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

export default IconButton
