import React from 'react'

import { IconWithDefs } from './icon'

interface Props {
  id?: string
  title?: string
  width?: number
  className?: string
  onClick?: () => void
}

const Fullscreen = ({
  id = 'icon-fullscreen',
  title = 'View fullscreen',
  width,
  className,
  onClick,
}: Props): React.ReactElement => {
  return (
    <IconWithDefs
      sourceWidth={30}
      sourceHeight={30}
      viewBox="-2 -2 34 34"
      id={id}
      title={title}
      width={width}
      className={className}
      onClick={onClick}
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="30" height="30" rx="42.8571429" />
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="30"
          height="30"
        >
          <use xlinkHref="#path-1" />
        </mask>
      </defs>
      <g
        className="icon__content"
        strokeWidth="1.71428571"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17.3734957,12.4566846 L22.0679377,7.78475813" />
        <path d="M8.57142857,21.216535 L13.2658706,16.5446086" />
        <polyline points="11.5054897 21.216535 8.5714053 21.216535 8.5714053 18.2965231" />
        <polyline points="22.0678912 10.7048858 22.0678912 7.78487392 19.1338067 7.78487392" />
        <path d="M17.3734957,16.5446318 L22.0679377,21.2165582" />
        <path d="M8.57142857,7.78480444 L13.2658706,12.4567309" />
        <polyline points="8.57142857 10.7048858 8.57142857 7.78487392 11.505513 7.78487392" />
        <polyline points="19.13383 21.216535 22.0679144 21.216535 22.0679144 18.2965231" />
        <use
          className="icon__border"
          mask="url(#mask-2)"
          strokeWidth="4"
          xlinkHref="#path-1"
        />
      </g>
    </IconWithDefs>
  )
}

export default Fullscreen
