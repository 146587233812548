import cs from 'classnames'
import get from 'lodash/get'
import React from 'react'
import ReactTooltip from 'react-tooltip'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './LikeTooltipList.module.scss'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  count: number
  likeList: Array<any>
  userMap: any
  commentId: string
  isMobile: boolean
  openLikesModal: Function
  showTooltip: boolean
  noHover: boolean
  fetching: boolean
}

function LikeTooltipList({
  count,
  likeList,
  userMap,
  commentId,
  isMobile,
  openLikesModal,
  showTooltip,
  noHover,
  fetching,
}: Props): React.ReactElement {
  const tooltipProps = {
    id: String(commentId),
    className: 'tooltip',
    place: 'top' as const,
    type: 'dark' as const,
    effect: 'solid' as const,
  }
  const list: Array<any> = convertObjToArray(likeList)

  if (!isMobile && showTooltip && !fetching && list.length > 0) {
    return (
      <span style={{ position: 'relative' }} key={'desktop-span'}>
        <span
          onClick={() => openLikesModal(isMobile)}
          data-tip="userlist"
          data-for={commentId}
          className={cs(style.count, { [style.noHover]: noHover })}
        >
          {count}
        </span>

        {!isMobile && showTooltip && !fetching ? (
          <ReactTooltip {...tooltipProps}>
            <ul className={style.likeList}>
              {renderLikeList(list, userMap)}
              {count > list.length ? (
                <li key={'last-one'}>and {count - list.length} more...</li>
              ) : null}
            </ul>
          </ReactTooltip>
        ) : null}
      </span>
    )
  }

  noHover = noHover || list.length === 0

  return (
    <span
      key={'mobile-span'}
      onClick={list.length > 0 ? () => openLikesModal(isMobile) : undefined}
      data-tip="userlist"
      data-for={commentId}
      className={cs(style.count, { [style.noHover]: noHover })}
    >
      {count}
    </span>
  )
}

// ///////////////////////////////////////////////////////////////// RENDER LIST
function renderLikeList(likeList: Array<any>, userMap: any) {
  if (likeList.length && userMap) {
    return likeList.map((item: any) => {
      return (
        <li key={`${item.user_id}-${item.id}`}>
          {get(userMap, `${item.user_id}.name`)}
        </li>
      )
    })
  }

  return null
}

function convertObjToArray(value: Array<any> | Object): Array<any> {
  if (Array.isArray(value)) {
    return value
  } else if (typeof value === 'object') {
    return [value]
  }

  return []
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { LikeTooltipList as default, renderLikeList, convertObjToArray }
