import { request } from '../../api/actions'
import { reload } from '../../routing/actions'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const UPDATE_REQUEST = 'entities/connect.REQUEST'
export const UPDATE_SUCCESS = 'entities/connect.SUCCESS'
export const UPDATE_FAILURE = 'entities/connect.FAILURE'

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default function update(resource_type, resource_id, connect) {
  const url = `/api/profile/connections/${resource_type}/${resource_id}`

  return request({
    url: url,
    id: `${url}[${connect}]`,
    method: 'POST',
    json: { connect: connect },
    start: UPDATE_REQUEST,
    success:
      resource_type === 'group'
        ? reload()
        : {
            type: UPDATE_SUCCESS,
            enable: connect,
            resource: { type: resource_type, id: resource_id },
          },
    failure: UPDATE_FAILURE,
  })
}
