import * as schemas from '../../entities/schemas'

export const ENTITY_UPDATE = 'entities/ENTITY_UPDATE'

export function updateUser(user_data) {
  return {
    type: ENTITY_UPDATE,
    payload: user_data,
    meta: { schema: schemas.userSchema },
  }
}
