import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const Menu = ({ id }: { id?: string }): React.ReactElement => {
  return (
    <Icon
      id={id || 'icon-menu'}
      sourceWidth={24}
      sourceHeight={20}
      title="Menu"
    >
      <rect x="0" y="0" width="24" height="2" />
      <rect x="0" y="6" width="24" height="2" />
      <rect x="0" y="12" width="24" height="2" />
      <rect x="0" y="18" width="24" height="2" />
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Menu
