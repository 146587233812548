import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'

import Spinner from '../../ui/icons/spinner'

const ActivityIndicator = ({ apiActivityType }) => {
  const { t } = useTranslation('common')

  const [count, setCount] = useState(0)
  const onScroll = (): any => {
    setCount(count + 1)
  }

  useEffect(() => {
    global['window'] && global['window'].addEventListener('scroll', onScroll)

    return () => {
      global['window'] &&
        global['window'].removeEventListener('scroll', onScroll)
    }
  }, [])

  const activityMessages = {
    load: 'ui.activity_indicator',
    save: 'ui.activity_indicator_save',
    delete: 'ui.activity_indicator_delete',
  }

  return apiActivityType ? (
    <div
      className="global-activity-indicator page__container fs-unmask"
      style={{ bottom: 100 }}
    >
      <div className="global-activity-indicator__content">
        {t(activityMessages[apiActivityType] || 'ui.activity_indicator')}
        <Spinner theme="dark" width={14} />
      </div>
    </div>
  ) : null
}

export default ActivityIndicator
