import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import CloseIcon from './icons/x'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/Modals.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////// MODAL
type ModalType = {
  children?: React.ReactNode
  fullpage?: boolean
  className?: string
  styles?: any
  id: string
}

const Modal = ({
  children,
  className,
  fullpage,
  id,
  styles,
}: ModalType): any => {
  const classes = {
    container: cs(className, 'modal-container', `modal-container--${id}`, {
      'modal-container--fullpage': fullpage,
    }),
    child: cs('modal-container__content', {
      page__container: !fullpage,
    }),
  }

  return (
    <div className={classes.container} style={styles}>
      <div className={classes.child}>{children}</div>
    </div>
  )
}

// ///////////////////////////////////////////////////////////////// MODAL CLOSE
type ModalCloseType = {
  onClose: Function
  className?: string
}

const ModalClose = ({ className, onClose }: ModalCloseType): any => (
  <CloseIcon
    className={cs('modal-container__close', className)}
    onClick={() => onClose()}
  />
)

// //////////////////////////////////////////////////////////////// MODAL HEADER
type ModalHeaderType = {
  children?: React.ReactNode
  title?: string
  className?: string
  bolder?: boolean
  onClose?: Function
}

const ModalHeader = ({
  onClose,
  className,
  title,
  bolder,
  children,
}: ModalHeaderType): any => {
  const styles = {
    header: cs('mega', { [style.bolderHeader]: bolder }),
  }
  return (
    <header className={cs('modal-container__header', 'fs-unmask', className)}>
      <div className="page__container">
        {title ? <h1 className={styles.header}>{title}</h1> : null}
        {onClose ? <ModalClose onClose={onClose} /> : null}
        {children}
      </div>
    </header>
  )
}

// ////////////////////////////////////////////////////////////////// MODAL BODY
type ModalBodyType = {
  children: React.ReactNode
  className?: string
  dialog?: boolean
  listing?: boolean
  centered?: boolean
  inlineStyle?: any
}

const ModalBody = ({
  dialog,
  listing,
  centered,
  className,
  children,
  inlineStyle,
}: ModalBodyType): any => {
  const classes = cs('modal', className, {
    'modal--dialog': dialog,
    'modal--listing': listing,
    'modal--centered': dialog || listing || centered,
  })

  return (
    <div className={classes}>
      {/* TEMP WEB-1276: needs height:100%; overflow: auto; */}
      <div className={cs({ dialog: dialog })} style={inlineStyle}>
        {children}
      </div>
    </div>
  )
}

// //////////////////////////////////////////////////////////////// MODAL FOOTER
type ModalFooterType = {
  children: React.ReactNode
  inlineStyle?: any
}

const ModalFooter = ({ inlineStyle, children }: ModalFooterType): any => (
  <footer
    className="modal-container__footer dark-background"
    style={inlineStyle}
  >
    <div className="page__container">{children}</div>
  </footer>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { ModalFooter, ModalBody, ModalHeader, ModalClose, Modal }
