export interface Category {
  children: Array<Category>
  id?: string
  label?: string
  level?: number
}

export function getCategoryFromTree(
  category_id: string,
  category_tree
): Category {
  let category: Category = { children: category_tree }
  const keys = category_id.split('.')
  for (let key of keys) {
    if (category.children && category.children.hasOwnProperty(key)) {
      category = category.children[key]
    } else {
      return undefined
    }
  }
  return category
}

export function getCategoryFromList(category_id, category_list) {
  return _getCategoryFromList(category_id.split('.'), category_list)
}

function _getCategoryFromList(ids, category_list) {
  const key = ids[0]
  for (let category of category_list) {
    if (key == category.id) {
      if (ids.length > 1) {
        return getCategoryFromList(
          ids.slice(1, ids.length - 1),
          category.children || []
        )
      }
      return category
    }
  }
  return undefined
}
