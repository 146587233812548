import { request } from '../../api/actions'
import * as schemas from '../../entities/schemas'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const UPDATE_REQUEST = 'entities/case_visibility_update.REQUEST'
const UPDATE_SUCCESS = 'entities/case_visibility_update.SUCCESS'
const UPDATE_FAILURE = 'entities/case_visibility_update.FAILURE'

function update(target, value) {
  const caseSchema = schemas.caseSchema
  const url = '/api/profile/cases/' + target.id + '/visibility'

  return request({
    url: url,
    id: url,
    method: 'PUT',
    json: { visibility: value },
    resource: target,
    visibility: value,
    start: UPDATE_REQUEST,
    success: {
      type: UPDATE_SUCCESS,
    },
    failure: UPDATE_FAILURE,
  })
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export { update as default, UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE }
