import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { Checkbox } from '../../../ui/forms/base'
// /////////////////////////////////////////////////////////////////////// UTILS
import safeId from '../../../utils/safeId'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  category: any
  onToggleCategory: Function
  tristate?: boolean
  selected?: boolean
}

const TagPickerRow = ({
  category,
  onToggleCategory,
  tristate,
  selected,
}: Props): any => {
  let checked = category.enabled
  let disabled = false

  if (tristate) {
    if (selected) {
      checked = true
    } else if (checked) {
      disabled = true
    }
  }

  return (
    <li className="tag-picker__subspecialty">
      <Checkbox
        align="right"
        id={safeId(category.id)}
        label={category.label}
        value={category.id}
        checked={checked}
        disabled={disabled}
        onChange={checked => onToggleCategory(category, checked)}
      >
        {(() => {
          /*confusing now that we can (de)select parent specialties:
          if (category.is_specialty && category.children.length > 1) {
            return (
              <span className="simple-results__row-subtitle">{ category.children.length } sub-specialties</span>
            )
          }
          else*/
          if (disabled) {
            return (
              <span className="simple-results__row-subtitle">
                Already added
              </span>
            )
          }
        })()}
      </Checkbox>
    </li>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TagPickerRow
