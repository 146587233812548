// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export default function userKindSelector(type: string): string {
  let result

  switch (type) {
    case 'doctor':
      result = 'userKind.doctor'
      break

    case 'dentist':
      result = 'userKind.dentist'
      break

    case 'nurse':
      result = 'userKind.nurse'
      break

    case 'student_clinical':
    case 'student_pre_clinical':
    case 'student':
      result = 'userKind.student'
      break

    case 'student_dental':
      result = 'userKind.student_dental'
      break

    // Default is health-care professional
    default:
      result = 'userKind.HCP'
  }

  return result
}
