import { appendQuery } from '../../../url'
import * as schemas from '../../entities/schemas'

import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const LOAD_REQUEST = 'entities/feed_load.REQUEST'
export const LOAD_SUCCESS = 'entities/feed_load.SUCCESS'
export const LOAD_FAILURE = 'entities/feed_load.FAILURE'
export const REMOVE_FEED = 'entities/feed_load.REMOVE_FEED'

export function loadFeedByURL(url, page, per_page = 36, searchTerm?: string) {
  return request({
    url: appendQuery(url, { page, per_page, q: searchTerm }),
    id: url,
    page: page,
    start: LOAD_REQUEST,
    success: {
      type: LOAD_SUCCESS,
      feed_type: url,
      meta: {
        schema: {
          response: schemas.getFeedSchemaByURL(url),
        },
      },
    },
    failure: LOAD_FAILURE,
  })
}

// ///////////////////////////////////////////////////////////////////// LOAD FEED
export function loadFeed(feed_type, page, per_page = 36, searchTerm?: string) {
  const url = schemas.Feeds[feed_type].url
  return request({
    url: appendQuery(url, { page, per_page, q: searchTerm }),
    id: url,
    page: page,
    start: LOAD_REQUEST,
    success: {
      type: LOAD_SUCCESS,
      feed_type: feed_type,
      meta: {
        schema: {
          response: schemas.getFeedSchema(feed_type),
        },
      },
    },
    failure: LOAD_FAILURE,
  })
}

// ///////////////////////////////////////////////////////////////////// LOAD OWNED FEED
export function loadOwnedFeed(
  feed_type,
  owner_id,
  page,
  per_page = 36,
  searchTerm
) {
  const url = schemas.Feeds[feed_type].url.replace('{id}', owner_id)

  return request({
    url: appendQuery(url, { page, per_page, q: searchTerm }),
    id: url,
    page: page,
    start: LOAD_REQUEST,
    success: loadOwnedFeedSuccess(feed_type, owner_id),
    failure: LOAD_FAILURE,
  })
}

// ///////////////////////////////////////////////////////////////////// LOAD OWNED FEED SUCCESS
export const loadOwnedFeedSuccess = (feed_type, owner_id) => ({
  type: LOAD_SUCCESS,
  feed_type: feed_type + '_' + owner_id,
  meta: {
    schema: {
      response: schemas.getFeedSchema(feed_type, owner_id),
    },
  },
})

// ///////////////////////////////////////////////////////////////////// REMOVE FEED FROM STORE
export const uncacheFeed = (feed_type, owner_id) => ({
  type: REMOVE_FEED,
  feed_type: feed_type + (owner_id ? '_' + owner_id : ''),
  meta: {
    schema: {
      response: schemas.getFeedSchema(feed_type, owner_id),
    },
  },
})
