import { request } from '../../api/actions'
import * as schemas from '../../entities/schemas'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const DELETE_REQUEST = 'entities/group_delete.REQUEST'
export const DELETE_SUCCESS = 'entities/group_delete.SUCCESS'
export const DELETE_FAILURE = 'entities/group_delete.FAILURE'

export function deleteGroup({ type, id }) {
  const url = `/api/${type}s/${id}`

  return request({
    url: url,
    id: `DELETE ${url}`,
    method: 'DELETE',
    start: DELETE_REQUEST,
    success: {
      type: DELETE_SUCCESS,
      redirect: payload => {
        return `/${type}s`
      },
      resource: { type, id },
    },
    failure: DELETE_FAILURE,
  })
}
