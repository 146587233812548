import * as actions from './actions'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return action.flags

    default:
      return state
  }
}

export default reducer
