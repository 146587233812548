import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const UPDATE_REQUEST = 'entities/hide_update.REQUEST'
export const UPDATE_SUCCESS = 'entities/hide_update.SUCCESS'
export const UPDATE_FAILURE = 'entities/hide_update.FAILURE'

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default function update(card, visible: boolean = false) {
  const url = `/api/profile/feeds/updates/visibility/${encodeURIComponent(
    card.original_id
  )}`
  visible = !!visible

  return request({
    url: url,
    id: `${url}[${visible}]`,
    method: 'POST',
    json: { visible },
    start: UPDATE_REQUEST,
    success: {
      type: UPDATE_SUCCESS,
      visible,
      update_id: card.id,
    },
    failure: UPDATE_FAILURE,
  })
}
