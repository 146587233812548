import cIcon from './icon'
export const Icon = cIcon
import cIconmoon from './Iconmoon'
export const Iconmoon = cIconmoon

import cArrowIcons from './ArrowIcons'
export const ArrowIcons = cArrowIcons

import cAwardBadge from './award-badge'
export const AwardBadge = cAwardBadge
import cBlockedIcon from './blocked'
export const BlockedIcon = cBlockedIcon

import cConnectIcons from './ConnectIcons'
export const ConnectIcons = cConnectIcons

import cEditorBadge from './medshr-editor-badge'
export const EditorBadge = cEditorBadge
import cEmail from './email'
export const Email = cEmail
import cFacebook from './fb'
export const Facebook = cFacebook
import cFullscreen from './fullscreen'
export const Fullscreen = cFullscreen
import cGroup from './group'
export const Group = cGroup
import cLinkedIn from './linkedin'
export const LinkedIn = cLinkedIn
import cMenuIcon from './menu'
export const MenuIcon = cMenuIcon
import cMoreIcon from './more'
export const MoreIcon = cMoreIcon
import cPauseIcon from './pause-large'
export const PauseIcon = cPauseIcon
import cPdfIcon from './pdf'
export const PdfIcon = cPdfIcon
import cPencil from './pencil'
export const Pencil = cPencil
import cPlayIcon from './play-large'
export const PlayIcon = cPlayIcon
import cPlusIcon from './plus'
export const PlusIcon = cPlusIcon
import cPlusCircle from './plus-circle'
export const PlusCircle = cPlusCircle
import cSearchIcon from './search'
export const SearchIcon = cSearchIcon
import cSpinnerIcon from './spinner'
export const SpinnerIcon = cSpinnerIcon
import cStarIcon from './star'
export const StarIcon = cStarIcon
import cStarEmptyIcon from './star-empty'
export const StarEmptyIcon = cStarEmptyIcon
import cStarHalfIcon from './star-half'
export const StarHalfIcon = cStarHalfIcon
import cStethoscope from './stethoscope'
export const Stethoscope = cStethoscope
import cTick from './tick'
export const Tick = cTick
import cTwitter from './twitter'
export const Twitter = cTwitter
import cUser from './user'
export const User = cUser
import cUserConnect from './user-connect'
export const UserConnect = cUserConnect
import cX from './x'
export const X = cX
import cXCircle from './x-circle'
export const XCircle = cXCircle

import cLike from './Like'
export const Like = cLike
import cReply from './Reply'
export const Reply = cReply
import cPost from './Post'
export const Post = cPost
