import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import { cond } from 'ramda'
import React from 'react'

import Spinner from '../icons/spinner'
import Stethoscope from '../icons/stethoscope'
import Button from './button'
import style from './styles/follow.module.scss'

const FollowingLabel = ({ resourceType, following }) => {
  const { t } =
    resourceType === 'user' ? useTranslation('member') : useTranslation('case')

  if (resourceType === 'user') {
    if (following) {
      return <>{t('member.following_button_label')}</>
    }
    return <>{t('member.follow_button_label')}</>
  }

  if (following) {
    return <>{t('case.following_button_label')}</>
  }
  return <>{t('case.follow_button_label')}</>
}

const SpinnerComp = <Spinner width={12} />
const StethoscopeComp = <Stethoscope />

type Props = {
  resourceType: string
  is_requesting?: boolean
  is_following?: boolean
  toggleFollowing: () => void
  flex?: boolean
  className?: string
  fill?: boolean
  textOnly?: boolean
  disabled?: boolean
}

const FollowButton = ({
  is_requesting,
  is_following,
  textOnly,
  fill,
  className,
  flex = false,
  toggleFollowing,
  resourceType,
  disabled,
  ...rest
}: Props): any => {
  const active = is_requesting
  const highlighted = active || is_following

  if (flex) {
    return (
      <div className={style.flexContainer}>
        <span className={style.linkText} onClick={() => toggleFollowing()}>
          <FollowingLabel resourceType={resourceType} following={highlighted} />
        </span>
        <Button
          dark={highlighted}
          fill={fill || highlighted}
          disabled={disabled}
          className={cs(style.btn)}
          onClick={toggleFollowing}
        >
          {active ? SpinnerComp : textOnly ? null : StethoscopeComp}
        </Button>
      </div>
    )
  }

  return (
    <Button
      {...rest}
      className={cs(className, 'stethoscope')}
      fill={fill || highlighted}
      dark={highlighted}
      onClick={toggleFollowing}
      disabled={disabled}
    >
      <FollowingLabel resourceType={resourceType} following={highlighted} />
      {active ? (
        <Spinner width={12} />
      ) : textOnly ? null : (
        <span style={{ display: 'inline-block', marginLeft: 6 }}>
          <Stethoscope />
        </span>
      )}
    </Button>
  )
}

export default FollowButton
