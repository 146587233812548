export const generateUUID = (): string => {
  var d = new Date().getTime()
  if (
    global['performance'] &&
    typeof global['performance']['now'] === 'function'
  ) {
    const performance: Performance = global['performance']
    d += performance.now() // use high-precision timer if available
  }

  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
    c
  ) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })

  return uuid
}
