import React from 'react'
import cs from 'classnames'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/MedshrModal.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type MedshrModalType = {
  title: string
  className?: string
  footer?: any
  message?: any
  sideKickBtn: React.ReactNode
  children: React.ReactNode
}

const MedshrModal = ({
  title,
  className,
  sideKickBtn,
  footer,
  message,
  children,
}: MedshrModalType): React.ReactElement => {
  return (
    <div className={cs(className, style.medshrModal)}>
      <header className={style.header}>
        <h2 className={style.title}>{title && title}</h2>
        <span className={style.sideKickBtn}>{sideKickBtn && sideKickBtn}</span>
      </header>

      <div className={style.body}>
        {message && renderMessage(message)}
        {children}
      </div>

      {footer && renderFooter(footer)}
    </div>
  )
}

export function renderMessage(message: any): any {
  return (
    <h3 className={style.message}>
      {message.warning && (
        <span className={style.warning}>{message.warning} </span>
      )}
      {message.text}{' '}
      {message.email && (
        <span className={cs('fs-exclude', style.email)}>{message.email}</span>
      )}
    </h3>
  )
}

export function renderFooter(footer: any): any {
  return (
    <footer className={style.footer}>
      <p className="small">
        {footer.text}{' '}
        {footer.link && (
          <a
            className={style.link}
            href={footer.href}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            {' '}
            {footer.link}{' '}
          </a>
        )}
      </p>
    </footer>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default MedshrModal
