import { all, fork, take, call, put, select } from 'redux-saga/effects'
import * as actions from './actions'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////// WORKER SAGA
function* fetchCaseFollowers(caseId: string) {
  try {
    const response = yield call(actions.getCaseFollowers, caseId, 1)

    if (response) {
      yield put(actions.loadCaseFollowersSuccess(response))
    }
  } catch (error) {
    yield put(actions.loadCaseFollowersFailure(error))
  }
}

function* seeMoreCaseFollowers(caseId: string) {
  try {
    const page = yield select(store => store.case_page.follows.followers.page)
    const response = yield call(actions.getCaseFollowers, caseId, page)

    if (response) {
      yield put(actions.seeMoreCaseFollowersSuccess(response))
    }
  } catch (error) {
    yield put(actions.seeMoreCaseFollowersFailure(error))
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////// WATCH SAGA
function* watchFetchCaseFollowers(): Generator {
  while (true) {
    const { caseId }: any = yield take(actions.LOAD_REQUEST)
    yield fork(fetchCaseFollowers, caseId)
  }
}

function* watchSeeMoreCaseFollowers(): Generator {
  while (true) {
    const { caseId }: any = yield take(actions.SEE_MORE_REQUEST)
    yield fork(seeMoreCaseFollowers, caseId)
  }
}

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////// ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([
    fork(watchFetchCaseFollowers),
    fork(watchSeeMoreCaseFollowers),
  ])
}
