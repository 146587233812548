import React from 'react'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'

interface SpinnerIconProps {
  width?: number
  theme?: string
  id?: string
  title?: string
  className?: string
  centered?: boolean
}

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const SpinnerIcon = ({
  title = 'Loading',
  theme = 'light',
  id = '',
  className = '',
  width = 50,
  centered = false,
  ...props
}: SpinnerIconProps): React.ReactElement => {
  const classes = cs('spinner', className, 'icon-spinner', {
    [theme]: true,
    'spinner--centered': centered,
  })

  return (
    <Icon
      className={classes}
      sourceWidth={98}
      sourceHeight={100}
      title={title}
      id={id}
      width={width}
      {...props}
    >
      <ellipse cx="73.3935062" cy="21.717094" rx="4.43496582" ry="4.46819586" />
      <ellipse
        cx="86.7600813"
        cy="36.0806228"
        rx="5.32377003"
        ry="5.36365964"
      />
      <ellipse
        cx="89.0388696"
        cy="57.0167074"
        rx="7.58878562"
        ry="7.64564641"
      />
      <ellipse
        cx="78.2214041"
        cy="77.5796973"
        rx="9.06585155"
        ry="9.13377961"
      />
      <ellipse
        cx="53.0895861"
        cy="89.4844592"
        rx="10.2483109"
        ry="10.3250988"
      />
      <ellipse
        cx="24.1739642"
        cy="78.2738095"
        rx="12.1652544"
        ry="12.2564054"
      />
      <ellipse
        cx="13.0600004"
        cy="45.6599989"
        rx="12.9648804"
        ry="13.1499165"
      />
      <ellipse cx="37.0725763" cy="15.7409316" rx="15.623866" ry="15.7409316" />
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default SpinnerIcon
