import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'
import XIcon from '../icons/x'
import style from './styles/notification.module.scss'

type NotificationType = {
  dismissNotification: Function
  notification: NotifyType
  index: number
  count: number
  className?: string
  inlineStyle?: any
  closeBtn: boolean
}
type NotifyType = {
  id?: string
  values?: any
  message?: string
  kind?: string
}

const height = 68 // see also css/_components.notifications.scss

const Notification = ({
  count = 1,
  index = 0,
  notification,
  className,
  inlineStyle,
  dismissNotification,
  closeBtn = true,
}: NotificationType): React.ReactElement => {
  const styles: any = {
    top: count * height - (index + 1) * height,
    ...inlineStyle,
  }
  const containerClasses: string = cs(
    style.notification,
    style[`notification--${notification.kind || 'info'}`],
    className
  )

  const paragraphClasses: string = cs(
    style.notification__message,
    !closeBtn ? style.notification__message__extended : ''
  )

  return (
    <div className={containerClasses} style={styles}>
      <div className={`page__container ${style.container}`}>
        <div className={style.item}>
          <p className={paragraphClasses}>
            {notification && renderNotificationMessage(notification)}
          </p>
        </div>

        {closeBtn ? (
          <div className={style.item}>
            <button
              onClick={(): any => dismissNotification(index)}
              type="button"
              className={style.notification__close}
            >
              <XIcon />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

// RENDER NOTIFICATION MESSAGE
export const renderNotificationMessage = ({ message, id, values }: any) => {
  const { t } = useTranslation('common')

  if (message) {
    return message
  } else if (values) {
    return t(id, { name: values.name, count: values.count })
  } else if (id) return t(id)

  return null
}

export default Notification
