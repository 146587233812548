import { request } from '../../../modules/api/actions'
import * as types from '../../../modules/entities/types'
import * as schemas from '../../../modules/entities/schemas'

export const UPDATE_REQUEST = 'filters/update.REQUEST'
export const UPDATE_SUCCESS = 'filters/update.SUCCESS'
export const UPDATE_FAILURE = 'filters/update.FAILURE'

export default function updateFilters(filters, page?: number, count?: number) {
  const feed_type = types.FEED_MAIN_CONTENT
  const url = schemas.Feeds[feed_type].url
  return request({
    url: url,
    method: 'POST',
    purpose: 'load',
    json: { filters },
    page: page,
    count: count,
    start: UPDATE_REQUEST,
    success: {
      type: UPDATE_SUCCESS,
      feed_type: feed_type,
      meta: {
        schema: { response: schemas.getFeedSchema(feed_type) },
      },
    },
    failure: UPDATE_FAILURE,
  })
}
