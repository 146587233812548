import { useTranslation } from 'next-i18next'
import React from 'react'
import cs from 'classnames'
import style from './styles/Error.module.scss'

interface Props {
  customClass?: string
  message: string
  hasWarning?: boolean
  hasError?: boolean
  size?: '' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg'
}

const Error = ({
  message = '',
  hasWarning = false,
  hasError = false,
  customClass = '',
  size = '',
}: Props): React.ReactElement => {
  const classes = cs({
    [style.warning]: hasWarning,
    [style.error]: hasError,
    [style[size]]: size,
  })

  const { t } = useTranslation('common')

  return (
    <span className={`form__error ${customClass} ${classes}`}>
      {typeof message === 'string' ? t(message) : message}
    </span>
  )
}

export default Error
