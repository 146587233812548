import React from 'react'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  icon?:
    | 'checkFill'
    | 'checkLine'
    | 'uploadFill'
    | 'uploadLine'
    | 'empty'
    | 'circlePlus'
  height?: string
  width?: string
  viewBox?: string
  color?: string
  onClick?: (e: React.MouseEvent<Element>) => void
}

const icons: any = {
  checkFill: 'M864 128l-480 480-224-224-160 160 384 384 640-640z',
  checkLine:
    'M397.434 917.696l-397.868-391.6 197.378-194.27 200.49 197.332 429.62-422.852 197.378 194.27-626.998 617.12zM107.912 526.096l289.524 284.962 518.656-510.482-89.036-87.632-429.62 422.852-200.49-197.334-89.034 87.634z',
  uploadFill:
    'M512 704h256.018c88.356 0 159.982-71.814 159.982-160 0-67.085-41.175-124.528-99.748-148.304v0c-16.731-79.784-87.495-139.696-172.252-139.696-27.613 0-53.74 6.359-76.998 17.692-38.484-67.886-111.394-113.692-195.002-113.692-123.712 0-224 100.288-224 224 0 4.421 0.128 8.812 0.381 13.169v0c-56.712 24.642-96.381 81.203-96.381 146.831 0 88.366 71.44 160 159.982 160h256.018v-192l-104 104-24-24 144-144 144 144-24 24-104-104v192zM480 704v160h32v-160h-32z',
  uploadLine:
    'M480 512l-104 104-24-24 144-144 144 144-24 24-104-104v352h-32v-352zM448 672h-223.91c-70.556 0-128.090-57.308-128.090-128 0-60.652 42.388-111.624 99.232-124.731v0c-2.123-11.433-3.232-23.221-3.232-35.269 0-106.039 85.961-192 192-192 83.694 0 154.881 53.551 181.166 128.257 24.779-20.167 56.395-32.257 90.834-32.257 75.467 0 137.375 58.053 143.502 131.938v0c55.362 14.081 96.498 64.286 96.498 124.062 0 70.549-57.348 128-128.090 128h-223.91v32h224.018c88.356 0 159.982-71.814 159.982-160 0-67.085-41.175-124.528-99.748-148.304v0c-16.731-79.784-87.495-139.696-172.252-139.696-27.613 0-53.74 6.359-76.998 17.692-38.484-67.886-111.394-113.692-195.002-113.692-123.712 0-224 100.288-224 224 0 4.421 0.128 8.812 0.381 13.169v0c-56.712 24.642-96.381 81.203-96.381 146.831 0 88.366 71.44 160 159.982 160h224.018v-32z',
  empty:
    'M672 512h216.801l-140-224h-441.602l-140 224h216.801v64.067c0 35.309 28.667 63.933 63.917 63.933h160.167c35.3 0 63.917-28.744 63.917-63.933v-64.067zM704 544v48c0 44.491-35.761 80-79.874 80h-192.252c-44.185 0-79.874-35.817-79.874-80v-48h-192v224h736v-224h-192zM128 528v-16l160-256h480l160 256v288h-800v-272z',
  circlePlus: [
    'M9 1c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM9 15c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z',
    'M12.010 8h-2.010v-2.010c0-0.547-0.443-0.99-0.99-0.99h-0.020c-0.547 0-0.99 0.443-0.99 0.99v2.010h-2.010c-0.547 0-0.99 0.443-0.99 0.99v0.020c0 0.547 0.443 0.99 0.99 0.99h2.010v2.010c0 0.547 0.443 0.99 0.99 0.99h0.020c0.547 0 0.99-0.443 0.99-0.99v-2.010h2.010c0.547 0 0.99-0.443 0.99-0.99v-0.020c-0-0.547-0.443-0.99-0.99-0.99z',
  ],
}

const Icon = ({
  icon,
  width,
  height,
  color,
  viewBox,
  onClick,
}: Props): React.ReactElement => {
  icon = icon ?? 'checkFill'
  height = height ?? '22'
  width = width ?? '22'
  viewBox = viewBox ?? '0 0 1024 1024'
  color = color ?? '#2c313f'

  const styles = {
    svg: { display: 'inline-block', verticalAlign: 'middle' },
    path: { fill: color },
  }

  return (
    <svg
      style={styles.svg}
      width={width}
      height={height}
      viewBox={viewBox}
      onClick={onClick}
    >
      {renderPath(icons[icon], styles)}
    </svg>
  )
}

function renderPath(paths: Array<string> | string, styles: any) {
  if (Array.isArray(paths)) {
    return paths.map(i => <path key={i} style={styles.path} d={i} />)
  }

  return <path style={styles.path} d={paths} />
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Icon
