import { useTranslation } from 'next-i18next'
import React from 'react'
import * as userState from '../../modules/auth/selectors'
import connectTo from '../../modules/entities/actions/connect'
import {
  userHasConnection,
  userHasConnectionRequestInFlight,
  userHasReceivedConnectionRequest,
  userHasRequestedConnection,
} from '../../modules/entities/selectors'
import { postNotification } from '../../modules/notify/actions'
import { useDispatch, useSelector } from '../../react-redux'
import { AuthenticatedUser, BasicUser } from '../../types/userTypes'

import ConnectButton from '../buttons/connect'

interface Props {
  user: BasicUser
  flex?: boolean
  wide?: boolean
  className?: string
}

const SmartConnectButton = ({ className, flex, user, wide }: Props) => {
  const { t } = useTranslation('common')

  const currentUser: AuthenticatedUser = useSelector(
    userState.selectAuthenticatedUser
  )
  const user_id = user.id
  const isCurrentUser: boolean = currentUser && currentUser.id == user_id
  const isConnectedToUser: boolean = !!useSelector(
    state => !!currentUser && userHasConnection(state, user_id)
  )

  const hasRequestedConnectionToUser: boolean = !!useSelector(
    state =>
      !!currentUser &&
      !isConnectedToUser &&
      userHasRequestedConnection(state, user_id)
  )

  const hasReceivedConnectionRequest: boolean = !!useSelector(
    state =>
      !!currentUser &&
      !isConnectedToUser &&
      userHasReceivedConnectionRequest(state, user_id)
  )

  const isConnectingToUser: boolean = useSelector(
    state =>
      !!currentUser &&
      !isConnectedToUser &&
      !hasRequestedConnectionToUser &&
      userHasConnectionRequestInFlight(state, user_id, true)
  )

  const userVerified =
    !!currentUser && currentUser.verification_status === 'verified'

  const dispatch = useDispatch()

  const connectToUser = (user_id: any, connect: any): any => {
    dispatch(connectTo('user', user_id, connect))
  }

  const postVerificationNotification = (message: any): any => {
    dispatch(postNotification({ kind: 'info', message, closeBtn: false }))
  }

  const onConnect = () => {
    const connect = !(hasRequestedConnectionToUser || isConnectedToUser)
    connectToUser(user.id, connect)

    if (!userVerified && connect) {
      postVerificationNotification(t('notification.unverified_users.connect'))
    }
  }

  if (
    !currentUser ||
    isCurrentUser ||
    !currentUser.can_follow_user ||
    (typeof user['seniority'] === 'string'
      ? user['seniority'] === 'advisory_board_mod'
      : false)
  ) {
    return null
  }

  return (
    <ConnectButton
      flex={flex}
      wide={wide}
      onConnectClick={onConnect}
      connected={isConnectedToUser}
      active={isConnectingToUser}
      sent={hasRequestedConnectionToUser}
      received={hasReceivedConnectionRequest}
      className={className}
    />
  )
}

export default SmartConnectButton
