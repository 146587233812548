import React from 'react'
import Linkify from 'react-linkify'
import cs from 'classnames'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export type TrimSpec = {
  maxSentences?: number
  maxWords?: number
  maxLetters?: number
  text: string
}

export type TrimProps = TrimSpec & {
  className?: string
  sideKick?: React.ReactElement
}

// //////////////////////////////////////////////////////////////////////// TRIM
const trim = ({ maxSentences, maxWords, text, maxLetters }: TrimSpec) => {
  let trimmedText = text

  if (maxSentences) {
    const sentences = trimmedText.split(/[\.\?!]/)
    if (sentences.length > maxSentences) {
      trimmedText = sentences.slice(0, maxSentences).join('.') + '.'
      if (trimmedText.length < 10 && sentences.length > maxSentences) {
        trimmedText += ' ' + sentences[maxSentences] + '.'
      }
    }
  }

  if (maxWords) {
    const words = trimmedText.split(' ')
    if (words.length > maxWords) {
      trimmedText = words.slice(0, maxWords).join(' ') + '…'
    }
  }

  if (maxLetters) {
    const letterArr = trimmedText.split('')
    if (letterArr.length > maxLetters) {
      trimmedText = `${letterArr.slice(0, maxLetters).join('')}…`
    }
  }

  return trimmedText
}

export const rawTextTrimmer = ({
  maxSentences,
  maxWords,
  text,
  maxLetters,
}: TrimSpec) => {
  return trim({ maxSentences, maxWords, text, maxLetters })
}

// ////////////////////////////////////////////////////////////////////// FORMAT
const TextFormatter = ({
  maxSentences,
  maxWords,
  text,
  className,
  sideKick,
}: TrimProps) => {
  const texts = trim({ maxSentences, maxWords, text })

  if (texts.length) {
    return (
      <div className={cs(className, 'formatted-text')}>
        <Linkify>
          {texts.split('\n\n').map((paragraph, i) => {
            const lines = paragraph.split('\n')
            return (
              <p key={i}>
                <>
                  {lines.map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      {i < lines.length - 1 ? <br /> : null}
                    </React.Fragment>
                  ))}{' '}
                  {sideKick ? sideKick : null}
                </>
              </p>
            )
          })}
        </Linkify>
      </div>
    )
  }

  return null
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TextFormatter
