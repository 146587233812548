import React from 'react'
import { useDispatch } from 'react-redux'
import { redirect } from '../../modules/routing/actions'
import Button from './invite-colleagues'
import { TargetType } from '../../types/caseTypes'
import { AuthenticatedUser } from '../../types/userTypes'

const SmartInviteColleaguesButton: React.FC<{
  currentUser: AuthenticatedUser
  resource: TargetType
  wide?: boolean
}> = ({ currentUser, resource, wide, ...props }) => {
  const dispatch = useDispatch()
  const shareCase = ({ id }) => dispatch(redirect(`/cases/${id}/invite`))

  if (currentUser?.can_invite && resource.visibility !== 'public') {
    return (
      <Button
        {...props}
        wide={wide}
        inviteColleagues={() => shareCase(resource)}
      />
    )
  }

  return null
}

export default SmartInviteColleaguesButton
