import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { IconWithDefs } from './icon'
import { IconWithDefsType } from './icon'

interface PauseIconProps {
  id?: string
  title?: string
  width?: number
  className?: string
  onClick?: () => void
}

// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////////// IMPORT
// /////////////////////////////////////////////////////////////////////////////

const PauseIcon = (props: PauseIconProps): React.ReactElement => {
  const filter_id = `${props.id}-shadow-filter`

  const defaultProps = {
    id: 'icon-pause-large',
    title: 'Pause',
  }

  return (
    <IconWithDefs
      {...{ ...defaultProps, ...props }}
      sourceWidth={68}
      sourceHeight={68}
      viewBox="0 0 68 68"
    >
      <defs>
        <filter id={filter_id}>
          <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(5.000000, 4.000000)" stroke="none" fill="none">
        <circle
          className={`${props.id}__shadow`}
          filter={`url(#'${filter_id}')`}
          cx="29"
          cy="29"
          r="32"
          fill="rgba(0, 0, 0, 0.4)"
        />
        <circle
          className={`${props.id}__background`}
          cx="29"
          cy="29"
          r="29"
          stroke="#4B9BEF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#4B9BEF"
        />
        <g transform="translate(19.000000, 16.000000)" fill="#FFFFFF">
          <rect x="0" y="0" width="8" height="26" rx="1" />
          <rect x="12" y="0" width="8" height="26" rx="1" />
        </g>
      </g>
    </IconWithDefs>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default PauseIcon
