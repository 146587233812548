import React from 'react'
import TextareaAutosize from '../TextareaAutosize'
import cs from 'classnames'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'
import { filterReduxFormProps } from './base'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/AutoSizeTextAreaField.module.scss'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  input: any
  meta: Meta
  type: string
  placeholder: string | Element
  label?: string
  getRef: Function
  theme: string
  withLimitContent?: boolean
  textCount: number
  maxLength: number
}
export type Meta = {
  touched: boolean
  error: string
  warning: boolean
  dirty: boolean
  valid: boolean
  active: boolean
}

const AutoSizeTextAreaField = ({
  input: { ...restInput },
  meta: { touched, error, warning, dirty, ...restMeta },
  placeholder,
  type,
  label,
  getRef,
  theme,
  withLimitContent,
  textCount,
  maxLength,
  ...rest
}: PropsType): React.ReactElement => {
  const labelText = <label htmlFor={label}>{label}</label>
  const forId = label ? { id: label } : {}
  const reachLimit = textCount >= maxLength

  return (
    <div className={style.container}>
      {label ? labelText : null}

      <TextareaAutosize
        innerRef={input => getRef(input)}
        placeholder={placeholder}
        type={type}
        maxLength={maxLength}
        {...filterReduxFormProps(restInput)}
        {...filterReduxFormProps(restMeta)}
        {...filterReduxFormProps(rest)}
        {...forId}
      />

      {withLimitContent ? (
        <div className={style.counterCont}>
          <span
            className={cs(style.counterText, style[theme], {
              [style.reachLimit]: reachLimit,
            })}
          >
            {textCount} / {maxLength}
          </span>
        </div>
      ) : null}

      {touched &&
        ((error && (
          <Error customClass={style.textError} message={error} hasError />
        )) ||
          (warning && (
            <Error customClass={style.textError} message={error} hasWarning />
          )))}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export { AutoSizeTextAreaField as default }
