import update from 'react-addons-update'
import forEach from 'lodash/forEach'
import pickBy from 'lodash/pickBy'

// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as load from './actions/load'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// ///////////////////////////////////////////////////////////////////// REDUCER
const reducer = (state = {}, action) => {
  switch (action.type) {
    case load.LOAD_REQUEST:
      return state

    case load.LOAD_SUCCESS:
      return Object.assign({}, action.payload.response, {
        specialties: labelSpecialtyLevels(action.payload.response.specialties),
        specialty_tree: buildTree(action.payload.response.specialties),
        ambiguous_terms: countTerms(action.payload.response.specialties),
      })

    case load.LOAD_FAILURE:
      return update(state, { error: { $set: action.error } })
  }

  return state
}

export function labelSpecialtyLevels(specialties, depth: number = 0) {
  depth = depth || 0
  const specialtyLevels = ['field', 'specialty', 'subspecialty']
  const level = specialtyLevels[depth]
  for (let category of specialties) {
    category.level = level
    if (category.children && category.children.length) {
      labelSpecialtyLevels(category.children, depth + 1)
    }
  }
  return specialties
}

// ///////////////////////////////////////////////////////////////////// BUILD TREE
export function buildTree(specialties) {
  var tree = {}

  for (let category of specialties) {
    let id = category.id.split('.').slice(-1)[0]
    if (category.children && category.children.length) {
      tree[id] = { ...category, children: buildTree(category.children) }
    } else {
      tree[id] = category
    }
  }
  return tree
}

// ///////////////////////////////////////////////////////////////////// COUNT TERMS
function countTerms(specialties) {
  return pickBy(_countTerms(specialties), v => v > 1)
}

function _countTerms(specialties) {
  var terms = {}

  for (let category of specialties) {
    let label = category.label
    if (category.children && category.children.length) {
      const childTerms = _countTerms(category.children)
      forEach(childTerms, (v, label) => {
        terms[label] = terms[label] ? terms[label] + v : v
      })
    } else {
      terms[label] = terms[label] ? terms[label] + 1 : 1
    }
  }
  return terms
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default reducer
