import { reduce, values } from 'ramda'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export function isRequestActive(state, request) {
  const id = typeof request === 'string' ? request : request.id
  return state.api[id] && state.api[id].active
}

export function isRequestSuccessful(state, request) {
  const id = typeof request === 'string' ? request : request.id
  return state.api[id] && state.api[id].success
}

export function requestTimestamp(state, request) {
  const id = typeof request === 'string' ? request : request.id
  return state.api[id] && state.api[id].ts
}

type ErrorWithStatusCode = Error & { statusCode?: number }

export function requestError(state, request): ErrorWithStatusCode | undefined {
  const e = requestErrorObject(state, request)
  if (e) {
    const res = new Error(e.message) as ErrorWithStatusCode
    res.statusCode = e.code
    return res
  }
}

export function requestErrorString(state, request): string | undefined {
  return requestErrorObject(state, request)?.message
}

export type RequestErrorState = {
  message: string
  id: string
  code: number
}

export function requestErrorObject(
  state,
  request
): RequestErrorState | undefined {
  const id = typeof request === 'string' ? request : request.id
  const status = state.api[id]
  if (status && status.error) {
    if (
      status.payload &&
      status.payload.response &&
      status.payload.response.blocked
    ) {
      return {
        message: status.payload.response.blocked_reason,
        id: status.payload.response.blocked_reason_id,
        code:
          typeof status.error === 'object'
            ? status.error.status
            : status.status,
      }
    }
    const error = status.error
    const errorString = typeof error === 'object' ? error.error : error
    const code = typeof error === 'object' ? error.status : status.status
    return {
      message: errorString,
      id: code,
      code: code,
    }
  }
}

export function activityType(state) {
  return reduce(
    (a, v) => {
      if (v.active) {
        if (a === null || a === 'load') {
          a = v.purpose || 'load'
        }
      }
      return a
    },
    null,
    values(state.api)
  )
}

export function countActiveRequests(state) {
  return reduce(
    (a, v) => {
      return a + (v.active ? 1 : 0)
    },
    0,
    values(state.api)
  )
}
