import { omit, pickBy } from 'ramda'
import get from 'lodash/get'

// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as actions from './actions'

const actionType = action => {
  return (
    action.purpose ||
    (get(action, 'method', 'GET') === 'GET'
      ? 'load'
      : action.method === 'DELETE'
      ? 'delete'
      : 'save')
  )
}

// ///////////////////////////////////////////////////////////////////// REDUCERS
const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.API_CALL:
      return {
        ...state,
        [action.id]: {
          active: true,
          queued: true,
          purpose: actionType(action),
        },
      }

    case actions.API_REQUEST:
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || {}),
          active: true,
          queued: false,
        },
      }

    case actions.API_FAILURE:
      return {
        ...state,
        [action.id]: {
          active: false,
          error: action.error,
          ts: new Date(),
          payload: action.payload,
        },
      }

    case actions.API_SUCCESS:
      return {
        ...state,
        [action.id]: { active: false, success: true, ts: new Date() },
      }

    case actions.API_FORGET:
      return omit([action.id], state)

    case actions.API_GC:
      const now = new Date().getTime()
      return pickBy(a => !a.ts || now - a.ts < 10000, state)

    default:
      return state
  }
}

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default reducer
