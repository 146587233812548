import { request } from '../../api/actions'
import { postNotification } from '../../notify/actions'
import { loadOwnedFeedSuccess } from '../../entities/actions/feed_load'

import {
  FEED_GROUPS_ADMIN,
  FEED_GROUP_ADMIN,
  FEED_INSTITUTION_ADMIN,
} from '../../entities/types'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const UPDATE_REQUEST = 'entities/group_admin_join_request.REQUEST'
export const UPDATE_SUCCESS = 'entities/group_admin_join_request.SUCCESS'
export const UPDATE_FAILURE = 'entities/group_admin_join_request.FAILURE'

export const UPDATE_ALL_REQUEST =
  'entities/group_admin_join_request.ALL_REQUEST'
export const UPDATE_ALL_SUCCESS =
  'entities/group_admin_join_request.ALL_SUCCESS'
export const UPDATE_ALL_FAILURE =
  'entities/group_admin_join_request.ALL_FAILURE'

export function accept(resource_type, resource_id, user_id) {
  return action(resource_type, resource_id, user_id, 'accept')
}

export function ignore(resource_type, resource_id, user_id) {
  return action(resource_type, resource_id, user_id, 'ignore')
}

function action(resource_type, resource_id, user_id, request_type) {
  const url = '/api/' + resource_type + 's/' + resource_id + '/' + request_type
  return request({
    url: url,
    id: url + '[' + user_id + ']',
    method: 'POST',
    json: { user_id },
    start: UPDATE_REQUEST,
    success: {
      type: UPDATE_SUCCESS,
      resource: { type: resource_type, id: resource_id, user_id, request_type },
    },
    failure: [
      {
        type: UPDATE_FAILURE,
        resource: {
          type: resource_type,
          id: resource_id,
          user_id,
          request_type,
        },
      },
      postNotification({
        kind: 'error',
        id: 'member.group_admin_join_request_conflict',
      }),
    ],
  })
}

// ///////////////////////////////////////////////////////////////////// ACCEPT ALL REQUESTS
export const acceptAllRequests = (
  feedType,
  feedId?: string,
  closeModalAction?: any
) => {
  const feeds = {
    [FEED_GROUPS_ADMIN]: '/api/profile/connections/accept/requests/groups',
    [FEED_GROUP_ADMIN]: `/api/profile/connections/accept/requests/group/${feedId}`,
    [FEED_INSTITUTION_ADMIN]: `/api/profile/connections/accept/requests/institution/${feedId}`,
  }
  const url = feeds[feedType]

  if (url) {
    return request({
      url: url,
      id: url,
      method: 'POST',
      json: { connect: true },
      start: [UPDATE_ALL_REQUEST, closeModalAction],
      success: [
        loadOwnedFeedSuccess(feedType, feedId),
        postNotification({
          kind: 'info',
          id: 'member.accepted_all_connection_requests',
        }),
      ],
      failure: UPDATE_ALL_FAILURE,
    })
  }

  return postNotification({
    kind: 'error',
    message: `Incorrect feed type ${feedType}`,
  })
}
