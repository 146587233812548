import { concat, equals, filter } from 'ramda'

import * as actions from './actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const INITIAL_STATE = {
  stack: [],
  closeBtn: true,
}

const append = (stack, action) => {
  if (
    stack.length &&
    action.notification &&
    stack[stack.length - 1].notification &&
    equals(stack[stack.length - 1].notification, action.notification)
  ) {
    return concat(stack.slice(0, stack.length - 1), [action])
  }

  return concat(stack, [action])
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SHOW:
      return {
        ...state,
        // WEB-553, the decision was made to display only one
        // notification at a time
        stack: [action],
        closeBtn: action.notification.closeBtn,
      }

    case actions.DISMISS:
      return {
        ...state,
        stack: filter(a => a.id !== action.id, state.stack),
      }

    default:
      return state
  }
}
