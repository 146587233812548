import { useSelector } from '../../react-redux'
import { AuthenticatedUser } from '../../types/userTypes'
import {
  selectAuthenticatedUser,
  selectIsAuthenticating,
  selectIsAuthenticated,
} from './selectors'

export type AuthenticationState = {
  isAuthenticating?: boolean
  isAuthenticated?: boolean
  user?: AuthenticatedUser
}

export const useAuthenticationState = (): AuthenticationState => ({
  isAuthenticating: useSelector(selectIsAuthenticating),
  isAuthenticated: useSelector(selectIsAuthenticated),
  user: useSelector(selectAuthenticatedUser),
})

export const useUser = (): AuthenticatedUser =>
  useSelector(selectAuthenticatedUser)
