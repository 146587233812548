import React from 'react'
import cs from 'classnames'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
// /////////////////////////////////////////////////////////////////////// UTILS
import safeId from '../../../utils/safeId'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { Checkbox } from '../../../ui/forms/base'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  specialty_list: Array<any>
  selections: any
  onToggleCategory: Function
  onToggleCategories: Function
}

// TagPickerList: List of current selections grouped by specialty
const TagPickerList = (props: Props): any => {
  const selections = props.selections

  return (
    <div>
      {props.specialty_list.map(specialty => {
        const enabledCount = reduce(
          specialty.subfilters,
          (r, v) => r + (selections[v.id] ? 1 : 0),
          0
        )
        const specialtyFull = specialty.children_count === enabledCount
        const addAllClassName = cs({
          'action-link': !specialtyFull,
          'disabled-link': specialtyFull,
        })
        const addAllLabel =
          'Add all ' + specialty.children_count + ' sub-specialties'
        const onAddAll = () => {
          props.onToggleCategories(specialty.children, true)
        }
        const removeAllClassName = cs({
          'action-link': enabledCount > 0,
          'disabled-link': enabledCount === 0,
        })
        const removeAllLabel = 'Remove all'
        const onRemoveAll = () => {
          let enabled = filter(
            specialty.subfilters,
            category => selections[category.id]
          )
          props.onToggleCategories(enabled, false)
        }
        const checked = selections[specialty.id]

        return (
          <div
            key={specialty.id}
            className="tag-picker__specialty modal__section"
          >
            <div className="tag-picker__specialty-header">
              <h5 className="tag-picker__specialty-label">
                <Checkbox
                  align="right"
                  id={safeId(specialty.id)}
                  label={specialty.label}
                  value={specialty.id}
                  checked={checked}
                  onChange={checked =>
                    props.onToggleCategory(specialty, checked)
                  }
                />
                {(() => {
                  if (specialty.children.length > 0) {
                    return (
                      <div className="simple-results__row-subtitle tag-picker__specialty-tools">
                        <span
                          className={addAllClassName}
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            onAddAll()
                            return false
                          }}
                        >
                          {addAllLabel}
                        </span>
                        <span
                          className={removeAllClassName}
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            onRemoveAll()
                            return false
                          }}
                        >
                          {removeAllLabel}
                        </span>
                      </div>
                    )
                  }
                })()}
              </h5>
            </div>
            <ul className="tag-picker__subspecialties list-bare">
              {specialty.subfilters.map(category => (
                <li key={category.id} className="tag-picker__subspecialty">
                  <Checkbox
                    align="right"
                    id={safeId(category.id)}
                    label={category.label}
                    value={category.id}
                    checked={category.enabled}
                    onChange={checked =>
                      props.onToggleCategory(category, checked)
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TagPickerList
