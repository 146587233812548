import * as schemas from '../../entities/schemas'

import { request } from '../../api/actions'

export const SETTINGS_SAVE_REQUEST = 'auth/SETTINGS_SAVE_REQUEST'
export const SETTINGS_SAVE_SUCCESS = 'auth/SETTINGS_SAVE_SUCCESS'
export const SETTINGS_SAVE_FAILURE = 'auth/SETTINGS_SAVE_FAILURE'

export default function saveAccountSettings(
  profile,
  authIdentifier,
  authToken
) {
  return request({
    method: 'POST',
    url: '/api/profile/account-settings',
    id: `/api/profile/account-settings[${authIdentifier}]`,
    json: { ...profile, identifier: authIdentifier, token: authToken },
    start: SETTINGS_SAVE_REQUEST,
    success: {
      type: SETTINGS_SAVE_SUCCESS,
      meta: { schema: { response: schemas.userSchema } },
    },
    failure: SETTINGS_SAVE_FAILURE,
  })
}
