import getConfig from '@/config'
import * as actions from './actions'

const handlers = {
  [actions.CLEAR_LAYOUT_CLASSES]: state => {
    return { ...state, classNames: [] }
  },
  [actions.ADD_LAYOUT_CLASS]: (state, action) => {
    return {
      ...state,
      classNames: (state.classNames ?? []).concat([action.className]),
    }
  },
}

const reducer = (state, action) => {
  if (typeof handlers[action.type] === 'function') {
    state = handlers[action.type](state, action)
  }
  return state ?? { classNames: [] }
}

export default reducer
