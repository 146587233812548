import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import ReactModal from './ReactModal'
import { Img } from '../Img/index'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  isOpen: boolean
  onModalClose: Function
  modalOpened: Function
  onCancel?: Function
  imgUrl?: string
  shouldCloseOnOverlayClick?: boolean
}

const SampleImgModal = ({
  isOpen,
  onModalClose,
  imgUrl,
  ...rest
}: Props): any => (
  <ReactModal
    size="sampleImg"
    isOpen={isOpen}
    shouldDisplayCloseButton
    onRequestClose={onModalClose}
    {...rest}
  >
    <Img
      alt="Verify photo"
      adaptive
      src={imgUrl || '/static/media/images/verify-photo-id.png'}
    />
  </ReactModal>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default SampleImgModal
