import callAPI from '../../../modules/api/call'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////// ACTIONS
export const LOAD_LIKES_REQUEST = 'likes/LOAD_LIKES_REQUEST'
export const LOAD_LIKES_SUCCESS = 'likes/LOAD_LIKES_SUCCESS'
export const LOAD_LIKES_FAILURE = 'likes/LOAD_LIKES_FAILURE'

export const RESET_LOADING_STATE = 'likes/RESET_LOADING_STATE'

export const SEE_MORE_REQUEST = 'likes/SEE_MORE_REQUEST'
export const SEE_MORE_SUCCESS = 'likes/SEE_MORE_SUCCESS'
export const SEE_MORE_FAILURE = 'likes/SEE_MORE_FAILURE'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////// ACTION CREATORS

// ///////////////////////////////////////////////////// ACTION CREATORS
export function seeMoreRequest({ page, pages }: any): any {
  return { type: SEE_MORE_REQUEST, page, pages }
}
export function seeMoreSuccess(payload: any): any {
  return { type: SEE_MORE_SUCCESS, payload }
}
export function seeMoreFailed(error: any): any {
  return { type: SEE_MORE_FAILURE, error }
}

// ///////////////////////////////////////////////////// ACTION CREATORS
export function loadLikeRequest({ commentId, caseId, page }: any): any {
  return { type: LOAD_LIKES_REQUEST, caseId, commentId, page }
}
export function loadLikeSuccess(payload: any, { caseId, commentId }: any): any {
  return { type: LOAD_LIKES_SUCCESS, payload, caseId, commentId }
}
export function loadLikeFailed(error: any): any {
  return { type: LOAD_LIKES_FAILURE, error }
}

// /////////////////////////////////////////////////////////////// RESET
export function resetLoading(): any {
  return { type: RESET_LOADING_STATE }
}

// //////////////////////////////////////////////////////////// API CALL
export function getLikes(caseId: string, commentId: string, page: number): any {
  return callAPI({
    url: `/api/cases/${caseId}/comments/${commentId}/likes?page=${page}`,
    method: 'GET',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: any): any => err)
}
