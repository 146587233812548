import cs from 'classnames'
import React from 'react'

import noop from '../../utils/noop'
import FormattedHTMLMessage from '../FormattedHTMLMessage'
import GroupIcon from '../icons/group'
import Button from './button'
import style from './styles/invite-colleagues.module.scss'

type Props = {
  inviteColleagues: (e: React.MouseEvent<Element>) => void
  className?: string
  multiline?: boolean
  flex?: boolean
  wide?: boolean
}

const InviteColleaguesButton = ({
  inviteColleagues,
  className,
  multiline,
  flex,
  wide,
  ...rest
}: Props): React.ReactElement => {
  if (flex) {
    return (
      <div className={style.flexContainer}>
        <span className={style.linkText} onClick={inviteColleagues}>
          <FormattedHTMLMessage id="group:group.invite_colleagues_button_label" />
        </span>
        <Button className={style.btn} onClick={inviteColleagues}>
          <GroupIcon className={style.icon} />
        </Button>
      </div>
    )
  }

  return (
    <Button
      {...rest}
      wide={wide}
      className={cs(className, { multiline: multiline })}
      onClick={inviteColleagues}
    >
      <FormattedHTMLMessage id="group:group.invite_colleagues_button_label" />
      <GroupIcon />
    </Button>
  )
}

export default InviteColleaguesButton
