import { request } from '../../../modules/api/actions'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const DELETE_REQUEST = 'entities/case_delete.REQUEST'
export const DELETE_SUCCESS = 'entities/case_delete.SUCCESS'
export const DELETE_FAILURE = 'entities/case_delete.FAILURE'

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default function deleteCase(case_id) {
  return request({
    url: `/api/profile/cases/${encodeURIComponent(case_id)}`,
    method: 'DELETE',
    id: 'DELETE /api/profile/cases/id',
    start: DELETE_REQUEST,
    success: {
      type: DELETE_SUCCESS,
      resource: { type: 'case', id: case_id },
      redirect: () => {
        return '/profile/cases'
      },
    },
    failure: DELETE_FAILURE,
  })
}
