import debounce from 'lodash/debounce'
import get from 'lodash/get'
import set from 'lodash/set'
import browserStorage from '../../lib/browserStorage'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////// STORAGE AVAILABLE
const storageAvailable = type => {
  if (!(type in global)) {
    return false
  }

  try {
    const storage = global[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

// //////////////////////////////////////////////////////////// GET STORED STATE
const getStoredState = (paths, config) => {
  let storedState = {}

  if (browserStorage.usable()) {
    for (let i = 0; i < paths.length; ++i) {
      const path = paths[i]
      const key = `${config.prefix}:${path}`
      const json = browserStorage.get(key)

      if (json) {
        set(storedState, path, json)
      }
    }
  }

  return storedState
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
const createStoreEnhancer = (paths, config = { prefix: 'sync' }) => {
  return createStore => {
    return (reducer, initialState, enhancer) => {
      const storedState = getStoredState(paths, config)
      const store = createStore(
        reducer,
        { ...initialState, ...storedState },
        enhancer
      )

      if (browserStorage.usable()) {
        store.subscribe(
          debounce(() => {
            const state = store.getState()
            for (let i = 0; i < paths.length; ++i) {
              const path = paths[i]
              const key = config.prefix + ':' + path
              browserStorage.set(key, get(state, path))
            }
          }, 500)
        )
      }

      return store
    }
  }
}

export default createStoreEnhancer
