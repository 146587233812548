import React from 'react'
import Icon from './icon'

const defaultProps = {
  width: 20,
  id: 'icon-blocked',
  title: 'Not available',
}

interface BlockedIconProps {
  width?: number
  id?: string
  title?: string
  className?: string
}

const BlockedIcon = ({
  width,
  id,
  title,
  className,
}: BlockedIconProps): React.ReactElement => {
  return (
    <Icon
      width={width ?? defaultProps.width}
      sourceWidth={20}
      sourceHeight={20}
      viewBox="-2 -2 24 24"
      title={title ?? defaultProps.title}
      id={id ?? defaultProps.id}
      className={className}
    >
      <circle cx="10" cy="10" r="10" />
      <path d="M3,17 L17,3" strokeLinecap="square" />
    </Icon>
  )
}

export default BlockedIcon
