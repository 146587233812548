import { map } from 'ramda'
import { schema as NormalizrSchema } from 'normalizr'
// ///////////////////////////////////////////////////////////////////// MODULES
import * as schemas from '../schemas'
import * as types from '../types'
// ////////////////////////////////////////////////////////////////////// ACTION
import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const SEARCH_REQUEST = 'entities/network-search.REQUEST'
const SEARCH_SUCCESS = 'entities/network-search.SUCCESS'
const SEARCH_FAILURE = 'entities/network-search.FAILURE'
const AREA_ALL_MEMBERS = 'users'
const AREA_CONNECTIONS = 'connections'
const AREA_GROUPS = 'groups'
const AREA_INSTITUTIONS = 'institutions'
const ALL_AREAS = [
  AREA_ALL_MEMBERS,
  AREA_CONNECTIONS,
  AREA_GROUPS,
  AREA_INSTITUTIONS,
]

function search(userID, areas, searchTerm) {
  const schema = new NormalizrSchema.Entity('network_search', undefined, {
    idAttribute: () => userID,
  })

  schema.define({
    results: new NormalizrSchema.Array(
      {
        [types.CASE]: schemas.caseSchema,
        [types.USER]: schemas.userSchema,
        [types.GROUP]: schemas.groupSchema,
        [types.INSTITUTION]: schemas.institutionSchema,
      },
      entry => {
        return entry.type
      }
    ),
  })

  const searchTerms = encodeURIComponent(searchTerm)

  if (areas.length === 1 && areas[0] === AREA_CONNECTIONS) {
    const url = `/api/profile/network/colleagues?q=${searchTerms}&per_page=100`

    return request({
      url: url,
      debounce: true,
      start: SEARCH_REQUEST,
      success: {
        type: SEARCH_SUCCESS,
        searchTerm,
        userID,
        areas,
        meta: {
          schema: schema,
        },
      },
      failure: SEARCH_FAILURE,
    })
  }

  const mapAreas = map(
    area => 'types[]=' + encodeURIComponent(area),
    areas
  ).join('&')
  const url = `/api/network/search?q=${searchTerms}&${mapAreas}&per_page=100`

  return request({
    url: url,
    debounce: true,
    id: 'network-search',
    start: SEARCH_REQUEST,
    success: {
      type: SEARCH_SUCCESS,
      searchTerm,
      userID,
      areas,
      meta: {
        schema: schema,
      },
    },
    failure: SEARCH_FAILURE,
  })
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export {
  search,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  AREA_ALL_MEMBERS,
  AREA_CONNECTIONS,
  AREA_GROUPS,
  AREA_INSTITUTIONS,
  ALL_AREAS,
}
