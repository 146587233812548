import { request } from '../../../modules/api/actions'

export const PATCH = 'entities/case_files_transcoded.PATCH'

export default function create(case_id, files) {
  return {
    type: PATCH,
    case_id,
    files,
  }
}
