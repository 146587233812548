import * as schemas from '../../entities/schemas'
import { request } from '../../api/actions'
import { link_for_resource } from '../../../url'

const SAVE_REQUEST = 'entities/case_save.REQUEST'
const SAVE_SUCCESS = 'entities/case_save.SUCCESS'
const SAVE_FAILURE = 'entities/case_save.FAILURE'

function saveCase(caseJson) {
  const caseSchema = schemas.caseSchema

  return request({
    url: '/api/profile/cases/store',
    method: 'POST',
    json: caseJson,
    start: SAVE_REQUEST,
    success: {
      type: SAVE_SUCCESS,
      meta: { schema: { response: caseSchema } },
      redirect: payload => {
        return link_for_resource('case', payload.response.id)
      },
      was_new: !caseJson.id,
      resource_id: caseJson.id,
    },
    failure: SAVE_FAILURE,
  })
}

export { saveCase as default, SAVE_REQUEST, SAVE_SUCCESS, SAVE_FAILURE }
