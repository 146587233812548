import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  children: React.ReactNode
  className?: string
  onClick?: Function
  disabled?: boolean
  underline?: boolean
  style?: any
  secret?: boolean
}

const TextButton = ({
  className,
  style,
  children,
  underline,
  ...rest
}: Props): React.ReactElement => (
  <a
    className={cs(className, {
      'fs-unmask': !rest.secret,
      'btn--disabled': rest.disabled,
      'btn--link--underline': underline,
    })}
    onClick={(e: any): any => onClick(e, rest)}
    style={style}
  >
    {children}
  </a>
)

function onClick(e: any, { onClick, disabled }: any): any {
  e.preventDefault()
  if (onClick && !disabled) {
    onClick(e)
  }

  return false
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TextButton
