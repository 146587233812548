import * as account_load from './actions/account_load'
import * as update_jwt from './actions/update_jwt'
import * as update_csrf from './actions/update_csrf'
import * as update_client_token from './actions/update_client_token'

export type AuthState = {
  authed: boolean
  fetching: boolean
  user_id?: string
}

const INITIALSTATE: AuthState = {
  authed: false,
  fetching: false,
}

const reducer = (state: any = INITIALSTATE, action: any): any => {
  switch (action.type) {
    case account_load.LOAD_REQUEST:
      return state

    case account_load.LOAD_FAILURE:
      return { ...state, authed: false }

    case account_load.LOAD_SUCCESS:
      const user_id = action.normalized.response.result
      return { ...state, authed: true, user_id }

    case update_jwt.UPDATE_JWT:
      return { ...state, jwt: action.jwt }

    case update_csrf.UPDATE_CSRF:
      return { ...state, csrf_token: action.csrf_token }

    case update_client_token.UPDATE_CLIENT_TOKEN:
      return { ...state, client_token: action.client_token }
  }

  return state
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default reducer
