// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////// ACTIONS
export const ADD_REPLY_REQUEST = 'replyComment/ADD_REPLY_REQUEST'
export const ADD_REPLY_SUCCESS = 'replyComment/ADD_REPLY_SUCCESS'
export const ADD_REPLY_FAILURE = 'replyComment/ADD_REPLY_FAILURE'

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////// ACTION CREATORS
export function addReplyRequest({
  caseId,
  commentId,
  text,
  is_dislike,
}: any): any {
  return { type: ADD_REPLY_REQUEST, caseId, commentId, text, is_dislike }
}
export function addReplySuccess(payload: any): any {
  return { type: ADD_REPLY_SUCCESS, payload }
}
export function addReplyFailed(error: any): any {
  return { type: ADD_REPLY_FAILURE, error }
}
