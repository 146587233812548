import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
}

const Pencil = ({
  id,
  title,
  className,
  onClick,
}: Props): React.ReactElement => (
  <Icon
    id={id || 'icon-pencil'}
    sourceWidth={17}
    sourceHeight={17}
    title={title ?? 'Edit'}
    className={className}
    onClick={onClick}
  >
    <g transform="translate(1.000000, 1.000000)" strokeWidth="1.1">
      <path
        d="M4.22703818,14.125652 L0,15.1824116 L1.05675955,10.9553734 L11.3601651,0.651967802 C11.8806192,0.131777915 12.6890402,-0.132411971 13.2094943,0.387777915 L14.7946336,1.97291723 C15.3150877,2.49337131 15.0508978,3.30152817 14.5304438,3.82224644 L4.22703818,14.125652 L4.22703818,14.125652 Z"
        strokeLinecap="round"
      />
      <path d="M13.2662539,5.08643633 L10.0959752,1.91615769" />
      <path d="M11.4736842,6.87900599 L8.30340557,3.70872735" />
      <path d="M4.22703818,14.125652 L1.05675955,10.9553734" />
    </g>
  </Icon>
)

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Pencil
