import React, { Component, createElement } from 'react'
import { connect } from 'react-redux'

// ///////////////////////////////////////////////////////////////////// ACTION
import { modalOpen, modalClose } from '../../../modules/modal/actions'

// ///////////////////////////////////////////////////////////////////// COMPONENT
import ReactModal from '../index'

interface ReactModalContainerProps {
  openModal: (modal: any) => void
  closeModal: () => void
  component: any
  componentProps?: any
  className?: string
  id?: string
  title?: string
  fullpage?: boolean
}

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

class ReactModalContainer extends Component<ReactModalContainerProps> {
  closeModal = () => {
    this.props.closeModal()
  }

  render(): React.ReactElement {
    const componentProps = {
      closeModal: this.closeModal,
      ...this.props.componentProps,
    }

    if (this.props.fullpage) {
      return (
        <ReactModal {...this.props} size="fullscreen">
          {createElement(this.props.component, componentProps)}
        </ReactModal>
      )
    }

    return (
      <ReactModal {...this.props}>
        {createElement(this.props.component, componentProps)}
      </ReactModal>
    )
  }
}

const mapStateToProps = ({ modal }: any): any => {
  return { modal }
}

const mapDispatchToProps = (dispatch: Function, ownProps: any): any => {
  return {
    openModal: (modal: any): any => dispatch(modalOpen(modal)),
    closeModal: (): any => dispatch(modalClose(ownProps.id)),
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default connect(mapStateToProps, mapDispatchToProps)(ReactModalContainer)
