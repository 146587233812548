import React from 'react'

// ////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
import { IconWithDefsType } from './icon'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////// VARIABLES
const iconState = {
  replied: { stroke: '#314559', fill: '#314559' },
  hover: { stroke: '#1B6EBF', fill: '#1B6EBF' },
  passive: { stroke: '#4B9BEF' },
}
const noop = () => {}
const fillWithColor =
  'M12.664891,6.92120577 L17.2626225,2.36134823 L5.26262251,2.36134823 L5.26262251,14.262498 L10.0062119,9.55798401 C10.9029158,10.1413911 13.1567049,11.8276534 14.240783,14.6325599 C15.2144373,17.1517596 13.3942059,21.7384278 13.3942059,21.7384278 C13.3942059,21.7384278 13.3293558,21.8766084 13.298348,21.9408733 C13.0514883,22.4525005 13.3942058,22.3536738 13.3942058,22.3536738 C13.3942058,22.3536738 17.428283,19.1443572 16.8351545,14.0501284 C16.3507982,9.89011666 13.642088,7.61555311 12.664891,6.92120577 Z'
const passive =
  'M14.2700913,20.7315023 C14.4278948,20.5364399 14.5831426,20.3303004 14.733931,20.1134377 C15.9609146,18.3487993 16.5975553,16.3328315 16.3385095,14.1079535 C15.9718273,10.9586142 14.3352766,8.72146625 12.3752811,7.32879142 L11.8909036,6.98461704 L16.0484201,2.86134823 L5.76262251,2.86134823 L5.76262251,13.0624163 L9.940642,8.91881335 L10.2788866,9.13887959 C12.1016041,10.3247629 13.7930982,12.0872912 14.7071617,14.4523076 C15.0191224,15.2594639 15.0918542,16.2655762 14.9751002,17.429805 C14.8884574,18.2937747 14.7008043,19.2101483 14.4424838,20.1394639 C14.3858256,20.343293 14.3273704,20.5419083 14.2679061,20.7342026 L14.2700913,20.7315023 Z'

interface ReplyIconProps {
  width?: number
  id?: string
  title?: string
  className?: string
  isMobile?: boolean
  replied?: boolean
  hover?: boolean
  onClick?: () => void
}

const ReplyIcon = ({
  className = '',
  hover = false,
  id = 'icon-reply',
  isMobile = false,
  onClick = noop,
  replied = false,
  title = 'Reply',
  width = 20,
}: ReplyIconProps): React.ReactElement => {
  const selectedState = replied
    ? 'replied'
    : hover && !isMobile
    ? 'hover'
    : 'passive'

  return (
    <Icon
      width={width}
      sourceWidth={20}
      sourceHeight={18}
      title={title}
      id={id}
      className={className}
      onClick={onClick}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g {...iconState[selectedState]}>
          <path
            d={replied || hover ? fillWithColor : passive}
            transform="translate(11.262623, 12.361348) rotate(-44.000000) translate(-11.262623, -12.361348)"
          />
        </g>
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default ReplyIcon
