import { delay } from 'redux-saga/effects'
import { put, fork, take, all, call } from 'redux-saga/effects'
import { chain, filter, forEach, identity, map, path } from 'ramda'
import getConfig from '@/config'
import { redirect } from '../routing/actions'
import callAPI from './call'
import * as actions from './actions'
import { updateFeatureFlags } from '../features/actions'
import { updateJWT } from '../auth/actions/update_jwt'
import { updateCSRF } from '../auth/actions/update_csrf'
import { updateClientToken } from '../auth/actions/update_client_token'
import {
  error as logError,
  jwt,
  tokens,
  trackClientEvents,
  installSessionScripts,
} from '../analytics/track'
import { redirectAuthCheck, url_for_resource } from '../../url'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

let fetchCount = 0
const config = () => getConfig().publicRuntimeConfig

function* gcRoutine() {
  yield delay(23000)
  yield put({ type: actions.API_GC })
}

function* fetchRoutine(action: any) {
  yield put({ type: actions.API_REQUEST, id: action.id })
  yield* map(
    a => put(a),
    filter(
      a => a && a.type,
      map(a => extractAction(a, action), chain(identity, [action.start]))
    )
  )

  try {
    const { payload, error, cancelled } = yield call(callAPI, action)

    if (payload) {
      if (payload.feature_flags) {
        yield put(updateFeatureFlags(payload.feature_flags))
      }

      if (payload.redirect) {
        yield put(
          redirect(
            typeof payload.redirect === 'object'
              ? url_for_resource(payload.redirect.type, payload.redirect.id)
              : payload.redirect
          )
        )
      }

      const success = filter(
        identity,
        chain(
          a => [
            a,
            a.redirect
              ? redirect(
                  typeof a.redirect === 'function'
                    ? a.redirect(payload)
                    : a.redirect
                )
              : undefined,
          ],
          filter(
            a => a && a.type,
            map(
              a => extractAction(a, { payload }),
              chain(identity, action.successes || [action.success])
            )
          )
        )
      )
      for (var i = 0; i < success.length; ++i) {
        yield put(success[i])
      }
      yield put({ type: actions.API_SUCCESS, id: action.id, payload })
    } else if (!cancelled) {
      yield* handleError(action, payload, error)
    }
  } catch (e) {
    console.error('api error', action, e)
    if (typeof e === 'string') {
      if (!ignoreErrorString(e)) {
        logError(e, { action })
      }
    } else if (e.error) {
      const payload = e.payload

      if (payload && payload.action && payload.action.type === 'auth') {
        if (
          process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT &&
          /authed=/.test(document.cookie)
        ) {
          redirectAuthCheck()
        }
      } else if (!e.notrack) {
        logError(e.error, { action, ex: e.payload || e })
      }
    } else if (!e.notrack) {
      logError(e.err ?? e, { action, e })
    }

    yield* handleError(action, e ? e.payload || {} : {}, e)
  }

  if (++fetchCount % 10 === 0) {
    yield put({ type: actions.API_GC_ASYNC })
  }
}

function* handleError(action: any, payload: any, error: any) {
  yield* map(
    (a: any): any => put(a),
    filter(
      (a: any): any => a && a.type,
      map(
        (a: any): any => extractAction(a, { payload, error }),
        chain(identity, [action.failure])
      )
    )
  )
  yield put({ type: actions.API_FAILURE, id: action.id, payload, error })
}

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////// FUNCTIONS
function ignoreErrorString(e: any): any {
  const userAgent: string = path(['navigator', 'userAgent'], global)
  return (
    userAgent &&
    / Chrome\//.test(userAgent) &&
    -1 < e.indexOf('Request has been terminated')
  )
}

function extractAction(action: any, payload: any): any {
  switch (typeof action) {
    case 'string':
      return { ...payload, type: action }

    case 'function':
      return action(payload)

    case 'object':
      return { ...payload, ...action }

    default:
      return action
  }
}

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////// WATCH
function* watchAPI() {
  while (true) {
    const action = yield take(actions.API_CALL)
    yield fork(fetchRoutine, action)
  }
}

function* watchCleanupStore() {
  while (true) {
    yield take(actions.API_GC_ASYNC)
    yield fork(gcRoutine)
  }
}

function* watchJWTRefresh() {
  if (typeof window !== 'undefined') {
    let currentJWT

    while (true) {
      const { payload } = yield take(actions.API_SUCCESS)
      if (payload) {
        if (payload.jwt && currentJWT !== payload.jwt) {
          currentJWT = payload.jwt
          jwt(currentJWT)
          yield put(updateJWT(currentJWT))

          const decodedJWT = JSON.parse(window.atob(currentJWT.split(/\./g)[1]))
          const t = decodedJWT.exp * 1000 - new Date().getTime() //- 5000
          if (t > 0) {
            if (config().debug) {
              console.log('Refreshing JWT in ' + Math.round(t / 1000) + 's')
            }
            yield delay(t)
          }
          yield put(actions.request({ url: '/oauth/check' }))
        }
      }
    }
  }
}

function* watchTokenRefresh() {
  if (typeof window !== 'undefined') {
    let currentCSRF, currentClientToken
    while (true) {
      const { payload } = yield take([actions.API_SUCCESS, actions.API_FAILURE])
      if (payload) {
        if (payload.session_scripts) {
          installSessionScripts(payload.session_scripts)
        }
        if (payload.csrf_token && currentCSRF !== payload.csrf_token) {
          currentCSRF = payload.csrf_token
          yield put(updateCSRF(currentCSRF))
        }
        if (
          payload.client_token &&
          currentClientToken !== payload.client_token
        ) {
          currentClientToken = payload.client_token
          yield put(updateClientToken(currentClientToken))
        }
        tokens({ csrf_token: currentCSRF, client_token: currentClientToken })
      }
    }
  }
}

function* watchClientEvents() {
  if (typeof window !== 'undefined') {
    while (true) {
      const { payload } = yield take(actions.API_SUCCESS)
      if (payload) {
        if (payload.client_events) {
          trackClientEvents(payload.client_events)
        }
      }
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////// ROOT SAGA
export default function* rootSaga() {
  return yield all([
    fork(watchAPI),
    fork(watchCleanupStore),
    fork(watchJWTRefresh),
    fork(watchTokenRefresh),
    fork(watchClientEvents),
  ])
}
