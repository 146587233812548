import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  width?: number
  id?: string
  title?: string
  className?: string
  isMobile?: boolean
  liked: boolean
  hover: any
  needReply?: boolean
  onClick?: Function
}

const iconState = {
  liked: { stroke: '#314559', fill: '#314559' },
  hover: { stroke: '#1B6EBF', fill: '#1B6EBF' },
  passive: { stroke: '#4B9BEF' },
}

const LikeIcon = ({
  width,
  title,
  id,
  className,
  liked,
  isMobile,
  hover,
  needReply,
  onClick,
}: Props): React.ReactElement => {
  const selectedState = liked
    ? 'liked'
    : hover && !isMobile
    ? 'hover'
    : needReply
    ? 'hover'
    : 'passive'

  return (
    <Icon
      width={width ?? 18}
      sourceWidth={18}
      sourceHeight={16}
      title={title ?? 'Like'}
      id={id ?? 'icon-like'}
      className={className}
      onClick={onClick}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g {...iconState[selectedState]} strokeWidth="0.8">
          <path d="M15.3763428,12.1504517 C15.3912048,12.6567078 14.8650731,13.1038838 14.2747036,13.1038838 L13.4268775,13.1038838 C14.017247,13.1038838 14.5738525,13.3696289 14.5573123,13.9610328 C14.5632629,14.4469299 14.2998557,14.8181818 13.7094862,14.8181818 L8.90513834,14.8181818 C7.27975508,14.8181818 6.5622634,14.5018645 5.07548523,14.1901093 C5.04326863,14.1833539 5,14.1400003 5,14.1400003 L5,7.6500001 C5,7.6500001 5.05863984,7.55336203 5.0854674,7.53741851 C6.73991968,6.55418364 8.62252964,4.0282067 8.62252964,1.76694504 C8.62252964,0.628079699 10.1825296,0.203790931 10.7604644,2.28494877 C11.116834,3.56810086 10.0355731,6.24669156 10.0355731,6.24669156 L15.687747,6.24669156 C16.362854,6.24669156 16.7810669,6.81777954 16.8001709,7.47076416 C16.8205298,8.0674216 16.454747,8.81813864 15.687747,8.81813864 L14.7983775,8.81813864 C15.0872033,8.81813864 15.4864652,8.90180753 15.6763611,9.06091309 C15.97995,9.3152771 16.071228,9.63876532 16.071228,10.0110474 C16.037262,10.5821228 15.5640329,11.1201477 14.9736633,11.1201477 L14.2747036,11.1038694 C14.8650731,11.1038694 15.4187927,11.4656677 15.3763428,12.1504517 Z" />
          <polygon points="0.819999993 15.0062386 0.819999993 7.00993155 3.81999999 7.01921693 3.81999999 15.0099316" />
        </g>
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default LikeIcon
