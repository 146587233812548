// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as types from './actions'

// ///////////////////////////////////////////////////////////////////// TYPES
import { TeamType } from './flowTypes'

const INITIALSTATE: TeamType = {
  error: '',
  isLoading: false,
  fetchingList: false,
  medshr: {},
  editors: {},
  partners: {},
  students: {},
  teamName: '',
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default function Reducer(
  state: TeamType = INITIALSTATE,
  { type, payload, teamName, error }: any
): TeamType {
  switch (type) {
    // GET INITIAL EMAIL TO STORE
    case types.GET_MEMBER_LIST_REQUEST:
      return { ...state, fetchingList: true, teamName }
    case types.GET_MEMBER_LIST_SUCCESS:
      return { ...state, fetchingList: false, [teamName]: payload }
    case types.GET_MEMBER_LIST_FAILURE:
      return { ...state, fetchingList: false, error }

    default:
      return state
  }
}
