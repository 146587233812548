import dynamic from 'next/dynamic'

import cAutoSizeTextAreaField from './AutoSizeTextAreaField'
export const AutoSizeTextAreaField = cAutoSizeTextAreaField
import cInputField from './InputField'
export const InputField = cInputField
import cSelectField from './SelectField'
export const SelectField = cSelectField
import cReactSelectField from './ReactSelectField'
export const ReactSelectField = cReactSelectField
import cInstitutionPicker from './InstitutionPickerField'
export const InstitutionPicker = cInstitutionPicker
import cCheckboxField from './CheckboxField'
export const CheckboxField = cCheckboxField
export const PhoneNumberField = dynamic(() => import('./PhoneNumberField'))
