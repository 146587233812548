import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
  color?: string
  width?: number
  style?: React.CSSProperties
}

const X = ({
  id,
  title,
  className,
  onClick,
  color,
  width,
  style,
}: Props): React.ReactElement => {
  return (
    <Icon
      id={id || 'icon-x'}
      sourceWidth={19}
      sourceHeight={20}
      width={width ?? 19}
      title={title ?? 'Close'}
      className={className}
      onClick={onClick}
      style={style}
    >
      <g strokeWidth="2" stroke={color || '#fff'}>
        <path d="M0.658249628,0.548688616 L18.3152437,18.2008231" />
        <path d="M18.3152437,0.548688616 L0.658249628,18.2008231" />
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default X
