import { path } from 'ramda'
import {
  put,
  call,
  fork,
  select,
  throttle,
  take,
  all,
} from 'redux-saga/effects'

// /////////////////////////////////////////////////////////////////// SELECTORS
import { selectAuthenticatedUser } from '../../modules/auth/selectors'
import { WIDGET_EDIT_MODE_ON } from '../components/promowidget/actions'
import { LOAD_SUCCESS } from '../../modules/auth/actions/account_load'
import { modalOpen } from '../../modules/modal/actions'
import { redirect } from '../../modules/routing/actions'
import VerifyModal from '../components/VerifyModal/VerifyModal'
// ////////////////////////////////////////////////////////////////////// HELPER
import browserStorage from '../../lib/browserStorage'
// ///////////////////////////////////////////////////////////////////////// API
import {
  sendConfirmationEmail,
  checkDomainTrusted,
  sendDocument,
  postPhoneNumber,
} from './api'
// ///////////////////////////////////////////////////////////////////// ACTIONS
import * as actions from './actions'
import * as userActions from '../../modules/entities/actions/entity_update'
import loadAccount from '../../modules/auth/actions/account_load'
import { hasEmail } from '../../modules/entities/user'
import { postNotification } from '../../modules/notify/actions'
// /////////////////////////////////////////////////////////////////////// TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const documentMaxFileSize = 10 * 1024 * 1024

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////// SAGAS

/**
 * Posting the email and getting the trusted or not response
 * @param email
 */
// todo delete this saga after refactor
function* checkTrustedEmail({ email }: any) {
  try {
    const { payload } = yield call(checkDomainTrusted, email)
    const { success, trusted, error } = payload

    // return Api error
    if (error) yield put(actions.checkTrustedEmailFailed(error))
    // update the state with the response
    if (success) yield put(actions.checkTrustedEmailSuccess(trusted))
  } catch (err) {
    throw err
  }
}

// //////////////////////////////////// CONFIRM EMAIL BY TYPE (pro_email, email)
function* postConfirmationEmail({ email, emailType }: any) {
  try {
    const { payload } = yield call(sendConfirmationEmail, email, emailType)
    const { success, trusted, error } = payload

    // return Api error
    if (error) {
      yield put(postNotification({ kind: 'error', message: error }))
      yield put(actions.postConfirmEmailFailed(error))
    }
    // update the state with the response
    if (success) {
      yield put({ type: actions.UPDATE_INITIAL_EMAIL, email })
      yield put(loadAccount())
      yield put(actions.postConfirmEmailSuccess(trusted))

      if (emailType === 'proemail') {
        const user = yield select((state: any): any =>
          selectAuthenticatedUser(state)
        )
        yield put(
          userActions.updateUser({ ...user, professional_email: email })
        )
      }

      // get the route pathname state
      const route = yield select((state: any): any => state.router.url)
      if (route.startsWith('/join/verification')) {
        yield put({ type: actions.EMAIL_SAVING })

        if (emailType === 'email') {
          const user = yield select((state: any): any =>
            selectAuthenticatedUser(state)
          )
          yield put(userActions.updateUser({ ...user, email }))
        }

        yield put(loadAccount())
        yield put(redirect('/join/continue'))
        yield put({ type: actions.EMAIL_SAVED })
      }
    }

    // get the widgetform state
    const widgetForm = yield select(
      (state: any): any => state.widget.widgetForm
    )

    // if the widgetForm edit mode on close it
    if (widgetForm) {
      yield put({ type: WIDGET_EDIT_MODE_ON })
    }
  } catch (err) {
    yield put(actions.postConfirmEmailFailed(err))
  }
}

/**
 * Post documents
 * @param file
 */
function* postDocument({ file }: any) {
  try {
    if (file) {
      //check the file size
      if (file.size > documentMaxFileSize) {
        yield put(
          actions.postDocumentFailed(
            new Error(`Max File Size is ${documentMaxFileSize / 1024}Mb`)
          )
        )
      } else {
        const { payload } = yield call(sendDocument, file)
        const { success, photo_url: photoUrl } = payload

        if (success) {
          yield put(actions.postDocumentSuccess())
          yield put({ type: actions.POST_DOCUMENT_SUCCESS, photoUrl })
        } else {
          yield put(actions.postDocumentFailed('Upload Error'))
        }

        // get the route pathname state
        const route = yield select((state: any): any => state.router.url)
        if (route.startsWith('/join/verification')) {
          yield put(loadAccount())
          yield put(redirect('/join/continue'))
        }
      }
    } else {
      yield put(actions.postDocumentFailed('No File Selected'))
    }
  } catch (err) {
    yield put(actions.postConfirmEmailFailed(err))
  }
}

/**
 * Select verification email initial value for redux-form
 */
function* installInitialEmail() {
  try {
    const user = yield select((state: any): any =>
      selectAuthenticatedUser(state)
    )
    let initialEmail = ''

    if (hasEmail(user)) {
      initialEmail = user.professional_email || user.email
    }

    yield put(actions.saveInitialEMail(initialEmail))
  } catch (err) {
    console.log(err)
  }
}

// ////////////////////////////////////////////////////////// CHECK PHONE NUMBER
function* verifyPhoneNumber({
  phone_number,
  country_code,
  verification_code,
  id,
}: any) {
  try {
    const { payload } = yield call(
      postPhoneNumber,
      { phone_number, verification_code },
      true
    )

    if (payload.error) {
      yield put(postNotification({ kind: 'error', message: payload.error }))
      yield put(
        actions.verifyCode.failure({
          code: 'invalid-code',
          message: payload.error,
        })
      )
    } else if (payload.success) {
      yield put(loadAccount())
      yield put(actions.verifyCode.success())
      yield put(redirect('/join/continue'))
      browserStorage.remove(id)
    }
  } catch (err) {
    yield put(actions.verifyCode.failure(err))
    yield put(
      postNotification({
        kind: 'error',
        message: `something went wrong ${err}`,
      })
    )
  }
}

// /////////////////////////////////////////////////////////// SAVE PHONE NUMBER
function* savePhoneNumber({
  phone_number,
  country_code,
  countryCodeFull,
  id,
  method,
}: any) {
  try {
    let phoneResult = phoneNumberFixer(phone_number, country_code)
    // Post the phone number
    const {
      payload: { success, code_length, error, via },
    } = yield call(postPhoneNumber, { phone_number: phoneResult }, false)

    // Post response
    if (error || !success) {
      yield put(postNotification({ kind: 'error', message: `${error}` }))
      yield put(actions.savePhone.failure(error))
    } else if (success) {
      // Save the phone detail to LocalStorage
      if (browserStorage.usable()) {
        browserStorage.set(id, {
          phone_number: phone_number,
          country_code: countryCodeFull,
          phoneResult,
          via,
        })
      }

      if (method === 'verification') {
        yield put(
          postNotification({
            kind: 'info',
            message: `New code has been resent`,
          })
        )
      } else {
        yield put(redirect('/join/verification/coderequest'))
      }

      yield put(
        postNotification({ kind: 'info', message: `Code has been sent` })
      )
      yield put(actions.savePhone.success({ code_length, via }))
    }
  } catch (err) {
    yield put(actions.savePhone.failure(err))
    yield put(
      postNotification({
        kind: 'error',
        message: `Something went wrong ${err}`,
      })
    )
  }
}

// //////////////////////////////////////////////////////////// HELPER FUNCTIONS
export function phoneNumberFixer(
  phone_number: string,
  country_code?: string
): string {
  // phone_number is full with + (international)
  if (
    !country_code ||
    phone_number.includes(country_code) ||
    phone_number.includes('+')
  ) {
    return phone_number.split(' ').join('')
  } else {
    const clearSpaces = phone_number.split(' ').join('')
    // phone_number is national
    return `${country_code}${clearSpaces}`
  }
}

// export function computePhoneException(phone, code) {
//   let result = [...phone];
//   if (phone.includes('+')) {
//     return result.slice(code.length, result.length).join('');
//   }
//
//   return phone;
// }

// /////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////// WATCHERS
function* watchCheckTrustedEmail() {
  while (true) {
    const email = yield take(actions.CHECK_TRUSTED_EMAIL_REQUEST)
    yield fork(checkTrustedEmail, email)
  }
}

function* watchConfirmationEmail() {
  yield throttle(600, actions.POST_CONFIRMATION_EMAIL, postConfirmationEmail)
}

function* watchPostDocument() {
  // yield takeEvery(actions.POST_DOCUMENT, postDocument);
  while (true) {
    const file = yield take(actions.POST_DOCUMENT)
    yield fork(postDocument, file)
  }
}

function* watchInitialEmail() {
  // yield takeEvery(actions.GET_INITIAL_EMAIL, installInitialEmail);
  while (true) {
    yield take(actions.GET_INITIAL_EMAIL)
    yield fork(installInitialEmail)
  }
}

function* watchCheckPhoneNumber() {
  while (true) {
    const payload = yield take(actions.VERIFY_CODE.REQUEST)
    yield fork(verifyPhoneNumber, payload)
  }
}

function* watchSavePhoneNumber() {
  while (true) {
    const payload = yield take(actions.SAVE_PHONE.REQUEST)
    yield fork(savePhoneNumber, payload)
  }
}

let done = false

function* authSuccess(action) {
  if (
    !done &&
    'verified' !== path(['payload', 'response', 'verification_status'], action)
  ) {
    const email =
      path(['payload', 'response', 'professional_email'], action) ||
      path(['payload', 'response', 'email'], action)
    yield fork(installInitialEmail)
    yield fork(checkTrustedEmail, { email })

    const queryString = path(['location', 'search'], global)
    if (queryString && /prompt=verification/.test(queryString)) {
      yield put(modalOpen({ component: VerifyModal }))
    }
  }
  done = true
}

function* watchAuthSuccess() {
  while (true) {
    const action = yield take(LOAD_SUCCESS)
    yield fork(authSuccess, action)
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function* rootSaga() {
  return yield all([
    fork(watchAuthSuccess),
    fork(watchCheckTrustedEmail),
    fork(watchConfirmationEmail),
    fork(watchPostDocument),
    fork(watchInitialEmail),
    fork(watchSavePhoneNumber),
    fork(watchCheckPhoneNumber),
  ])
}
