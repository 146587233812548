import callAPI from '../../modules/api/call'

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT

/**
 * Return the trusted email true or false
 * @param email
 */
export function checkDomainTrusted(email: string): any {
  return callAPI({
    url: '/api/trusted-domains/validate',
    method: 'POST',
    json: {
      email: email,
    },
  }).catch((err: any): any => err)
}

// ///////////////////////////////////////////////////// SEND CONFIRMATION EMAIL
export function sendConfirmationEmail(email: string, type: string): any {
  const URL =
    type === 'proemail'
      ? '/api/profile/resend-pro-email'
      : '/api/profile/update-email'

  return callAPI({
    url: URL,
    method: 'POST',
    json: {
      email: email,
    },
  }).catch((err: any): any => err)
}

// /////////////////////////////////////////////////////////////// SEND DOCUMENT
export function sendDocument(file: any): any {
  const data = new FormData()
  data.append('photo', file)

  return callAPI({
    url: '/api/profile/verification-id',
    method: 'POST',
    data: data,
  }).catch((err: any): any => err)
}

// /////////////////////////////////////////////////////////// POST PHONE NUMBER
export function postPhoneNumber(dataObj: any, verify: boolean = false): any {
  const option = verify ? `/verify` : ''

  return callAPI({
    url: `/api/profile/phone${option}`,
    method: 'POST',
    json: dataObj,
  }).catch((err: any): any => err)
}
