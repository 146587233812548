import { useTranslation } from 'next-i18next'
import Router from 'next/router'
import React from 'react'

// /////////////////////////////////////////////////////////////////// SELECTORS
import { isRequestActive } from '../../modules/api/selectors'
import {
  checkGroupMembership,
  selectAuthenticatedUser,
} from '../../modules/auth/selectors'
import connectTo from '../../modules/entities/actions/connect'
import {
  userHasGroupInvite,
  userHasRequestedGroupMembership,
} from '../../modules/entities/selectors'
import { postNotification } from '../../modules/notify/actions'
import { redirectAs } from '../../modules/routing/actions'
import { useDispatch, useSelector } from '../../react-redux'
import FormattedHTMLMessage from '../../ui/FormattedHTMLMessage'
import { link_for_resource } from '../../url'
import Button from './button'
import InviteColleaguesButton from './invite-colleagues'
import JoinGroupButton from './join-group'

const SmartJoinButton: React.FC<{
  group: any
  resource?: any
  showLeave?: boolean
  fill?: boolean
  dark?: boolean
  wide?: boolean
  brief?: boolean
  flex?: boolean
  multiline?: boolean
  old?: boolean
  className?: string
}> = ({
  group,
  resource,
  showLeave,
  fill,
  dark,
  wide,
  brief,
  flex,
  multiline,
  old,
  className,
}) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectAuthenticatedUser)
  const is_member = useSelector(state => checkGroupMembership(state, group))
  const is_requesting = useSelector(state =>
    isRequestActive(state, connectTo(group.type, group.id, !is_member))
  )
  const is_requested = useSelector(
    state =>
      !is_member && userHasRequestedGroupMembership(state, currentUser, group)
  )
  const is_invited = useSelector(
    state => !is_member && userHasGroupInvite(state, currentUser, group)
  )

  const { t } = useTranslation('common')

  const can_join_group =
    currentUser?.can_join_group && resource && resource.can_join
  const can_invite =
    currentUser?.can_invite &&
    resource &&
    (resource.target.type === 'institution' ? true : resource.can_invite)
  const userVerified = currentUser?.verification_status === 'verified'

  const connect = (resource_type: any, resource_id: any): any => {
    dispatch(connectTo(resource_type, resource_id, true))
  }
  const disconnect = (resource_type: any, resource_id: any): any => {
    dispatch(connectTo(resource_type, resource_id, false))
  }
  const inviteColleagues = (target: any): any => {
    dispatch(
      redirectAs(
        link_for_resource(target.type, target.id, 'invite', Router.query)
      )
    )
  }
  const postVerificationNotification = (message: string): any => {
    dispatch(postNotification({ kind: 'info', message, closeBtn: false }))
  }

  const toggleJoin = (): any => {
    if (is_member) {
      disconnect(group.type, group.id)
    } else {
      connect(group.type, group.id)

      if (!userVerified) {
        postVerificationNotification(
          t('notification.unverified_users.follow_case')
        )
      }
    }
  }

  const buttonProps = {
    group,
    resource,
    showLeave,
    fill,
    dark,
    wide,
    brief,
    flex,
    multiline,
    old,
    className,
    is_member,
    is_invited,
    is_requested,
    is_requesting,
  }

  if (is_member && !showLeave && can_invite) {
    if (userVerified) {
      return (
        <InviteColleaguesButton
          {...buttonProps}
          inviteColleagues={e => inviteColleagues(group)}
        />
      )
    } else {
      return (
        <Button {...buttonProps} disabled>
          <FormattedHTMLMessage id={'join.request_pending'} />
        </Button>
      )
    }
  } else if (!is_member) {
    if (can_join_group || is_invited) {
      return (
        <JoinGroupButton
          {...buttonProps}
          old={old}
          access_type={group.access}
          toggleJoin={toggleJoin}
          fill={fill || is_member || is_requested}
          dark={is_member}
        />
      )
    } else if (fill && resource.restricted_reason) {
      return <span>{resource.restricted_reason}</span>
    }
  }

  return null
}

export default SmartJoinButton
