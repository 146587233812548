import callAPI from '../../../modules/api/call'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const UPDATE_DISLIKE_REQUEST = 'UPDATE_DISLIKE_REQUEST'
export const UPDATE_DISLIKE_SUCCESS = 'UPDATE_DISLIKE_SUCCESS'
export const UPDATE_DISLIKE_FAILURE = 'UPDATE_DISLIKE_FAILURE'

export const OPTIMISTIC_UPDATE_DISLIKE = 'OPTIMISTIC_UPDATE_DISLIKE'

export const BLOCK_DISLIKE_REQUEST = 'BLOCK_DISLIKE_REQUEST'
export const UNBLOCK_DISLIKE_REQUEST = 'UNBLOCK_DISLIKE_REQUEST'

// /////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////// ACTION CREATORS

// /////////////////////////////////////////////////////// BLOCK DISLIKE REQUEST
export function blockDisLikeRequest(): any {
  return { type: BLOCK_DISLIKE_REQUEST }
}
export function unBlockDisLikeRequest(): any {
  return { type: UNBLOCK_DISLIKE_REQUEST }
}

// ///////////////////////////////////////////////////////////////////// DISLIKE
export function updateDisLikeRequest(caseId: string, commentId: string): any {
  return { type: UPDATE_DISLIKE_REQUEST, caseId, commentId }
}
export function updateDisLikeSuccess(newState: any): any {
  return { type: UPDATE_DISLIKE_SUCCESS, newState }
}
export function updateDisLikeFailed(error: string): any {
  return { type: UPDATE_DISLIKE_FAILURE, error }
}

// ////////////////////////////////////////////////////////// OPTIMISTIC DISLIKE
export function optimisticUpdate(newState: any): any {
  return { type: OPTIMISTIC_UPDATE_DISLIKE, newState }
}

// //////////////////////////////////////////////////////////// DISLIKE API CALL
export function updateDisLikeById(
  caseId: string,
  commentId: string
): Promise<any> {
  return callAPI({
    url: `/api/cases/${caseId}/comments/${commentId}/dislikes`,
    method: 'POST',
  })
    .then(({ payload }: any): any => payload)
    .catch((err: Error): any => err)
}
