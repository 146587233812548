import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
  style?: Record<string, string | number>
  width?: number
}

const Stethoscope = ({
  id,
  title = '',
  className,
  onClick,
  style,
  width,
}: Props): React.ReactElement => {
  return (
    <Icon
      id={id || 'icon-stethoscope'}
      sourceWidth={11}
      sourceHeight={12}
      title={title}
      className={className}
      onClick={onClick}
      style={style}
      width={width}
    >
      <g strokeWidth="0">
        <path d="M10,4.5 C10,4.7734375 9.7734375,5 9.5,5 C9.2265625,5 9,4.7734375 9,4.5 C9,4.2265625 9.2265625,4 9.5,4 C9.7734375,4 10,4.2265625 10,4.5 L10,4.5 Z M11,4.5 C11,3.671875 10.328125,3 9.5,3 C8.671875,3 8,3.671875 8,4.5 C8,5.15625 8.4140625,5.703125 9,5.9140625 L9,9 C9,10.1015625 7.875,11 6.5,11 C5.125,11 4,10.1015625 4,9 L4,7.96875 C5.6953125,7.7578125 7,6.5078125 7,5 L7,1 C7,0.7265625 6.7734375,0.5 6.5,0.5 C6.453125,0.5 6.4140625,0.5078125 6.375,0.515625 C6.203125,0.2109375 5.875,0 5.5,0 C4.9453125,0 4.5,0.4453125 4.5,1 C4.5,1.5546875 4.9453125,2 5.5,2 C5.6796875,2 5.8515625,1.9453125 6,1.859375 L6,5 C6,6.1015625 4.875,7 3.5,7 C2.125,7 1,6.1015625 1,5 L1,1.859375 C1.1484375,1.9453125 1.3203125,2 1.5,2 C2.0546875,2 2.5,1.5546875 2.5,1 C2.5,0.4453125 2.0546875,0 1.5,0 C1.125,0 0.796875,0.2109375 0.625,0.515625 C0.5859375,0.5078125 0.546875,0.5 0.5,0.5 C0.2265625,0.5 0,0.7265625 0,1 L0,5 C0,6.5078125 1.3046875,7.7578125 3,7.96875 L3,9 C3,10.65625 4.5703125,12 6.5,12 C8.4296875,12 10,10.65625 10,9 L10,5.9140625 C10.5859375,5.703125 11,5.15625 11,4.5 L11,4.5 Z" />
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Stethoscope
