export const UPDATE_TERM = 'search/global.UPDATE_TERM'
export const UPDATE_FILTER = 'search/global.UPDATE_FILTER'

export const RECENT_SAVE = 'search/global.RECENT_SAVE'
export const RECENT_CLEAR = 'search/global.RECENT_CLEAR'

export function updateTerm(term) {
  return { type: UPDATE_TERM, term }
}

export function updateFilter(filter) {
  return { type: UPDATE_FILTER, filter }
}

export function saveRecentSearch(searchType, term) {
  return { type: RECENT_SAVE, term, searchType }
}

export function clearRecentSearches(searchType) {
  return { type: RECENT_CLEAR, searchType }
}
