import getConfig from '@/config'
import { normalize, schema as NormalizrSchema } from 'normalizr'
import { path } from 'ramda'
import update from 'react-addons-update'
import { applyMiddleware, compose, createStore } from 'redux'
import { responsiveStoreEnhancer } from 'redux-responsive'
import createSagaMiddleware from 'redux-saga'
import { createAnalyticsMiddleware } from './modules/analytics/middleware'

import loadAccount from './modules/auth/actions/account_load'
import { initializeCategoriesStore } from './modules/categories/initializer'
import { createRealtimeMiddleware } from './modules/realtime/middleware'
import * as socket from './modules/realtime/socket'
import { createRoutingMiddleware } from './modules/routing/middleware'
// ///////////////////////////////////////////////////////////////////// MODULES
import syncStoreEnhancer from './modules/sync/createStoreEnhancer'

// ///////////////////////////////////////////////////////////// SAGAS & REDUCER
import reducer, { RootState } from './rootReducer'
import sagas from './rootSaga'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const config = getConfig().publicRuntimeConfig

const sagaMiddleware = createSagaMiddleware()
const middlewares: Array<any> = [normalizrMiddleware, sagaMiddleware]

//if (typeof global['window'] === 'object') {
middlewares.push(createRealtimeMiddleware())
middlewares.push(createRoutingMiddleware())
middlewares.push(createAnalyticsMiddleware())
//}

const INITIAL_STATE = {}

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////// STORE
export const initializeStore = (initialState?: RootState) => {
  const notProd = process.env.NODE_ENV !== 'production'
  const queryString: string = path(['document', 'location', 'search'], global)
  const wantDevTools = notProd || (queryString && /redux=1/.test(queryString))
  const composeEnhancers =
    global['window'] && wantDevTools
      ? global['window']['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose
      : compose

  const store = createStore(
    reducer(),
    initialState === undefined ? INITIAL_STATE : initialState,
    composeEnhancers(
      responsiveStoreEnhancer,
      syncStoreEnhancer(['search.recentSearches']),
      applyMiddleware.apply(this, middlewares)
    )
  )

  sagaMiddleware.run(sagas)
  // store.close = (): any => store.dispatch(END);

  initializeCategoriesStore(store)

  if (typeof window !== 'undefined') {
    if (typeof document !== 'undefined') {
      if (
        /authed=/.test(document.cookie) ||
        /^https?:\/\/localhost[^:\/]*:/.test(document.location.href)
      ) {
        store.dispatch(loadAccount())
      }
    }
  }

  return store
}

/**
 * Normalizer Middleware
 * @returns {function(*): function(*=)}
 */
function normalizrMiddleware() {
  return next => action => {
    const schema = action.meta?.schema
    const patch = action.meta?.patch

    if (patch) {
      action = { ...action, payload: update(action.payload, patch) }
    }

    if (schema && action.payload) {
      if (schema instanceof NormalizrSchema.Entity) {
        const normalized = normalize(action.payload, schema)
        action = { ...action, normalized, schemaKeys: [schema.key] }
      } else {
        let normalized = {}
        const schemaKeys = []
        for (let key in schema) {
          if (action.payload[key]) {
            normalized[key] = normalize(action.payload[key], schema[key])
            schemaKeys.push(schema[key].key)
          }
        }
        action = { ...action, normalized, schemaKeys }
      }
    }

    return next(action)
  }
}
