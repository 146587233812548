import React from 'react'
import cs from 'classnames'

const style = {
  divider: 'divider',
  left: 'divider--left',
  right: 'divider--right',
  top: 'divider--top',
  bottom: 'divider--bottom',
  vertical: 'divider--vertical',
  horizontal: 'divider--horizontal',
}

type PropsType = {
  children?: React.ReactNode
  text?: string | boolean
  left?: boolean
  right?: boolean
  vertical?: boolean
  bottom?: boolean
  textBgInline?: any
}

interface DividerProps {
  children?: React.ReactNode
  text?: string | boolean
  left?: boolean
  right?: boolean
  vertical?: boolean
  bottom?: boolean
  textBgInline?: {}
}

const Divider = ({
  left,
  right,
  bottom,
  vertical,
  children,
  textBgInline,
  text,
}: DividerProps): React.ReactElement => {
  const classes = cs(style.divider, {
    [style.left]: left,
    [style.right]: right,
    [style.top]: !bottom,
    [style.bottom]: bottom,
    [style.vertical]: vertical,
    [style.horizontal]: !vertical,
  })

  return (
    <div className={classes}>
      {(text || children) && (
        <span style={textBgInline}>{text ? text : children && children}</span>
      )}
    </div>
  )
}

export default Divider
