import React from 'react'
import cs from 'classnames'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Error from './Error'
import { Input } from './base'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
import { Meta } from './AutoSizeTextAreaField'
// /////////////////////////////////////////////////////////////////////// STYLE
import style from './styles/InputField.module.scss'

// ////////////////////////////////////////////////////////////////////// IMPORT
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  input: any
  inlineWrapper?: any
  meta: Meta
  type: string
  placeholder: string
  name?: string
  className?: string
  label?: string
  theme: 'dark' | 'light'
  withError: boolean
  withLimitContent?: boolean
  disabled?: boolean
  textCount?: number
  maxLength?: number
}

const InputField = ({
  input: { value, ...restInput },
  meta: { touched, error, warning, ...restMeta },
  placeholder,
  type,
  label,
  withError,
  withLimitContent,
  textCount,
  inlineWrapper,
  theme,
  ...rest
}: PropsType): React.ReactElement => {
  const labelText = (
    <label htmlFor={label || placeholder}>{label || placeholder}</label>
  )
  const reachLimit = textCount >= rest.maxLength

  return (
    <div className={style.container} style={inlineWrapper}>
      {label ? labelText : null}
      <Input
        className={style.input}
        id={label || placeholder}
        value={value}
        placeholder={placeholder}
        type={type}
        {...restInput}
        {...restMeta}
        {...rest}
      />
      {withLimitContent && !rest.disabled ? (
        <div className={style.counterCont}>
          <span
            className={cs(style.counterText, style[theme], {
              [style.reachLimit]: reachLimit,
            })}
          >
            {textCount || value.length} / {rest.maxLength}
          </span>
        </div>
      ) : null}

      {withError &&
        touched &&
        ((error && (
          <Error customClass={style.textError} message={error} hasError />
        )) ||
          (warning && (
            <Error customClass={style.textError} message={error} hasWarning />
          )))}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default InputField
