// ////////////////////////////////////////////////////////////////////// ACTION
import { request } from '../../api/actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// //////////////////////////////////////////////////////////////// ACTION TYPES
export const COMMENT_SUCCESS: string = 'entities/comment.SUCCESS'

export const UPDATE_COMMENT_REPLY: string =
  'entities/comment.UPDATE_COMMENT_REPLY'

// ////////////////////////////////////////////////////////////// ACTION CREATOR
export function updateCommentReply(newState: any): any {
  return { type: UPDATE_COMMENT_REPLY, newState }
}

export function postCommentIdentifier(entry: any): string {
  const resource = entry.target
  return `POST /api/cases/${resource.id}/comments`
}

// //////////////////////////////////////////////////////////////// POST COMMENT
export function postComment(entry: any, text: string): any {
  const resource = entry.target
  let json: any = { body: text }

  if (entry.comments && entry.comments.length) {
    json.updated_since = entry.comments[entry.comments.length - 1].created_at
  }

  return request({
    url: `/api/cases/${resource.id}/comments`,
    method: 'POST',
    json: json,
    id: postCommentIdentifier(entry),
    success: {
      type: COMMENT_SUCCESS,
      resource: { type: resource.type, id: resource.id },
    },
  })
}
