import { TFunction, withTranslation } from 'next-i18next'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

// ///////////////////////////////////////////////////////////////////// ACTIONS
import { modalClose } from '../../../modules/modal/actions'
import { saveInitialEMail } from '../../../routes/Verification/actions'
import loadAccount from '../../../modules/auth/actions/account_load'
import { initDefaultLoaded } from '../../Verification/actions'

// /////////////////////////////////////////////////////////////////// SELECTORS
import { hasEmail, hasProEmail } from '../../../modules/entities/user'
import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { selectIsMobile } from '../../../modules/responsive/selectors'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import MedshrModal from '../../../ui/Modal/MedshrModal'
import { Button } from '../../../ui/buttons'
import { Divider } from '../../../ui/Divider/index'
import UploadVerifyImg from '../../Verification/components/UploadVerifyImg'
import VerifyForm from '../../Verification/components/VerifyForm'

type VerifyModalType = {
  t: TFunction
  user: any
  modalType: string
  modalID: string
  isConfirmationSent: boolean
  isDocumentLoaded: boolean
  isMobile: boolean
  isTrusted: boolean
  onCloseModal: Function
  loadAccount: Function
  initDefaultLoaded: Function
  emailState: string
  saveBackInitialEmail: (email: string) => void
  proEmail?: string
}
type StateProps = {
  localInitialEmail: any
  modalType: string
  newSession: boolean
  userProEmail?: string
  isTrusted: boolean
}

const footerHref = '/pages/verification'

class VerifyModal extends Component<
  VerifyModalType,
  {
    localInitialEmail?: string
    modalType?: string
    newSession?: boolean
    userProEmail?: string
    isTrusted?: boolean
  }
> {
  constructor(props: any) {
    super(props)

    this.state = {
      localInitialEmail: this.props.emailState,
      modalType: props.modalType,
      newSession: true,
      userProEmail: props.user.professional_email,
      isTrusted: props.isTrusted,
    }
  }

  componentDidMount() {
    this.props.initDefaultLoaded()
  }

  /**
   * Check the modalType and if it change rerender the new one
   * @param nextProps
   */
  componentDidUpdate(prevProps: VerifyModalType): any {
    const nextProps = this.props
    const prevModalType = prevProps.modalType
    const nextModalType = nextProps.modalType

    if (prevModalType !== nextModalType) {
      this.setState({ ...this.state, modalType: nextModalType })
    }

    // update local state if the initial email is change and the local state is empty
    if (
      prevProps.emailState !== nextProps.emailState &&
      !this.state.localInitialEmail
    ) {
      this.setState({ localInitialEmail: prevProps.emailState })
    }

    const preDocLoading = prevProps.isDocumentLoaded
    const nextDocLoading = nextProps.isDocumentLoaded
    if (
      (nextDocLoading && preDocLoading !== nextDocLoading) ||
      prevProps.isConfirmationSent !== nextProps.isConfirmationSent
    ) {
      this.setState({ newSession: false })
    }

    if (hasEmail(nextProps.user) || hasProEmail(nextProps.user)) {
      const preUserProEmail = prevProps.user.professional_email
      const nextUserProEmail = nextProps.user.professional_email
      const preEmail = prevProps.user.email
      const nextEmail = nextProps.user.email

      if (preUserProEmail !== nextUserProEmail) {
        this.setState({
          userProEmail: nextProps.user.professional_email,
        })
      }
      if (preEmail !== nextEmail) {
        this.forceUpdate()
      }
    }

    const preTrust = prevProps.isTrusted
    const nextTrust = nextProps.isTrusted

    if (preTrust !== nextTrust) {
      this.setState({ isTrusted: nextTrust })
    }

    if (this.props.modalType !== prevProps.modalType) {
      this.forceUpdate()
    }
  }

  /**
   * Close the modal and load me if document or email updated changed
   */
  closeModalHandler = (): any => {
    const { modalID, isConfirmationSent, isDocumentLoaded, emailState } =
      this.props

    if (isConfirmationSent || isDocumentLoaded) {
      this.props.loadAccount() // load me to update the state
    }

    if (!emailState) {
      this.props.saveBackInitialEmail(this.state.localInitialEmail)
    }

    this.setState({ newSession: false })
    this.props.onCloseModal(modalID) // close the modal
  }

  // /////////////////////////////////////////////////// GETTING MODAL TYPE FROM
  buildModalObj = (): any => {
    const { user, t, proEmail } = this.props
    const { modalType, userProEmail } = this.state
    const emailExist = hasEmail(user)
    const userHasPhone = user.confirmed_phone && !emailExist
    const userKind = (): any => {
      const kind = user.kind
      if (kind === 'student') return `student_${user.seniority}`
      return kind
    }
    const documentRejectedMessageByUserKind = (): string => {
      switch (userKind()) {
        case 'doctor':
          return t('document_rejected.modal.message.doctor')
        case 'dentist':
          return t('document_rejected.modal.message.dentist')
        case 'nurse':
          return t('document_rejected.modal.message.nurse')
        case `student_clinical`:
        case 'student_pre_clinical':
          return t('document_rejected.modal.message.student_clinical')
        case 'student_dental':
          return t('document_rejected.modal.message.student_dental')

        // Default is healthcare professional
        default:
          return t('document_rejected.modal.message.hcp')
      }
    }
    const untrustedConfirmedMessageByUserKind = (): string => {
      switch (userKind()) {
        case 'doctor':
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.doctor'
          )
        case 'dentist':
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.dentist'
          )
        case 'nurse':
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.nurse'
          )
        case 'student_clinical':
        case 'student_pre_clinical':
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.student_clinical'
          )
        case 'student_dental':
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.student_dental'
          )

        // Default is health care professional
        default:
          return t(
            'untrusted_confirmed_email_upload_document.modal.message.hcp'
          )
      }
    }
    const unconfimedDocumentPendingMessageByUserKind = (): string => {
      switch (userKind()) {
        case 'doctor':
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.doctor'
          )
        case 'dentist':
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.dentist'
          )
        case 'nurse':
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.nurse'
          )
        case 'student_clinical':
        case 'student_pre_clinical':
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.student_clinical'
          )
        case 'student_dental':
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.student_dental'
          )

        // Default is healthcare professional
        default:
          return t(
            'untrusted_confirmed_email_document_pending.modal.message.hcp'
          )
      }
    }
    const addEmailByUserKind = (): string => {
      switch (userKind()) {
        case 'student_clinical':
        case 'student_dental':
        case 'student_pre_clinical':
          return 'untrusted_confirmed_email_upload_document.modal.verify_form_text.title.add_academic'

        default:
          return 'untrusted_confirmed_email_upload_document.modal.verify_form_text.title.add_institutional'
      }
    }
    const hasPhoneUserKind = (): string => {
      let result = ''

      switch (userKind()) {
        case 'doctor':
          result = 'userKind.doctor'
          break
        case 'dentist':
          result = 'userKind.dentist'
          break
        case 'nurse':
          result = 'userKind.nurse'
          break
        case 'student_clinical':
        case 'student_pre_clinical':
          result = 'userKind.student'
          break
        case 'student_dental':
          result = 'userKind.student_dental'
          break

        // Default is health care professional
        default:
          result = 'userKind.HCP'
          break
      }

      return t(result)
    }
    const emailType =
      user.kind === 'student'
        ? t('email_type.academic')
        : t('email_type.institutional')
    let result = {}

    switch (modalType) {
      // /////////////////////////////////////////////////////////////// 1-2-3-4
      case 'trusted_email_confirm':
      case 'verified_pending_email_confirmation':
        result = {
          title: t('common:verification_modal.title'),
          inltMessage: {
            text: t('common:verification_modal.message'),
            email: userProEmail || proEmail || (emailExist && user.email),
          },
          footerText: {
            text: t('common:verification_modal.disclaimer'),
            link: t('common:verification_modal.disclaimer_more'),
            href: footerHref,
          },
          verifyForm: {
            text: 'common:verify_form_text.title_pro_email',
            subText: t('common:verify_form_text.subtitle_pro_email'),
          },
        }
        break

      // //////////////////////////////////////////////////////////////// 5-6-12
      case 'untrusted_unconfirmed_email_confirm':
        result = {
          title: t('untrusted_unconfirmed_email_confirm.modal.title'),
          inltMessage: {
            text: t(
              `untrusted_unconfirmed_email_confirm${
                userHasPhone ? '_nofb' : ''
              }.modal.message`,
              { emailType: emailType, userKind: hasPhoneUserKind() }
            ),
            email: userProEmail
              ? user.professional_email
              : userHasPhone
              ? ''
              : user.email,
          },
          footerText: {
            text: t('untrusted_unconfirmed_email_confirm.modal.disclaimer'),
            link: t(
              'untrusted_unconfirmed_email_confirm.modal.disclaimer_more'
            ),
            href: footerHref,
          },
          verifyForm: {
            text: `untrusted_unconfirmed_email_confirm${
              userHasPhone ? '_nofb' : ''
            }.modal.verify_form_text.title`,
            subText: t(
              'untrusted_unconfirmed_email_confirm.modal.verify_form_text.subtitle'
            ),
          },
        }
        break

      // ///////////////////////////////////////////////////////////////////// 7
      case 'untrusted_confirmed_email_upload_document':
        result = {
          title: t('untrusted_confirmed_email_upload_document.modal.title'),
          inltMessage: {
            text: untrustedConfirmedMessageByUserKind(),
          },
          footerText: {
            text: t(
              'untrusted_confirmed_email_upload_document.modal.disclaimer'
            ),
            link: t(
              'untrusted_confirmed_email_upload_document.modal.disclaimer_more'
            ),
            href: footerHref,
          },
          verifyForm: {
            text: addEmailByUserKind(),
            subText: t(
              'untrusted_confirmed_email_upload_document.modal.verify_form_text.subtitle'
            ),
          },
        }
        break

      // ///////////////////////////////////////////////////////////////////// 8
      case 'untrusted_unconfirmed_email_document_pending':
        result = {
          title: t('untrusted_unconfirmed_email_document_pending.modal.title'),
          inltMessage: {
            text: t(
              `untrusted_unconfirmed_email_document_pending${
                userHasPhone ? '_nofb' : ''
              }.modal.message`,
              { emailType: emailType }
            ),
            email: userHasPhone
              ? ''
              : userProEmail || proEmail || (emailExist && user.email),
          },
          footerText: {
            text: t(
              'untrusted_unconfirmed_email_document_pending.modal.disclaimer'
            ),
            link: t(
              'untrusted_unconfirmed_email_document_pending.modal.disclaimer_more'
            ),
            href: footerHref,
          },
          verifyForm: {
            text: `untrusted_unconfirmed_email_document_pending${
              userHasPhone ? '_nofb' : ''
            }.modal.verify_form_text.title`,
            subText: t(
              'untrusted_unconfirmed_email_document_pending.modal.verify_form_text.subtitle'
            ),
          },
        }
        break

      // ///////////////////////////////////////////////////////////////////// 9
      case 'document_rejected':
        result = {
          title: t('document_rejected.modal.title'),
          inltMessage: {
            text: documentRejectedMessageByUserKind(),
          },
          footerText: {
            text: t('document_rejected.modal.disclaimer'),
            link: t('document_rejected.modal.disclaimer_more'),
            href: footerHref,
          },
          verifyForm: {
            text: addEmailByUserKind(),
            subText: t('document_rejected.modal.verify_form_text.subtitle'),
          },
        }
        break

      // //////////////////////////////////////////////////////////////////// 10
      case 'unconfirmed_email_document_approved':
        result = {
          title: t('promo_unconfirmed_user_document_approved.title'),
          inltMessage: {
            text: t('unconfirmed_email_document_approved.content.text'),
            email: userProEmail,
          },
          footerText: {
            text: t('document_rejected.modal.disclaimer'),
            link: t('document_rejected.modal.disclaimer_more'),
            href: footerHref,
          },
          verifyForm: {
            text: 'untrusted_unconfirmed_email_confirm.modal.verify_form_text.title',
            subText: t('common:verify_form_text.subtitle_confirm'),
          },
        }
        break

      // //////////////////////////////////////////////////////////////////// 11
      case 'untrusted_confirmed_email_document_pending':
        result = {
          title: t('untrusted_unconfirmed_email_document_pending.modal.title'),
          inltMessage: {
            text: unconfimedDocumentPendingMessageByUserKind(),
          },
          footerText: {
            text: t(
              'untrusted_unconfirmed_email_document_pending.modal.disclaimer'
            ),
            link: t(
              'untrusted_unconfirmed_email_document_pending.modal.disclaimer_more'
            ),
            href: footerHref,
          },
          verifyForm: {
            text: addEmailByUserKind(),
            subText: t(
              'untrusted_confirmed_email_document_pending.modal.verify_form_text.subtitle'
            ),
          },
        }
        break

      default:
        result = {}
    }

    return result
  }

  render(): React.ReactElement {
    const { isMobile, user, t } = this.props
    const { modalType, newSession, isTrusted } = this.state
    const result = this.buildModalObj()
    const buttonText = newSession
      ? 'common:verification_modal.later_button_label'
      : 'common:verification_modal.done_button_label'

    const sideKickBtn = (
      <Button
        onClick={this.closeModalHandler}
        fill
        wide={!isMobile}
        whiteStroke
      >
        <>
          {modalType === 'unconfirmed_email_document_approved'
            ? t('common:verification_modal.later_button_label')
            : t(buttonText)}
        </>
      </Button>
    )
    const styles = {
      rightCol: !isMobile ? { paddingLeft: 15 } : { paddingTop: 15 },
    }
    const untrustedEmailNote =
      modalType !== 'trusted_email_confirm' &&
      modalType !== 'unconfirmed_email_document_approved'
    const hasDeepNote = untrustedEmailNote

    return (
      <MedshrModal
        className="fs-unmask"
        title={result.title}
        message={result.inltMessage}
        footer={result.footerText}
        sideKickBtn={sideKickBtn}
      >
        <section className="layout layout--tiny" style={{ marginBottom: 20 }}>
          <div className="layout__item u-6-of-12">
            <VerifyForm
              emailConfirmed={user.confirmed_email}
              title={result.verifyForm.text}
              subTitle={result.verifyForm.subText}
              untrustedEmails={untrustedEmailNote}
              deepnote={hasDeepNote}
            />
          </div>
          {(() => {
            if (
              !isTrusted &&
              modalType !== 'unconfirmed_email_document_approved' &&
              modalType !== 'trusted_email_confirm' &&
              modalType !== 'verified_pending_email_confirmation'
            ) {
              return (
                <div className="layout__item u-6-of-12">
                  {!isMobile && <Divider left vertical={!isMobile} />}
                  <div style={styles.rightCol}>
                    <div className="row">
                      <UploadVerifyImg photoType="proof" />
                    </div>
                  </div>
                </div>
              )
            }
          })()}
        </section>
      </MedshrModal>
    )
  }
}

function mapStateToProps(state: any): any {
  const modalStack = state.modal.newStack
  const modalID = modalStack.length ? modalStack[0].id : ''
  const user = selectAuthenticatedUser(state)
  const isTrusted = get(state, 'verification.isTrusted')

  return {
    user,
    proEmail: get(user, 'professional_email', ''),
    isMobile: selectIsMobile(state),
    modalType: get(state, 'activity.promo.type'),
    isConfirmationSent: get(state, 'verification.isLoaded', false),
    isDocumentLoaded: get(state, 'verification.isDocumentLoaded', false),
    emailState: get(state, 'verification.initialEmail'),
    isTrusted,
    modalID,
  }
}

function mapDispatchToProps(dispatch: Function): any {
  return {
    initDefaultLoaded: (): any => dispatch(initDefaultLoaded()),
    saveBackInitialEmail: (email: string): void =>
      dispatch(saveInitialEMail(email)),
    onCloseModal: (modalID: string): any => dispatch(modalClose(modalID)),
    loadAccount: (): any => dispatch(loadAccount()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VerifyModal))
