import { put, fork, take, all, call, select } from 'redux-saga/effects'

// ///////////////////////////////////////////////////////////// ACTIONS
import * as types from './actions/loadLikes'
import {
  getLikes,
  loadLikeSuccess,
  loadLikeFailed,
  seeMoreSuccess,
  seeMoreFailed,
} from './actions/loadLikes'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////// SAGAS

// ///////////////////////////////////////////////////////// FETCH LIKES
function* fetchLikes(
  caseId: string,
  commentId: string,
  page: number
): Generator {
  try {
    const response = yield call(getLikes, caseId, commentId, page)

    if (response) {
      yield put(loadLikeSuccess(response, { caseId, commentId }))
    }
  } catch (err) {
    yield put(loadLikeFailed(err))
  }
}

// ////////////////////////////////////////////////////// SEE MORE LIKES
function* seeMoreLikers(page: number, pages: number): Generator {
  try {
    const { currentLike }: any = yield select(
      (state: any): any => state.comments.likes
    )
    const nextPage = page + 1
    const response = yield call(
      getLikes,
      currentLike.caseId,
      currentLike.commentId,
      nextPage
    )
    if (response) {
      yield put(seeMoreSuccess(response))
    }
  } catch (err) {
    yield put(seeMoreFailed(err))
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////// WATCH SAGA

function* watchFetchLikes(): Generator {
  while (true) {
    const { caseId, commentId, page }: any = yield take(
      types.LOAD_LIKES_REQUEST
    )
    yield fork(fetchLikes, caseId, commentId, page)
  }
}

function* watchSeeMoreLikers(): Generator {
  while (true) {
    const { page, pages }: any = yield take(types.SEE_MORE_REQUEST)
    yield fork(seeMoreLikers, page, pages)
  }
}

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////ROOT SAGA
export default function* rootSaga(): Generator {
  return yield all([fork(watchFetchLikes), fork(watchSeeMoreLikers)])
}
