import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { concat, uniq } from 'ramda'

import * as search from './actions'
import { SEARCH_TYPE_MAIN } from './types'

const defaultState = {
  term: '',
  filter: {
    user: true,
    case: true,
    group: true,
    learning: true,
  },
  recentSearches: {},
}

const searchReducer = (state = defaultState, action) => {
  if (action.type === search.UPDATE_TERM) {
    return { ...state, term: action.term }
  }

  if (action.type === search.UPDATE_FILTER) {
    return { ...state, filter: action.filter }
  }

  if (isArray(state.recentSearches)) {
    state = {
      ...state,
      recentSearches: { [SEARCH_TYPE_MAIN]: state.recentSearches },
    }
  }

  if (action.type === search.RECENT_SAVE) {
    const recentSearches = {
      ...state.recentSearches,
      [action.searchType]: uniq(
        concat([action.term], get(state.recentSearches, action.searchType, []))
      ),
    }
    return { ...state, recentSearches }
  }

  if (action.type === search.RECENT_CLEAR) {
    const recentSearches = { ...state.recentSearches, [action.searchType]: [] }
    return { ...state, recentSearches }
  }

  return state
}

export default searchReducer
