import cs from 'classnames'
import React, { MouseEventHandler } from 'react'
import Image from 'next/image'

import Bronze from '/public/static/media/svg/awards/Level1_CL_Logo.svg'
import Silver from '/public/static/media/svg/awards/Level2_CL_Logo.svg'
import Gold from '/public/static/media/svg/awards/Level3_CL_Logo.svg'

enum AwardLevel {
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
}

const awardImageMap = {
  [AwardLevel.Gold]: Gold,
  [AwardLevel.Silver]: Silver,
  [AwardLevel.Bronze]: Bronze,
}

interface AwardBadgeType {
  award: string
  id?: string
  title?: string
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  width?: number
}

const AwardBadge: React.FC<AwardBadgeType> = ({
  award,
  id = 'icon-award-badge',
  title = 'Clinical Leader Award',
  className,
  onClick,
  width = 70,
}) => {
  const classes = cs(className, `icon-award-badge--${award}`)
  const levelSrc = awardImageMap[award]

  return (
    <div className="profile-badges">
      <Image
        id={id}
        alt={title}
        title={`${award} level`}
        src={levelSrc}
        className={classes}
        width={width}
        height={width}
        onClick={onClick}
        style={{ margin: 0 }}
      />
    </div>
  )
}

export default AwardBadge
