import React, { useRef } from 'react'
import { number } from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

type BadgeProps = {
  value: number
}

const Badge = ({ value }: BadgeProps) => {
  const ref = useRef()
  return (
    <TransitionGroup>
      <CSSTransition
        nodeRef={ref}
        classNames="badge-scale-transition"
        appear
        timeout={{ appear: 500, enter: 500, exit: 300 }}
      >
        {value > 0 ? (
          <span ref={ref} key="badge">
            <span className="badge">{value > 99 ? '99+' : value}</span>
          </span>
        ) : (
          <span ref={ref}></span>
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default Badge
