import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  id?: string
  title?: string
  className?: string
  onClick?: Function
}

const PlusCircle = ({ id, title, className, onClick }: Props): any => {
  return (
    <Icon
      id={id ?? 'plus-circle'}
      sourceWidth={18}
      sourceHeight={18}
      title={title ?? 'Add'}
      className={className}
      onClick={onClick}
    >
      <g strokeWidth="1.25" fill="none">
        <path d="M9,13 L9,5" strokeLinecap="round" />
        <path d="M13,9 L5,9" strokeLinecap="round" />
        <path d="M17,9 C17,13.4185 13.418,17 9,17 C4.582,17 1,13.4185 1,9 C1,4.5815 4.582,1 9,1 C13.418,1 17,4.5815 17,9 L17,9 Z" />
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default PlusCircle
