import { delay, put, fork, call, all, take } from 'redux-saga/effects'

import { generateUUID } from '../../lib/uuid'
import * as actions from './actions'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////// SAGAS
function* notifyRoutine({ duration, notification }: any) {
  try {
    const showAction = yield call(generateShowAction, duration, notification)
    yield put(showAction)
    yield delay(showAction.duration)
    yield put({
      type: actions.DISMISS,
      id: showAction.id,
    })
  } catch (err) {
    throw err
  }
}

// /////////////////////////////////////////////////////////////////// FUNCTIONS
function generateShowAction(duration: number, notification: string): any {
  return {
    duration: duration || 7000,
    notification: notification,
    id: generateUUID(),
    type: actions.SHOW,
  }
}

// //////////////////////////////////////////////////////////////////// WATCHERS
// /////////////////////////////////////////////////////////////////////////////
function* watchNotify() {
  while (true) {
    const { duration, notification } = yield take(actions.NOTIFY)
    yield fork(notifyRoutine, { duration, notification })
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default function* rootSaga() {
  return yield all([fork(watchNotify)])
}
