import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import * as lookup from './lookup'

function getRole(categories, kind) {
  return (
    kind &&
    categories.roles &&
    lookup.getCategoryFromList(kind, categories.roles)
  )
}

function getSeniority(categories, kind, seniority) {
  return (
    seniority &&
    categories.seniorities[kind] &&
    lookup.getCategoryFromList(seniority, categories.seniorities[kind])
  )
}

export function getKindLabel(member, categories) {
  let category
  if (
    member &&
    member.kind &&
    !isEmpty(categories) &&
    (category = lookup.getCategoryFromList(member.kind, categories.roles))
  ) {
    return category.label
  }
}

export function getRoleTitle(member, categories, specialties) {
  let labels = []
  let category
  if (member) {
    if (
      member.kind === 'student' &&
      (category = getSeniority(categories, member.kind, member.seniority))
    ) {
      return category.label
    } else if (member.kind && !isEmpty(categories)) {
      if (
        (category = getSeniority(categories, member.kind, member.seniority))
      ) {
        labels.push(category.label)
      } else if (
        (category = lookup.getCategoryFromList(member.kind, categories.roles))
      ) {
        labels.push(category.label)
      }

      if (
        member.kind !== 'nurse' &&
        member.kind !== 'professional' &&
        labels.length > 0
      ) {
        if (
          member.specialty &&
          member.specialty.length > 0 &&
          (category = specialties.getLabelFor(
            specialties.findPrimary(member.specialty)
          ))
        ) {
          if (
            !labels.length ||
            labels[0].substring(category.length) !== category
          ) {
            labels.push(category)
          }
        }
      }

      return labels.join(', ')
    }
    return member.job_title
  }
  return ''
}

export function getRoleOptions(categories) {
  return map(categories.roles, category => {
    return [category.label, category.id]
  })
}

export function getSignupRoleOptions(categories) {
  return map(categories.signup_roles ?? categories.roles, category => {
    return [category.label, category.id]
  })
}

export function getSeniorityOptionsByRole(categories) {
  return mapValues(categories.seniorities, seniorities => {
    return map(seniorities, category => {
      return [category.label, category.id]
    })
  })
}

export function getSeniorityGroupOptions(categories) {
  return map(categories.seniority_groups, category => {
    return [category.label, category.id]
  })
}

export function getSeniorityGroupLabels(categories, ids) {
  if (
    categories.seniority_groups &&
    categories.seniority_groups.length === ids.length
  ) {
    return ['Everyone']
  }
  return map(ids, id => getSeniorityGroupLabel(categories, id))
}

export function getSeniorityGroupLabel(categories, id) {
  const category = find(
    categories.seniority_groups,
    category => category.id == id
  )
  if (category) {
    return category.label
  }
}

const getSeniorityOrRoleCategory = (categories, kind, seniority) => {
  return (
    (seniority && getSeniority(categories, kind, seniority)) ||
    getRole(categories, kind)
  )
}

export const generateJobTitleForRole = (
  categories,
  specialties,
  user_specialties,
  kind,
  seniority = undefined
) => {
  let category

  if (kind === 'student' && seniority === 'dental') {
    category = getSeniority(categories, kind, seniority)
  } else if (kind === 'dentist') {
    category = getSeniorityOrRoleCategory(categories, kind, seniority)
  } else if (kind === 'nurse' || kind === 'student') {
    category = getRole(categories, kind)
  } else if (kind === 'doctor') {
    category = getSeniorityOrRoleCategory(categories, kind, seniority)
    if (seniority && seniority !== 'gp') {
      const specialty = specialties.findPrimary(user_specialties)
      if (specialty) {
        return category.label + ', ' + specialties.getLabelFor(specialty)
      }
    }
  }

  return category ? category.label : ''
}
